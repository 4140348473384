import { Component, OnInit } from '@angular/core';
import { DesignerBranch, StoreEnvironment } from '../../../schema-dotnet';
import { DesignerReleaseChannelService } from '../../services/designer-release-channel.service';
import { MatDialogRef } from '@angular/material/dialog';

export interface CreateStoreDialogResult {
    label: string;
    environment: StoreEnvironment;
    designerReleaseChannel: string;
    appId?: string;
}

@Component({
    templateUrl: './create-store-dialog.component.html',
    styleUrls: ['./create-store-dialog.component.scss']
})
export class CreateStoreDialogComponent implements OnInit {
    label: string;
    environment: StoreEnvironment = StoreEnvironment.Production;
    designerReleaseChannel: string = '';
    environments: string[] = [];
    appId?: string;
    designerReleaseChannels: DesignerBranch[] = [];

    constructor(
        private readonly dialogRef: MatDialogRef<CreateStoreDialogComponent, CreateStoreDialogResult>,
        private readonly designerReleaseChannelService: DesignerReleaseChannelService
    ) {
    }

    ngOnInit(): void {
        this.designerReleaseChannelService.getDesignerReleaseChannels().subscribe((designerReleaseChannels) => {
            this.designerReleaseChannels = designerReleaseChannels;
        });
        for (let storeEnvironment in StoreEnvironment) {
            this.environments.push(storeEnvironment);
        }
    }

    valid() {
        this.dialogRef.close({
            label: this.label,
            appId: this.appId,
            environment: this.environment,
            designerReleaseChannel: this.designerReleaseChannel
        })
    }
}
