<mat-card matRipple (click)="selectSubscription.next(subscription)">
    <mat-card-header>
        <mat-card-title>
            {{subscription.subscriptionLabel || subscription.subscriptionId}}
        </mat-card-title>
        <mat-card-subtitle>
            <span class="subscription-id">{{subscription.subscriptionId}}</span>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
    </mat-card-content>
</mat-card>
