import { NgModule } from '@angular/core';

import { AdminLetDirective } from './let/let.component';
import { AutoTrimDirective } from './auto-trim.directive';

@NgModule({
    declarations: [AdminLetDirective, AutoTrimDirective],
    exports: [AdminLetDirective, AutoTrimDirective],
})
export class AdminDirectivesModule {}
