import {Component, OnInit} from '@angular/core';
import {Subscription, SubscriptionPermission, SubscriptionRole, SubscriptionUserRole} from '../../../schema-dotnet';
import {AddAuthorizedUserDialogComponent, AddAuthorizedUserDialogData, AddAuthorizedUserDialogResult} from '../authorized-users-page/add-authorized-user-dialog.component';
import {PageStatus} from '../../models';
import {forkJoin} from 'rxjs';
import {AuthorizationContext, AuthorizationService, AuthorizedUsersService, SubscriptionsService} from '../../services';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog-service';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {ActivatedRoute} from '@angular/router';

@Component({
    templateUrl: './subscription-authorized-user-page.component.html',
    styleUrls: ['./subscription-authorized-user-page.component.scss']
})
export class SubscriptionAuthorizedUserPageComponent extends AdminComponentBase implements OnInit {
    public subscriptionRoles: SubscriptionRole[] = [];

    public subscriptionId: string;
    public subscriptionWithAuthorizedUsers: Subscription;
    public authContext: AuthorizationContext;

    constructor(
        private readonly authorizedUsersService: AuthorizedUsersService,
        private readonly authorizationService: AuthorizationService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.subscriptionId = params.subscriptionId;
                this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.authorizedUsersService.getAvailableRoles(),
                    this.subscriptionsService.getAuthorizedUsers(this.subscriptionId)
                ]), ([availableRoles, subscriptionWithAuthorizedUsers]) => {
                    this.subscriptionWithAuthorizedUsers = subscriptionWithAuthorizedUsers;
                    this.subscriptionRoles = availableRoles.subscriptionRoles;
                });
            }));
    }

    canManageUsers(subscriptionId: string) {
        return this.authContext.hasSubscriptionPermissions(subscriptionId, SubscriptionPermission.ManageAdminUsers);
    }

    openAddSubscriptionRole(subscription: Subscription, user?: SubscriptionUserRole) {
        const dialogRef = this.dialog.open<AddAuthorizedUserDialogComponent<SubscriptionRole>, AddAuthorizedUserDialogData<SubscriptionRole>, AddAuthorizedUserDialogResult<SubscriptionRole>>(AddAuthorizedUserDialogComponent, {
            data: {
                email: user?.user?.email,
                target: {
                    availableRoles: this.subscriptionRoles.filter(role => !user || !user.roles.includes(role)),
                    label: subscription.label || subscription.id
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.pageStatus = PageStatus.saving;
            this.authorizedUsersService.grantUserRoleOnSubscription({
                email: result.email,
                subscriptionAccess: {id: subscription.id, roles: [result.role]}
            }).subscribe({
                next: (user) => {
                    this.pageStatus = PageStatus.loaded;
                    let existingUser = subscription.authorizedUsers.find(e => e.user?.email === user.email);
                    if (existingUser) {
                        existingUser.roles.push(result.role);
                    } else {
                        subscription.authorizedUsers.push({
                            user,
                            roles: [result.role]
                        });
                    }
                },
                error: err => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = err;
                }
            });
        });
    }

    removeSubscriptionRole(subscription: Subscription, email: string, role: SubscriptionRole) {
        this.confirmDialogService.confirm('Remove role', `Remove the role <code>${role}</code> for the user <code>${email}</code>?`, undefined, {
            yes: {
                label: 'Remove role',
                warn: true
            }
        }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email,
                subscriptions: [{id: subscription.id, roles: [role]}]
            }), () => {
                let user = subscription.authorizedUsers.find(e => e.user?.email == email);
                if (user) {
                    let roleIndex = user.roles.indexOf(role);
                    if (roleIndex !== -1) {
                        user.roles.splice(roleIndex, 1);
                    }
                }
            });
        });
    }
}
