import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageStatus} from '../../models';
import {OrdersService} from '../../services';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Order} from '../../../schema-dotnet';

@Component({
    selector: 'admin-recent-order-page',
    templateUrl: './recent-order-page.component.html',
    styleUrls: ['./recent-order-page.component.scss']
})
export class RecentOrderPageComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    public pageStatus: PageStatus = PageStatus.loading;
    public pageError?: Error;
    public orders: Order[] = [];
    public columnsToDisplay: string[] = ['id', 'dateCreatedUtc'];

    constructor(
        private readonly orderService: OrdersService,
        private readonly route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.subscription.add(this.route.params.subscribe(params => {
                this.orderService.getRecentOrders(params['storeId']).subscribe({
                    next: orders => {
                        this.orders = orders;
                        this.pageStatus = PageStatus.loaded;
                    },
                    error: error => {
                        this.pageError = error;
                        this.pageStatus = PageStatus.loadError;
                    }
                });
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    order$(order: any): Order {
        return order as Order;
    }
}
