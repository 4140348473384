@if (kind === 'Generic') {
  <div class="endpoint-with-headers">
    <h3>Http Headers</h3>
    <span>Additional headers to add when the endpoint is called</span>
    <div class="properties-container">
      @for (header of configs.headers; track header; let i = $index) {
        <div class="property-container"
          >
          <mat-form-field>
            <mat-label>Header Name</mat-label>
            <input matInput
              [(ngModel)]="header.name"
              [name]="'configsHeaderName' + i"
              pattern="^[^: ]+$"
              required
              autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Header Value</mat-label>
            <input matInput
              [type]="header.isSecret ? 'password' : 'text'"
              [(ngModel)]="header.value"
              [name]="'configsHeaderValue' + i"
              required
              autocomplete="off">
          </mat-form-field>
          <mat-checkbox
            [(ngModel)]="header.isSecret"
            [name]="'configsHeaderIsSecret' + i">
            Secret
          </mat-checkbox>
          <button mat-icon-button
            (click)="removeProperty(configs.headers, i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      }
      <button mat-stroked-button
        (click)="addProperty(configs.headers)"
        class="add-header">
        <mat-icon>add</mat-icon>
        Add header
      </button>
    </div>
  </div>
  <div class="properties-container">
    <h3>Editor Context</h3>
    @for (property of configs.editorParameters; track property; let i = $index) {
      <div class="property-container"
        >
        <mat-form-field>
          <mat-label>Property Name</mat-label>
          <input matInput
            [(ngModel)]="property.name"
            [name]="'generatedImageDefinition.configsEditorPropertyName' + i"
            required
            autocomplete="off">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Property Value</mat-label>
          <input matInput
            [type]="property.isSecret ? 'password' : 'text'"
            [(ngModel)]="property.value"
            [name]="'generatedImageDefinition.configsEditorPropertyValue' + i"
            required
            autocomplete="off">
        </mat-form-field>
        <mat-checkbox
          [name]="'generatedImageDefinition.configsEditorPropertyisSecret' + i"
          [(ngModel)]="property.isSecret">
          Secret
        </mat-checkbox>
        <button mat-icon-button
          (click)="removeProperty(configs.editorParameters,i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    }
    <button mat-stroked-button
      (click)="addProperty(configs.editorParameters)"
      class="add-property">
      <mat-icon>add</mat-icon>
      Add property
    </button>
  </div>
  <div class="properties-container">
    <h3>Additional properties</h3>
    <span>A list of properties to include in the request body</span>
    @for (property of configs.additionalProperties; track property; let i = $index) {
      <div class="property-container"
        >
        <mat-form-field>
          <mat-label>Property Name</mat-label>
          <input matInput
            [(ngModel)]="property.name"
            [name]="'configsAddPropertyName' + i"
            required adminAutoTrim
            autocomplete="off">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Property Value</mat-label>
          <input matInput
            [type]="property.isSecret ? 'password' : 'text'"
            [(ngModel)]="property.value"
            [name]="'configsAddPropertyValue' + i"
            required adminAutoTrim
            autocomplete="off">
        </mat-form-field>
        <mat-checkbox
          [name]="'configsAddPropertyIsSecret' + i"
          [(ngModel)]="property.isSecret">
          Secret
        </mat-checkbox>
        <button mat-icon-button
          (click)="removeProperty(configs.additionalProperties,i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    }
    <button mat-stroked-button
      (click)="addProperty(configs.additionalProperties)"
      class="add-property">
      <mat-icon>add</mat-icon>
      Add property
    </button>
  </div>
}
@if (kind === GeneratedImageKind.MapBox) {
  <mat-form-field>
    <mat-label>Mapbox Access Token</mat-label>
    <input matInput
      #secretInput
      name="mapBoxAccessKey"
      [ngModel]="mapBoxAccessKey"
      (ngModelChange)="mapBoxAccessKeyChange.emit($event)"
      [required]="!isDefinition"
      adminAutoTrim
      autocomplete="off">
    <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
    <mat-hint>You can find this at <a href="https://account.mapbox.com" target="_blank" class="external">https://account.mapbox.com
      <mat-icon>open_in_new</mat-icon>
    </a></mat-hint>
  </mat-form-field>
}
