import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface LinkFulfillerAppDialogResult {
    appId: string;
}

@Component({
    templateUrl: './link-fulfiller-app-dialog.component.html',
    styleUrls: ['./link-fulfiller-app-dialog.component.scss']
})
export class LinkFulfillerAppDialogComponent {
    fulfillerInfo: LinkFulfillerAppDialogResult = {
        appId: ''
    };

    constructor(
        public dialogRef: MatDialogRef<LinkFulfillerAppDialogResult>
    ) {
    }

    valid() {
        this.dialogRef.close(this.fulfillerInfo);
    }
}
