import { Pipe, PipeTransform } from '@angular/core';

const regex = /<[^>]+>/gm;

@Pipe({ name: 'removeHtml' })
export class RemoveHtmlPipe implements PipeTransform {
    transform(value: string, notice: string): string {
        if (typeof value !== 'string') {
            return <any>value;
        }

        const result = value.replace(regex, '');

        return result === value ? value : `${result} ${notice}`;
    }
}
