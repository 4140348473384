import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MediaclipMutationSetRemoteFilterThumbnailArgs, RemoteFilter, SetRemoteFilterThumbnailInput } from 'schema-dotnet';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from './graph-dotnet-service';

@Injectable({
    providedIn: 'root'
})
export class RemoteFilterService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    setRemoteFilterThumbnail(input: SetRemoteFilterThumbnailInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationSetRemoteFilterThumbnailArgs>(
            `mutation setRemoteFilterThumbnail($input: SetRemoteFilterThumbnailInput!) {
                setRemoteFilterThumbnail(input: $input)
            }`, {
                input: input
            }).pipe(map(result => result.setRemoteFilterThumbnail));
    }

    loadRemoteFilters(input: string): Observable<RemoteFilter[]> {
        return this.graphDotnetService.query(
            `query LoadStoreRemoteFilters($storeId: String!) {
            store(id: $storeId) {
              id
              remoteFilters {
                id
                name
                alias
                group
                visible
                order
                kind
                urlThumb
                data {
                  ... on GenericRemoteFilterData {
                    url
                    headers {
                      name
                      value
                    }
                  }
                  ... on PerfectlyClearRemoteFilterData {
                    apiKey
                    customPreset
                  }
                  ... on PicsartRemoveBackgroundRemoteFilterData {
                    apiKey
                    outputType
                    backgroundImageUrl
                    backgroundColor
                    backgroundBlur
                    scale
                    format
                  }
                  ... on PicsartEffectsRemoteFilterData {
                    apiKey
                    effect
                  }
                  ... on PicsartStyleTransferRemoteFilterData {
                    apiKey
                    level
                    referenceImageUrl
                  }
                }
              }
            }
          }`, {storeId:input}
        ).pipe(map(result => result.store.remoteFilters))
    }
}
