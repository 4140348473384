import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AdminComponentsModule } from '../components/components.module';
import { FuseSharedModule } from '../../@fuse/shared.module';
import { PageSimpleContentComponent } from './page-simple-content/page-simple-content.component';

@NgModule({
    declarations: [PageSimpleContentComponent],
    imports: [
        CommonModule,
        RouterModule,

        FuseSharedModule,
        MatIconModule,
        AdminComponentsModule,
        MatProgressSpinnerModule,
    ],
    exports: [PageSimpleContentComponent],
})
export class LayoutsModule {}
