import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fromNow' })
export class FromNowPipe implements PipeTransform {
    transform(value: Date | string | number): string {
        return timeFromNow(value);
    }
}

const rtf = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' });

const minuteDuration = 60;
const hourDuration = 60 * minuteDuration;
const dayDuration = 24 * hourDuration;
const monthDuration = 31 * dayDuration;
const yearDuration = 365 * dayDuration;

// Based on https://codepen.io/cferdinandi/pen/Vwwbpyr but using Intl.RelativeTimeFormat
function timeFromNow(time: Date | string | number): string {

    // Get timestamps
    let unixTime = new Date(time).getTime();
    if (!unixTime) {
        return '';
    }
    let now = new Date().getTime();

    let difference = (unixTime / 1000) - (now / 1000);

    let absDiff = Math.abs(difference);

    if (absDiff > yearDuration) {
        return rtf.format(Math.floor(difference / yearDuration), 'year')
    } else if (absDiff > monthDuration) {
        return rtf.format(Math.floor(difference / monthDuration), 'months')
    } else if (absDiff > dayDuration) {
        return rtf.format(Math.floor(difference / dayDuration), 'day')
    } else if (absDiff > hourDuration) {
        return rtf.format(Math.floor(difference / hourDuration), 'hour')
    } else if (absDiff > minuteDuration) {
        return rtf.format(Math.floor(difference / minuteDuration), 'minute')
    } else {
        return rtf.format(Math.floor(difference), 'second')
    }
}
