<h2 mat-dialog-title>Edit subscription information</h2>
<div mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput
                   cdkFocusInitial
                   name="label"
                   [(ngModel)]="result.label"
                   required
                   (keydown.enter)="editSubscription()"
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Contact</mat-label>
            <input matInput
                   placeholder="John Doe <john.doe@example.org>"
                   [pattern]="contactPattern"
                   name="contact"
                   [(ngModel)]="result.contact"
                   required
                   (keydown.enter)="editSubscription()" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Notes</mat-label>
            <textarea matInput
                      name="notes"
                      [(ngModel)]="result.notes"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="4"></textarea>
        </mat-form-field>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button color="primary"
            [disabled]="form.invalid"
            (click)="editSubscription()">
        OK
    </button>
</mat-dialog-actions>
