import {Component, OnInit, ViewChild} from '@angular/core';
import {GlobalPermission, Subscription, SubscriptionPermission} from 'schema-dotnet';
import {fuseAnimations} from '@fuse/animations';
import {PageStatus} from '../../models';
import {AuthorizationContext, AuthorizationService, SubscriptionsService} from '../../services';
import {MatTable} from '@angular/material/table';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {MatDialog} from "@angular/material/dialog";
import {AddSubscriptionDialogComponent, AddSubscriptionDialogResult} from "./add-subscription-dialog.component";

@Component({
    selector: 'admin-subscriptions-page',
    templateUrl: './subscriptions-page.component.html',
    styleUrls: ['./subscriptions-page.component.scss'],
    animations: fuseAnimations
})
export class SubscriptionsPageContainerComponent extends AdminComponentBase implements OnInit {
    @ViewChild('subscriptionTable', { static: false })
    subscriptionTable: MatTable<any>;

    subscriptions: Subscription[] = [];
    displayedSubscription: Subscription[] = [];
    authContext: AuthorizationContext;

    filter: {
        name?: string;
        showBillableOnly: boolean;
        showActiveOnly: boolean,
    } = {
        showActiveOnly: true,
        showBillableOnly: false
    };
    displayedColumns: string[] = [
        'label',
        'contact',
        'billable',
        'storeCount'
    ];

    constructor(
        private readonly subscriptionsService: SubscriptionsService,
        private readonly authorizationService: AuthorizationService,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        const savedFilter = localStorage.getItem('subscriptions-page-filter');
        if (savedFilter) {
            this.filter = { ...this.filter, ...JSON.parse(savedFilter) as any };
        }
        this.subscriptionsService.list().subscribe({
            next: subscriptions => {
                this.subscriptions = subscriptions;
                this.pageStatus = PageStatus.loaded;
                this.updateDisplayedSubscription();
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        });
    }

    updateDisplayedSubscription() {
        this.displayedSubscription = this.subscriptions
            .filter(s => this.isSubscriptionVisible(s))
            .sort((a, b) => a.label.localeCompare(b.label));
        this.subscriptionTable.renderRows();
    }

    toggleShowActiveOnly() {
        this.filter.showActiveOnly = !this.filter.showActiveOnly;
        this.updateFilter();
    }

    toggleShowBillableOnly() {
        this.filter.showBillableOnly = !this.filter.showBillableOnly;
        this.updateFilter();
    }

    public updateFilter() {
        localStorage.setItem('subscriptions-page-filter', JSON.stringify({ ...this.filter, name: undefined }));
        this.updateDisplayedSubscription();
    }

    private isSubscriptionVisible(s: Subscription) {
        if (this.filter.showBillableOnly) {
            if (!s.billing?.billingStartDateUtc) {
                return false;
            }
        }
        if (this.filter.showActiveOnly) {
            if (!s.active) {
                return false;
            }
        }
        if (this.filter.name) {
            if (!s.label.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return false;
            }
        }
        return true;

    }

    subscriptionId(i: number, s: Subscription) {
        return s.id;
    }

    canReadInvoice(subscription: Subscription): boolean {
        return this.authContext.hasSubscriptionPermissions(subscription.id, SubscriptionPermission.ReadInvoices);
    }

    $subscription(subscription: any): Subscription {
        return subscription as Subscription;
    }

    canCreateSubscriptions() {
        return this.authContext.hasGlobalPermissions(GlobalPermission.CreateSubscription);
    }

    reload(){
        this.subscribeWithGenericLoadingErrorHandling(this.subscriptionsService.list(), subscriptions => {
            this.subscriptions = subscriptions;
        });
    }
    addSubscriptions() {
        this.dialog.open<AddSubscriptionDialogComponent, void, AddSubscriptionDialogResult>(AddSubscriptionDialogComponent).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.subscriptionsService.createSubscription({
                label: result.subscriptionLabel,
            }), (response) => {
                this.subscriptions.push(response);
                this.updateDisplayedSubscription()
            });
        });
    }
}
