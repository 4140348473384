import {NgModule} from '@angular/core';

import {AdminCurrency} from './admin-currency.pipe';
import {FromNowPipe} from './from-now.pipe';
import {LinkifyPipe} from './linkify.pipe';
import {RemoveHtmlPipe} from './remove-html.pipe';
import {TruncatePipe} from './truncate.pipe';

@NgModule({
    declarations: [
        FromNowPipe,
        LinkifyPipe,
        RemoveHtmlPipe,
        AdminCurrency,
        TruncatePipe
    ],
    exports: [
        FromNowPipe,
        LinkifyPipe,
        RemoveHtmlPipe,
        AdminCurrency,
        TruncatePipe
    ],
})
export class AdminPipesModule {
}
