<div id="main">

  <!-- TOOLBAR: Above -->
  @if (fuseConfig.layout.toolbar.position === 'above') {
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  }
  <!-- / TOOLBAR: Above -->

  <div id="container-1" class="container">

    <!-- NAVBAR: Left -->
    @if (fuseConfig.layout.navbar.position === 'left') {
      <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
    }
    <!-- / NAVBAR: Left -->

    <div id="container-2" class="container">

      <!-- TOOLBAR: Below fixed -->
      @if (fuseConfig.layout.toolbar.position === 'below-fixed') {
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      }
      <!-- / TOOLBAR: Below fixed -->

      <div id="container-3" class="container" fusePerfectScrollbar
        [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

        <!-- TOOLBAR: Below static -->
        @if (fuseConfig.layout.toolbar.position === 'below-static') {
          <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        }
        <!-- / TOOLBAR: Below static -->

        <!-- CONTENT -->
        <content></content>
        <!-- / CONTENT -->

        <!-- FOOTER: Below static -->
        @if (fuseConfig.layout.footer.position === 'below-static') {
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        }
        <!-- / FOOTER: Below static -->

      </div>

      <!-- FOOTER: Below fixed -->
      @if (fuseConfig.layout.footer.position === 'below-fixed') {
        <ng-container *ngTemplateOutlet="footer"></ng-container>
      }
      <!-- / FOOTER: Below fixed -->

    </div>

    <!-- NAVBAR: Right -->
    @if (fuseConfig.layout.navbar.position === 'right') {
      <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
    }
    <!-- / NAVBAR: Right -->

  </div>

  <!-- FOOTER: Above -->
  @if (fuseConfig.layout.footer.position === 'above') {
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  }
  <!-- FOOTER: Above -->

</div>

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel" (openedChanged)="quickPanelOpenedChanged($event)">
  <quick-panel [opened]="quickPanelOpened$ | async"></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
  @if (!fuseConfig.layout.toolbar.hidden) {
    <toolbar
      [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background : fuseConfig.layout.toolbar.position">
    </toolbar>
  }
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
  @if (!fuseConfig.layout.footer.hidden) {
    <footer
      [ngClass]="fuseConfig.layout.footer.customBackgroundColor === true ? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background : fuseConfig.layout.footer.position">
    </footer>
  }
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
  @if (!fuseConfig.layout.navbar.hidden) {
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar"
      [folded]="fuseConfig.layout.navbar.folded"
      [lockedOpen]="lockedOpen"
      >
      <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
  }
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
  @if (!fuseConfig.layout.navbar.hidden) {
    <fuse-sidebar name="navbar" position="right" class="navbar-fuse-sidebar"
      [folded]="fuseConfig.layout.navbar.folded"
      [lockedOpen]="lockedOpen"
      >
      <navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
    </fuse-sidebar>
  }
</ng-template>
<!-- / RIGHT NAVBAR -->
