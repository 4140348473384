import { Component } from '@angular/core';

@Component({
  templateUrl: './store-fulfiller-enable-log-dialog.component.html',
  styleUrls: ['./store-fulfiller-enable-log-dialog.component.scss']
})
export class StoreFulfillerEnableLogDialogComponent {
    duration: number = 7;

}
