@if (pageStatus === 'saveError' && pageError) {
  <admin-error [error]="pageError"></admin-error>
}

<div class="header">
  <div class="actions">
    @if (selectedFile) {
      <button mat-stroked-button
        color="warn"
        (click)="deleteFile(selectedFile)"
        [disabled]="pageStatus === 'saving'">
        <mat-icon>delete</mat-icon>
        Delete file
      </button>
    }
    @if (selectedFile) {
      <input #singleFileUploader
        type="file"
        (change)="uploadSelectedFileContent(selectedFile.filename, $any($event.target).files)"
        [accept]="acceptedUploadFileTypes"
        style="display: none;" />
      <button mat-stroked-button (click)="singleFileUploader.click()">
        <mat-icon>upload</mat-icon>
        Upload...
      </button>
    }
    @if (selectedFile) {
      <admin-save-button [pageStatus]="pageStatus" (save)="save(selectedFile)" useContent="true" [disabled]="!isFileModified(selectedFile)">
        <mat-icon>upload</mat-icon>&nbsp;
        Save File
      </admin-save-button>
    }
    <admin-save-button [pageStatus]="pageStatus" (save)="saveAll()" useContent="true" [disabled]="!isFileNotSaved()">
      <mat-icon>save</mat-icon>&nbsp;
      Save All
    </admin-save-button>
  </div>
</div>

<div class="files">
  <div class="file-list">
    <mat-nav-list>
      @for (file of generatedImageDefinition.fileEditorInformation; track file) {
        <a mat-list-item
          [activated]="selectedFile === file"
          (click)="selectFile(file)">{{file.filename}}@if (isFileModified(file)) {
          *
        }</a>
      }
    </mat-nav-list>
    <div class="actions">
      <button mat-stroked-button
        (click)="addNewFile()">
        <mat-icon>add</mat-icon>
        Add file
      </button>
      <input #multipleFilesUploader type="file"
        multiple="multiple"
        (change)="uploadFromFiles($any($event.target).files)"
        [accept]="acceptedUploadFileTypes"
        style="display: none;" />
      <button mat-stroked-button (click)="multipleFilesUploader.click()">
        <mat-icon>upload</mat-icon>
        Upload files...
      </button>
    </div>
  </div>
  @if (selectedFile !== undefined) {
    <div
      class="file-editor">
      @if (isTextEditableFile(selectedFile)) {
        <div class="editor">
          <ngx-codemirror [(ngModel)]="fileContents[selectedFile.filename]"
                            [options]="{
                                lineNumbers: true,
                                matchBrackets: true,
                                lint: true,
                                scroll: 'overlay',
                                theme: 'material',
                                mode: this.fileMimeTypes[selectedFile.filename]
                        }"></ngx-codemirror>
        </div>
      }
      @if (isImage(selectedFile)) {
        <img [src]="selectedFile.url" [alt]="'Preview of the file ' + selectedFile.filename">
      }
      @if (pageStatus === 'loadError' && pageError) {
        <admin-error [error]="pageError"></admin-error>
      }
    </div>
  } @else {
    <span class="no-selected-file">Select a file to edit it</span>
  }


</div>
