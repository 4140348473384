import {Injectable} from '@angular/core';
import {GeneratedImageData, GeneratedImageConfigProperty} from '../../../schema-dotnet';

@Injectable({
    providedIn: 'root'
})
export class GeneratedImageConfigUtil {

    constructor() {
    }

    updateMapBoxApiKey(configs: GeneratedImageData, mapBoxAccessKey: string | undefined) {
        this.updateProperty(configs.additionalProperties, 'MapBoxAccessKey', mapBoxAccessKey);
        this.updateProperty(configs.editorParameters, 'accessToken', mapBoxAccessKey);
    }

    updateProperty(properties: GeneratedImageConfigProperty[], key: string, value?: string) {
        let currentValueIndex = properties.findIndex(x => x.name === key);
        if (value) {
            let updatedProperty = {name: key, value: value, isSecret: false};
            if (currentValueIndex !== -1) {
                properties[currentValueIndex] = updatedProperty;
            } else {
                properties.push(updatedProperty);
            }
        } else if (currentValueIndex !== -1) {
            properties.splice(currentValueIndex, 1);
        }
    }

    getMapBoxAccessToken(configs: GeneratedImageData) {
        return configs?.additionalProperties.find(x => x.name === 'MapBoxAccessKey')?.value;
    }
}
