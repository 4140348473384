import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { PageStatus } from '../../models';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationContext, AuthorizationService } from '../../services';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';
import { PhotosService } from '../../services/photos.service';

@Component({
    templateUrl: './photo-page.component.html',
    styleUrls: ['./photo-page.component.scss']
})
export class PhotoPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    photoUrn: string;
    authContext: AuthorizationContext;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authorizationService: AuthorizationService,
        private readonly photosService: PhotosService,
        private readonly confirmDialogService: ConfirmDialogService
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.photoUrn = params.photoUrn;
            })
        );
        this.setPageStatus(PageStatus.loaded);
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    reEncodeImage(urn: string) {
        this.confirmDialogService
            .confirm('Re-Encode Photo', 'Do you really want to re-encode this photo?',
                'This should only be used if this photo cannot be rendered by the production server', {
                    yes: {
                        warn: true,
                        label: 'Re-Encode'
                    },
                    no: {
                        label: 'Cancel'
                    }
                })
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.photosService.reEncodePhoto(urn));
            });
    }

    canManagePhoto() {
        // FIXME: When we'll load photo info we can check this
        // this.authContext.hasStorePermissions(, , StorePermission.ManagePhotos);
        return true;
    }
}
