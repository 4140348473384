import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FulfillerClient } from '../../../schema-dotnet';

export interface AddFulfillerDialogResult {
    fulfillerId: string;
    client: FulfillerClient;
}

@Component({
    templateUrl: './add-fulfiller-dialog.component.html',
    styleUrls: ['./add-fulfiller-dialog.component.scss']
})
export class AddFulfillerDialogComponent {
    fulfillerInfo: AddFulfillerDialogResult = {
        fulfillerId: '',
        client: FulfillerClient.Default
    };

    clients = Object.values(FulfillerClient);

    constructor(
        public dialogRef: MatDialogRef<AddFulfillerDialogComponent>
    ) {
    }

    valid() {
        this.dialogRef.close(this.fulfillerInfo);
    }
}
