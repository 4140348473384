import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { Order, Store } from '../../../schema-dotnet';
import { OrdersService, StoreFulfillersService } from '../../services';

@Component({
    templateUrl: './create-order.component.html',
    styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent extends AdminComponentBase implements OnInit, OnDestroy {
    storeWithFulfiller?: Store;
    createOrderRequest?: CreateOrderRequest;
    checkoutJson: string = '';
    createdOrder?: Order;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly storeFulfillersService: StoreFulfillersService,
        private readonly ordersService: OrdersService
    ) {
        super();
    }

    ngOnInit() {
        this.registerSubscription(this.route.params.subscribe(param => {
            let storeId = param.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeFulfillersService.getStoreFulfillers(storeId), storeWithFulfiller => {
                this.storeWithFulfiller = storeWithFulfiller;
                this.updatePreview();
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    updatePreview() {
        try {
            this.createOrderRequest = JSON.parse(this.checkoutJson);
        } catch (e) {
            this.createOrderRequest = undefined;
        }
    }

    createOrder() {
        if (!this.storeWithFulfiller) {
            return;
        }

        this.createdOrder = undefined;
        this.subscribeWithGenericSavinErrorHandling(this.ordersService.createOrder({
            checkoutJson: this.checkoutJson,
            storeId: this.storeWithFulfiller?.id,
            release: true
        }), (order) => {
            this.createdOrder = order;
        });
    }
}

export interface CreateOrderRequest {
    orderRequestHeader: OrderRequestHeaderRequest;
    itemOut: ItemOutRequest[];
}

export interface ItemOutRequest {
    lineNumber: number;
    itemId: ItemIDRequest;
    quantity: number;
    itemDetail: ItemDetailRequest;
    supplierId: ItemSupplierIdRequest;
}

export interface ItemSupplierIdRequest {
    domain: string;
    value: string;
}

export interface ItemDetailRequest {
    unitPrice: UnitPriceRequest;
}

export interface UnitPriceRequest {
    money: MoneyRequest;
}

export interface MoneyRequest {
    currency: string;
    value: number;
}

export interface ItemIDRequest {
    buyerPartId: string;
    supplierPartAuxiliaryId: string;
}

export interface OrderRequestHeaderRequest {
    orderID: string;
    shipTo: ShipToRequest;
    billTo: BillToRequest;
    shipping: ShippingRequest;
    contacts: ContactRequest[];
}

export interface BillToRequest {
    address: AddressRequest;
}

export interface AddressRequest {
    postalAddress: PostalAddressRequest;
    email: DescriptionRequest;
    phone: PhoneRequest;
}

export interface DescriptionRequest {
    value: string;
}

export interface PhoneRequest {
    number: string;
}

export interface PostalAddressRequest {
    deliverTo: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: CountryRequest;
}

export interface CountryRequest {
    isoCountryCode: string;
    value: string;
}

export interface ContactRequest {
    role: string;
    idReference: IDReferenceRequest[];
    postalAddress?: PostalAddressRequest;
    email?: DescriptionRequest;
    phone?: PhoneRequest;
}

export interface IDReferenceRequest {
    identifier: string;
    domain: string;
}

export interface ShipToRequest {
    address: AddressRequest;
    carrierIdentifier: CarrierIdentifierRequest[];
}

export interface CarrierIdentifierRequest {
    domain: string;
    value: string;
}

export interface ShippingRequest {
    money: MoneyRequest;
    description: DescriptionRequest;
}
