<h1 mat-dialog-title>Edit subscription billing information</h1>
<div mat-dialog-content>
  <p>
    Change can take up to one hour to take effect. Only affect future orders.
  </p>
  <form #form="ngForm">
    <mat-form-field>
      <mat-label>Start billing on</mat-label>

      <input matInput
        name="billingStartDateUtc"
        [matDatepicker]="picker"
        [(ngModel)]="data.billing!.billingStartDateUtc"
        />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Currency</mat-label>
      <input matInput
        name="currency"
        placeholder="USD"
        [(ngModel)]="data.billing!.currency"
        [pattern]="currencyPattern" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Monthly fee</mat-label>
      <input matInput
        name="monthlyFee"
        type="number"
        [(ngModel)]="data.billing!.monthlyFee" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Calculate revenue share based on</mat-label>
      <mat-select name="revShareCalculatedOn"
        [(value)]="data.billing!.revShare!.calculatedOn">
        @for (calculatedOn of ['Undefined', 'NetSellingPrice']; track calculatedOn) {
          <mat-option
          [value]="calculatedOn"> {{ calculatedOn }} </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <div class="group-fields">
      <mat-form-field>
        <mat-label>Revenue share (in percent)</mat-label>
        <input matInput
          type="number"
          name="percentage"
          min="0"
          [(ngModel)]="data.billing!.revShare!.percentage" />
        <span matSuffix>%&nbsp;</span>
      </mat-form-field>
      <mat-checkbox [(ngModel)]="data.billing!.multiplyByQuantity" name="multiplyByQuantity">
        Multiply Price By Quantity
      </mat-checkbox>
    </div>

    <div class="group-fields">
      <mat-form-field>
        <mat-label>Minimum per line</mat-label>
        <input matInput
          type="number"
          name="minimum"
          min="0"
          [(ngModel)]="data.billing!.revShare!.minimum" />
        <mat-hint>Enter 0 if none</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Maximum per line</mat-label>
        <input matInput
          type="number"
          name="maximum"
          min="0"
          [(ngModel)]="data.billing!.revShare!.maximum" />
        <mat-hint>Enter 0 if none</mat-hint>
      </mat-form-field>
    </div>

  </form>


</div>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="data" cdkFocusInitial>OK</button>
</mat-dialog-actions>
