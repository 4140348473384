import { Component, OnInit } from '@angular/core';
import { Library } from '../../../schema-dotnet';
import { MatDialog } from '@angular/material/dialog';
import { PromptDialogService } from '../prompt-dialog/prompt-dialog.service';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { LibraryService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'admin-libraries-page',
    templateUrl: './libraries-page.component.html',
    styleUrls: ['./libraries-page.component.scss']
})
export class LibrariesPageComponent extends AdminComponentBase implements OnInit {
    public libraries: Library[] = [];
    public filter = '';
    public displayedLibraries: Library[] = [];

    constructor(
        private readonly dialog: MatDialog,
        private readonly promptService: PromptDialogService,
        private readonly libraryService: LibraryService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeWithGenericLoadingErrorHandling(this.libraryService.getLibraries(), (libraries) => {
            this.libraries = libraries;
            this.updateDisplayedLibraries();
        });
    }

    displayLibrary(owner: string) {
        this.router.navigate([owner], { relativeTo: this.route });
    }

    deployNewLibrary() {
        this.promptService.prompt('Deploy a new library', 'The name of the library (should match the name of the Git repository)', {
            fieldLabel: 'Owner'
        }).subscribe(result => {
            this.router.navigate([result], { relativeTo: this.route });
        });
    }

    updateDisplayedLibraries() {
        if (!this.filter) {
            this.displayedLibraries = this.libraries.sort((a, b) => a.owner.localeCompare(b.owner));
            return;
        }
        this.displayedLibraries = this.libraries
            .filter(l => l.owner.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1)
            .sort((a, b) => a.owner.localeCompare(b.owner));

    }
}
