import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StockPhotoSourcesService } from "../../services";
import { StockPhotoSourceConfig } from "../../../schema-dotnet";

export interface StockPhotoSourceAddDialogData {
    alreadyMapped: string[];
}
export interface StockPhotoSourceAddDialogResult {
    photoSourceId?: string;
}

@Component({
    templateUrl: './stock-photo-source-add-dialog.component.html',
    styleUrls: ['./stock-photo-source-add-dialog.component.scss']
})
export class StockPhotoSourceAddDialogComponent implements OnInit {
    result: StockPhotoSourceAddDialogResult = {
        photoSourceId: undefined,
    };
    stockPhotoSources: StockPhotoSourceConfig[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: StockPhotoSourceAddDialogData,
        public dialogRef: MatDialogRef<StockPhotoSourceAddDialogComponent>,
        private readonly stockPhotoSourceService: StockPhotoSourcesService
    ) {
    }

    valid() {
        this.dialogRef.close(this.result);
    }

    ngOnInit(): void {
        this.stockPhotoSourceService.list().subscribe(value => {
            this.stockPhotoSources = value.filter(s => this.data.alreadyMapped.indexOf(s.id) === -1);
        });
    }
}
