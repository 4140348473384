import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    DeactivateStoreInput,
    MediaclipMutationDeactivateStoreAndScheduleDeletionOfUserDataArgs,
    MediaclipMutationUpdateStoreArgs,
    Store,
    UpdateStoreInput
} from '../../schema-dotnet';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class StoresService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getStore(storeId: string): Observable<Store> {
        return this.graphDotnetService.query(`
                query getStore($storeId: String!) {
                    store(id: $storeId) {
                        id
                        subscriptionId
                        label
                        environment
                        status
                        deactivationDateUtc
                        automaticDesignerUpgrade
                        subscription {
                            id
                            label
                        }
                        metadata {
                            platform
                            plugin
                            ignoredProblems
                            testDesignerInfo {
                                url
                                description
                            }
                        }
                    }
                }
            `, {storeId}
        ).pipe(map(q => q.store));
    }

    updateStore(input: UpdateStoreInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreArgs>(`
                mutation updateStore($input: UpdateStoreInput!) {
                    updateStore(input: $input)
                }
            `, {input: input}
        ).pipe(map(r => r.updateStore));
    }

    deactivateStore(input: DeactivateStoreInput) {
        return this.graphDotnetService.mutate<MediaclipMutationDeactivateStoreAndScheduleDeletionOfUserDataArgs>(`
            mutation DeactivateStoreAndScheduleDeletionOfUserData($input: DeactivateStoreInput!){
                deactivateStoreAndScheduleDeletionOfUserData(input: $input)
            }
            `, {input: input}
        ).pipe(map(r => r.deactivateStoreAndScheduleDeletionOfUserData));
    }
}
