import { ContentModule } from 'app/containers/content/content.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { NavbarModule } from 'app/components/navbar/navbar.module';
import { NgModule } from '@angular/core';
import { QuickPanelModule } from 'app/components/quick-panel/quick-panel.module';
import { RouterModule } from '@angular/router';
import { ToolbarModule } from 'app/components/toolbar/toolbar.module';

import { LayoutComponent } from './layout.component';

@NgModule({
    declarations: [LayoutComponent],
    imports: [
        RouterModule,

        FuseSharedModule,
        FuseSidebarModule,

        ContentModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule,
    ],
    exports: [LayoutComponent],
})
export class LayoutModule {}
