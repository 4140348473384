import * as auth0 from 'auth0-js';
import { Observable, shareReplay, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { switchMap, take, tap } from 'rxjs/operators';
import { AuthenticatedUser } from '../../schema-dotnet';
import { MeService } from './me.service';
import { Injectable } from '@angular/core';
import { GraphDotnetService } from './graph-dotnet-service';

export class AuthenticationContext {
    accessToken: string;
    idToken: string;
    expiresAt: Date;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    _authenticationContext$: Subject<AuthenticationContext> = new Subject<AuthenticationContext>();

    authenticatedUser$: Observable<AuthenticatedUser>;

    get authenticationContext$(): Observable<AuthenticationContext> {
        return this._authenticationContext$;
    }

    private auth0 = new auth0.WebAuth({
        clientID: environment.auth0.clientID,
        domain: environment.auth0.domain,
        responseType: 'token id_token',
        redirectUri: environment.auth0.redirectPath ? new URL(environment.auth0.redirectPath, document.location.origin).toString() : environment.auth0.redirectUri,
        scope: 'openid',
        audience: environment.auth0.audience
    });

    constructor(
        private graphDotnetService: GraphDotnetService,
        private meService: MeService
    ) {
        this.authenticatedUser$ = this.authenticationContext$.pipe(
            tap((c) => this.graphDotnetService.accessToken = c.accessToken),
            switchMap(() => this.meService.getUserInfo()),
            shareReplay(1)
        );
    }

    handleAuthentication() {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.saveAuthResult(authResult);
                return;
            }
            this.renewToken();
        });
        return this.authenticatedUser$.pipe(take(1));
    }

    login(returnUrl?: string): void {
        if (!returnUrl) {
            return;
        }
        try {
            localStorage.setItem('returnUrl', returnUrl);
        } catch (e) {
            console.error('Failed to save return url', e);
        }

        this.auth0.authorize();
    }

    logout(): void {
        this.graphDotnetService.mutate(`mutation logout { signOut() }`).subscribe(() => {
            this.auth0.logout({
                clientID: environment.auth0.clientID,
                returnTo: document.location.origin
            });
        })
    }

    renewToken() {
        this.auth0.checkSession({}, (err, result) => {
            if (err) {

                this.login(window.location.pathname);
                console.error(err);
            } else {
                this.saveAuthResult(result);
            }
        });
    }

    getReturnUrl(): string | null {
        try {
            const value = localStorage.getItem('returnUrl');
            if (value) {
                try {
                    localStorage.removeItem('returnUrl');
                } catch (e) {
                    console.error('Failed to clear return url', e);
                }
            }
            return value;
        } catch (e) {
            console.error('Failed to get return url', e);
            return null;
        }
    }

    private saveAuthResult(authResult: auth0.Auth0DecodedHash): void {
        if (!authResult.expiresIn) {
            throw new Error('Authorization result has no expiration');
        }
        const expiresAtValue = authResult.expiresIn * 1000 + new Date().getTime();
        setTimeout(() => this.renewToken(), (authResult.expiresIn * 0.8) * 1000);
        this._authenticationContext$.next({
            idToken: authResult.idToken!,
            accessToken: authResult.accessToken!,
            expiresAt: new Date(expiresAtValue)
        });
    }
}
