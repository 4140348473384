import { Component, OnDestroy, OnInit } from '@angular/core';

import { PageStatus } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreConfigService } from '../../services';
import { StoreConfig } from '../../../schema-dotnet';
import { AdminComponentBase } from '../../utils/admin-component-base';

@Component({
    selector: 'admin-store-settings',
    templateUrl: './store-settings-page.component.html',
    styleUrls: ['./store-settings-page.component.scss']
})
export class StoreSettingsPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    storeId: string;
    storeConfig?: StoreConfig;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly storeConfigService: StoreConfigService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeConfigService.getStoreConfig(this.storeId),
                store => {
                    this.storeConfig = store;
                });
        }))
    }


    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    save() {
        if (!this.storeConfig) {
            return;
        }

        this.pageStatus = PageStatus.saving;
        this.subscribeWithGenericSavinErrorHandling(this.storeConfigService.updateStoreConfig({
            storeId: this.storeId,
            defaultLibraryPackage: this.storeConfig.defaultLibraryPackage,
            allowAnonymousRequest: this.storeConfig.allowAnonymousRequest,
            isRecentUploadsEnabled: this.storeConfig.isRecentUploadsEnabled,
            isProofingEnabled: this.storeConfig.isProofingEnabled,
            uploadInBackgroundEnabled: this.storeConfig.uploadInBackgroundEnabled,
            enableThumbnailGeneration: this.storeConfig.enableThumbnailGeneration
        }));
    }
}
