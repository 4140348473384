import { ActivatedRoute } from '@angular/router';
import { AuthorizationContext, AuthorizationService, ProjectsService } from 'app/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalPermission, Maybe, Project, ProjectVersion, StorePermission } from 'schema-dotnet';
import { DynamicScriptLoaderService } from '../../services/dynamic-script-loader.service';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';
import { ViewContextService } from '../../navigation/view-context.service';

@Component({
    templateUrl: './project-page.component.html',
    styleUrls: ['./project-page.component.scss']
})
export class ProjectPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    projectId: string;
    projectVersionId: number | null;

    project?: Maybe<Project>;
    projectVersion?: Maybe<ProjectVersion>;
    authContext?: AuthorizationContext;

    loadHubMinJs: Promise<any>;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authService: AuthorizationService,
        private readonly projectsService: ProjectsService,
        private readonly dynamicScriptLoader: DynamicScriptLoaderService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly viewContextService: ViewContextService,
    ) {
        super();
        this.projectId = this.route.snapshot.params.projectId;
        this.projectVersionId = +this.route.snapshot.params.projectVersionId || null;
        this.loadHubMinJs = this.dynamicScriptLoader.load('mediaclip-hub');
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            let projectVersionId = +params.projectVersionId || null;
            if (projectVersionId != this.projectVersionId) {
                this.selectProjectVersion(projectVersionId);
            }
        });

        this.loadProject();
    }

    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    selectProjectVersion(projectVersionId?: number | null) {
        if (!this.project) {
            return;
        }

        this.projectVersionId = projectVersionId || null;
        if (projectVersionId) {
            this.projectsService.getProjectVersion(this.projectId, projectVersionId).subscribe({
                next: (version) => {
                    this.projectVersion = version;
                },
                error: (err) => {
                    this.setLoadingError(err);
                }
            });
        }
    }

    getOrientation(width: number, height: number): string {
        if (width === height) {
            return 'square';
        } else if (width > height) {
            return 'landscape';
        } else {
            return 'portrait';
        }
    }

    getVersionPosition(project: Project, projectVersion: ProjectVersion) {
        return project.versions.findIndex((v) => v.id === projectVersion.id) + 1;
    }

    saveXml(projectVersionId: number, xml: string) {
        const domParser = new DOMParser();
        const doc = domParser.parseFromString(xml, 'application/xml');
        if (doc.querySelector('parsererror')) {
            alert('Errors in the XML document. Please fix any errors and try again.');
            return;
        }
        this.subscribeWithGenericSavinErrorHandling(this.projectsService.updateProjectContent({
            projectVersionId: projectVersionId,
            projectXml: xml
        }));
    }

    download(projectVersionId: number){
        this.subscribeWithGenericSavinErrorHandling(this.projectsService.downloadProjectVersion({
            projectVersionId: projectVersionId
        }));
    }

    delete(projectId: string) {
        this.confirmDialogService.confirm(
            'Delete project',
            'Do you really want to delete this project?',
            undefined,
            {
                yes: {
                    label: 'Delete',
                    warn: true
                },
                no: {
                    label: 'Cancel'
                }
            }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.projectsService.deleteProject({ id: projectId }), () => {
                this.loadProject();
            });
        });
    }

    canManageProject(project: Project) {
        return this.authContext?.hasStorePermissions(project.store.id, project.store.subscriptionId, StorePermission.ManageProjects);
    }

    canDownloadProject(project: Project) {
        return this.authContext?.hasGlobalPermissions(GlobalPermission.DownloadProjects);
    }

    canReadStoreUsers(project: Project) {
        return this.authContext?.hasStorePermissions(project.store.id, project.store.subscriptionId, StorePermission.ReadStoreUsers);
    }

    canManagePhotos(project: Project) {
        return this.authContext?.hasStorePermissions(project.store.id, project.store.subscriptionId, StorePermission.ManagePhotos);
    }

    private loadProject() {
        this.subscribeWithGenericLoadingErrorHandling(this.projectsService.getProject(this.projectId), (project) => {
            this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
            this.project = project;
            if (project) {
                this.viewContextService.selectStore(project.storeId);
                if (!this.projectVersionId) {
                    this.selectProjectVersion(project.currentVersionId);
                } else {
                    this.selectProjectVersion(this.projectVersionId);
                }
            }
        });
    }

    invalidateCachedPhoto(projectVersion: ProjectVersion) {
        this.confirmDialogService.confirm('Invalidate photos cache', 'Do you want to invalidate cache of all external photos in this project ? If the user token to the photo source is not valid anymore this project will not be able to be rendered.').subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.projectsService.invalidateProjectStorageExternalPhotosCache(projectVersion.id));
        });
    }
}
