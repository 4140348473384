import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    DeleteProjectInput,
    Maybe,
    MediaclipMutationInvalidateProjectStorageExternalPhotosCacheArgs, MediaclipMutationReEncodePhotoArgs,
    MediaclipMutationUpdateProjectVersionContentArgs,
    Project,
    ProjectVersion,
    UpdateProjectVersionContentInput
} from 'schema-dotnet';
import { MediaclipMutationDeleteProjectArgs } from '../../schema-dotnet';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from './graph-dotnet-service';

@Injectable({
    providedIn: 'root'
})
export class PhotosService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    reEncodePhoto(urn: string): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationReEncodePhotoArgs>(`
                mutation reEncodePhoto($urn: String!) {
                    reEncodePhoto(urn: $urn)
                }
            `, { urn }
        ).pipe(map(x => x.reEncodePhoto));
    }
}
