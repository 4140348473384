import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './dashboards-overview-page.component.html',
    styleUrls: ['./dashboards-overview-page.component.scss']
})
export class DashboardsOverviewPageContainerComponent {
    storeId: string;

    constructor(
        private readonly route: ActivatedRoute
    ) {
        this.storeId = this.route.snapshot.params.storeId;
    }
}
