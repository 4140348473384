import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Maybe,
    MediaclipMutationRevokeSharedProjectArgs,
    RevokeSharedProjectInput,
    SharedProject,
} from 'schema-dotnet';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from './graph-dotnet-service';

@Injectable()
export class SharedProjectsService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getSharedProject(sharedProjectId: string): Observable<Maybe<SharedProject> | undefined> {
        return this.graphDotnetService.query(`
                query getSharedProject($sharedProjectId: UUID!) {
                    sharedProject(id: $sharedProjectId) {
                        id
                        storeId
                        sharingMode
                        shareDate
                        expiration
                        projectId
                        projectStorageId
                        project {
                          id
                          storeId
                          store {
                            id
                            subscriptionId
                          }
                        }
                    }
                }
            `,
            {sharedProjectId}
        ).pipe(map((result) => result.sharedProject));
    }

    revokeSharedProject(input: RevokeSharedProjectInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationRevokeSharedProjectArgs>(`
                mutation revokeSharedProject($input: RevokeSharedProjectInput!) {
                    revokeSharedProject(input: $input)
                }
            `, {input}
        ).pipe(map(x => x.revokeSharedProject));
    }
}
