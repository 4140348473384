import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { AuthorizationContext, AuthorizationService, AuthorizedUsersService } from '../../services';
import { GlobalPermission, PageInfo, UserRoles } from '../../../schema-dotnet';
import { MatPaginator } from '@angular/material/paginator';
import { PromptDialogService } from '../prompt-dialog/prompt-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './admin-users-page.component.html',
    styleUrls: ['./admin-users-page.component.scss']
})
export class AdminUsersPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    users: UserRoles[] = [];
    pageInfo: PageInfo = { page: 0, pageSize: 5, hasNextPage: false };
    filter?: string;
    authorizationContext: AuthorizationContext;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly authorizationService: AuthorizationService,
        private readonly authorizedUsersService: AuthorizedUsersService,
        private readonly promptDialogService: PromptDialogService,
    ) {
        super();
    }


    ngOnInit() {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.subscribeWithGenericLoadingErrorHandling(this.authorizedUsersService.listUsers(0), result => {
            this.users = result.items || [];
            this.pageInfo = result.pageInfo;
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    search() {
        this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.listUsers(0, this.filter), result => {
            this.users = result.items || [];
            this.pageInfo = result.pageInfo;
        });
    }

    loadPage(page: number) {
        this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.listUsers(page, this.filter), result => {
            this.users = result.items || [];
            this.pageInfo = result.pageInfo;
        });
    }

    userRole$(element: any): UserRoles {
        return element as UserRoles;
    }

    canAddUsers() {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageAdminUsers);
    }

    openAddUserDialog() {
        this.registerSubscription(this.promptDialogService.prompt('Add user', undefined, { fieldLabel: 'Email' }).subscribe((email) => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRole({
                email: email
            }), () => {
                this.router.navigate([email], {relativeTo: this.route})
            });
        }));
    }
}
