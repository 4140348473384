<div id="style" class="page-layout carded fullwidth inner-scroll">
    <div class="center">
        <div class="content-card" fusePerfectScrollbar>
            <div class="content">
                <h1>Development Style Page</h1>

                <div>
                    <h2>Line Status</h2>
                    <p>Line preceding status</p>
                    <p>This status is inlined: <admin-line-status [status]="sentToRenderingStatus"></admin-line-status></p>
                    <p>
                        This status is action required:
                        <admin-line-status [status]="errorStatus" [flags]="$any('StoreActionRequired')"></admin-line-status>
                    </p>
                    <p>Line following status</p>
                </div>
            </div>
        </div>
    </div>
</div>
