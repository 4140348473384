import {Injectable} from '@angular/core';
import {MediaclipMutationUpdateStoreConfigArgs, StoreConfig, UpdateStoreConfigInput} from 'schema-dotnet';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class StoreConfigService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getStoreConfig(storeId: string): Observable<StoreConfig> {
        return this.graphDotnetService.query(`
                query getStoreSettings($storeId: String!) {
                    store(id: $storeId) {
                        label
                        config {
                            defaultLibraryPackage
                            allowAnonymousRequest
                            isRecentUploadsEnabled
                            isProofingEnabled
                            uploadInBackgroundEnabled
                            enableThumbnailGeneration
                            cartRedirectionPage {
                                url
                            }
                            exitPage {
                                url
                            }
                            loginPage {
                                url
                            }
                            correctionsCompletedPage {
                                url
                            }
                            addToCartEndpoint {
                                url
                                headers {
                                    name
                                    value
                                }
                            }
                            getPriceEndpoint {
                                url
                                headers {
                                    name
                                    value
                                }
                            }
                            getProductsPriceEndpoint {
                                url
                                headers {
                                    name
                                    value
                                }
                            }
                            onProjectDeletedEndpoint {
                                url
                                headers {
                                    name
                                    value
                                }
                            }
                            statusUpdateEndpoint {
                                url
                                headers {
                                    name
                                    value
                                }
                            }
                            getProductQuantitiesEndpoint {
                                url
                                headers {
                                    name
                                    value
                                }
                            }
                            getAlternateProductsEndpoint {
                                url
                                headers {
                                    name
                                    value
                                }
                            }
                        }
                    }
                }
            `,
            {
                storeId
            }
        ).pipe(map(q => q.store.config));
    }

    updateStoreConfig(input: UpdateStoreConfigInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreConfigArgs>(
            `
                mutation updateStoreConfig($input: UpdateStoreConfigInput!) {
                    updateStoreConfig(input: $input)
                }
            `,
            {input}
        ).pipe(map(x => x.updateStoreConfig));
    }
}
