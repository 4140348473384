<div class="fuse-search-bar" [ngClass]="{'expanded':!collapsed}">

  <div class="fuse-search-bar-content">

    <label for="fuse-search-bar-input">
      @if (collapsed) {
        <button mat-icon-button
          class="fuse-search-bar-expander"
          aria-label="Expand Search Bar"
          (click)="expand()"
          >
          <mat-icon class="secondary-text">search</mat-icon>
        </button>
      }
    </label>

    <!-- The input event was customized to use keydown.enter instead -->
    <input id="fuse-search-bar-input" class="ml-24" type="text" placeholder="Search orders, stores and more" (input)="search($event)" (keydown.enter)="enter($event)" (keyup.esc)="collapse()"
      onfocus="this.setSelectionRange(0, this.value.length)">

    <button mat-icon-button class="fuse-search-bar-collapser" (click)="collapse()"
      aria-label="Collapse Search Bar">
      <mat-icon class="s-24 secondary-text">close</mat-icon>
    </button>

  </div>

</div>
