<h2 mat-dialog-title>
  {{data.title}}
</h2>
<mat-dialog-content>
  @if (data.message) {
    <p>
      {{data.message}}
    </p>
  }
  <mat-form-field>
    @if (data.label) {
      <mat-label>{{data.label}}</mat-label>
    }
    <input matInput type='text' [(ngModel)]='value' autocomplete="off"/>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    CANCEL
  </button>

  <button mat-button color="primary" [mat-dialog-close]="value">
    OK
  </button>
</mat-dialog-actions>
