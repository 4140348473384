import { NgModule } from '@angular/core';
import { GitLabUrlBuilder } from "./git-lab-url-builder.util";
import { ResourceIdentityParser } from "./resource-identity-parser.util";

@NgModule({
    providers: [
        GitLabUrlBuilder,
        ResourceIdentityParser,
    ],
})

export class AdminUtilsModule {}
