import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { DesignerReleaseChannelService } from '../../services/designer-release-channel.service';
import { PageStatus } from '../../models';
import { DesignerBranch, DesignerSelectorRule, DesignerModuleName } from '../../../schema-dotnet';
import { AdminComponentBase } from '../../utils/admin-component-base';

@Component({
    selector: 'admin-designer-versions',
    templateUrl: './store-designer-release-channel-configuration.component.html',
    styleUrls: ['./store-designer-release-channel-configuration.component.scss']
})
export class StoreDesignerVersionConfigurationComponent extends AdminComponentBase implements OnInit, OnDestroy {
    storeId: string;
    designerReleaseChannel: string;
    releaseChannels: DesignerBranch[] = [];

    rules: DesignerSelectorRule[] = [];
    modules = [
        { value: null, display: '' },
        { value: DesignerModuleName.Photobook, display: DesignerModuleName.Photobook },
        { value: DesignerModuleName.Gifting, display: DesignerModuleName.Gifting },
        { value: DesignerModuleName.Print, display: DesignerModuleName.Print }
    ];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly designerReleaseChannelService: DesignerReleaseChannelService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.designerReleaseChannelService.getDesignerReleaseChannels(),
                    this.designerReleaseChannelService.getDesignerReleaseChannelConfiguration(this.storeId)
                ]),
                ([branches, { designerBranch, rules }]) => {
                    this.releaseChannels = branches;
                    if (!branches.find(x => x.id === designerBranch.id)) {
                        this.releaseChannels.push(designerBranch);
                    }
                    this.designerReleaseChannel = designerBranch.id;
                    this.rules = rules ?? [];
                    this.addEmptyRule();
                });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    private addEmptyRule() {
        this.rules.push(<any>{
            weight: 1
        });
    }

    public deleteRule(rule: DesignerSelectorRule) {
        if (!confirm('Are you sure you want to delete the rule ' + rule.label)) {
            return;
        }

        this.subscribeWithGenericSavinErrorHandling(this.designerReleaseChannelService.deleteStoreDesignerReleaseChannelRule(this.storeId, rule.id), () => {
            const i = this.rules.findIndex((r) => r.id === rule.id);
            this.rules.splice(i, 1);
        });
    }

    public editRule(rule: DesignerSelectorRule) {
        this.subscribeWithGenericSavinErrorHandling(this.designerReleaseChannelService.updateStoreDesignerReleaseChannelRule(
            this.storeId,
            rule.id,
            rule.designerBranch,
            rule.weight,
            rule.label,
            rule.module
        ));
    }


    addRule(rule: DesignerSelectorRule) {
        this.subscribeWithGenericSavinErrorHandling(this.designerReleaseChannelService.addStoreDesignerReleaseChannelRule(
            this.storeId,
            rule.designerBranch,
            rule.weight,
            rule.label,
            rule.module
        ), addedRule => {
            rule.id = addedRule.id;
            this.addEmptyRule();
        });
    }

    isRuleValid(rule: DesignerSelectorRule) {
        return rule.designerBranch && rule.weight && rule.label;
    }

    saveDefaultDesignerReleaseChannel(designerReleaseChannel: string) {
        this.subscribeWithGenericSavinErrorHandling(this.designerReleaseChannelService.updateStoreDefaultDesignerReleaseChannel(
                this.storeId,
                designerReleaseChannel
            )
        );
    }
}
