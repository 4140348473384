import {Injectable} from '@angular/core';
import {
    DeleteStorePhotoUpscalingInput,
    Maybe,
    MediaclipMutationDeleteStorePhotoUpscalingArgs, MediaclipMutationUpdateStorePhotoUpscalingArgs, UpdateStorePhotoUpscalingInput,
    PhotoUpscalingConfiguration
} from 'schema-dotnet';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class StorePhotosUpscalingService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getConfig(storeId: string): Observable<Maybe<PhotoUpscalingConfiguration> | undefined> {
        return this.graphDotnetService.query(`
                query getStoreUpscaling($storeId: String!) {
                    store(id: $storeId) {
                        photoUpscaling {
                            type
                            enabled
                            minDpiUpscaleConfiguration
                        }
                    }
                }
            `,
            {
                storeId
            }
        ).pipe(map(q => q.store.photoUpscaling));
    }

    deleteConfiguration(input: DeleteStorePhotoUpscalingInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteStorePhotoUpscalingArgs>(
            `
                mutation deleteStorePhotoUpscaling($input: DeleteStorePhotoUpscalingInput!) {
                    deleteStorePhotoUpscaling(input: $input)
                }
            `,
            { input }
        );
    }

    updateConfiguration(input: UpdateStorePhotoUpscalingInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStorePhotoUpscalingArgs>(
            `
                mutation UpdateStorePhotoUpscaling($input: UpdateStorePhotoUpscalingInput!) {
                    updateStorePhotoUpscaling(input: $input)
                }
            `,
            { input }
        );
    }
}
