import {Component, OnDestroy} from '@angular/core';
import {fuseAnimations} from '../../../@fuse/animations';
import {PageStatus} from '../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {ExternalPhotosConfigService} from '../../services';
import {ExternalPhotoSourceStatus, GenericExternalPhotoSourceConfig} from '../../../schema-dotnet';

@Component({
    templateUrl: './generic-external-photo-source-edit-page.component.html',
    styleUrls: ['./generic-external-photo-source-edit-page.component.scss'],
    animations: fuseAnimations
})
export class GenericExternalPhotoSourceEditPageComponent implements OnDestroy {
    public mode: 'edit' | 'create';
    public title = '';
    public storeId: string;
    public photoSourceId: string;
    public pageStatus: PageStatus = PageStatus.loading;
    public pageError?: Error;

    public genericExternalSource ?: GenericExternalPhotoSourceConfig;
    public appSecret ?: string;
    private redirectTimerId?: number;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly photosConfigService: ExternalPhotosConfigService,
        private readonly router: Router
    ) {
        this.storeId = this.route.snapshot.params.storeId;
        this.photoSourceId = this.route.snapshot.params.photoSourceId;
        this.mode = this.route.snapshot.data.mode;
        if (this.mode === 'edit') {
            this.title = 'Edit Generic External Photo Source';
            this.photosConfigService.loadGenericExternalPhotoSource(this.storeId, this.photoSourceId)
                .subscribe({
                    next: source => {
                        if (!source) {
                            this.pageStatus = PageStatus.loadError;
                            this.pageError = new Error('Photo source not found');
                            return;
                        }

                        this.genericExternalSource = source;
                        this.pageStatus = PageStatus.loaded;
                    },
                    error: err => {
                        this.pageStatus = PageStatus.loadError;
                        this.pageError = err;
                    }
                });
        } else {
            this.title = 'Add New Generic External Photo Source';

            this.genericExternalSource = {
                albumUrl: '',
                appId: '',
                authorizationUrl: '',
                icon: '',
                label: '',
                photoUrl: '',
                photoSourceId: '',
                status: ExternalPhotoSourceStatus.Disabled,
                store: { id: this.storeId } as any,
                tokenUrl: ''
            };
            this.pageStatus = PageStatus.loaded;
        }

    }

    save() {
        if (!this.genericExternalSource) {
            return;
        }
        if (this.mode == 'edit') {
            this.photosConfigService.updateGenericExternalPhotoSource({
                storeId: this.storeId,
                photoSourceId: this.photoSourceId,
                appId: this.genericExternalSource.appId,
                appSecret: this.appSecret,
                label: this.genericExternalSource.label,
                scopes: this.genericExternalSource.scopes,
                icon: this.genericExternalSource.icon,
                photoUrl: this.genericExternalSource.photoUrl,
                albumUrl: this.genericExternalSource.albumUrl,
                tokenUrl: this.genericExternalSource.tokenUrl,
                authorizationUrl: this.genericExternalSource.authorizationUrl
            }).subscribe({
                next: this.completeSave.bind(this),
                error: this.saveError.bind(this)
            });
        } else {
            if (!this.appSecret)
                return;

            let input = {
                storeId: this.storeId,
                photoSourceId: this.photoSourceId,
                appId: this.genericExternalSource.appId,
                appSecret: this.appSecret,
                label: this.genericExternalSource.label,
                scopes: this.genericExternalSource.scopes,
                icon: this.genericExternalSource.icon,
                photoUrl: this.genericExternalSource.photoUrl,
                albumUrl: this.genericExternalSource.albumUrl,
                tokenUrl: this.genericExternalSource.tokenUrl,
                authorizationUrl: this.genericExternalSource.authorizationUrl
            };
            this.photosConfigService.createGenericExternalPhotoSource(input).subscribe({
                next: this.completeSave.bind(this),
                error: this.saveError.bind(this)
            });
        }

        this.pageStatus = PageStatus.saving;
    }

    private saveError(err: Error) {
        this.pageStatus = PageStatus.loadError;
        this.pageError = err;
    }

    private completeSave(source: GenericExternalPhotoSourceConfig) {
        this.pageStatus = PageStatus.loaded;
        this.genericExternalSource = source;
        this.appSecret = undefined;
        this.redirectTimerId = window.setTimeout(() => {
            this.router.navigate(['stores', this.storeId, 'settings', 'external-photo-sources']);
        }, 500);
    }

    ngOnDestroy(): void {
        if (this.redirectTimerId) {
            clearTimeout(this.redirectTimerId);
            this.redirectTimerId = undefined;
        }
    }
}
