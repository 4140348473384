import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'content', // eslint-disable-line
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ContentComponent {
    constructor() {}
}
