import {AuthenticatedUser} from 'schema-dotnet';
import {AuthenticationService} from 'app/services';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {fuseAnimations} from '@fuse/animations';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'admin-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    animations: fuseAnimations,
})
export class HomePageContainerComponent implements OnInit {
    me$: Observable<AuthenticatedUser>;

    constructor(
        private readonly authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.me$ = this.authenticationService.authenticatedUser$;
    }
}
