import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { DesignerCustomizationsService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { AddLocaleDialogComponent } from './add-locale-dialog.component';
import { forkJoin } from 'rxjs';

@Component({
    templateUrl: './store-designer-custom-locales-component.component.html',
    styleUrls: ['./store-designer-custom-locales-component.component.scss']
})
export class StoreDesignerCustomLocalesComponentComponent extends AdminComponentBase implements OnInit, OnDestroy {
    storeId: string;
    cultures: string[] = [];
    localesContent: Record<string, string> = {};
    savedCulture: Record<string, string> = {};

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly designerCustomizationsService: DesignerCustomizationsService,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.designerCustomizationsService.getStoreFiles(this.storeId),
                customization => {
                    this.subscribeWithGenericLoadingErrorHandling(
                        forkJoin(customization.locales.map(s => this.designerCustomizationsService.getCustomizationFile(s.url))), (files) => {
                            for (let i = 0; i < customization.locales.length; i++) {
                                let locale = customization.locales[i];
                                let content = files[i];
                                this.localesContent[locale.culture] = content;
                                this.savedCulture[locale.culture] = content;
                            }
                            this.cultures = customization.locales.map(x => x.culture);

                        });
                });
        }));
    }

    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    addCulture() {
        this.dialog.open(AddLocaleDialogComponent).afterClosed().subscribe((result: string) => {
            if (!result) {
                return;
            }
            let culture = result.toLowerCase();
            if (culture in this.localesContent) {
                return;
            }
            this.cultures.push(culture);
            this.localesContent[culture] = '{\n}';
        });
    }

    save(culture: string) {
        let content = this.localesContent[culture];
        this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateLocale({
            storeId: this.storeId,
            content: content,
            culture: culture
        }), () => {
            this.savedCulture[culture] = content;
        });
    }

    saveAll() {
        let cultureToSave = this.cultures
            .filter(culture => this.isCultureNotSaved(culture))
            .map(culture => this.designerCustomizationsService.updateLocale({
                storeId: this.storeId,
                content: this.localesContent[culture],
                culture: culture
            }));
        this.subscribeWithGenericSavinErrorHandling(forkJoin(cultureToSave), () => {
            for (let culture of this.cultures) {
                this.savedCulture[culture] = this.localesContent[culture];
            }
        });
    }

    isCultureNotSaved(culture?: string): boolean {
        if (culture) {
            return this.localesContent[culture] !== this.savedCulture[culture];
        }
        for (let culture of this.cultures) {
            if (this.localesContent[culture] !== this.savedCulture[culture])
                return true;
        }
        return false;
    }

    async updateLocaleFromFile(culture: string, files: FileList) {
        if (!files.length) {
            return;
        }
        this.localesContent[culture] = await files[0].text();
    }

    async updateLocalesFromFile(files: FileList) {
        if (!files.length) {
            return;
        }

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let content = await file.text();
            const cultureMatch = file.name.match(/\b[a-z]{2}(-[A-Z]{2})?\b/i);
            if (!cultureMatch) {
                continue;
            }
            const culture = cultureMatch[0];
            this.localesContent[culture] = content;
            if (!this.cultures.includes(culture)) {
                this.cultures.push(culture);
            }
        }
    }
}
