import { Component, OnDestroy, OnInit } from '@angular/core';
import { Maybe, StorePermission, User } from 'schema-dotnet';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AuthorizationContext, AuthorizationService, UserService } from '../../services';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewContextService } from '../../navigation/view-context.service';
import { ConsolidateUserDialogComponent, ConsolidateUserDialogData, ConsolidateUserDialogResult } from './consolidate-user-dialog.component';

@Component({
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.scss']
})
export class UserPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    userId: string;
    user?: Maybe<User>;

    authContext: AuthorizationContext;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly authService: AuthorizationService,
        private readonly matDialog: MatDialog,
        private readonly viewContextService: ViewContextService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.userId = params.userId;
            this.subscribeWithGenericLoadingErrorHandling(this.userService.getUser(this.userId), (user) => {
                if (user) {
                    this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
                    this.user = user;
                    this.viewContextService.selectStore(user.storeId);
                }
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    deleteUser(user: User) {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Confirmation',
                message: `Do you really want to delete this user?`
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.subscribeWithGenericSavinErrorHandling(this.userService.deleteUser({ id: user.id }), () => {
                this.subscribeWithGenericLoadingErrorHandling(this.userService.getUser(this.userId), (user) => {
                    this.user = user;
                });
            });
        });
    }

    canManageUser(user: User) {
        return this.authContext.hasStorePermissions(user.store.id, user.store.subscriptionId, StorePermission.ManageStoreUsers);
    }

    openConsolidateDialog(user: User) {
        let dialogRef = this.matDialog.open<ConsolidateUserDialogComponent, ConsolidateUserDialogData, ConsolidateUserDialogResult>(
            ConsolidateUserDialogComponent, {
                data: {
                    anonymousStoreUSerId: user.storeUserId
                }
            });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.subscribeWithGenericSavinErrorHandling(this.userService.consolidateUser({
                storeId: user.storeId,
                anonymousStoreUserId: user.storeUserId,
                identifiedStoreUserId: result.targetStoreUserId
            }), consolidatedUser => {
                this.router.navigate(['..', consolidatedUser.identifiedHubUserId], { relativeTo: this.route }).then();
            });
        });
    }
}
