import { Injectable } from '@angular/core';
import { ViewContextService } from './view-context.service';
import { AuthorizationService } from '../services';
import { combineLatestWith, Observable } from 'rxjs';
import { Navigation } from './navigation-types';
import { map } from 'rxjs/operators';
import { buildNavigation } from './build-navigation';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public navigation$: Observable<Navigation>;

    constructor(
        private readonly viewContextService: ViewContextService,
        private readonly authorizationService: AuthorizationService
    ) {
        this.navigation$ = this.viewContextService.context$
            .pipe(
                combineLatestWith(this.authorizationService.authorizationContext()),
                map(([viewContext, authorizationContext]) => {
                    return buildNavigation(viewContext, authorizationContext);
                })
            );
    }

}
