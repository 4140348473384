import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationContext, AuthorizationService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { GeneratedTextConfiguration } from '../../../schema-dotnet';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { StoreGeneratedTextService } from "../../services/store-generated-text.service";

@Component({
    selector: 'admin-store-generated-text',
    templateUrl: './store-generated-text-page.component.html',
    styleUrls: ['./store-generated-text-page.component.scss']
})
export class StoreGeneratedTextPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly defaultConfig : GeneratedTextConfiguration = {
        accessToken: '',
        organization: '',
    };
    private storeId: string;
    private authContext?: AuthorizationContext;

    public hasConfigDefined: boolean;
    public currentGeneratedTextConfig: GeneratedTextConfiguration = this.defaultConfig;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authService: AuthorizationService,
        private readonly storeGeneratedTextService: StoreGeneratedTextService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
        this.registerSubscription(this.route.params.subscribe(async params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeGeneratedTextService.getConfig(this.storeId), (generatedTextConfig) => {
                this.hasConfigDefined = !!generatedTextConfig;
                if (generatedTextConfig)
                    this.currentGeneratedTextConfig = generatedTextConfig;
            });
        }))
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    deleteConfig() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Delete configuration',
                message: `Do you really want to delete that configuration ?`,
                options: {
                    yes: {
                        label: 'Delete',
                        warn: true
                    },
                    no: {
                        label: 'Cancel'
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.storeGeneratedTextService.deleteConfiguration({ storeId: this.storeId }), () => {
                this.hasConfigDefined = false;
                this.currentGeneratedTextConfig = this.defaultConfig;
            });
        });
    }

    save() {
        this.subscribeWithGenericSavinErrorHandling(this.storeGeneratedTextService.updateConfiguration({
            ...this.currentGeneratedTextConfig,
            storeId: this.storeId
        }), () => {
            this.hasConfigDefined = true;
        });
    }
}
