import {Injectable} from '@angular/core';
import {CustomerSupportToken, GenerateCustomerSupportTokenInput, MediaclipMutationGenerateCustomerSupportTokenArgs} from '../../schema-dotnet';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class HubTokenService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    generateCustomerSupportToken(
        input: GenerateCustomerSupportTokenInput
    ): Observable<CustomerSupportToken> {
        return this.graphDotnetService.mutate<MediaclipMutationGenerateCustomerSupportTokenArgs>(`
                mutation generateCustomerSupportToken($input: GenerateCustomerSupportTokenInput!) {
                    generateCustomerSupportToken(input: $input) {
                        token
                        scheme
                    }
                }
            `,
            {input}
        ).pipe(map((result) => result.generateCustomerSupportToken));
    }
}
