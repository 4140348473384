<admin-page-simple-content id="customJavascript"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Designer: Custom Javascript'"
  [icon]="'description'">
  <div class="content">
    <div class="header">
      <div class="description">
        <p>
          This page allows you to update a custom javascript that will be executed in the designer context.
          See <a href="https://doc.mediaclip.ca/hub/features/designer-customization/"
        target="_blank">documentation</a> for more information.
      </p>
      <p>
        Please take note that Mediaclip does not support your custom code.
        By uploading overrides, we cannot guarantee compatibility of your code for all browsers,
        and even though we try to minimize changes, future Mediaclip Designers versions may
        break any non-documented hooks and css changes.
      </p>
    </div>
    <div class="actions">
      <input #file type="file" (change)="onFilesChanged($any($event.target).files)" accept="text/javascript" style="display: none;" />
      <button mat-stroked-button (click)="file.click()">Load from file...</button>
      <admin-save-button [pageStatus]="pageStatus" (save)="save()">
        Save
      </admin-save-button>
    </div>
  </div>
  @if (pageStatus === 'saveError' && pageError) {
    <admin-error [error]="pageError"></admin-error>
  }
  <div class="editor">
    <ngx-codemirror [(ngModel)]="javascript"
                            [options]="{
                                lineNumbers: true,
                                matchBrackets: true,
                                lint: true,
                                theme: 'material',
                                mode: 'application/javascript',
                                extraKeys: {'Ctrl-S': save.bind(this)}
                            }"
    ></ngx-codemirror>
  </div>
</div>
</admin-page-simple-content>
