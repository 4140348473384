<admin-page-simple-content id="external-photo-sources"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'External Photo Sources'"
  [icon]="'photos'">
  <div class="content">
    @if (pageStatus === 'saveError') {
      <admin-error [error]="pageError"></admin-error>
    }
    <h2>Built-in external photo sources</h2>
    @for (source of builtInExternalPhotoSources; track source) {
      <mat-card class="photo-source-card"
        [attr.data-photoSourceId]="source.photoSourceId">
        <mat-card-header>
          <mat-card-title>{{ source.label }}</mat-card-title>
          <mat-card-subtitle><code adminCopyToClipboard>{{ source.photoSourceId }}</code></mat-card-subtitle>
          <div mat-card-avatar class="source-img">
            @if (source.icon) {
              <img [src]="source.icon">
            }
          </div>
          <span class="padding"></span>
          <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-button">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>
          @if (source.details) {
            <p class="mat-body">
              <mat-icon color="warn">warning</mat-icon>
              {{ source.details }}
            </p>
          }
          <div class="properties">
            <h2 class="mat-h2">
              Config
            </h2>
            <span>State</span>
            <span class><code>{{ source.status }}</code></span>
            @if (source.status === 'Disabled') {
              <span class="prop-warn"><mat-icon
              color="warn">warning</mat-icon>
              This source will not be listed in the designer until it's enabled
            </span>
          }
          <span>App Id</span>
          <span><code adminCopyToClipboard>{{ source.appId }}</code></span>
          @for (data of source.data; track data) {
            <span><code adminCopyToClipboard>{{ data.key }}</code></span>
            <span class="data-value"><code adminCopyToClipboard>{{ data.value }}</code></span>
          }
        </div>
      </mat-card-content>
      <mat-menu #appMenu="matMenu">
        <ng-container>
          @if (source.status === 'Disabled') {
            <button mat-menu-item
              (click)="enableBuiltInPhotoSource(source)" class="enable-button">
              <mat-icon>visibility_on</mat-icon>
              Enable
            </button>
          }
          @if (source.status === 'Enabled') {
            <button mat-menu-item
              (click)="disableBuiltInPhotoSource(source)" class="disable-button">
              <mat-icon>visibility_off</mat-icon>
              Disable
            </button>
          }
          <button mat-menu-item
            (click)="openBuiltInPhotoSourceEditor(source.photoSourceId)" class="edit-button">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-menu-item
            (click)="deleteBuiltInPhotoSource(source)" class="delete-button">
            <mat-icon color="warn">delete</mat-icon>
            Delete
          </button>
        </ng-container>
      </mat-menu>
    </mat-card>
  }
  <div class="create-photo-source-list">
    @for (info of builtinsExternalPhotoSourceDefinitions; track info) {
      @if (!isProviderExistsForStore(info.providerId)) {
        <button mat-stroked-button
          color="primary"
          (click)="openAddBuiltInPhotoSourceEditor(info.providerId)">
          <span class="label">
            <img src="{{info.iconUrl}}"/>
            <span>Configure {{ info.label }} photo source</span>
          </span>
        </button>
      }
    }
  </div>
  <h2>Generic external photo sources</h2>
  @for (source of genericExternalPhotoSources; track source) {
    <mat-card class="photo-source-card"
      [attr.data-photoSourceId]="source.photoSourceId">
      <mat-card-header>
        <mat-card-title>{{ source.label }}</mat-card-title>
        <mat-card-subtitle><code adminCopyToClipboard>{{ source.photoSourceId }}</code></mat-card-subtitle>
        <div mat-card-avatar class="source-img">
          @if (source.icon) {
            <img [src]="source.icon">
          }
        </div>
        <span class="padding"></span>
        <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-button">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        @if (source.details) {
          <p class="mat-body">
            <mat-icon color="warn">warning</mat-icon>
            {{ source.details }}
          </p>
        }
        <div class="properties">
          <h2 class="mat-h2">
            Config
          </h2>
          <span>State</span>
          <span class><code>{{ source.status }}</code></span>
          @if (source.status === 'Disabled') {
            <span class="prop-warn"><mat-icon
            color="warn">warning</mat-icon>
            This source will not be listed in the designer until it's enabled
          </span>
        }
        <span>App Id</span>
        <span><code adminCopyToClipboard>{{ source.appId }}</code></span>
        <span>Scopes</span>
        <span><code adminCopyToClipboard>{{ source.scopes }}</code></span>
        <h2 class="mat-h2">
          Endpoints
        </h2>
        <span>Authorization url</span>
        <span><code adminCopyToClipboard>{{ source.authorizationUrl }}</code></span>
        <span>Token url</span>
        <span><code adminCopyToClipboard>{{ source.tokenUrl }}</code></span>
        <span>Album url</span>
        <span><code adminCopyToClipboard>{{ source.albumUrl }}</code></span>
        <span>Photo url</span>
        <span><code adminCopyToClipboard>{{ source.photoUrl }}</code></span>
      </div>
    </mat-card-content>
    <mat-menu #appMenu="matMenu">
      <ng-container>
        @if (source.status === 'Disabled') {
          <button mat-menu-item
            (click)="enableGenericPhotoSource(source)" class="enable-button">
            <mat-icon>visibility_on</mat-icon>
            Enable
          </button>
        }
        @if (source.status === 'Enabled') {
          <button mat-menu-item
            (click)="disableGenericPhotoSource(source)" class="disable-button">
            <mat-icon>visibility_off</mat-icon>
            Disable
          </button>
        }
        <button mat-menu-item
          (click)="editGenericExternalPhotoSource(source)" class="edit-button">
          <mat-icon>edit</mat-icon>
          Edit
        </button>
        <button mat-menu-item
          (click)="deleteGenericPhotoSource(source)" class="delete-button">
          <mat-icon color="warn">delete</mat-icon>
          Delete
        </button>
      </ng-container>
    </mat-menu>
  </mat-card>
}
<div class="add-source-action">
  <button mat-stroked-button color="primary" (click)="openAddGenericExternalPhotoSourceDialog()"
    class="add-source-button">
    <mat-icon>add</mat-icon>
    Add a generic external photo source
  </button>
</div>
<h2>Stock photo sources</h2>
@for (source of stockPhotoSources; track source) {
  <mat-card class="photo-source-card"
    [attr.data-id]="source.id">
    <mat-card-header>
      <mat-card-title>{{ source.label }}</mat-card-title>
      <mat-card-subtitle><code>{{ source.id }}</code></mat-card-subtitle>
      <div mat-card-avatar class="source-img">
        @if (source.thumbnailUrl) {
          <img [src]="source.thumbnailUrl">
        }
      </div>
      <span class="padding"></span>
      @if (canManageStockPhotoSource()) {
        <button mat-icon-button [matMenuTriggerFor]="appMenu"
          class="menu-button">
          <mat-icon>more_vert</mat-icon>
        </button>
      }
    </mat-card-header>
    <mat-card-content>
      <div class="properties">
        <h2 class="mat-h2">
          Config
        </h2>
        <span>State</span>
        <span><code>{{ source.disabled ? "disabled" : "enabled" }}</code></span>
        @if (source.disabled) {
          <span class="prop-warn">
            <mat-icon color="warn">warning</mat-icon>
            This source will not be listed in the designer until it's enabled
          </span>
        }
        @if (canManageStockPhotoSource()) {
          <h2 class="mat-h2">
            Endpoints
          </h2>
          <span>Thumbnail url</span>
          <span><code>{{ source.thumbnailUrl }}</code></span>
          <span>Photos url</span>
          <span><code>{{ source.photosEndpoint.url }}</code></span>
          <span>Albums url</span>
          <span><code>{{ source.albumsEndpoint?.url }}</code></span>
        }
      </div>
    </mat-card-content>
    <mat-menu #appMenu="matMenu">
      <ng-container>
        <button mat-menu-item
          [routerLink]="'/stock-photo-sources/' + source.id">
          <mat-icon>edit</mat-icon>
          Edit
        </button>
        <button mat-menu-item
          (click)="deleteStockPhotoSource(source)" class="delete-button">
          <mat-icon color="warn">delete</mat-icon>
          Delete
        </button>
      </ng-container>
    </mat-menu>
  </mat-card>
}
@if (canManageStockPhotoSource()) {
  <div class="add-source-action">
    <button mat-stroked-button color="primary" (click)="openStockPhotoSourceAddDialog()"
      class="add-source-button">
      <mat-icon>add</mat-icon>
      Add a stock photo source
    </button>
  </div>
}
<h2>Direct photo sources</h2>
@for (source of directPhotoSources; track source) {
  <mat-card class="photo-source-card"
    [attr.data-id]="source.name">
    <mat-card-header>
      <mat-card-title>{{ source.name }}</mat-card-title>
      <mat-card-subtitle><code>{{ source.name }}</code></mat-card-subtitle>
      <span class="padding"></span>
      @if (canManageStockPhotoSource()) {
        <button mat-icon-button [matMenuTriggerFor]="appMenu"
          class="menu-button">
          <mat-icon>more_vert</mat-icon>
        </button>
      }
      <mat-menu #appMenu="matMenu">
        <ng-container>
          <button mat-menu-item
            (click)="openDirectPhotoSourceEditor(source.name)" class="edit-button">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
        </ng-container>
      </mat-menu>
    </mat-card-header>
    <mat-card-content>
      <div class="properties">
        @if (canManageStockPhotoSource()) {
          <h3 class="mat-h3">
            Endpoints
          </h3>
          <span>Photos url</span>
          <span><code adminCopyToClipboard>{{ source.photoEndpoint.url }}</code></span>
          @if (source.photoEndpoint.headers.length) {
            <span>Photos headers</span>
            <ul>
              @for (header of source.photoEndpoint.headers; track header) {
                <li><code adminCopyToClipboard>{{ header.name }}</code></li>
              }
            </ul>
          }
        }
      </div>
    </mat-card-content>
  </mat-card>
}
<div class="add-source-action">
  <button mat-stroked-button color="primary" (click)="openDirectPhotoSourceAddDialog()"
    class="add-source-button">
    <mat-icon>add</mat-icon>
    Add a direct photo source
  </button>
</div>
</div>
</admin-page-simple-content>
