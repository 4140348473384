<admin-page-simple-content [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Fulfiller: Authorized Users'"
  [icon]="'perm_identity'">
  @if (fulfillerWithAuthorizedUsers) {
    <div class="content">
      @if (!canManageUsers()) {
        <p>
          <em>Please contact <a href="mailto:support@mediacliphub.com">support&#64;mediacliphub.com</a> to add or remove
        authorized users.</em>
      </p>
    }
    <div>
      <h2>Users with access to the fulfiller <code>{{fulfillerWithAuthorizedUsers.id}}</code></h2>
      <admin-users-role-management
        [users]="fulfillerWithAuthorizedUsers.authorizedUsers"
        [availableRoles]="fulfillerRoles"
        [loading]="pageStatus !== 'loaded'"
        [canManagePermissions]="canManageUsers()"
        (addUser)="openAddFulfillerRole(fulfillerWithAuthorizedUsers)"
        (addUserRole)="openAddFulfillerRole(fulfillerWithAuthorizedUsers, $event.user)"
        (roleRemoved)="removeFulfillerRole(fulfillerWithAuthorizedUsers, $event.email, $event.role)"
        >
      </admin-users-role-management>
    </div>
    <div>
      @if (pageStatus === 'saveError' && pageError) {
        <admin-error [error]="pageError"></admin-error>
      }
    </div>
  </div>
}
</admin-page-simple-content>
