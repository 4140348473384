@if (availableContexts.length > 10) {
  <mat-card>
    <mat-card-title>
      Select a subscription to configure
    </mat-card-title>
    <mat-card-content>
      <mat-form-field class="subscription-selector">
        <mat-label>Choose your subscription</mat-label>
        <input type="text"
          #selectedSubscriptionId
          aria-label="Select subscription"
          [(ngModel)]="contextFilterText"
          (change)="updateSelectedSubscription(selectedSubscriptionId.value)"
          (keydown)="updateFilter()"
          matInput
          [matAutocomplete]="subscriptionAutocomplete">
        <mat-autocomplete #subscriptionAutocomplete="matAutocomplete"
          (optionSelected)="updateSelectedSubscription(selectedSubscriptionId.value)">
          @for (context of filteredContexts; track context) {
            <mat-option [value]="context.subscriptionId">
              <span class="subscription-info">
                <span class="subscription-display-name">{{context.subscriptionLabel}}</span>
              </span>
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button (click)="selectSubscription(selectedSubscription)" [disabled]="!selectedSubscription" class="select-button"
        color="primary">
        SELECT
      </button>
    </mat-card-actions>
  </mat-card>
} @else {
  <div>
    <h2 class="mat-h2">
      Select a subscription to configure
    </h2>
    @for (subscription of filteredContexts; track subscription) {
      <admin-selectable-subscription-element [subscription]="subscription"
        (selectSubscription)="selectSubscription($event)">
      </admin-selectable-subscription-element>
    }
  </div>
}



