@if (!item.hidden) {
  <!-- normal collapse -->
  @if (!item.url && !item.function) {
    <a class="nav-link" [ngClass]="item.classes">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.url -->
  @if (item.url && !item.externalUrl && !item.function) {
    <a class="nav-link" [ngClass]="item.classes"
      [routerLink]="[item.url]" [queryParams]="item.query" [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
      [target]="item.openInNewTab ? '_blank' : '_self'">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.externalUrl -->
  @if (item.url && item.externalUrl && !item.function) {
    <a class="nav-link" [ngClass]="item.classes"
      [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.function -->
  @if (!item.url && item.function) {
    <span class="nav-link" [ngClass]="item.classes"
      (click)="item.function()">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>
  }
  <!-- item.url && item.function -->
  @if (item.url && !item.externalUrl && item.function) {
    <a class="nav-link" [ngClass]="item.classes"
      (click)="item.function()"
      [routerLink]="[item.url]" [queryParams]="item.query" [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.externalUrl && item.function -->
  @if (item.url && item.externalUrl && item.function) {
    <a class="nav-link" [ngClass]="item.classes"
      (click)="item.function()"
      [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <ng-template #itemContent>
    @if (item.icon) {
      <mat-icon class="nav-link-icon">{{item.icon}}</mat-icon>
    }
    <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
    @if (item.badge) {
      <span class="nav-link-badge" [translate]="item.badge.translate"
        [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
      </span>
    }
    <mat-icon class="collapsable-arrow">keyboard_arrow_right</mat-icon>
  </ng-template>
  <div class="children" [ngClass]="{'open': isOpen}">
    <div class="{{fuseConfig.layout.navbar.primaryBackground}}">
      @for (item of item.children; track item) {
        @if (item.type=='item') {
          <fuse-nav-horizontal-item [item]="item"></fuse-nav-horizontal-item>
        }
        @if (item.type=='collapsable') {
          <fuse-nav-horizontal-collapsable
          [item]="item"></fuse-nav-horizontal-collapsable>
        }
        @if (item.type=='group') {
          <fuse-nav-horizontal-collapsable
          [item]="item"></fuse-nav-horizontal-collapsable>
        }
      }
    </div>
  </div>
}
