<mat-toolbar class="p-0 mat-elevation-z1">

    <div class="toolbar">

        <div class="toolbar-left">

            <div class="navbar-toggle-button hide-xl">
                <button mat-icon-button
                        *ngIf="!hiddenNavbar && !rightNavbar"
                        (click)="toggleSidebarOpen('navbar')">
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>
            </div>

            <div class="toolbar-separator hide-xl" *ngIf="!hiddenNavbar && !rightNavbar"></div>

            <button mat-button
                    [matMenuTriggerFor]="storeMenu"
                    (menuOpened)="contextFilter.focus()"
                    class="store-button"
                    [ngClass]="{'search-mode': searchMode}">
                <div class="selected-context">
                    <ng-container [ngSwitch]="viewContext.type">
                        <ng-container *ngSwitchCase="'none'">
                            <span>None</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'store'">
                            <div class="store-info">
                                <span class="store-env"
                                      [ngClass]="['store-env-' + viewContext.storeEnvironment]">{{viewContext.storeEnvironment | shortStoreEnv}}
                                </span>
                                <span class="store-display-name" [ngClass]="{'striked-text': viewContext.status !== 'Active'}">{{ viewContext.storeLabel}}</span>
                                <span class="store-id" [ngClass]="{'striked-text': viewContext.status !== 'Active'}">{{ viewContext.storeId}}</span>
                                <span class="subscription-name">{{ viewContext.subscriptionLabel}}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'subscription'">
                            <span>Subscription: {{viewContext.subscriptionLabel}}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'library'">
                            <span>Library: {{viewContext.libraryOwner}}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'fulfiller'">
                            <span>Fulfiller: {{viewContext.fulfillerId}}</span>
                        </ng-container>
                    </ng-container>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #storeMenu="matMenu"
                      [overlapTrigger]="false"
                      class="context-selector-menu">

                <div mat-menu-item disableRipple="true" (click)="$event.stopPropagation();" class="store-field">
                    <input #contextFilter
                           type="text"
                           class="filter"
                           [(ngModel)]="contextFilterText"
                           (keyup)="updateFilter()"
                           placeholder="Filter"
                           (keydown)="preventMenuFocus($event)"
                           (keydown.enter)="selectFirstContext($event);"/>
                    <button mat-icon-button [matMenuTriggerFor]="filterConfig">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #filterConfig>
                        <button mat-menu-item (click)="toggleFilterShowActiveStores($event)">
                            <mat-icon color="accent" *ngIf="filter.showActiveStores">check_box</mat-icon>
                            <mat-icon color="accent" *ngIf="!filter.showActiveStores">check_box_outline_blank</mat-icon>
                            Stores - Active
                        </button>
                        <button mat-menu-item (click)="toggleFilterShowDeactivatedStores($event)">
                            <mat-icon color="accent" *ngIf="filter.showDeactivatedStores">check_box</mat-icon>
                            <mat-icon color="accent" *ngIf="!filter.showDeactivatedStores">check_box_outline_blank</mat-icon>
                            Stores - Deactivated
                        </button>
                        <button mat-menu-item (click)="toggleFilterShowSubscriptions($event)">
                            <mat-icon color="accent" *ngIf="filter.showSubscriptions">check_box</mat-icon>
                            <mat-icon color="accent" *ngIf="!filter.showSubscriptions">check_box_outline_blank
                            </mat-icon>
                            Subscriptions
                        </button>
                        <button mat-menu-item (click)="toggleFilterShowLibraries($event)">
                            <mat-icon color="accent" *ngIf="filter.showLibraries">check_box</mat-icon>
                            <mat-icon color="accent" *ngIf="!filter.showLibraries">check_box_outline_blank</mat-icon>
                            Libraries
                        </button>
                        <button mat-menu-item (click)="toggleFilterShowFulfillers($event)">
                            <mat-icon color="accent" *ngIf="filter.showFulfillers">check_box</mat-icon>
                            <mat-icon color="accent" *ngIf="!filter.showFulfillers">check_box_outline_blank</mat-icon>
                            Fulfillers
                        </button>
                    </mat-menu>
                </div>
                <ng-container *ngFor="let contextResult of filteredContexts; trackBy: identifyContext;">
                    <button mat-menu-item
                            *ngIf=" contextResult.obj as context"
                            class="context-element"
                            (click)="selectContext(context)"
                            [disabled]="areContextEquals(viewContext, context)">
                        <ng-container [ngSwitch]="context.type">
                            <ng-container *ngSwitchCase="'none'">
                                <span>None</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'store'">
                                <div class="store-context">
                                    <span class="store-env" [ngClass]="['store-env-' + context.storeEnvironment]">
                                        {{context.storeEnvironment | shortStoreEnv}}
                                    </span>
                                    <span class="store-display-name" [ngClass]="{'striked-text': context.status !== 'Active'}">
                                        <ng-container *ngFor="let m of highlight(contextResult, 'storeLabel')">
                                            <ng-container *ngIf="isString(m); else match">{{m}}</ng-container>
                                            <ng-template #match><span class="match-segment">{{m.match}}</span></ng-template>
                                        </ng-container>
                                    </span>
                                    <span class="store-id" [ngClass]="{'striked-text': context.status !== 'Active'}">
                                        <ng-container *ngFor="let m of highlight(contextResult, 'storeId')">
                                            <ng-container *ngIf="isString(m); else match">{{m}}</ng-container>
                                            <ng-template #match><span class="match-segment">{{m.match}}</span></ng-template>
                                        </ng-container>
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'subscription'">
                                <span class="subscription-context">
                                    <span>
                                        Sub:
                                        <ng-container *ngFor="let m of highlight(contextResult, 'subscriptionLabel')">
                                            <ng-container *ngIf="isString(m); else match">{{m}}</ng-container>
                                            <ng-template #match><span class="match-segment">{{m.match}}</span></ng-template>
                                        </ng-container>
                                    </span>
                                    <span class="subscription-id">
                                        {{context.subscriptionId}}
                                    </span>

                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'library'">
                                <span>
                                    Library:
                                    <ng-container *ngFor="let m of highlight(contextResult, 'libraryOwner')">
                                        <ng-container *ngIf="isString(m); else match">{{m}}</ng-container>
                                        <ng-template #match><span class="match-segment">{{m.match}}</span></ng-template>
                                    </ng-container>
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'fulfiller'">
                                <span>Fulfiller:
                                    <ng-container *ngFor="let m of highlight(contextResult, 'fulfillerId')">
                                        <ng-container *ngIf="isString(m); else match">{{m}}</ng-container>
                                        <ng-template #match><span class="match-segment">{{m.match}}</span></ng-template>
                                    </ng-container>
                                </span>
                            </ng-container>
                        </ng-container>
                    </button>
                </ng-container>
                <button mat-menu-item
                        class="filtered-result-indicator"
                        [disabled]="true"
                        *ngIf="someFilteredContextsHidden">
                    <span>Too many items, please use filter</span>
                </button>

            </mat-menu>
        </div>

        <div class="toolbar-right">

            <div class="toolbar-separator"></div>

            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button"
                    *ngIf="userAccount$ | async as userAccount">
                <div class="user-info">
                    <img class="avatar" [src]="userAccount.picture" *ngIf="userAccount.picture">
                    <span class="username">{{ userAccount.email }}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item class="" (click)="signOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <fuse-search-bar (go)="search($event)"
                             (collapsedChanged)="searchCollapsedChanged($event);"></fuse-search-bar>

            <div class="toolbar-separator"></div>

            <div class="quick-panel-toggle-button">
                <button mat-icon-button
                        (click)="toggleSidebarOpen('quickPanel')"
                        aria-label="Toggle quick panel">
                    <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
                </button>
            </div>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar"></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')">
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
