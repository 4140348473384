import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { AuthorizationContext, AuthorizationService, FulfillersService } from '../../services';
import { AppSecret, CreateAppSecret, Fulfiller, FulfillerPermission } from '../../../schema-dotnet';
import { ActivatedRoute } from '@angular/router';
import { PromptDialogService } from '../prompt-dialog/prompt-dialog.service';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';
import { PageStatus } from '../../models';
import { AppSecretService } from '../../services/app-secret.service';

@Component({
  templateUrl: './fulfiller-secrets-page.component.html',
  styleUrls: ['./fulfiller-secrets-page.component.scss']
})
export class FulfillerSecretsPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    authorizationContext: AuthorizationContext;

    fulfillerId: String = '';
    secrets: AppSecret[] = [];
    fulfillerWithSecrets?: Fulfiller | null;
    lastCreatedSecret?: CreateAppSecret;

    constructor(
        private readonly fulfillersService: FulfillersService,
        private readonly appSecretService: AppSecretService,
        private readonly route: ActivatedRoute,
        private readonly promptService: PromptDialogService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly authorizationService: AuthorizationService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.registerSubscription(this.route.params.subscribe(params => {
                const fulfillerId = params.fulfillerId;
                this.fulfillerId = fulfillerId;
                this.secrets = [];
                this.subscribeWithGenericLoadingErrorHandling(this.fulfillersService.getFulfillerSecrets(fulfillerId), (fulfillerWithSecrets) => {
                    this.fulfillerWithSecrets = fulfillerWithSecrets;
                    this.secrets = fulfillerWithSecrets?.secrets || [];
                });
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    revokeSecret(fulfiller: Fulfiller, secret: AppSecret) {
        if (!fulfiller.fulfillerAppId) {
            return;
        }
        this.confirmDialogService
            .confirm(`Revoke secret ${secret.label}`, `Do you really want to revoke the secret ${secret.label} for the fulfiller ${fulfiller.id} ?`, undefined, { yes: { warn: true } })
            .subscribe(_ => {
            this.subscribeWithGenericSavinErrorHandling(this.appSecretService.revokeSecret({appId: fulfiller.fulfillerAppId!, id: secret.id}), () => {
                const index = this.secrets.findIndex(s => s.id == secret.id);
                if (index !== -1) {
                    this.secrets.splice(index, 1);
                }
            })
        });
    }

    createSecret(fulfiller: Fulfiller) {
        if (!fulfiller.fulfillerAppId) {
            return;
        }
        this.promptService.prompt('Create a new secret', undefined, {defaultValue: this.secrets.length == 0 ? 'default' : undefined, fieldLabel: 'Label'})
            .subscribe(result => {
                this.pageStatus = PageStatus.saving;
                if (this.secrets.find(s => s.label == result)) {
                    this.pageError = new Error('This label is already used by another secret');
                    this.pageStatus = PageStatus.saveError;
                    return;
                }
                this.subscribeWithGenericSavinErrorHandling(this.appSecretService.createAppSecret({appId: fulfiller.fulfillerAppId!, label: result}), secret => {
                    this.secrets.push({id: secret.id, label: secret.label});
                    this.lastCreatedSecret = secret;
                })
            });
    }

    canManageSecrets(fulfiller: Fulfiller): boolean {
        return this.authorizationContext.hasFulfillerPermissions(fulfiller.id, FulfillerPermission.ManageFulfillerAppSecrets);
    }
}
