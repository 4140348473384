import { ActivatedRoute } from '@angular/router';
import { AuthorizationContext, AuthorizationService, SharedProjectsService } from 'app/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Maybe, Project, SharedProject, StorePermission } from 'schema-dotnet';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';
import { ViewContextService } from '../../navigation/view-context.service';

@Component({
    templateUrl: './shared-project-page.component.html',
    styleUrls: ['./shared-project-page.component.scss']
})
export class SharedProjectPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    sharedProjectId: string;

    sharedProject?: Maybe<SharedProject>;
    authContext?: AuthorizationContext;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authService: AuthorizationService,
        private readonly sharedProjectsService: SharedProjectsService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly viewContextService: ViewContextService,
    ) {
        super();
        this.sharedProjectId = this.route.snapshot.params.sharedProjectId;
    }

    ngOnInit(): void {
        this.loadSharedProject();
    }

    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    canReadProject(sharedProject: SharedProject) {
        return this.authContext?.hasStorePermissions(sharedProject.project.store.id, sharedProject.project.store.subscriptionId, StorePermission.ReadProjects);
    }

    canManageProject(sharedProject: SharedProject) {
        return this.authContext?.hasStorePermissions(sharedProject.project.store.id, sharedProject.project.store.subscriptionId, StorePermission.ManageProjects);
    }

    openRevokeSharing(sharedProject: SharedProject) {
        this.confirmDialogService.confirm(
            'Revoke project sharing',
            'Do you really want to revoke this project sharing?',
            undefined,
            {
                yes: {
                    label: 'Revoke',
                    warn: true
                },
                no: {
                    label: 'Cancel'
                }
            }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.sharedProjectsService.revokeSharedProject({ id: sharedProject.id }), () => {
                this.loadSharedProject();
            });
        });
    }


    private loadSharedProject() {
        this.subscribeWithGenericLoadingErrorHandling(this.sharedProjectsService.getSharedProject(this.sharedProjectId), (sharedProject) => {
            this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
            this.sharedProject = sharedProject;
            if (sharedProject) {
                this.viewContextService.selectStore(sharedProject.storeId);
            }
        });
    }

    protected readonly Date = Date;

    hasExpired() {
        if (!this.sharedProject?.expiration)
            return;

        return Date.now() - Date.parse(this.sharedProject.expiration) > 0
    }
}
