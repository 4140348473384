import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    PendingProjectExpirationConfig,
    ProjectExpirationConfig,
    Subscription,
    SubscriptionPermission
} from '../../../schema-dotnet';
import { AuthorizationContext, AuthorizationService, SubscriptionsService } from '../../services';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from "../confirm-dialog/confirm-dialog-service";
import { MatDialog } from "@angular/material/dialog";
import {
    UpdateSubscriptionProjectExpirationsDialogComponent,
    UpdateSubscriptionProjectExpirationsDialogData,
    UpdateSubscriptionProjectExpirationsDialogResult
} from "../../components/update-subscription-project-expirations-dialog/update-subscription-project-expirations-dialog";

@Component({
    selector: 'admin-subscription-cleanup-config',
    templateUrl: './subscription-cleanup-config-page.component.html',
    styleUrls: ['./subscription-cleanup-config-page.component.scss']
})
export class SubscriptionCleanupConfigPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private subscriptionId: any;

    subscriptionWithCleanup: Subscription;
    authContext: AuthorizationContext;
    currentProjectExpirations: ProjectExpirationConfig | undefined;
    pendingProjectExpirations: PendingProjectExpirationConfig | undefined;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly authorizationService: AuthorizationService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly dialog: MatDialog,
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });

        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.subscriptionId = params.subscriptionId;
                this.load();
            }));
    }

    load() {
        this.subscribeWithGenericLoadingErrorHandling(this.subscriptionsService.loadWithCleanup(this.subscriptionId), (subscriptionWithCleanup) => {
            this.subscriptionWithCleanup = subscriptionWithCleanup;
            this.currentProjectExpirations = this.subscriptionWithCleanup.cleanup.projectExpirations || {};
            this.pendingProjectExpirations = this.subscriptionWithCleanup.cleanup.pendingProjectExpirations || {};
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    cancelPendingProjectExpiration() {
        this.confirmDialogService
            .confirm(
                'Confirmation',
                'Do you want to cancel this configuration request?',
            )
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(
                    this.subscriptionsService.cancelSubscriptionPendingProjectExpirations({
                        subscriptionId: this.subscriptionWithCleanup.id,
                    }),
                    () => {
                        this.load();
                    });
            });
    }

    editExpirations() {
        let dialogRef = this.dialog.open<UpdateSubscriptionProjectExpirationsDialogComponent, UpdateSubscriptionProjectExpirationsDialogData, UpdateSubscriptionProjectExpirationsDialogResult>(
            UpdateSubscriptionProjectExpirationsDialogComponent,
            {
                data: <UpdateSubscriptionProjectExpirationsDialogData>{
                    photobook: this.currentProjectExpirations?.photobookDelayInDays,
                    gifting: this.currentProjectExpirations?.giftingDelayInDays,
                    print: this.currentProjectExpirations?.printDelayInDays
                }
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.subscriptionsService.updateSubscriptionCleanupDelay({
                        subscriptionId: this.subscriptionWithCleanup.id,
                        photobookProjectCleanupDelayInDays: result.photobook,
                        giftingProjectCleanupDelayInDays: result.gifting,
                        printProjectCleanupDelayInDays: result.print
                    }
                ),
                () => {
                    this.load();
                }
            );
        });
    }

    canManageSubscription(): boolean {
        return this.authContext.hasSubscriptionPermissions(this.subscriptionWithCleanup.id, SubscriptionPermission.ManageSubscriptionMetadata);
    }
}
