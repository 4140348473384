import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';
import {LocalFilterDefinition, LocalFilterName, LocalFilterSelectionMode, MediaclipMutationUpdateLocalFiltersConfigurationArgs} from '../../schema-dotnet';

@Injectable()
export class PhotoFilterService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    public getFiltersDefinitions(): Observable<LocalFilterDefinition[]> {
        return this.graphDotnetService.query(`
                query getLocalFilterDefinitions {
                    localFilterDefinitions {
                        displayName
                        id
                    }
                }
            `,
            {}
        ).pipe(map((q) => q.localFilterDefinitions));
    }

    public getLocalFiltersConfiguration(
        storeId: string
    ): Observable<{ photoFiltersBlockList?: LocalFilterName[] | null; photoFiltersAllowList?: LocalFilterName[] | null }> {
        return this.graphDotnetService.query(`
                query getLocalFiltersConfiguration($storeId: String!) {
                    store(id: $storeId) {
                        localFilters {
                            allowList
                            blockList
                        }
                    }
                }
            `,
            {storeId}
        ).pipe(map((q) => {
                return {
                    photoFiltersBlockList: q.store.localFilters?.blockList,
                    photoFiltersAllowList: q.store.localFilters?.allowList
                };
            }
        ));
    }

    public updateList(storeId: string, mode: LocalFilterSelectionMode, filters?: LocalFilterName[]): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateLocalFiltersConfigurationArgs>(
            `
                mutation updateLocalFiltersConfiguration($input: UpdateLocalFiltersConfigurationInput!) {
                    updateLocalFiltersConfiguration(input: $input)
                }
            `,
            {
                input: {
                    storeId: storeId,
                    mode: mode,
                    filters: filters
                }
            }
        ).pipe(map(m => m.updateLocalFiltersConfiguration));
    }
}
