import { AdminComponentsModule } from 'app/components/components.module';
import { AdminDirectivesModule } from 'app/directives/directives.module';
import { AdminPipesModule } from 'app/pipes/pipes.module';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseChartDirective, } from "ng2-charts";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AnomaliesPageContainerComponent } from './anomalies-page/anomalies-page.component';
import { AuthorizedUsersPageContainerComponent } from './authorized-users-page/authorized-users-page.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DashboardsOverviewPageContainerComponent } from './dashboards-overview-page/dashboards-overview-page.component';
import { Error404PageContainerComponent } from './error-404-page/error-404-page.component';
import { FulfillerPageContainerComponent } from './fulfiller-page/fulfiller-page.component';
import { FulfillersPageContainerComponent } from './fulfillers-page/fulfillers-page.component';
import { HomePageContainerComponent } from './home-page/home-page.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { MediaclipDesignerComponent } from './mediaclip-designer/mediaclip-designer.component';
import { OrderPageContainerComponent } from './order-page/order-page.component';
import { OrdersPageContainerComponent } from './orders-page/orders-page.component';
import { ExternalPhotoSourcesPageComponent } from './external-photo-source/external-photo-sources-page.component';
import { GenericExternalPhotoSourceAddDialogComponent } from './external-photo-source/generic-external-photo-source-add-dialog.component';
import { ProductPageContainerComponent } from './product-page/product-page.component';
import { ProductsPageContainerComponent } from './products-page/products-page.component';
import { ProjectPageContainerComponent } from './project-page/project-page.component';
import { SharedProjectPageContainerComponent } from './shared-project-page/shared-project-page.component';
import { RootPageContainerComponent } from './root-page/root-page.component';
import { SearchPageContainerComponent } from './search-page/search-page.component';
import { SignInPageContainerComponent } from './signin/signin-page.component';
import { StoreDesignerCustomCssComponent } from './store-designer-custom-css/store-designer-custom-css.component';
import { StoreDesignerCustomJavascriptComponent } from './store-designer-custom-javascript/store-designer-custom-javascript.component';
import { StoreDesignerLogoComponent } from './store-designer-logo/store-designer-logo.component';
import { StoreDesignerVersionConfigurationComponent } from './store-designer-release-channel-configuration/store-designer-release-channel-configuration.component';
import { StoreEndpointsPageContainerComponent } from './store-endpoints-page/store-endpoints-page.component';
import { StoreFulfillerLogsPageContainerComponent } from './store-fulfiller-logs-page/store-fulfiller-logs-page.component';
import { StoreFulfillerPageContainerComponent } from './store-fulfiller-page/store-fulfiller-page.component';
import { StoreFulfillersPageContainerComponent } from './store-fulfillers-page/store-fulfillers-page.component';
import { StoreLogsPageContainerComponent } from './store-logs-page/store-logs-page.component';
import { StorePhotoFilterConfigurationsComponent } from './store-photo-filter-configurations/store-photo-filter-configurations.component';
import { StoreSecretsPageContainerComponent } from './store-secrets-page/store-secrets-page.component';
import { StoreSelectorPageComponent } from './store-selector-page/store-selector-page.component';
import { SubscriptionSelectorPageComponent } from './subscription-selector-page/subscription-selector-page.component'
import { StoreSettingsPageContainerComponent } from './store-settings-page/store-settings-page.component';
import { StoreStatusPageContainerComponent } from './store-status-page/store-status-page.component';
import { StylePageContainerComponent } from './style-page/style-page.component';
import { SubscriptionPageContainerComponent } from './subscription-page/subscription-page.component';
import { SubscriptionsPageContainerComponent } from './subscriptions-page/subscriptions-page.component';
import { UserPageContainerComponent } from './user-page/user-page.component';
import { StoreRemoteFilterPageComponent } from './store-remote-filter-page/store-remote-filter-page.component';
import { EditRemoteFilterDialogComponent } from './edit-remote-filter-dialog/edit-remote-filter-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { LibrariesPageComponent } from './libraries-page/libraries-page.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { RecentOrderPageComponent } from './recent-order-page/recent-order-page.component';
import { GenericExternalPhotoSourceEditPageComponent } from './external-photo-source/generic-external-photo-source-edit-page.component';
import { BuiltInExternalPhotoSourceEditPageComponent } from './external-photo-source/built-in-external-photo-source-edit-page.component';
import { UsersRolesManagementComponent } from './authorized-users-page/users-roles-management.component';
import { AddAuthorizedUserDialogComponent } from './authorized-users-page/add-authorized-user-dialog.component';
import { SubscriptionAuthorizedUserPageComponent } from './subscription-page/subscription-authorized-user-page.component';
import { SubscriptionCleanupConfigPageComponent } from './subscription-page/subscription-cleanup-config-page.component';
import { CreateStoreDialogComponent } from './subscription-page/create-store-dialog.component';
import { SubscriptionStoresComponentComponent } from './subscription-page/subscription-stores-component.component';
import { AddStoreFulfillerDialogComponent } from './store-fulfillers-page/add-store-fulfiller-dialog.component';
import { StoreDesignerCustomLocalesComponentComponent } from './store-designer-custom-locales-component/store-designer-custom-locales-component.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddLocaleDialogComponent } from './store-designer-custom-locales-component/add-locale-dialog.component';
import { StockPhotoSourcesPageContainerComponent } from './stock-photo-sources-page/stock-photo-sources-page.component';
import { AddFulfillerDialogComponent } from './fulfillers-page/add-fulfiller-dialog.component';
import { CreateFulfillerAppDialogComponent } from './fulfiller-page/create-fulfiller-app-dialog.component';
import { LinkFulfillerAppDialogComponent } from './fulfiller-page/link-fulfiller-app-dialog.component';
import { EditFulfillerDialogComponent } from './fulfiller-page/edit-fulfiller-dialog.component';
import { LibraryPageComponent } from './libraries-page/library-page.component';
import { LibraryAuthorizedUserComponent } from './libraries-page/library-authorized-user.component';
import { FulfillerAuthorizedUserComponent } from './fulfiller-page/fulfiller-authorized-user.component';
import { FulfillerSecretsPageComponent } from './fulfiller-page/fulfiller-secrets-page.component';
import { CreateOrderComponent } from './orders-page/create-order.component';
import { SubscriptionBillingPageComponent } from './subscription-page/subscription-billing-page.component';
import { AdminUsersPageComponent } from './admin-users/admin-users-page.component';
import { AdminUserPageComponent } from './admin-users/admin-user-page.component';
import { StoreFulfillerEnableLogDialogComponent } from './store-fulfiller-page/store-fulfiller-enable-log-dialog.component';
import { AddStockPhotoSourceDialogComponent } from "./stock-photo-sources-page/add-stock-photo-source-dialog.component";
import { StockPhotoSourcePageComponent } from './stock-photo-source-page/stock-photo-source-page.component';
import { StockPhotoSourceAddDialogComponent} from "./external-photo-source/stock-photo-source-add-dialog.component";
import { RelatedStoresStockPhotoSourceDialogComponent } from "./stock-photo-sources-page/related-store-stock-photo-source-dialog.component";
import { PhotoPageComponent } from './photo-page/photo-page.component';
import { GeneratedImagesPageContainerComponent } from "./generated-images-page/generated-images-page.component";
import { GeneratedImagePageComponent } from "./generated-image-page/generated-image-page.component";
import { GeneratedImageAssetsEditorComponent } from './generated-image-page/generated-image-assets-editor.component';
import { GeneratedImageStoreAssociationsPageComponent } from './generated-image-page/generated-image-store-associations-page.component';
import { GeneratedImageStoreAssociationPageComponent } from './generated-image-page/generated-image-store-association-page.component';
import { GeneratedImageConfigEditorComponent } from './generated-image-page/generated-image-config-editor.component';
import { ManageDesignerReleaseChannelVersionPageContainerComponent } from "./manage-designer-release-channel-version/manage-designer-release-channel-version.component";
import { StorePhotosUpscalingPageContainerComponent } from "./store-photos-upscaling-page/store-photos-upscaling-page.component";
import { StoreGeneratedTextPageComponent } from "./store-generated-text-page/store-generated-text-page.component";
import { ConsolidateUserDialogComponent } from './user-page/consolidate-user-dialog.component';
import {
    StoreFilterCategoriesPageComponent
} from "./store-filter-categories-page/store-filter-categories-page.component";
import {  EditFilterCategoryDialogComponent } from "./edit-filter-category-dialog/edit-filter-category.component";
import { DirectPhotoSourceAddDialogComponent } from './external-photo-source/direct-photo-source-add-dialog.component';
import { DirectPhotoSourceEditPageComponent } from './external-photo-source/direct-photo-source-edit-page.component';
import { RequestLoggerPageComponent } from './request-logger/request-logger-page.component';
import { AddRequestLoggerRuleDialogComponent } from './request-logger/add-request-logger-rule-dialog.component';
import { AddSubscriptionDialogComponent } from './subscriptions-page/add-subscription-dialog.component';
import { StoreLogsGroupComponent } from './store-logs-page/store-logs-group.component'
import {EditInvoiceDialogComponent} from "./subscription-page/edit-invoice-dialog.component";

@NgModule({
    declarations: [
        // Pages
        RootPageContainerComponent,
        SignInPageContainerComponent,
        Error404PageContainerComponent,
        HomePageContainerComponent,
        SearchPageContainerComponent,
        DashboardsOverviewPageContainerComponent,
        OrdersPageContainerComponent,
        OrderPageContainerComponent,
        OrderPageContainerComponent,
        ProjectPageContainerComponent,
        SharedProjectPageContainerComponent,
        UserPageContainerComponent,
        ProductPageContainerComponent,
        ProductsPageContainerComponent,
        StoreEndpointsPageContainerComponent,
        StoreSettingsPageContainerComponent,
        StoreStatusPageContainerComponent,
        ExternalPhotoSourcesPageComponent,
        StoreSecretsPageContainerComponent,
        AnomaliesPageContainerComponent,
        SubscriptionsPageContainerComponent,
        SubscriptionPageContainerComponent,
        AuthorizedUsersPageContainerComponent,
        StoreFulfillersPageContainerComponent,
        StoreFulfillerLogsPageContainerComponent,
        StoreFulfillerPageContainerComponent,
        FulfillersPageContainerComponent,
        FulfillerPageContainerComponent,
        StoreLogsPageContainerComponent,
        LibraryAuthorizedUserComponent,
        StylePageContainerComponent,
        MediaclipDesignerComponent,
        StoreDesignerCustomJavascriptComponent,
        ManageDesignerReleaseChannelVersionPageContainerComponent,
        // Dialogs
        GenericExternalPhotoSourceAddDialogComponent,
        DirectPhotoSourceAddDialogComponent,
        StockPhotoSourceAddDialogComponent,
        ConfirmDialogComponent,
        EditInvoiceDialogComponent,
        StoreSelectorPageComponent,
        SubscriptionSelectorPageComponent,
        StoreDesignerCustomCssComponent,
        StoreDesignerVersionConfigurationComponent,
        StorePhotoFilterConfigurationsComponent,
        StorePhotosUpscalingPageContainerComponent,
        StoreGeneratedTextPageComponent,
        StoreDesignerLogoComponent,
        StoreRemoteFilterPageComponent,
        StoreFilterCategoriesPageComponent,
        EditRemoteFilterDialogComponent,
        LibrariesPageComponent,
        PromptDialogComponent,
        RecentOrderPageComponent,
        GenericExternalPhotoSourceEditPageComponent,
        BuiltInExternalPhotoSourceEditPageComponent,
        UsersRolesManagementComponent,
        AddAuthorizedUserDialogComponent,
        SubscriptionAuthorizedUserPageComponent,
        SubscriptionCleanupConfigPageComponent,
        CreateStoreDialogComponent,
        SubscriptionStoresComponentComponent,
        AddStoreFulfillerDialogComponent,
        StoreDesignerCustomLocalesComponentComponent,
        AddLocaleDialogComponent,
        AddStockPhotoSourceDialogComponent,
        StockPhotoSourcesPageContainerComponent,
        AddFulfillerDialogComponent,
        CreateFulfillerAppDialogComponent,
        LinkFulfillerAppDialogComponent,
        EditFulfillerDialogComponent,
        LibraryPageComponent,
        FulfillerAuthorizedUserComponent,
        FulfillerSecretsPageComponent,
        CreateOrderComponent,
        SubscriptionBillingPageComponent,
        StockPhotoSourcePageComponent,
        AdminUsersPageComponent,
        AdminUserPageComponent,
        StoreFulfillerEnableLogDialogComponent,
        RelatedStoresStockPhotoSourceDialogComponent,
        PhotoPageComponent,
        GeneratedImagesPageContainerComponent,
        GeneratedImagePageComponent,
        GeneratedImageAssetsEditorComponent,
        GeneratedImageStoreAssociationsPageComponent,
        GeneratedImageStoreAssociationPageComponent,
        GeneratedImageConfigEditorComponent,
        EditFilterCategoryDialogComponent,
        ConsolidateUserDialogComponent,
        DirectPhotoSourceEditPageComponent,
        RequestLoggerPageComponent,
        AddRequestLoggerRuleDialogComponent,
        AddSubscriptionDialogComponent,

        StoreLogsGroupComponent
    ],
    imports: [
        CommonModule,
        RouterModule,

        FuseSharedModule,

        LayoutsModule,

        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCardModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatTabsModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatListModule,
        MatTooltipModule,
        MatMenuModule,
        ScrollingModule,
        MatRippleModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,

        CodemirrorModule,
        BaseChartDirective,

        AdminDirectivesModule,
        AdminPipesModule,
        AdminComponentsModule,
        MatSortModule,
        MatToolbarModule,
        MatSliderModule,
        MatExpansionModule
    ],
    exports: [
        // Pages
        RootPageContainerComponent,
        SignInPageContainerComponent,
        Error404PageContainerComponent,
        HomePageContainerComponent,
        SearchPageContainerComponent,
        DashboardsOverviewPageContainerComponent,
        OrdersPageContainerComponent,
        OrderPageContainerComponent,
        OrderPageContainerComponent,
        ProjectPageContainerComponent,
        ProjectPageContainerComponent,
        UserPageContainerComponent,
        ProductPageContainerComponent,
        ProductsPageContainerComponent,
        StoreEndpointsPageContainerComponent,
        StoreSettingsPageContainerComponent,
        StoreStatusPageContainerComponent,
        ExternalPhotoSourcesPageComponent,
        StoreSecretsPageContainerComponent,
        AnomaliesPageContainerComponent,
        SubscriptionsPageContainerComponent,
        SubscriptionPageContainerComponent,
        AuthorizedUsersPageContainerComponent,
        StoreFulfillersPageContainerComponent,
        StoreFulfillerLogsPageContainerComponent,
        StoreFulfillerPageContainerComponent,
        FulfillersPageContainerComponent,
        FulfillerPageContainerComponent,
        StoreLogsPageContainerComponent,
        StylePageContainerComponent,
        ManageDesignerReleaseChannelVersionPageContainerComponent,
        // Dialogs
        ConfirmDialogComponent,
        EditInvoiceDialogComponent,
        GenericExternalPhotoSourceAddDialogComponent
    ]
})
export class AdminContainersModule {
}
