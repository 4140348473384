<admin-page-simple-content id="generated-images-global"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Generated Image Definitions'"
  [icon]="'qr_code'">
  <div class="content">
    <div class="header">
      <mat-form-field class="dense-2" floatLabel="always">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Filter</mat-label>
        <input matInput
          (keydown)="updateFilter()"
          (keyup)="updateFilter()"
          [(ngModel)]="filter.name">
      </mat-form-field>
      @if (canManage()) {
        <button mat-stroked-button (click)="addGeneratedImage()">
          <mat-icon>add</mat-icon>
          Add Definition
        </button>
      }
    </div>

    <p>
      This page allows you to add a new definition of a generated image, see the <a
      class="external"
      target="_blank"
      href="https://doc.mediaclip.ca/hub/features/generated-images/#create-your-own-generated-image-definition">documentation<mat-icon>open_in_new</mat-icon></a> for more
      information.
    </p>

    @if (pageStatus === 'saveError' && pageError) {
      <admin-error [error]="pageError"></admin-error>
    }

    <table mat-table #generatedImageDefinitionsTable="matTable" [dataSource]="displayedGeneratedImageDefinitions"
      [trackBy]="generateImageDefinitionId" class="sources-table mat-elevation-z4">
      <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let generatedImageDefinition">
          <a [routerLink]="'./' + generatedImageDefinition.id">{{$generatedImageDefinition(generatedImageDefinition).displayName || $generatedImageDefinition(generatedImageDefinition).id}}</a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="alias">
        <mat-header-cell *matHeaderCellDef>Alias</mat-header-cell>
        <mat-cell *matCellDef="let generatedImageDefinition">
          <span>
            <code adminCopyToClipboard>{{$generatedImageDefinition(generatedImageDefinition).defaultAlias}}</code>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
        <mat-cell *matCellDef="let generatedImageDefinition">
          {{$generatedImageDefinition(generatedImageDefinition).description}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let generatedImageDefinition">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-button">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #appMenu="matMenu">
            <ng-container>
              @if (canManage()) {
                <button mat-menu-item [routerLink]="'./' + generatedImageDefinition.id"
                  >
                  <mat-icon>edit</mat-icon>
                  Edit
                </button>
              }
            </ng-container>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
  </div>
</admin-page-simple-content>
