<admin-page-simple-content [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="associationId ? 'Edit Generated Image': 'Add Generated Image'"
  [icon]="'qr_code'">
  <div class="content">
    @if (pageStatus === 'saveError' && pageError) {
      <admin-error [error]="pageError"></admin-error>
    }
    @if (formModel && selectedDefinition) {
      <form #form="ngForm">
        @if (formModel.warning !== undefined) {
          <p class="warn-notice">
            <mat-icon color="warn">warning</mat-icon>
            {{formModel.warning}}
          </p>
        }
        <mat-form-field>
          <mat-label>Generated Image Definition</mat-label>
          <mat-select name="definitionId"
            [(ngModel)]="formModel.definitionId"
            [disabled]="!!associationId"
            (valueChange)="selectDefinition($event)"
            required>
            @for (definition of availableDefinitions; track definition) {
              <mat-option
                [value]="definition.id">
                {{definition.displayName}} {{definition.subscriptionId ? '(Subscription)' : '(Global)'}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Alias</mat-label>
          <input matInput
            #alias="ngModel"
            name="alias"
            [(ngModel)]="formModel.alias"
            required>
          <mat-hint>The identifier used in projects or themes. To use this generated image, you must use <code>urn:mediaclip:generated-image:&lbrace;alias&rbrace;</code>.</mat-hint>
          @if (alias.control?.errors?.duplicate) {
            <mat-error>This alias is already used by another generated image</mat-error>
          }
          @if (alias.control?.errors?.required) {
            <mat-error>The alias is required</mat-error>
          }
          @if (alias.control?.errors?.pattern) {
            <mat-error>Invalid name, should only be alpha numeric lowercase</mat-error>
          }
        </mat-form-field>
        <admin-generated-image-config-editor [kind]="selectedDefinition.kind"
          [configs]="formModel.config"
          isDefinition="false"
          [(mapBoxAccessKey)]="mapBoxAccessKey">
        </admin-generated-image-config-editor>
        <admin-save-button [disabled]="form.invalid"
          (save)="save()"
          [pageStatus]="pageStatus">
          Save
        </admin-save-button>
      </form>
    } @else {
      No definition available
    }
  </div>
</admin-page-simple-content>
