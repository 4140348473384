import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogDataOptions} from './confirm-dialog.component';

@Injectable({ providedIn: 'root' })
export class ConfirmDialogService {
    constructor(
        private readonly dialog: MatDialog
    ) {
    }

    confirm(title: string, message: string, subMessage?: string,  options?: ConfirmDialogDataOptions): Observable<string> {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, string>(ConfirmDialogComponent, {
            data: { title: title, message: message, subMessage: subMessage, options }
        });
        return dialogRef.afterClosed().pipe(
            filter(r => !!r),
            map(r => r!)
        );
    }

}
