import { Component, OnInit } from '@angular/core';
import { Product } from 'schema-dotnet';
import { fuseAnimations } from '@fuse/animations';
import { LibraryService } from '../../services';
import { PageStatus } from '../../models';
import { ActivatedRoute } from '@angular/router';
import { GitLabUrlBuilder } from "../../utils/git-lab-url-builder.util";

@Component({
    selector: 'admin-products-page',
    templateUrl: './products-page.component.html',
    styleUrls: ['./products-page.component.scss'],
    animations: fuseAnimations
})
export class ProductsPageContainerComponent implements OnInit {
    storeId: string;

    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;

    products: Product[] = [];
    filteredProducts: Product[] = [];
    filter: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly libraryService: LibraryService,
        private readonly gitLabUrlBuilder: GitLabUrlBuilder
    ) {
        this.storeId = this.route.snapshot.params.storeId;
    }

    ngOnInit(): void {
        this.libraryService.getProducts(this.storeId).subscribe({
            next: (products) => {
                this.products = products;
                this.updateFilter();
                this.pageStatus = PageStatus.loaded;
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        });
    }

    updateFilter() {
        if (this.filter) {
            this.filteredProducts = this.products.filter(p =>
                p.id.includes(this.filter) || p.label?.includes(this.filter)
            );
        } else {
            this.filteredProducts = this.products;
        }
    }

    getGitlabLink(productId: string) {
        return this.gitLabUrlBuilder.GetResourceUrl(productId);
    }

    getThumbnailUrlWithStoreId(product: Product): string | undefined {
        if (!product.thumbnail)
            return undefined;
        let url = new URL(product.thumbnail)
        url.searchParams.append('data-storeId', this.storeId);
        return url.toString();
    }
}
