import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CreateAppSecret, Store} from '../../schema-dotnet';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class StoreSecretsService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getStoreSecrets(storeId: string): Observable<Store> {
        return this.graphDotnetService.query(`
                query getStoreSecrets($storeId: String!) {
                    store(id: $storeId) {
                        id
                        subscriptionId
                        secrets {
                            id
                            label
                        }
                    }
                }
            `,
            {storeId}
        ).pipe(map(queryResult => queryResult.store));
    }
}
