import {Component, OnInit} from '@angular/core';
import {AdminComponentBase} from "../../utils/admin-component-base";
import {AuthorizationContext, AuthorizationService, StockPhotoSourcesService} from "../../services";
import {ActivatedRoute} from "@angular/router";
import {
    GlobalPermission,
    HttpHeader,
    StockPhotoSourceConfig
} from "../../../schema-dotnet";

@Component({
  templateUrl: './stock-photo-source-page.component.html',
  styleUrls: ['./stock-photo-source-page.component.scss']
})
export class StockPhotoSourcePageComponent  extends AdminComponentBase implements OnInit {
    urlPattern = /^https?:\/\/.+$/;
    invalidUrlMessage = 'This url is not valid.';
    stockPhotoSource: StockPhotoSourceConfig;

    private authorizationContext: AuthorizationContext;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authorizationService: AuthorizationService,
        private readonly stockPhotoSourcesService: StockPhotoSourcesService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authorizationContext = authContext;
        });

        this.registerSubscription(
            this.route.params.subscribe(params => {
                let stockPhotoSourceId = params.stockPhotoSourceId;
                this.subscribeWithGenericLoadingErrorHandling(this.stockPhotoSourcesService.load(stockPhotoSourceId), (stockPhotoSourceConfig) => {
                    let stockPhotoSourceConfigNoNull = {...stockPhotoSourceConfig};
                    if (!stockPhotoSourceConfigNoNull.photosEndpoint.headers)
                        stockPhotoSourceConfigNoNull.photosEndpoint.headers = [];
                    if (!stockPhotoSourceConfigNoNull.albumsEndpoint)
                        stockPhotoSourceConfigNoNull.albumsEndpoint = { url: '', headers: []};
                    if (!stockPhotoSourceConfigNoNull.albumsEndpoint.headers)
                        stockPhotoSourceConfigNoNull.albumsEndpoint.headers = [];
                    this.stockPhotoSource = stockPhotoSourceConfigNoNull;
                });
            }));
    }

    canManageStockPhotoSource(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageStockPhotoSources);
    }

    save() {
        this.subscribeWithGenericSavinErrorHandling(this.stockPhotoSourcesService.updateStockPhotoSource({
            id: this.stockPhotoSource.id,
            label: this.stockPhotoSource.label,
            visible: this.stockPhotoSource.visible,
            disabled: this.stockPhotoSource.disabled,
            thumbnailUrl: this.stockPhotoSource.thumbnailUrl,
            photosEndpoint: this.stockPhotoSource.photosEndpoint,
            albumsEndpoint: this.stockPhotoSource.albumsEndpoint
        }), () => {
            this.reload();
        });
    }

    reload() {
        this.subscribeWithGenericLoadingErrorHandling(this.stockPhotoSourcesService.load(this.stockPhotoSource.id), source => {
            this.stockPhotoSource = source;
        });
    }

    removeHeader(headers: Array<HttpHeader>, index: number): void {
        headers.splice(index, 1);
    }

    addHeader(headers: Array<HttpHeader>): void {
        headers.push({
            name: '',
            value: ''
        });
    }
}
