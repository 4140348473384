import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneratedImagesService } from '../../services/generated-images.service';
import {GeneratedImageData, GeneratedImageDefinition, GeneratedImageKind, Store} from '../../../schema-dotnet';
import { forkJoin } from 'rxjs';
import { ViewContextService } from '../../navigation/view-context.service';
import { AbstractControl, NgForm, Validators } from '@angular/forms';
import {GeneratedImageConfigUtil} from './generated-image-config-util.service';

interface GeneratedImageStoreModel {
    definitionId: string;
    warning?: string;
    alias: string;
    config: GeneratedImageData;
}

@Component({
    templateUrl: './generated-image-store-association-page.component.html',
    styleUrls: ['./generated-image-store-association-page.component.scss']
})
export class GeneratedImageStoreAssociationPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    @ViewChild('form', { static: false })
    form: NgForm;

    storeId: string;
    associationId?: string;
    formModel?: GeneratedImageStoreModel;
    availableDefinitions: GeneratedImageDefinition[] = [];
    selectedDefinition?: GeneratedImageDefinition;
    mapBoxAccessKey?: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly generatedImagesService: GeneratedImagesService,
        private readonly viewContextService: ViewContextService,
        private readonly generatedImageConfigUtil: GeneratedImageConfigUtil,
    ) {
        super();
    }

    ngOnInit() {
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.storeId = params.storeId;
                let context = this.viewContextService.context;
                if (context.type !== 'store') {
                    throw new Error(`Invalid context. Expected context of type store but got ${context.type}`);
                }
                if (context.storeId !== this.storeId) {
                    throw new Error(`Invalid store. Expected storeId in the context to be the same as in the route. Route = '${this.storeId}' Context = '${context.type}'`);
                }

                this.associationId = params.associationId;
                if (this.associationId) {
                    this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                        this.generatedImagesService.loadStoreAssociation(this.storeId, this.associationId),
                        this.generatedImagesService.listStoreAssociations(this.storeId)
                    ]), ([existingAssociation, storeWithAssociations]) => {
                        this.availableDefinitions = [existingAssociation.definition];
                        this.selectedDefinition = existingAssociation.definition;
                        this.formModel = {
                            definitionId: existingAssociation.definition.id,
                            alias: existingAssociation.alias,
                            warning: existingAssociation.definition.warning || undefined,
                            config: {
                                headers: existingAssociation.configs.headers,
                                additionalProperties: existingAssociation.configs.additionalProperties,
                                editorParameters: existingAssociation.configs.editorParameters
                            }
                        };
                        this.mapBoxAccessKey = this.generatedImageConfigUtil.getMapBoxAccessToken(existingAssociation.configs);
                        this.addAliasValidator(storeWithAssociations, existingAssociation.alias);
                    });
                } else {
                    this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                        this.generatedImagesService.listAvailableDefinitionForStore(this.storeId),
                        this.generatedImagesService.listStoreAssociations(this.storeId)
                    ]), ([definitions, storeWithAssociations]) => {
                        this.availableDefinitions = this.listUnusedDefinitions(definitions, storeWithAssociations);
                        let preselectedDefinition = this.availableDefinitions[0];
                        if (preselectedDefinition) {
                            this.selectedDefinition = preselectedDefinition;
                            this.formModel = {
                                definitionId: preselectedDefinition.id,
                                alias: preselectedDefinition.defaultAlias,
                                warning: preselectedDefinition.warning || undefined,
                                config: {
                                    headers: [],
                                    additionalProperties: [],
                                    editorParameters: []
                                }
                            };
                        }
                        this.addAliasValidator(storeWithAssociations);
                    });
                }
            })
        );
    }

    private addAliasValidator(storeWithAssociations: Store, currentAlias?: string) {
        setTimeout(() => {
            this.form.controls['alias'].clearValidators();
            this.form.controls['alias'].addValidators([
                Validators.required,
                Validators.pattern('^[a-z0-9-_]+$'),
                (control: AbstractControl) => {
                    if (control.value) {
                        if (control.value === currentAlias) {
                            return null;
                        }
                        if (storeWithAssociations.generatedImageAssociations.find(x => x.alias === control.value)) {
                            return { ['duplicate']: true };
                        }
                    }
                    return null;
                }]);
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    selectDefinition(definitionId: string) {
        let definition = this.availableDefinitions.find(x => x.id === definitionId);
        if (!definition)
            return;
        if (!this.formModel)
            return;
        this.formModel.alias = definition.defaultAlias;
        this.formModel.warning = definition.warning || undefined;
    }

    save() {
        if (!this.formModel) {
            return;
        }
        const formModel = this.formModel;
        const definition = this.availableDefinitions.find(x => x.id === formModel.definitionId);
        if (!definition) {
            return;
        }

        if (definition.kind === GeneratedImageKind.MapBox) {
            this.generatedImageConfigUtil.updateMapBoxApiKey(this.formModel.config, this.mapBoxAccessKey)
        }
        if (this.associationId) {
            this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.updateGeneratedImageStoreAssociation({
                id: this.associationId,
                alias: formModel.alias,
                configs: formModel.config
            }), () => {
                this.router.navigate(['..'], { relativeTo: this.route });
            });
        } else {
            this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.createGeneratedImageStoreAssociation({
                storeId: this.storeId,
                alias: formModel.alias,
                configs: formModel.config,
                generatedImageDefinitionId: formModel.definitionId
            }), () => {
                this.router.navigate(['..'], { relativeTo: this.route });
            });
        }
    }

    private listUnusedDefinitions(
        allDefinitions: GeneratedImageDefinition[],
        storeWithAssociations: Store): GeneratedImageDefinition[] {
        return allDefinitions.filter(x => !storeWithAssociations.generatedImageAssociations.find(a => a.id === x.id));
    }
}
