<admin-page-simple-content id="store-fulfillers"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Orders: Action Required'"
  [icon]="'warning'">
  <div class="content">
    @if (!lines.length) {
      <div>
        <h2>No results</h2>
      </div>
    }

    @if (lines?.length) {
      <table mat-table class="orders-table mat-elevation-z4" [dataSource]="lines">
        <!-- Store Order ID Column -->
        <ng-container matColumnDef="storeOrderId">
          <mat-header-cell *matHeaderCellDef>Store Order Id</mat-header-cell>
          <mat-cell *matCellDef="let line">
            <p class="text-truncate">{{$orderLine(line).order.storeOrderId}} (line {{$orderLine(line).storeOrderLineNumber}})</p>
          </mat-cell>
        </ng-container>
        <!-- Date Created Column -->
        <ng-container matColumnDef="dateCreatedUtc">
          <mat-header-cell *matHeaderCellDef>Date Created</mat-header-cell>
          <mat-cell *matCellDef="let line">
            <p class="text-truncate">
              {{$orderLine(line).dateCreatedUtc | date:'mediumDate'}}
              <span>({{$orderLine(line).dateCreatedUtc | fromNow}})</span>
            </p>
          </mat-cell>
        </ng-container>
        <!-- Date Created Column -->
        <ng-container matColumnDef="shortDate">
          <mat-header-cell *matHeaderCellDef>Date Created</mat-header-cell>
          <mat-cell *matCellDef="let line">
            <p class="text-truncate">
              <span>{{$orderLine(line).dateCreatedUtc | fromNow}}</span>
            </p>
          </mat-cell>
        </ng-container>
        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let line">
            <admin-line-status [status]="$orderLine(line).status.value" [flags]="$orderLine(line).status.flags"></admin-line-status>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
        <mat-row *matRowDef="let line; columns: displayedColumns;" class="order" mat-ripple
          [routerLink]="'/stores/'+$orderLine(line).store.id+'/orders/'+$orderLine(line).order.storeOrderId">
        </mat-row>
      </table>
    }

    @if (lines?.length >= 200) {
      <div class="too-many-results warn">
        <p>Some results may have been truncated.</p>
      </div>
    }

  </div>
</admin-page-simple-content>
