<button #button class="mat-button-toggle-button mat-focus-indicator"
        type="button"
        [id]="buttonId"
        [attr.role]="isSingleSelector() ? 'radio' : 'button'"
        [attr.tabindex]="disabled ? -1 : tabIndex"
        [attr.aria-pressed]="!isSingleSelector() ? checked : null"
        [attr.aria-checked]="isSingleSelector() ? checked : null"
        [disabled]="disabled || null"
        [attr.name]="_getButtonName()"
        [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledby"
        (click)="_onButtonClick()">
  <span class="mat-button-toggle-label-content">
    <!-- Render checkmark at the beginning for single-selection. -->
    @if (buttonToggleGroup && checked && !buttonToggleGroup.multiple && !buttonToggleGroup.hideSingleSelectionIndicator) {
      <mat-pseudo-checkbox
          class="mat-mdc-option-pseudo-checkbox"
          [disabled]="disabled"
          state="checked"
          aria-hidden="true"
          appearance="minimal"></mat-pseudo-checkbox>
    }
    <!-- Render checkmark at the beginning for multiple-selection. -->
    @if (buttonToggleGroup && checked && buttonToggleGroup.multiple && !buttonToggleGroup.hideMultipleSelectionIndicator) {
      <mat-pseudo-checkbox
          class="mat-mdc-option-pseudo-checkbox"
          [disabled]="disabled"
          state="checked"
          aria-hidden="true"
          appearance="minimal"></mat-pseudo-checkbox>
    }
    <ng-content></ng-content>
  </span>
</button>

<span class="mat-button-toggle-focus-overlay"></span>
<span class="mat-button-toggle-ripple" matRipple
     [matRippleTrigger]="button"
     [matRippleDisabled]="this.disableRipple || this.disabled">
</span>
