<admin-page-simple-content id="store-fulfillers"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Settings: Endpoints'"
  [icon]="'settings_ethernet'">
  @if (storeConfig) {
    <div class="content">
      <form #form="ngForm" (ngSubmit)="save()">
        <mat-form-field>
          <mat-label>Default cart page</mat-label>
          <input
            matInput
            [(ngModel)]="storeConfig.cartRedirectionPage.url"
            name="cartRedirectionPageUrl"
            placeholder="https://"
            [pattern]="urlPattern"
            type="url"
            autocomplete="off">
          <mat-error>{{invalidUrlMessage}}</mat-error>
          <mat-hint>
            The shopping cart page on your store (when adding to cart, if the "Add To Cart" endpoint
            is not configured).
          </mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Exit page</mat-label>
          <input
            matInput
            [(ngModel)]="storeConfig.exitPage.url"
            name="exitPageUrl"
            placeholder="https://"
            [pattern]="urlPattern"
            type="url"
            autocomplete="off">
          <mat-error>{{invalidUrlMessage}}</mat-error>
          <mat-hint>The store home page.</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Login page</mat-label>
          <input
            matInput
            [(ngModel)]="storeConfig.loginPage.url"
            name="loginPageUrl"
            placeholder="https://"
            [pattern]="urlPattern"
            type="url"
            autocomplete="off">
          <mat-error>{{invalidUrlMessage}}</mat-error>
          <mat-hint>The url where the user need to be redirected when a login is required in the designer. You can use <code>&lcub;ProjectId&rcub;</code> in the url</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Corrections completed page</mat-label>
          <input
            matInput
            [(ngModel)]="storeConfig.correctionsCompletedPage.url"
            name="correctionsCompletedPageUrl"
            placeholder="https://"
            [pattern]="urlPattern"
            type="url"
            autocomplete="off">
          <mat-error>{{invalidUrlMessage}}</mat-error>
          <mat-hint>
            When the <a href="https://doc.mediaclip.ca/hub/features/proofing/" rel="external"
          target="_blank">proofing workflow</a> is enabled, where to redirect once
          corrections are complete.
        </mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Add to Cart Endpoint</mat-label>
        <input
          matInput
          [(ngModel)]="storeConfig.addToCartEndpoint.url"
          [required]="storeConfig.addToCartEndpoint.headers.length > 0"
          name="addToCartEndpointUrl"
          placeholder="https://"
          [pattern]="urlPattern"
          type="url"
          autocomplete="off">
        <mat-error>{{invalidUrlMessage}}</mat-error>
        <mat-hint>When adding to cart, this endpoint should create the line item in the shopping cart.
          <a href="https://doc.mediaclip.ca/hub/store-endpoints/add-to-cart/" rel="external"
          target="_blank">More info...</a>
        </mat-hint>
      </mat-form-field>
      <div class="headers-container">
        @for (header of storeConfig.addToCartEndpoint.headers; track header; let i = $index) {
          <div class="header-container">
            <mat-form-field>
              <mat-label>Header Name</mat-label>
              <input
                matInput
                [(ngModel)]="header.name"
                [name]="'addToCartEndpointEndpointName' + i"
                required
                autocomplete="off">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Header Value</mat-label>
              <input
                #secretInput
                matInput
                [(ngModel)]="header.value"
                [name]="'addToCartEndpointEndpointValue' + i"
                required
                autocomplete="off">
              <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
            </mat-form-field>
            <div class="actions">
              <button mat-icon-button
                type="button"
                (click)="removeHeader(storeConfig.addToCartEndpoint.headers, i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        }
        <button mat-stroked-button
          type="button"
          (click)="addHeader(storeConfig.addToCartEndpoint.headers)"
          class="add-header">
          <mat-icon>add</mat-icon>
          Add header
        </button>
      </div>
      <mat-form-field>
        <mat-label>Get Price Endpoint</mat-label>
        <input
          matInput
          [(ngModel)]="storeConfig.getPriceEndpoint.url"
          [required]="storeConfig.getPriceEndpoint.headers.length > 0"
          name="getPriceEndpointUrl"
          placeholder="https://"
          [pattern]="urlPattern"
          type="url"
          autocomplete="off">
        <mat-error>{{invalidUrlMessage}}</mat-error>
        <mat-hint>
          Fetch the price to show for a project. <a
          href="https://doc.mediaclip.ca/hub/store-endpoints/get-price/" rel="external"
        target="_blank">More info...</a>
      </mat-hint>
    </mat-form-field>
    <div class="headers-container">
      @for (header of storeConfig.getPriceEndpoint.headers; track header; let i = $index) {
        <div class="header-container">
          <mat-form-field>
            <mat-label>Header Name</mat-label>
            <input
              matInput
              [(ngModel)]="header.name"
              [name]="'getPriceEndpointName' + i"
              required
              autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Header Value</mat-label>
            <input
              matInput
              #secretInput
              [(ngModel)]="header.value"
              [name]="'getPriceEndpointValue' + i"
              required
              autocomplete="off">
            <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
          </mat-form-field>
          <div class="actions">
            <button mat-icon-button
              type="button"
              (click)="removeHeader(storeConfig.getPriceEndpoint.headers, i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      }
      <button mat-stroked-button
        type="button"
        (click)="addHeader(storeConfig.getPriceEndpoint.headers)"
        class="add-header">
        <mat-icon>add</mat-icon>
        Add header
      </button>
    </div>
    <mat-form-field>
      <mat-label>Get Products Price Endpoint</mat-label>
      <input
        matInput
        [(ngModel)]="storeConfig.getProductsPriceEndpoint.url"
        [required]="storeConfig.getProductsPriceEndpoint.headers.length > 0"
        name="getProductsPriceEndpointUrl"
        placeholder="https://"
        [pattern]="urlPattern"
        type="url"
        autocomplete="off">
      <mat-error>{{invalidUrlMessage}}</mat-error>
      <mat-hint>
        Fetch the price to show for a list of products. <a
        href="https://doc.mediaclip.ca/hub/store-endpoints/get-products-price/" rel="external"
      target="_blank">More info...</a>
    </mat-hint>
  </mat-form-field>
  <div class="headers-container">
    @for (header of storeConfig.getProductsPriceEndpoint.headers; track header; let i = $index) {
      <div class="header-container">
        <mat-form-field>
          <mat-label>Header Name</mat-label>
          <input
            matInput
            [(ngModel)]="header.name"
            [name]="'getProductsPriceEndpointName' + i"
            required
            autocomplete="off">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Header Value</mat-label>
          <input
            matInput
            #secretInput
            [(ngModel)]="header.value"
            [name]="'getProductsPriceEndpointValue' + i"
            required
            autocomplete="off">
          <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
        </mat-form-field>
        <div class="actions">
          <button mat-icon-button
            type="button"
            (click)="removeHeader(storeConfig.getProductsPriceEndpoint.headers, i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    }
    <button mat-stroked-button
      type="button"
      (click)="addHeader(storeConfig.getProductsPriceEndpoint.headers)"
      class="add-header">
      <mat-icon>add</mat-icon>
      Add header
    </button>
  </div>
  <mat-form-field>
    <mat-label>Get Product Quantities Endpoint</mat-label>
    <input
      matInput
      [(ngModel)]="storeConfig?.getProductQuantitiesEndpoint.url"
      [required]="storeConfig.getProductQuantitiesEndpoint.headers.length > 0"
      name="getProductQuantitiesEndpointUrl"
      placeholder="https://"
      [pattern]="urlPattern"
      type="url"
      autocomplete="off">
    <mat-error>{{invalidUrlMessage}}</mat-error>
    <mat-hint>
      Fetch the product bundle quantities and associated prices.<a
      href="https://doc.mediaclip.ca/hub/store-endpoints/get-product-quantities/" rel="external"
    target="_blank">More info...</a>
  </mat-hint>
</mat-form-field>
<div class="headers-container">
  @for (header of storeConfig.getProductQuantitiesEndpoint.headers; track header; let i = $index) {
    <div class="header-container">
      <mat-form-field>
        <mat-label>Header Name</mat-label>
        <input
          matInput
          [(ngModel)]="header.name"
          [name]="'getProductQuantitiesEndpointName' + i"
          required
          autocomplete="off">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Header Value</mat-label>
        <input
          matInput
          #secretInput
          [(ngModel)]="header.value"
          [name]="'getProductQuantitiesEndpointValue' + i"
          required
          autocomplete="off">
        <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
      </mat-form-field>
      <div class="actions">
        <button mat-icon-button
          type="button"
          (click)="removeHeader(storeConfig.getProductQuantitiesEndpoint.headers, i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  }
  <button mat-stroked-button
    type="button"
    (click)="addHeader(storeConfig.getProductQuantitiesEndpoint.headers)"
    class="add-header">
    <mat-icon>add</mat-icon>
    Add header
  </button>
</div>
<mat-form-field>
  <mat-label>Get Alternate Products Endpoint</mat-label>
  <input
    matInput
    [(ngModel)]="storeConfig?.getAlternateProductsEndpoint.url"
    [required]="storeConfig.getAlternateProductsEndpoint.headers.length > 0"
    name="getAlternateProductsEndpointUrl"
    placeholder="https://"
    [pattern]="urlPattern"
    type="url"
    autocomplete="off">
  <mat-error>{{invalidUrlMessage}}</mat-error>
  <mat-hint>
    Fetch the product alternative products.<a
    href="https://doc.mediaclip.ca/hub/store-endpoints/get-alternative-products/" rel="external"
  target="_blank">More info...</a>
</mat-hint>
</mat-form-field>
<div class="headers-container">
  @for (header of storeConfig.getAlternateProductsEndpoint.headers; track header; let i = $index) {
    <div class="header-container">
      <mat-form-field>
        <mat-label>Header Name</mat-label>
        <input
          matInput
          [(ngModel)]="header.name"
          [name]="'getAlternateProductsEndpointName' + i"
          required
          autocomplete="off">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Header Value</mat-label>
        <input
          matInput
          #secretInput
          [(ngModel)]="header.value"
          [name]="'getAlternateProductsEndpointValue' + i"
          required
          autocomplete="off">
        <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
      </mat-form-field>
      <div class="actions">
        <button mat-icon-button
          type="button"
          (click)="removeHeader(storeConfig.getAlternateProductsEndpoint.headers, i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  }
  <button mat-stroked-button
    type="button"
    (click)="addHeader(storeConfig.getAlternateProductsEndpoint.headers)"
    class="add-header">
    <mat-icon>add</mat-icon>
    Add header
  </button>
</div>
<mat-form-field>
  <mat-label>Status Update Endpoint</mat-label>
  <input
    matInput
    [(ngModel)]="storeConfig.statusUpdateEndpoint.url"
    [required]="storeConfig.statusUpdateEndpoint.headers.length > 0"
    name="statusUpdateEndpointUrl"
    placeholder="https://"
    [pattern]="urlPattern"
    type="url"
    autocomplete="off">
  <mat-error>{{invalidUrlMessage}}</mat-error>
  <mat-hint>
    Receive updates after an order has been checked out. <a
    href="https://doc.mediaclip.ca/hub/store-endpoints/status-update/" rel="external"
  target="_blank">More info...</a>
</mat-hint>
</mat-form-field>
<div class="headers-container">
  @for (header of storeConfig.statusUpdateEndpoint.headers; track header; let i = $index) {
    <div class="header-container">
      <mat-form-field>
        <mat-label>Header Name</mat-label>
        <input
          matInput
          [(ngModel)]="header.name"
          [name]="'statusUpdateEndpointName' + i"
          required
          autocomplete="off">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Header Value</mat-label>
        <input
          matInput
          #secretInput
          [(ngModel)]="header.value"
          [name]="'statusUpdateEndpointValue' + i"
          required
          autocomplete="off">
        <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
      </mat-form-field>
      <div class="actions">
        <button mat-icon-button
          type="button"
          (click)="removeHeader(storeConfig.statusUpdateEndpoint.headers, i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  }
  <button mat-stroked-button
    type="button"
    (click)="addHeader(storeConfig.statusUpdateEndpoint.headers)"
    class="add-header">
    <mat-icon>add</mat-icon>
    Add header
  </button>
</div>
<mat-form-field>
  <mat-label>On Project Deleted Endpoint</mat-label>
  <input
    matInput
    [(ngModel)]="storeConfig.onProjectDeletedEndpoint.url"
    [required]="storeConfig.onProjectDeletedEndpoint.headers.length > 0"
    name="onProjectDeletedEndpointUrl"
    placeholder="https://"
    [pattern]="urlPattern"
    type="url"
    autocomplete="off">
  <mat-error>{{invalidUrlMessage}}</mat-error>
  <mat-hint>
    Once a project has been deleted. <a
    href="https://doc.mediaclip.ca/hub/store-endpoints/on-project-deleted/" rel="external"
  target="_blank">More info...</a>
</mat-hint>
</mat-form-field>
<div class="headers-container">
  @for (header of storeConfig.onProjectDeletedEndpoint.headers; track header; let i = $index) {
    <div class="header-container">
      <mat-form-field>
        <mat-label>Header Name</mat-label>
        <input
          matInput
          [(ngModel)]="header.name"
          [name]="'onProjectDeletedEndpointName' + i"
          required
          autocomplete="off">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Header Value</mat-label>
        <input
          matInput
          #secretInput
          [(ngModel)]="header.value"
          [name]="'onProjectDeletedEndpointValue' + i"
          required
          autocomplete="off">
        <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
      </mat-form-field>
      <div class="actions">
        <button mat-icon-button
          type="button"
          (click)="removeHeader(storeConfig.onProjectDeletedEndpoint.headers, i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  }
  <button mat-stroked-button
    type="button"
    (click)="addHeader(storeConfig.onProjectDeletedEndpoint.headers)"
    class="add-header">
    <mat-icon>add</mat-icon>
    Add header
  </button>
</div>
<admin-save-button
  [disabled]="!form.valid"
  [pageStatus]="pageStatus">
</admin-save-button>
</form>
</div>
}

<div>
  @if (pageStatus === 'saveError' && pageError) {
    <admin-error [error]="pageError"></admin-error>
  }
</div>
</admin-page-simple-content>
