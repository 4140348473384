<admin-page-simple-content [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Generated Image: ' + generatedImageDefinition?.displayName"
  [icon]="'qr_code'">
  @if (generatedImageDefinition) {
    <div class="content">
      <form #form="ngForm">
        <mat-tab-group dynamicHeight>
          <mat-tab label="Global settings">
            <div class="tab-content">
              <p>
                Any changes made to the configuration may take up to 2 minutes to become effective.
              </p>
              <mat-form-field>
                <mat-label>Display name</mat-label>
                <input matInput
                  [(ngModel)]="generatedImageDefinition.displayName"
                  name="displayName"
                  type="text"
                  autocomplete="off"
                  required>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput
                  [(ngModel)]="generatedImageDefinition.description"
                  name="description"
                  type="text"
                  autocomplete="off">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Default alias</mat-label>
                <input matInput
                  [(ngModel)]="generatedImageDefinition.defaultAlias"
                  name="defaultAlias"
                  pattern="[a-z0-9\-_]+"
                  type="text"
                  autocomplete="off"
                  required>
                <mat-hint>Alias suggested by default in the store integration</mat-hint>
                <mat-error>Alias is required and should only be alpha numeric lowercase</mat-error>
              </mat-form-field>
              @if (scope === 'global') {
                <mat-form-field>
                  <mat-label>Type</mat-label>
                  <mat-select placeholder="Type of generated image"
                    [(ngModel)]="generatedImageDefinition.kind"
                    name="kind"
                    aria-label="Type" required>
                    @for (kind of generatedImageKinds | keyvalue; track kind) {
                      <mat-option [value]="kind.key">{{kind.value}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
              <mat-form-field>
                <mat-label>Warning</mat-label>
                <input matInput
                  [(ngModel)]="generatedImageDefinition.warning"
                  name="warning"
                  type="text"
                  autocomplete="off">
                <mat-hint>Warning message to display in the store integration</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Endpoint URL</mat-label>
                <input matInput
                  name="configsUrl"
                  [(ngModel)]="generatedImageDefinition.endpoint"
                  [pattern]="urlPattern"
                  placeholder="https://"
                  type="url"
                  required>
                <mat-error>{{invalidUrlMessage}}</mat-error>
              </mat-form-field>
              <admin-generated-image-config-editor [kind]="generatedImageDefinition.kind"
                isDefinition="true"
                [configs]="generatedImageDefinition.configs"
                [(mapBoxAccessKey)]="mapBoxAccessKey">
              </admin-generated-image-config-editor>
              @if (pageStatus === 'saveError' && pageError) {
                <admin-error [error]="pageError"></admin-error>
              }
              @if (canManage()) {
                <admin-save-button
                  (save)="createOrUpdate()"
                  [disabled]="!form.valid"
                  [pageStatus]="pageStatus">
                </admin-save-button>
              }
            </div>
          </mat-tab>
          <mat-tab label="Editor settings">
            <div class="tab-content">
              <div class="config-checkbox">
                <mat-checkbox [(ngModel)]="generatedImageDefinition.preserveAspectRatio" name="preserveAspectRatio">
                  Preserve aspect ratio
                </mat-checkbox>
                <span class="mat-caption">Whether the elements can be freely resized or not.</span>
              </div>
              <mat-form-field>
                <mat-label>Max Width</mat-label>
                <input matInput [(ngModel)]="generatedImageDefinition.maxWidth" name="maxWidth" type="number"/>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Max Height</mat-label>
                <input matInput [(ngModel)]="generatedImageDefinition.maxHeight" name="maxHeight" type="number"/>
              </mat-form-field>
              @if (pageStatus === 'saveError' && pageError) {
                <admin-error [error]="pageError"></admin-error>
              }
              @if (canManage()) {
                <admin-save-button
                  (save)="createOrUpdate()"
                  [disabled]="!form.valid"
                  [pageStatus]="pageStatus">
                </admin-save-button>
              }
            </div>
          </mat-tab>
          @if (generatedImageDefinition.id) {
            <mat-tab label="Assets">
              <div class="tab-content">
                <admin-generated-image-assets-editor [generatedImageDefinition]="generatedImageDefinition">
                </admin-generated-image-assets-editor>
              </div>
            </mat-tab>
          }
        </mat-tab-group>
      </form>
    </div>
  }
</admin-page-simple-content>


