import {GlobalPermission} from 'schema-dotnet';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {AuthorizationContext, AuthorizationService,} from '../../services';
import {PageStatus} from "../../models";
import {DesignerBranchService} from "../../services/designer-branch.service";
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog-service';

enum Scope {
    global = 'global',
    subscription = 'subscription'
}

@Component({
    selector: 'admin-manage-designer-release-channel-version',
    templateUrl: './manage-designer-release-channel-version.component.html',
    styleUrls: ['./manage-designer-release-channel-version.component.scss'],
})
export class ManageDesignerReleaseChannelVersionPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {

    private authorizationContext: AuthorizationContext;

    subscriptionId?: string;
    scope: Scope;

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly designerBranchService: DesignerBranchService,
        private readonly confirmDialogService: ConfirmDialogService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authorizationContext = authContext;
        });
        this.setPageStatus(PageStatus.loaded);
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    canManage(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageDesignerBranch);
    }

    updateToDeprecated() {
        this.confirmDialogService.confirm('Pre-Deployment Stable', 'Are you sure you want to execute this action?', undefined, {yes: {label: 'Yes'}})
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.designerBranchService.switchNonAutomaticUpgradeStoresToDeprecatedStableChannel());
            })
    }

    updateToStable() {
        this.confirmDialogService.confirm('Post-deployment Stable (8 weeks later)', 'Are you sure you want to execute this action?', undefined, {yes: {label: 'Yes'}})
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.designerBranchService.switchStoresOnDeprecatedStableChannelToStableChannel());
            });
    }
}

