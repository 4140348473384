import { Pipe, PipeTransform } from '@angular/core';

import { StoreEnvironment } from '../../schema-dotnet';

@Pipe({
    name: 'shortStoreEnv',
})
export class ShortStoreEnvPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        switch (value) {
            case StoreEnvironment.Production:
                return 'prod';
            case StoreEnvironment.Staging:
                return 'stag';
            case StoreEnvironment.Development:
                return 'dev';
            case StoreEnvironment.Test:
                return 'test';
            case StoreEnvironment.Demonstration:
                return 'demo';
            default:
                return 'other';
        }
    }
}
