import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ViewContextStore} from '../../navigation/view-context.service';

@Component({
    selector: 'admin-selectable-store-element',
    templateUrl: './selectable-store-element.component.html',
    styleUrls: ['./selectable-store-element.component.scss'],
})
export class SelectableStoreElementComponent {
    @Input() store: ViewContextStore;
    @Input() lastAccessed?: number;
    @Output() selectStore: EventEmitter<ViewContextStore> = new EventEmitter<ViewContextStore>();

    constructor() {}
}
