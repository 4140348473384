@for (storeGroup of storeLogs; track storeGroup) {
  <div>
    <h2 [matTooltip]="storeGroup.timestampUtc | date:'mediumDate'">
      <mat-icon>calendar_today</mat-icon>
      {{ storeGroup.timestampUtc | fromNow }}
    </h2>
    @for (storeLog of storeGroup.storeLogs; track storeLog) {
      <mat-expansion-panel>
        <mat-expansion-panel-header class="log-header">
          <mat-panel-title>
            <mat-chip-set>
              <mat-chip [ngClass]="['store-log-level-' + storeLog.level]" class="log-level">
                {{storeLog.level}}
              </mat-chip>
            </mat-chip-set>
            {{storeLog.message | truncate:maxMessageLength}}
            x {{storeLog.occurrences}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="log-entry">
          <div class="log-info">
            <span class="label">Date</span>
            <span class="value">
                  @if (storeLog.occurrences !== 1) {
                    {{ storeLog.firstOccurrenceUtc | date:'medium' }}
                    to {{ storeLog.lastOccurrenceUtc | date:'medium' }}
                  }
              @if (storeLog.occurrences === 1) {
                {{ storeLog.firstOccurrenceUtc | date:'medium' }}
              }
                </span>
          </div>
          <div class="log-info">
            <span class="label">Message</span>
            <span class="value">{{storeLog.message}}</span>
          </div>
          <div class="log-info">
            <span class="label">Severity</span>
            <span class="value"><code>{{storeLog.level}}</code></span>
          </div>
          @if (storeLog.__typename === 'HubStoreLog') {
            <div class="log-info">
              <span class="label">Type</span>
              <span class="value"><code adminCopyToClipboard>{{storeLog.type}}</code></span>
            </div>
            <div class="log-info">
              <span class="label">Operation</span>
              <span class="value"><code adminCopyToClipboard>{{storeLog.operation}}</code></span>
            </div>
            <div class="log-info">
              <span class="label">Subsystem</span>
              <span class="value"><code adminCopyToClipboard>{{storeLog.appName}}</code></span>
            </div>
          }
          @if (storeLog.__typename === 'ShopifyStoreLog') {
            <div class="log-info">
              <span class="label">Error Code</span>
              <span class="value"><code adminCopyToClipboard>{{storeLog.errorCode}}</code></span>
            </div>
          }
          <div class="log-info">
            <span class="label">Affected users</span>
            <span class="value">{{storeLog.users}} (Sessions: {{storeLog.runs}})</span>
          </div>
          <div class="log-info">
            <span class="label">Occurrences</span>
            <span class="value">{{storeLog.occurrences}}</span>
          </div>
          @if (canReadApplicationInsightsLogs()) {
            <div class="log-info">
              <span class="label">Application insights</span>
              <span class="value">
                    <div>
                      <a [href]="storeLog.openItemQueryUrl"
                         class="external"
                         rel="external"
                         target="_blank">
                        Open query
                        <mat-icon>open_in_new</mat-icon>
                      </a>
                    </div>
                    <div>
                      <a [href]="storeLog.openOperationQueryUrl"
                         class="external"
                         rel="external"
                         target="_blank">
                        Open related logs
                        <mat-icon>open_in_new</mat-icon>
                      </a>
                    </div>
                  </span>
            </div>
          }
        </div>
      </mat-expansion-panel>
    }
  </div>
}
