import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface UpdateSubscriptionProjectExpirationsDialogData {
    photobook?: number;
    gifting?: number;
    print?: number;
}

export interface UpdateSubscriptionProjectExpirationsDialogResult {
    photobook?: number;
    gifting?: number;
    print?: number;
}

@Component({
    templateUrl: 'update-subscription-project-expirations-dialog.html',
    styleUrls: ['update-subscription-project-expirations-dialog.scss']
})
export class UpdateSubscriptionProjectExpirationsDialogComponent {
    contactPattern = /^[\w\-]+( .+)? <.+?>$/;
    result: UpdateSubscriptionProjectExpirationsDialogResult;

    constructor(
        public dialogRef: MatDialogRef<UpdateSubscriptionProjectExpirationsDialogComponent, UpdateSubscriptionProjectExpirationsDialogResult>,
        @Inject(MAT_DIALOG_DATA) data: UpdateSubscriptionProjectExpirationsDialogData
    ) {
        this.result = {};
    }

    cancel(): void {
        this.dialogRef.close();
    }

    editSubscription() {
        this.dialogRef.close(this.result);
    }
}
