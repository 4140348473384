import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import {ConfirmDialogComponent, ConfirmDialogData} from '../confirm-dialog/confirm-dialog.component';
import { GenericExternalPhotoSourceAddDialogComponent } from './generic-external-photo-source-add-dialog.component';
import {
    AuthorizationContext,
    AuthorizationService,
    ExternalPhotosConfigService,
    StockPhotoSourcesService
} from '../../services';
import { PageStatus } from '../../models';
import {
    BuiltInExternalPhotoSourceConfig,
    BuiltInExternalPhotoSourceDefinition, DirectPhotoSource,
    ExternalPhotoSourceStatus,
    GenericExternalPhotoSourceConfig,
    GlobalPermission,
    StockPhotoSourceConfig,
    UpdateBuiltInExternalPhotoSourceInput,
    UpdateGenericExternalPhotoSourceInput
} from '../../../schema-dotnet';
import { AdminComponentBase } from '../../utils/admin-component-base';
import {
    StockPhotoSourceAddDialogComponent, StockPhotoSourceAddDialogData,
    StockPhotoSourceAddDialogResult
} from "./stock-photo-source-add-dialog.component";
import { DirectPhotoSourceAddDialogComponent } from './direct-photo-source-add-dialog.component';

@Component({
    templateUrl: './external-photo-sources-page.component.html',
    styleUrls: ['./external-photo-sources-page.component.scss'],
    animations: fuseAnimations
})
export class ExternalPhotoSourcesPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    public storeId: string;

    builtinsExternalPhotoSourceDefinitions: Array<BuiltInExternalPhotoSourceDefinition> = [];
    builtInExternalPhotoSources: Array<BuiltInExternalPhotoSourceConfig> = [];
    genericExternalPhotoSources: Array<GenericExternalPhotoSourceConfig> = [];
    stockPhotoSources: Array<StockPhotoSourceConfig> = [];
    directPhotoSources: Array<DirectPhotoSource> = [];
    private authorizationContext: AuthorizationContext;

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly route: ActivatedRoute,
        private readonly photosConfigService: ExternalPhotosConfigService,
        private readonly stockPhotoSourcesService: StockPhotoSourcesService,
        private readonly router: Router,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));

        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;

            this.route.params.subscribe(params => {
                this.storeId = params.storeId;

                this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                        this.photosConfigService.loadBuiltInExternalPhotoSourceDefinitions(),
                        this.photosConfigService.loadExternalPhotoSources(this.storeId),
                        this.photosConfigService.loadDirectPhotoSources(this.storeId),
                    ]),
                    ([builtinsExternalPhotoSourceDefinitions, externalPhotoSources, directPhotoSources]) => {
                        this.builtinsExternalPhotoSourceDefinitions = builtinsExternalPhotoSourceDefinitions;
                        this.builtInExternalPhotoSources = externalPhotoSources.builtInExternalPhotoSources;
                        this.genericExternalPhotoSources = externalPhotoSources.genericExternalPhotoSources;
                        this.stockPhotoSources = externalPhotoSources.stockPhotoSources;
                        this.directPhotoSources = directPhotoSources;
                        this.pageStatus = PageStatus.loaded;
                    });
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    // region Built-in External Photo Source

    openBuiltInPhotoSourceEditor(providerId: string): void {
        this.router.navigate(['built-in', 'edit', providerId], { relativeTo: this.route });
    }

    openAddBuiltInPhotoSourceEditor(providerId: string): void {
        this.router.navigate(['built-in', 'create', providerId], { relativeTo: this.route });
    }

    isProviderExistsForStore(providerId: string): boolean {
        return !!this.builtInExternalPhotoSources.find(
            (x) => x.photoSourceId === providerId
        );
    }

    switchBuiltInSourceEnabled(
        source: BuiltInExternalPhotoSourceConfig,
        status: ExternalPhotoSourceStatus
    ): void {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
                title: 'Update photo source',
                message: `Do you really want to ${
                    status === ExternalPhotoSourceStatus.Enabled ? 'enable' : 'disable'
                } the photo source ${source.label} ?`
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.updateBuiltInExternalPhotoSource(source, {
                storeId: this.storeId,
                photoSourceId: source.photoSourceId,
                status: status,
                data: source.data
            });
        });
    }

    disableBuiltInPhotoSource(source: BuiltInExternalPhotoSourceConfig): void {
        this.switchBuiltInSourceEnabled(source, ExternalPhotoSourceStatus.Disabled);
    }

    enableBuiltInPhotoSource(source: BuiltInExternalPhotoSourceConfig): void {
        this.switchBuiltInSourceEnabled(source, ExternalPhotoSourceStatus.Enabled);
    }

    deleteBuiltInPhotoSource(source: BuiltInExternalPhotoSourceConfig): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Update photo source',
                message: `Do you really want to delete the photo source ${source.label} ?`
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.photosConfigService.deleteBuiltInExternalPhotoSource({
                storeId: this.storeId,
                photoSourceId: source.photoSourceId
            }).subscribe({
                next: () => {
                    let index = this.builtInExternalPhotoSources.findIndex(s => s.photoSourceId === source.photoSourceId);
                    if (index !== -1) {
                        this.builtInExternalPhotoSources.splice(index, 1);
                    }
                },
                error: err => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = err;
                }
            });
        });
    }

    private updateBuiltInExternalPhotoSource(source: BuiltInExternalPhotoSourceConfig, input: UpdateBuiltInExternalPhotoSourceInput) {
        this.photosConfigService.updateBuiltInExternalPhotoSource(input)
            .subscribe({
                next: (updatedSource) => {
                    source.appId = updatedSource.appId;
                    source.data = updatedSource.data;
                    source.details = updatedSource.details;
                    source.icon = updatedSource.icon;
                    source.label = updatedSource.label;
                    source.photoSourceId = updatedSource.photoSourceId;
                    source.status = updatedSource.status;
                    source.store = updatedSource.store;
                },
                error: (err) => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = err;
                }
            });
    }

    // endregion

    // region Generic External Photo Source

    editGenericExternalPhotoSource(source: GenericExternalPhotoSourceConfig): void {
        this.router.navigate(['generic', 'edit', source.photoSourceId], { relativeTo: this.route });
    }

    openAddGenericExternalPhotoSourceDialog(): void {
        const dialogRef = this.dialog.open<GenericExternalPhotoSourceAddDialogComponent, any, string>(GenericExternalPhotoSourceAddDialogComponent);
        dialogRef.afterClosed().subscribe((photoSourceId) => {
            if (!photoSourceId) {
                return;
            }

            this.router.navigate(['generic', 'create', photoSourceId], { relativeTo: this.route });
        });
    }

    switchGenericSourceStatus(
        source: GenericExternalPhotoSourceConfig,
        status: ExternalPhotoSourceStatus
    ): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Update photo source',
                message: `Do you really want to ${
                    status === ExternalPhotoSourceStatus.Enabled ? 'enable' : 'disable'
                } the photo source ${source.label} ?`
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.updateGenericExternalPhotoSource(source, {
                storeId: source.store.id,
                photoSourceId: source.photoSourceId,
                status: status
            });
        });
    }

    disableGenericPhotoSource(source: GenericExternalPhotoSourceConfig): void {
        this.switchGenericSourceStatus(source, ExternalPhotoSourceStatus.Disabled);
    }

    enableGenericPhotoSource(source: GenericExternalPhotoSourceConfig): void {
        this.switchGenericSourceStatus(source, ExternalPhotoSourceStatus.Enabled);
    }

    deleteGenericPhotoSource(source: GenericExternalPhotoSourceConfig): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Update photo source',
                message: `Do you really want to delete the photo source ${source.label} ?`
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.photosConfigService.deleteGenericExternalPhotoSource({
                storeId: this.storeId,
                photoSourceId: source.photoSourceId
            }).subscribe({
                next: () => {
                    let index = this.genericExternalPhotoSources.findIndex(s => s.photoSourceId === source.photoSourceId);
                    if (index !== -1) {
                        this.genericExternalPhotoSources.splice(index, 1);
                    }
                },
                error: err => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = err;
                }
            });
        });
    }

    private updateGenericExternalPhotoSource(source: GenericExternalPhotoSourceConfig, input: UpdateGenericExternalPhotoSourceInput) {
        this.photosConfigService.updateGenericExternalPhotoSource(input)
            .subscribe({
                next: (updatedSource) => {
                    source.details = updatedSource.details;
                    source.appId = updatedSource.appId;
                    source.icon = updatedSource.icon;
                    source.label = updatedSource.label;
                    source.photoSourceId = updatedSource.photoSourceId;
                    source.status = updatedSource.status;
                    source.store = updatedSource.store;
                    source.authorizationUrl = updatedSource.authorizationUrl;
                    source.photoUrl = updatedSource.photoUrl;
                    source.albumUrl = updatedSource.albumUrl;
                    source.tokenUrl = updatedSource.tokenUrl;
                },
                error: (err) => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = err;
                }
            });
    }

    // endregion

    // region StockPhotoSources

    canManageStockPhotoSource(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageStockPhotoSources);
    }

    openStockPhotoSourceAddDialog(): void {
        let currentStockPhotoSourceIds = this.stockPhotoSources.map(s => s.id);
        let dialogData = {
            alreadyMapped: currentStockPhotoSourceIds
        } as StockPhotoSourceAddDialogData;
        const dialogRef = this.dialog.open<StockPhotoSourceAddDialogComponent, StockPhotoSourceAddDialogData, StockPhotoSourceAddDialogResult>(
            StockPhotoSourceAddDialogComponent,
            {
                data: dialogData
            }
        );
        dialogRef.afterClosed().subscribe((result: StockPhotoSourceAddDialogResult) => {
            if (!result.photoSourceId) {
                return;
            }

            let index = this.stockPhotoSources.findIndex(s => s.id === result.photoSourceId);
            if (index !== -1) {
                return;
            }

            this.subscribeWithGenericSavinErrorHandling(this.stockPhotoSourcesService.addToStore({
                storeId: this.storeId,
                photoSourceId: result.photoSourceId
            }), () => {
                this.stockPhotoSourcesService.load(result.photoSourceId).subscribe(source => {
                    this.stockPhotoSources.push(source);
                });

            });
        });
    }

    deleteStockPhotoSource(source: StockPhotoSourceConfig) {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
                title: 'Remove photo source',
                message: `Removing this source will break all projects needing it to be opened and/or rendered. We recommend to disable this source first.`,
                subMessage: `Do you really want to remove the photo source '${source.label}'?`,
                options: {
                    yes: {
                        label: 'Remove',
                        warn: true,
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.stockPhotoSourcesService.removeFromStore({
                storeId: this.storeId,
                photoSourceId: source.id
            }).subscribe({
                next: () => {
                    let index = this.stockPhotoSources.findIndex(s => s.id === source.id);
                    if (index !== -1) {
                        this.stockPhotoSources.splice(index, 1);
                    }
                },
                error: err => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = err;
                }
            });
        });
    }

    // endregion

    // region Direct Photo Source

    openDirectPhotoSourceAddDialog(): void {
        const dialogRef = this.dialog.open<DirectPhotoSourceAddDialogComponent, any, string>(DirectPhotoSourceAddDialogComponent);
        dialogRef.afterClosed().subscribe((photoSourceId) => {
            if (!photoSourceId) {
                return;
            }

            this.router.navigate(['direct', 'create', photoSourceId], { relativeTo: this.route });
        });

    }

    openDirectPhotoSourceEditor(directPhotoSourceId: string): void {
        this.router.navigate(['direct', 'edit', directPhotoSourceId], { relativeTo: this.route });
    }

    // endregion
}
