<h2 mat-dialog-title>
    Add request logger rule
</h2>
<mat-dialog-content>
    <p class="mat-body">
        Configure rule to log raw requests for matching requests
    </p>
    <form>
        <mat-form-field>
            <mat-label>Path</mat-label>
            <input matInput
                   autocomplete="off"
                   name="routePrefix"
                   type="text"
                   required
                   [(ngModel)]="ruleInfo.routePrefix">
            <mat-hint>Only match on prefix, starts with /. (e.g. /stores/store-id/orders)</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Log expiration delay (seconds)</mat-label>
            <input matInput
                   autocomplete="off"
                   name="logsExpirationInSeconds"
                   type="number"
                   required
                   [(ngModel)]="ruleInfo.logsExpirationInSeconds">
            <span matTextSuffix>s</span>
            <mat-hint>Retention of the captured logs</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Status Code</mat-label>
            <input matInput
                   autocomplete="off"
                   name="statusCode"
                   type="number"
                   [(ngModel)]="ruleInfo.statusCode">
            <mat-hint>Status code to capture the request (leave empty to capture any)</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Maximum capture count</mat-label>
            <input matInput
                   autocomplete="off"
                   name="maximumCaptureCount"
                   type="number"
                   [(ngModel)]="ruleInfo.maximumCaptureCount">
            <mat-hint>Number of requests to capture before disabling the rule</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Store ID</mat-label>
            <input matInput
                   autocomplete="off"
                   name="storeId"
                   type="text"
                   [(ngModel)]="ruleInfo.storeId">
            <mat-hint>Store Id for which the capture should be done (not always available)</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        CANCEL
    </button>
    <button mat-button [matDialogClose]="ruleInfo" color="primary">
        CREATE
    </button>
</mat-dialog-actions>
