import { Injectable } from '@angular/core';
import { GraphDotnetService } from './graph-dotnet-service';
import { CreateAppSecret, CreateAppSecretInput, DeleteAppSecretInput, MediaclipMutationCreateAppSecretArgs, MediaclipMutationDeleteAppSecretArgs } from '../../schema-dotnet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppSecretService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    createAppSecret(input: CreateAppSecretInput): Observable<CreateAppSecret> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateAppSecretArgs>(`
            mutation createAppSecret($input: CreateAppSecretInput!) {
                createAppSecret(input: $input) {
                id
                label
                secret
              }
            }`, { input })
            .pipe(map(m => m.createAppSecret));
    }

    revokeSecret(input: DeleteAppSecretInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteAppSecretArgs>(`
            mutation deleteAppSecret($input: DeleteAppSecretInput!) {
              deleteAppSecret(input: $input)
            }`, { input }).pipe(map(m => m.deleteAppSecret!)
        );

    }
}
