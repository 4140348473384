import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface PromptDialogData {
    title: string;
    message?: string;
    defaultValue?: string;
    label?: string;
}

@Component({
    selector: 'admin-prompt-dialog',
    templateUrl: './prompt-dialog.component.html',
    styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {
    public value: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: PromptDialogData) {
        this.value = data.defaultValue || '';
    }
}
