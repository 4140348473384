<admin-page-simple-content id="external-photo-sources"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Settings: Authorized Users'"
  [icon]="'perm_identity'">
  @if (storeWithAuthorizedUsers) {
    <div class="content">
      @if (!canManageUsers(storeWithAuthorizedUsers.subscriptionId)) {
        <p>
          <em>Please contact <a href="mailto:support@mediacliphub.com">support&#64;mediacliphub.com</a> to add or remove
        authorized users.</em>
      </p>
    }
    <div class="store">
      <h2>Users with access to the store</h2>
      <admin-users-role-management
        [users]="storeWithAuthorizedUsers.authorizedUsers"
        [availableRoles]="storeRoles"
        [loading]="pageStatus !== 'loaded'"
        [canManagePermissions]="canManageUsers(storeWithAuthorizedUsers.subscriptionId)"
        (addUser)="openAddStoreRole(storeWithAuthorizedUsers)"
        (addUserRole)="openAddStoreRole(storeWithAuthorizedUsers, $event.user)"
        (roleRemoved)="removeStoreRole(storeWithAuthorizedUsers, $event.email, $event.role)"
        >
      </admin-users-role-management>
    </div>
    <div>
      @if (pageStatus === 'saveError' && pageError) {
        <admin-error [error]="pageError"></admin-error>
      }
    </div>
  </div>
}
</admin-page-simple-content>
