import { Params } from '@angular/router';

export enum NavigationCategoryIdValues {
    general = 'general',
    dashboards = 'dashboards',
    orders = 'orders',
    settings = 'settings',
    superadmin = 'superadmin',
    billing = 'billing',
    unauthorized = 'unauthorized',
    library = 'library',
    subscription = 'subscription',
}

export enum NavigationPageIdValues {
    home = 'home',
    dashboardsOverview = 'dashboards-overview',
    storeLogs = 'store-logs',
    ordersActionRequired = 'orders-action-required',
    ordersRecent = 'orders-recent',
    libraryProducts = 'library-products',
    libraries = 'libraries',
    settingsStatus = 'settings-status',
    settingsGeneral = 'settings-general',
    settingsEndpoints = 'settings-endpoints',
    settingsPhotosSources = 'settings-photos-sources',
    settingsPhotosUpscaling = 'settings-photos-upscaling',
    settingsGeneratedText = 'settings-generated-text',
    settingsSecrets = 'settings-secrets',
    storeFulfillers = 'store-fulfillers',
    setttingsAuthorizedUsers = 'authorized-users',
    anomalies = 'anomalies',
    subscriptions = 'subscriptions',
    subscription = 'subscription',
    fulfillers = 'fulfillers',
    generatedImagesGlobal = 'generated-images-global',
    generatedImagesSubscription = 'generated-images-subscription',
    stockPhotoSources = 'stock-photo-sources',
    requestLogger = 'request-logger',
    customJavascript = 'custom-javascript',
    customCss = 'custom-css',
    customLocales = 'custom-lcoales',
    designerLogo = 'designer-logo',
    builtInFilters = 'built-in-filters',
    remoteFilters = 'remote-filters',
    filterCategories = 'filter-categories',
    settingsDesignerReleaseChannels = 'designer-versions',
    libraryAuthorizedUsers = 'library-authorized-users',
    subscriptionAuthorizedUsers = 'subscription-authorized-users',
    globalAuthorizedUsers = 'global-authorized-users',
    subscriptionCleanup = 'subscription-cleanup',
    manageLibrary = 'manage-library',
    manageSubscription = 'manage-subscription',
    manageDesignerBranch = 'manage-designer-branch',
    fulfillerConfig = 'fulfiller-config',
    fulfillerAuthorizedUsers = 'fulfiller-authorized-users',
    fulfillerSecrets = 'fulfiller-secrets',

}

export type Navigation = NavCategory[];

export interface NavCategory {
    id: NavigationCategoryIdValues;
    title: string;
    type: NavCategoryTypes;
    icon?: string | null;
    children: (NavPage | NavCategory)[];
}

export interface NavPage {
    id: NavigationPageIdValues | string;
    title: string;
    type: NavPageType;
    icon: string;
    url: string;
    exactMatch?: boolean;
    query?: Params | null;
    badge?: NavPage[] | null;
}

export interface NavBadge {
    title: string;
    bg: string;
    fg: string;
}

export enum NavCategoryTypes {
    group = 'group',
    collapsable = 'collapsable',
}

export enum NavPageType {
    item = 'item',
}

export const NavBadges = {
    success: {
        bg: '#4caf50 ',
        fg: '#ffffff',
    },
    error: {
        bg: '#f44336',
        fg: '#ffffff',
    },
};
