<admin-page-simple-content id="store-fulfillers"
                           [pageError]="pageError"
                           [pageStatus]="pageStatus"
                           [title]="'Store Logs'"
                           [icon]="'list_alt'">
  <div class="content">

    <p>
      These logs represent all errors, typically stemming from store, library or integration issues.
      This list is not exhaustive; errors lacking a clear association with a resolution on your end will be forwarded to
      our development team.
      Additionally, keep in mind that client-side retries will artificially inflate the total "Occurrences" count.
      "Affected users" is usually a better indicator.
    </p>

    @if (hubStoreLogs.length && shopifyStoreLogs.length) {
      <mat-tab-group>
        <mat-tab label="Mediaclip Hub logs">
          <admin-store-logs-group [storeLogs]="hubStoreLogs" />
        </mat-tab>
        <mat-tab label="Shopify logs">
          <admin-store-logs-group [storeLogs]="shopifyStoreLogs" />
        </mat-tab>
      </mat-tab-group>
    } @else if (hubStoreLogs.length) {
      <h3>Hub logs</h3>
      <admin-store-logs-group [storeLogs]="hubStoreLogs" />
    } @else if (shopifyStoreLogs.length) {
      <h3>Shopify logs</h3>
      <admin-store-logs-group [storeLogs]="shopifyStoreLogs" />
    } @else {
      <div>
        <p class="no-logs">
          <mat-icon>check</mat-icon>
          Nothing to report!
        </p>
      </div>
    }
  </div>
</admin-page-simple-content>
