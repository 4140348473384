import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {ActivatedRoute, Router} from '@angular/router';
import {PageStatus} from '../../models';
import {DayOfWeek, GlobalPermission, Library, LibraryBranch, LibraryPermission, LibraryUpdateProgress, StartUpdateLibraryInput} from '../../../schema-dotnet';
import {AuthorizationContext, AuthorizationService, LibraryService} from '../../services';
import {parse} from 'tinyduration';
import * as signalR from '@microsoft/signalr';
import {PromptDialogService} from '../prompt-dialog/prompt-dialog.service';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog-service';

export interface UpdateBranchFormModel {
    targetVersion: string,
    validateLibrary: boolean,
    culture?: string,
    defaultPackage?: string,
}

export interface ScheduleUpdateFormModel {
    hourUtc: number;
    weekDays: DayOfWeek[];
}

export interface SchedulesUpdateFormModel {
    gitBranch?: string;
    scheduledUpdates: ScheduleUpdateFormModel[];
}

export interface UpdateBranchMetadataFormModel {
    doNotValidate: boolean;
    validationDefaultPackage?: string | null;
    validationCulture?: string | null;
}

@Component({
    selector: 'admin-library-page',
    templateUrl: './library-page.component.html',
    styleUrls: ['./library-page.component.scss']
})
export class LibraryPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    libraryOwner: string;
    library?: Library;
    gitlabGroup = 'librairies';

    hubConnection?: signalR.HubConnection;
    hours: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    weekDays: DayOfWeek[] = [
        DayOfWeek.Sunday,
        DayOfWeek.Monday,
        DayOfWeek.Tuesday,
        DayOfWeek.Wednesday,
        DayOfWeek.Thursday,
        DayOfWeek.Friday,
        DayOfWeek.Saturday
    ];

    branchForms: Record<string, UpdateBranchFormModel> = {};
    branchMetadataForms: Record<string, UpdateBranchMetadataFormModel> = {};
    scheduledMetadataForms: Record<string, SchedulesUpdateFormModel> = {};
    authorizationContext: AuthorizationContext;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly libraryService: LibraryService,
        private readonly authorizationService: AuthorizationService,
        private readonly promptDialogService: PromptDialogService,
        private readonly confirmDialogService: ConfirmDialogService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.libraryOwner = params.libraryOwner;
                if (this.libraryOwner.startsWith('mediaclip')) {
                    this.gitlabGroup = 'mediaclip';
                } else {
                    this.gitlabGroup = 'librairies';
                }
                this.setPageStatus(PageStatus.loading);
                this.subscribeWithGenericLoadingErrorHandling(this.libraryService.getLibraryStatus(this.libraryOwner), (library) => {
                    this.library = library;
                    this.branchForms = {};
                    this.branchMetadataForms = {};
                    let defaultBranch = this.library.gitBranches.find(x => x.name === 'master')?.name || this.library.gitBranches[0]?.name || '';
                    for (const branch of this.library.branches) {
                        this.branchForms[branch.name] = {
                            validateLibrary: !branch.metadata.doNotValidate,
                            targetVersion: defaultBranch,
                            defaultPackage: branch.metadata.validationDefaultPackage || this.libraryOwner + '/default',
                            culture: branch.metadata.validationCulture || undefined
                        };
                        this.branchMetadataForms[branch.name] = {
                            doNotValidate: branch.metadata.doNotValidate,
                            validationCulture: branch.metadata.validationCulture,
                            validationDefaultPackage: branch.metadata.validationDefaultPackage
                        };
                        this.scheduledMetadataForms[branch.name] = {
                            gitBranch: branch.scheduledUpdates.gitBranch || '',
                            scheduledUpdates: branch.scheduledUpdates.schedules.map(x => ({
                                hourUtc: x.hourUtc,
                                weekDays: [...x.weekDays],
                            } as ScheduleUpdateFormModel)) || []
                        };
                        if (branch.metadata.progress && !branch.metadata.progress.completed) {
                            this.subscribeUpdateProgress(branch.metadata.progress.progressSignalRUrl!, branch.metadata.progress.updateId!, branch);
                        }
                    }
                });
            })
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
        this.hubConnection?.stop();
    }

    formatDuration(durationStr: string): string {
        try {
            const duration = parse(durationStr);
            if (duration.minutes) {
                return duration.minutes + ' min ' + duration.seconds?.toFixed(2) + 's';
            }
            return duration.seconds?.toFixed(2) + 's';
        } catch (e) {
            const match = durationStr.match('(?<hours>\\d\\d):(?<minutes>\\d\\d):(?<seconds>\\d\\d(?:\\.\\d+)?)');
            if (match) {
                const hours = parseInt(match[1], 10);
                const minutes = parseInt(match[2], 10);
                const seconds = parseFloat(match[3]);
                if (minutes) {
                    return minutes + ' min ' + seconds.toFixed(2) + 's';
                }
                return seconds.toFixed(2) + 's';
            }
            return durationStr;
        }
    }

    startUpdateLibraryTo(branch: LibraryBranch, form: UpdateBranchFormModel) {
        let input: StartUpdateLibraryInput = {
            owner: this.libraryOwner,
            branch: branch.name,
            version: form.targetVersion,
            validate: form.validateLibrary
        };
        if (input.validate) {
            input.validationCulture = form.culture;
            input.validationDefaultPackage = form.defaultPackage;
        }

        this.subscribeWithGenericSavinErrorHandling(this.libraryService.startUpdateLibrary(input), result => {
            branch.metadata.progress = null;
            if (result.updateId) {
                branch.metadata.progress = {
                    stage: result.message,
                    completed: false,
                    updateId: result.updateId,
                    lastUpdateDateUtc: new Date()
                };
            }
            if (result.progressSignalRUrl) {
                this.subscribeUpdateProgress(result.progressSignalRUrl, result.updateId!, branch);
            }
        });
    }

    private subscribeUpdateProgress(signalrEndpoint: string, updateId: string, branch: LibraryBranch) {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(signalrEndpoint, {withCredentials: false})
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();
        this.hubConnection.on('updateProgress', (currentUpdateId: string, data: LibraryUpdateProgress) => {
            if (updateId === currentUpdateId) {
                branch.metadata.progress = data;
            }
            if (data.completed) {
                this.hubConnection?.stop();
                this.subscribeWithGenericLoadingErrorHandling(this.libraryService.getLibraryStatus(this.libraryOwner), (library) => {
                    this.library = library;
                })
            }
        });
        this.hubConnection.start()
            .then(() => {
                this.hubConnection?.send('SubscriptUpdateProgressAsync', updateId);
            });
    }

    canUpdateLibrary(library: Library, branch: LibraryBranch): boolean {
        if (branch.metadata.progress && !branch.metadata.progress.completed) {
            return false;
        }
        return this.authorizationContext.hasLibraryPermissions(library.owner, LibraryPermission.DeployLibrary);
    }

    canUnlockLibrary(library: Library, branch: LibraryBranch): boolean {
        if (!this.authorizationContext.hasLibraryPermissions(library.owner, LibraryPermission.UnlockLibrary)) {
            return false;
        }
        if (!branch.metadata.progress) {
            return false;
        }
        if (branch.metadata.progress.completed) {
            return false;
        }
        if (branch.metadata.progress.lastUpdateDateUtc) {
            let lastUpdateDate = branch.metadata.progress.lastUpdateDateUtc;
            if (typeof lastUpdateDate === 'string') {
                lastUpdateDate = Date.parse(branch.metadata.progress.lastUpdateDateUtc);
            }
            let diffSeconds = (Date.now() - lastUpdateDate) / 1_000;
            return diffSeconds > 600;
        }

        return true;
    }

    unlockLibrary(library: Library, branch: LibraryBranch) {
        this.confirmDialogService.confirm('Unlock library', 'Are you sure you want to unlock this library ? Make sure this has been stuck for at least 10 minutes before. If you start 2 library updates simultaneously it may create bugs.').subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.libraryService.releaseLibraryLock({
                owner: library.owner,
                branch: branch.name
            }));
        });
    }

    canManageLibraries(library: Library) {
        // Restrict this to SuperAdmins for now, the branch feature is not fully completed yet
        if (library.branches.length > 0) {
            return false;
        }
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ListLibraries)
            && this.authorizationContext.hasLibraryPermissions(library.owner, LibraryPermission.DeployLibrary);

    }

    canManageLibraryMetadata(library: Library, branch: LibraryBranch): boolean {
        if (branch.metadata.progress && !branch.metadata.progress.completed) {
            return false;
        }
        return this.authorizationContext.hasLibraryPermissions(library.owner, LibraryPermission.ManageLibraryMetadata);
    }

    openDeployNewBranch(library: Library) {
        this.promptDialogService.prompt('Deploy new branch', 'Choose the name of the branch to deploy. This is not the git branch. Use <code>production</copde> except someone or some documentation tell you do to otherwise', {defaultValue: 'production', fieldLabel: 'Library branch'}).subscribe(result => {
            this.branchForms[result] = {
                targetVersion: '',
                validateLibrary: true,
                defaultPackage: library.owner + '/default'
            };
            this.branchMetadataForms[result] = {
                doNotValidate: false
            };
            this.scheduledMetadataForms[result] = {
                scheduledUpdates: []
            };
            this.library?.branches.push({
                name: result,
                metadata: {
                    doNotValidate: false
                },
                scheduledUpdates: {}
            } as LibraryBranch);
        });
    }

    updateLibraryMetadata(library: Library, branch: LibraryBranch, formModel: UpdateBranchMetadataFormModel) {
        this.subscribeWithGenericSavinErrorHandling(this.libraryService.updateLibraryMetadata({
            owner: library.owner,
            branch: branch.name,
            doNotValidate: formModel.doNotValidate,
            validationCulture: formModel.validationCulture,
            validationDefaultPackage: formModel.validationDefaultPackage
        }), () => {
            branch.metadata.doNotValidate = formModel.doNotValidate;
            if (formModel.doNotValidate) {
                this.branchForms[branch.name].validateLibrary = false;
            }
            branch.metadata.validationCulture = formModel.validationCulture;
            branch.metadata.validationDefaultPackage = formModel.validationDefaultPackage;
        });
    }

    getBranchNames(library: Library, targetVersion: string): string[] {
        return library.gitBranches.filter(x => x.commitHash === targetVersion).map(x => x.name);
    }

    hasHubValidationError(branch: LibraryBranch) {
        return !!this.getHubValidationErrors(branch).length;
    }

    getHubValidationErrors(branch: LibraryBranch) {
        if (!branch.metadata.progress?.hubValidation?.logs) {
            return [];
        }
        return branch.metadata.progress.hubValidation.logs.filter(log => log.level === 'Warning' || log.level === 'Error' || log.level === 'Fatal');
    }

    hasValidationError(branch: LibraryBranch) {
        return !!this.getValidationError(branch).length;
    }

    getValidationError(branch: LibraryBranch) {
        if (!branch.metadata.progress?.validation?.entries) {
            return [];
        }
        return branch.metadata.progress.validation.entries.filter(log => log.level === 'warning' || log.level === 'error' || log.level === 'Warning' || log.level === 'Error');
    }

    addSchedule(branch: LibraryBranch) {
        this.scheduledMetadataForms[branch.name].scheduledUpdates.push({
            hourUtc: 9,
            weekDays: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday]
        });
    }

    removeSchedule(branch: LibraryBranch, scheduleIndex: number) {
        this.scheduledMetadataForms[branch.name].scheduledUpdates.splice(scheduleIndex, 1);
    }

    saveSchedule(library: Library, branch: LibraryBranch, formModel: SchedulesUpdateFormModel) {
        this.subscribeWithGenericSavinErrorHandling(this.libraryService.updateLibraryUpdateSchedules({
            owner: library.owner,
            branch: branch.name,
            gitBranch: formModel.gitBranch,
            schedules: formModel.scheduledUpdates
        }));
    }
}
