import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BlobStorageService {
    constructor(private readonly httpClient: HttpClient) {}

    load(url: string): Observable<string> {
        return this.httpClient.get(url, { responseType: 'text' });
    }

    save(url: string, value: string, contentType: string): Observable<void> {
        return this.httpClient.put<void>(url, value, {
            headers: {
                'Content-Type': contentType,
                Date: new Date().toUTCString(),
                'x-ms-blob-type': 'BlockBlob',
                'x-ms-version': '2018-03-28',
            },
        });
    }
}
