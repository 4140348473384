import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationContext, AuthorizationService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PhotoUpscalerType, PhotoUpscalingConfiguration } from '../../../schema-dotnet';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { StorePhotosUpscalingService } from "../../services/store-photos-upscaling.service";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
    selector: 'admin-store-photos-upscaling',
    templateUrl: './store-photos-upscaling-page.component.html',
    styleUrls: ['./store-photos-upscaling-page.component.scss']
})
export class StorePhotosUpscalingPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly defaultConfig : PhotoUpscalingConfiguration = {
        enabled: false,
        type: PhotoUpscalerType.Picsart
    };
    private storeId: string;
    public apiKey: string | undefined;

    public enableUpscalingByProduct: boolean = true;
    public hasConfigDefined: boolean;
    public currentUpscalingConfig: PhotoUpscalingConfiguration = this.defaultConfig;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly storePhotosUpscalingService: StorePhotosUpscalingService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(async params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storePhotosUpscalingService.getConfig(this.storeId), (upscalingConfig) => {
                this.hasConfigDefined = !!upscalingConfig;
                if (upscalingConfig){
                    this.computeMinDPIState(upscalingConfig);
                    this.currentUpscalingConfig = upscalingConfig;
                }
            });
        }))
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }
    computeMinDPIState(upscalingConfig: PhotoUpscalingConfiguration) {
        if(upscalingConfig.minDpiUpscaleConfiguration == -1) {
            upscalingConfig.minDpiUpscaleConfiguration = 300;
            this.enableUpscalingByProduct = true;
        }
        else if(upscalingConfig.minDpiUpscaleConfiguration != null){
            if(upscalingConfig.minDpiUpscaleConfiguration == 0)
                upscalingConfig.minDpiUpscaleConfiguration = 300;
            this.enableUpscalingByProduct = false;
        } else {
            upscalingConfig.minDpiUpscaleConfiguration = 300;
            this.enableUpscalingByProduct = false;
        }
    }

    deleteConfig() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Delete configuration',
                message: `Do you really want to delete that configuration ?`,
                options: {
                    yes: {
                        label: 'Delete',
                        warn: true
                    },
                    no: {
                        label: 'Cancel'
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.storePhotosUpscalingService.deleteConfiguration({ storeId: this.storeId }), () => {
                this.hasConfigDefined = false;
                this.apiKey = undefined;
                this.currentUpscalingConfig = this.defaultConfig;
            });
        });
    }

    save() {
        this.subscribeWithGenericSavinErrorHandling(this.storePhotosUpscalingService.updateConfiguration({
            ...this.currentUpscalingConfig,
            enableMinDpiUpscale: !this.enableUpscalingByProduct,
            upscaleOnlyProduct: this.enableUpscalingByProduct,
            storeId: this.storeId,
            apiKey: this.apiKey ? this.apiKey : undefined,
        }), () => {
            this.hasConfigDefined = true;
        });
    }

    protected readonly UpscalerType = PhotoUpscalerType;

    selectedUpscaleOption($event: MatCheckboxChange) {
        this.enableUpscalingByProduct = $event.checked;
    }
}
