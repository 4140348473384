import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateSubscriptionInput } from 'schema-dotnet';

@Component({
    selector: 'admin-update-subscription-billing-dialog',
    templateUrl: 'update-subscription-billing-dialog.html',
    styleUrls: ['update-subscription-billing-dialog.scss'],
})
export class UpdateSubscriptionBillingDialogComponent {
    currencyPattern = /^[A-Z]{3}$/;

    constructor(
        public dialogRef: MatDialogRef<UpdateSubscriptionBillingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UpdateSubscriptionInput
    ) {}

    cancel(): void {
        this.dialogRef.close();
    }
}
