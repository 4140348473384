import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { forkJoin } from 'rxjs';

import { PhotoFilterService } from '../../services/photo-filter.service';
import { LocalFilterDefinition, LocalFilterName, LocalFilterSelectionMode } from "../../../schema-dotnet";
import { AdminComponentBase } from '../../utils/admin-component-base';

type ListType = 'allow' | 'block' | 'all';

@Component({
    selector: 'admin-store-photo-filter-configurations',
    templateUrl: './store-photo-filter-configurations.component.html',
    styleUrls: ['./store-photo-filter-configurations.component.scss'],
})
export class StorePhotoFilterConfigurationsComponent extends AdminComponentBase implements OnInit, OnDestroy {
    public listType: ListType;
    public filterDefinitions: LocalFilterDefinition[];
    public enabledFiltersById: { [filterId: string]: boolean } = {};
    public storeId: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly photoFilterService: PhotoFilterService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                this.photoFilterService.getFiltersDefinitions(),
                this.photoFilterService.getLocalFiltersConfiguration(this.storeId)
            ]), ([filterDefinitions, storeFilters]) => {
                this.filterDefinitions = filterDefinitions;
                this.updateEnabledFilters(storeFilters.photoFiltersAllowList, storeFilters.photoFiltersBlockList);
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    public save() {
        let filterType: LocalFilterSelectionMode;
        let filterIds: LocalFilterName[] | undefined;

        switch (this.listType) {
            case 'all':
                filterType = LocalFilterSelectionMode.Block;
                filterIds = undefined;
                break;
            case 'allow':
                filterType = LocalFilterSelectionMode.Allow;
                filterIds = Object.entries(this.enabledFiltersById)
                    .filter(([_filterId, enabled]) => enabled)
                    .map(([filterId, _enabled]) => filterId as LocalFilterName);
                break;
            case 'block':
                filterType = LocalFilterSelectionMode.Block;
                filterIds = Object.entries(this.enabledFiltersById)
                    .filter(([_filterId, enabled]) => enabled)
                    .map(([filterId, _enabled]) => filterId as LocalFilterName);
                break;
        }
        this.subscribeWithGenericSavinErrorHandling(this.photoFilterService.updateList(this.storeId, filterType, filterIds));
    }

    changeListType(event: MatButtonToggleChange) {
        switch (event.value) {
            case 'all':
                this.enabledFiltersById = {};
                break;
            case 'allow':
            case 'block':
                this.enabledFiltersById = this.filterDefinitions.reduce((acc, filterDefinition) => {
                    acc[filterDefinition.id] = !this.enabledFiltersById[filterDefinition.id];
                    return acc;
                }, {} as { [filterId: string]: boolean });
                break;
        }
    }

    private updateEnabledFilters(allowList: LocalFilterName[] | null | undefined, blockList: LocalFilterName[] | null | undefined) {
        if (allowList) {
            this.listType = 'allow';
            this.enabledFiltersById = allowList.reduce((previousValue, filterId) => {
                previousValue[filterId] = true;
                return previousValue;
            }, {} as { [filterId: string]: boolean });
        } else if (blockList) {
            this.listType = 'block';
            this.enabledFiltersById = blockList.reduce((previousValue, filterId) => {
                previousValue[filterId] = true;
                return previousValue;
            }, {} as { [filterId: string]: boolean });
        } else {
            this.listType = 'all';
            this.enabledFiltersById = {};
        }
    }
}
