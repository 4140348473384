import {Component, Input} from '@angular/core';
import {OrderLineStatusFlags, OrderLineStatusValues} from 'schema-dotnet';

@Component({
    selector: 'admin-line-status',
    templateUrl: './line-status.component.html',
    styleUrls: ['./line-status.component.scss'],
})
export class LineStatusComponent {
    @Input()
    status: OrderLineStatusValues;

    @Input()
    flags?: OrderLineStatusFlags | null;

    constructor() {}
}
