<admin-page-simple-content id="store-fulfillers"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Search'"
  [icon]="'search'">
  <div class="content">
    <div class="result-info">
      <span class="result-count h3 secondary-text">
        <span>{{ searchResult.length }}</span>
        <span class="ml-4">Result@if (searchResult.length !== 1) {
          <span>s</span>
        }</span>
      </span>
    </div>

    <p>
      <em>Store identifiers are shown only for the current store, Hub identifiers are shown for all stores</em>
    </p>

    <div class="results">
      @for (item of searchResult; track item) {
        <mat-card class="result-item">
          <h3>
            <mat-icon>{{ item.icon }}</mat-icon>
            {{ item.type }}
            @if (item.store) {
              in {{ item.store.id }}
            }
          </h3>
          <a [routerLink]="[item.url]" [queryParams]="item.query">{{ item.label }}</a>
          @if (item.id) {
            <div class="excerpt">Hub ID: {{ item.id }}</div>
          }
        </mat-card>
      }
    </div>
  </div>
</admin-page-simple-content>

