import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestLoggerService } from '../../services/request-logger.service';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { forkJoin } from 'rxjs';
import { EnableRequestLoggerInput, RequestLog, RequestLoggingConfiguration } from '../../../schema-dotnet';
import { MatDialog } from '@angular/material/dialog';
import { AddRequestLoggerRuleDialogComponent } from './add-request-logger-rule-dialog.component';

@Component({
    templateUrl: './request-logger-page.component.html',
    styleUrls: ['./request-logger-page.component.scss']
})
export class RequestLoggerPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    readonly appNames: Readonly<string[]> = ['HubApi', 'Ecb', 'Render', 'Photos'];
    configurationsByAppName: Record<string, RequestLoggingConfiguration> = {};
    logsPerRuleId: Record<string, RequestLog[]> = {};

    constructor(
        private readonly requestLoggerService: RequestLoggerService,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.loadRules();
    }

    loadRules() {
        this.subscribeWithGenericLoadingErrorHandling(
            forkJoin(this.appNames.map(appName => this.requestLoggerService.loadRequestLoggerConfiguration(appName))),
            (configurations: RequestLoggingConfiguration[]): void => {
                for (let i = 0; i < this.appNames.length; i++) {
                    this.configurationsByAppName[this.appNames[i]] = configurations[i];
                    for (let rule of configurations[i].rules)
                        this.loadLogs(rule.id)
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    addNewRule(appName: string) {
        let dialogRef = this.dialog.open<AddRequestLoggerRuleDialogComponent, any, EnableRequestLoggerInput>(AddRequestLoggerRuleDialogComponent);
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            if (!result.storeId)
                result.storeId = undefined;
            if (!result.statusCode)
                result.statusCode = undefined;
            if (!result.logsExpirationInSeconds)
                result.logsExpirationInSeconds = undefined;
            if (!result.maximumCaptureCount)
                result.maximumCaptureCount = undefined;
            this.subscribeWithGenericSavinErrorHandling(this.requestLoggerService.enableRequestLogger(appName, result), () => {
                this.loadRules()
            })
        });
    }

    deleteRule(appName: string, ruleId: string) {
        this.subscribeWithGenericSavinErrorHandling(this.requestLoggerService.disableRequestLogger(appName, ruleId), () => {
            this.loadRules()
        })

    }

    loadLogs(ruleId: string) {
        this.requestLoggerService.loadRequestLogs(ruleId).subscribe((logs) => {
            this.logsPerRuleId[ruleId] = logs
        });
    }
}
