<admin-page-simple-content id="external-photo-sources"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="title"
  [icon]="'photo'">
  <div class="content">
    @if (genericExternalSource) {
      <section class="edit-form">
        @if (mode === 'edit') {
          <div class="title">
            <h2 class="mat-h2">
              @if (genericExternalSource.icon) {
                <img [src]="genericExternalSource.icon" alt="Photo Source Icon">
              }
              <p>{{genericExternalSource.label}}</p>
            </h2>
          </div>
        }
        <p>Photo source id: <code>{{photoSourceId}}</code></p>
        <p>You can find more information about the following fields in the <a href="https://doc.mediaclip.ca/hub/photo-sources/external/" target="_blank">documentation</a></p>
        <form (ngSubmit)="save()" #form="ngForm">
          <section class="section-config">
            <h2 class="mat-h2">
              General
            </h2>
            <mat-form-field>
              <mat-label>Label</mat-label>
              <input matInput
                [(ngModel)]="genericExternalSource.label"
                required
                autocomplete="off"
                name="label"
                >
              <mat-error>Should contains between 1 and 50 characters</mat-error>
            </mat-form-field>
          </section>
          <section class="section-config">
            <h2 class="mat-h2">
              Application authentication
            </h2>
            <mat-form-field subscriptSizing="fixed">
              <mat-label>App Id</mat-label>
              <input
                matInput
                [(ngModel)]="genericExternalSource.appId"
                required
                autocomplete="off"
                name="appId"
                >
            </mat-form-field>
            <mat-form-field>
              <mat-label>App Secret</mat-label>
              <input matInput
                [(ngModel)]="appSecret"
                type="password"
                autocomplete="off"
                name="appSecret"
                [required]="mode === 'create'"
                >
              <mat-hint>Leave this field blank to keep current secret</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Scopes</mat-label>
              <input
                matInput
                [(ngModel)]="genericExternalSource.scopes"
                autocomplete="off"
                name="scopes"
                >
            </mat-form-field>
          </section>
          <section class="section-config">
            <h2 class="mat-h2">
              Thumbnail
            </h2>
            <div class="thumbnail">
              <mat-form-field>
                <mat-label>Thumbnail url</mat-label>
                <input
                  matInput
                  #thumbnailInputField="ngModel"
                  [(ngModel)]="genericExternalSource.icon"
                  required
                  autocomplete="off"
                  pattern="^https://.+"
                  name="icon"
                  >
                <mat-error>Should starts with https://</mat-error>
              </mat-form-field>
              @if (thumbnailInputField.valid) {
                <img [src]="genericExternalSource.icon">
              }
            </div>
          </section>
          <section class="section-config">
            <h2 class="mat-h2">
              Endpoints
            </h2>
            <mat-form-field>
              <mat-label>Authorization url</mat-label>
              <input
                matInput
                [(ngModel)]="genericExternalSource.authorizationUrl"
                required
                autocomplete="off"
                pattern="^https://.+"
                name="authorizationUrl"
                >
              <mat-error>Should starts with https://</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Token url</mat-label>
              <input
                matInput
                [(ngModel)]="genericExternalSource.tokenUrl"
                required
                autocomplete="off"
                pattern="^https://.+"
                name="tokenUrl"
                >
              <mat-error>Should starts with https://</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Album url</mat-label>
              <input
                matInput
                [(ngModel)]="genericExternalSource.albumUrl"
                required
                autocomplete="off"
                pattern="^https://.+"
                name="albumUrl"
                >
              <mat-error>Should starts with https://</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Photo url</mat-label>
              <input
                matInput
                [(ngModel)]="genericExternalSource.photoUrl"
                required
                autocomplete="off"
                pattern="^https://.+"
                name="photoUrl"
                >
              <mat-error>Should starts with https://</mat-error>
            </mat-form-field>
          </section>
          <admin-save-button [disabled]="!form.valid" [pageStatus]="pageStatus">Save</admin-save-button>
        </form>
      </section>
    }

    <div>
      @if (pageStatus === 'saveError' && pageError) {
        <admin-error [error]="pageError"></admin-error>
      }
    </div>
  </div>

</admin-page-simple-content>
