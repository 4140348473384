import { Route } from '@angular/router';

import { AnomaliesPageContainerComponent } from './containers/anomalies-page/anomalies-page.component';
import { AuthorizedUsersPageContainerComponent } from './containers/authorized-users-page/authorized-users-page.component';
import { DashboardsOverviewPageContainerComponent } from './containers/dashboards-overview-page/dashboards-overview-page.component';
import { Error404PageContainerComponent } from './containers/error-404-page/error-404-page.component';
import { FulfillerPageContainerComponent } from './containers/fulfiller-page/fulfiller-page.component';
import { FulfillersPageContainerComponent } from './containers/fulfillers-page/fulfillers-page.component';
import { HomePageContainerComponent } from './containers/home-page/home-page.component';
import { MediaclipDesignerComponent } from './containers/mediaclip-designer/mediaclip-designer.component';
import { OrderPageContainerComponent } from './containers/order-page/order-page.component';
import { OrdersPageContainerComponent } from './containers/orders-page/orders-page.component';
import { ExternalPhotoSourcesPageComponent } from './containers/external-photo-source/external-photo-sources-page.component';
import { ProductPageContainerComponent } from './containers/product-page/product-page.component';
import { ProductsPageContainerComponent } from './containers/products-page/products-page.component';
import { ProjectPageContainerComponent } from './containers/project-page/project-page.component';
import { SharedProjectPageContainerComponent } from './containers/shared-project-page/shared-project-page.component';
import { RootPageContainerComponent } from './containers/root-page/root-page.component';
import { SearchPageContainerComponent } from './containers/search-page/search-page.component';
import { SignInPageContainerComponent } from './containers/signin/signin-page.component';
import { signInPageGuard } from './containers/signin/signin-page.guard';
import { StoreDesignerCustomCssComponent } from './containers/store-designer-custom-css/store-designer-custom-css.component';
import { StoreDesignerCustomJavascriptComponent } from './containers/store-designer-custom-javascript/store-designer-custom-javascript.component';
import { StoreDesignerLogoComponent } from './containers/store-designer-logo/store-designer-logo.component';
import {
    StoreDesignerVersionConfigurationComponent as StoreDesignerReleaseChannelConfigurationComponent
} from './containers/store-designer-release-channel-configuration/store-designer-release-channel-configuration.component';
import { StoreEndpointsPageContainerComponent } from './containers/store-endpoints-page/store-endpoints-page.component';
import { StoreFulfillerLogsPageContainerComponent } from './containers/store-fulfiller-logs-page/store-fulfiller-logs-page.component';
import { StoreFulfillerPageContainerComponent } from './containers/store-fulfiller-page/store-fulfiller-page.component';
import { StoreFulfillersPageContainerComponent } from './containers/store-fulfillers-page/store-fulfillers-page.component';
import { StoreLogsPageContainerComponent } from './containers/store-logs-page/store-logs-page.component';
import { StorePhotoFilterConfigurationsComponent } from './containers/store-photo-filter-configurations/store-photo-filter-configurations.component';
import { StoreSecretsPageContainerComponent } from './containers/store-secrets-page/store-secrets-page.component';
import { StoreSelectorPageComponent } from './containers/store-selector-page/store-selector-page.component';
import { SubscriptionSelectorPageComponent } from './containers/subscription-selector-page/subscription-selector-page.component';
import { StoreSettingsPageContainerComponent } from './containers/store-settings-page/store-settings-page.component';
import { StoreStatusPageContainerComponent } from './containers/store-status-page/store-status-page.component';
import { StylePageContainerComponent } from './containers/style-page/style-page.component';
import { SubscriptionsPageContainerComponent } from './containers/subscriptions-page/subscriptions-page.component';
import { UserPageContainerComponent } from './containers/user-page/user-page.component';
import { StoreRemoteFilterPageComponent } from './containers/store-remote-filter-page/store-remote-filter-page.component';
import { LibrariesPageComponent } from './containers/libraries-page/libraries-page.component';
import { RecentOrderPageComponent } from './containers/recent-order-page/recent-order-page.component';
import { GenericExternalPhotoSourceEditPageComponent } from './containers/external-photo-source/generic-external-photo-source-edit-page.component';
import { BuiltInExternalPhotoSourceEditPageComponent } from './containers/external-photo-source/built-in-external-photo-source-edit-page.component';
import { SubscriptionPageContainerComponent } from './containers/subscription-page/subscription-page.component';
import { StoreDesignerCustomLocalesComponentComponent } from './containers/store-designer-custom-locales-component/store-designer-custom-locales-component.component';
import { viewContextGuard } from './navigation/view-context-guard';
import { StockPhotoSourcesPageContainerComponent } from './containers/stock-photo-sources-page/stock-photo-sources-page.component';
import { LibraryPageComponent } from './containers/libraries-page/library-page.component';
import { LibraryAuthorizedUserComponent } from './containers/libraries-page/library-authorized-user.component';
import { FulfillerAuthorizedUserComponent } from './containers/fulfiller-page/fulfiller-authorized-user.component';
import { FulfillerSecretsPageComponent } from './containers/fulfiller-page/fulfiller-secrets-page.component';
import { CreateOrderComponent } from './containers/orders-page/create-order.component';
import { SubscriptionAuthorizedUserPageComponent } from './containers/subscription-page/subscription-authorized-user-page.component';
import { SubscriptionBillingPageComponent } from './containers/subscription-page/subscription-billing-page.component';
import { SubscriptionCleanupConfigPageComponent } from './containers/subscription-page/subscription-cleanup-config-page.component';
import { AdminUsersPageComponent } from './containers/admin-users/admin-users-page.component';
import { AdminUserPageComponent } from './containers/admin-users/admin-user-page.component';
import { StockPhotoSourcePageComponent } from "./containers/stock-photo-source-page/stock-photo-source-page.component";
import { PhotoPageComponent } from './containers/photo-page/photo-page.component';
import { GeneratedImagesPageContainerComponent} from "./containers/generated-images-page/generated-images-page.component";
import { GeneratedImagePageComponent } from "./containers/generated-image-page/generated-image-page.component";
import { GeneratedImageStoreAssociationsPageComponent } from './containers/generated-image-page/generated-image-store-associations-page.component';
import { GeneratedImageStoreAssociationPageComponent } from './containers/generated-image-page/generated-image-store-association-page.component';
import { ManageDesignerReleaseChannelVersionPageContainerComponent } from "./containers/manage-designer-release-channel-version/manage-designer-release-channel-version.component";
import { StorePhotosUpscalingPageContainerComponent } from "./containers/store-photos-upscaling-page/store-photos-upscaling-page.component";
import { StoreGeneratedTextPageComponent } from "./containers/store-generated-text-page/store-generated-text-page.component";
import {
    StoreFilterCategoriesPageComponent
} from "./containers/store-filter-categories-page/store-filter-categories-page.component";
import { DirectPhotoSourceEditPageComponent } from './containers/external-photo-source/direct-photo-source-edit-page.component';
import { RequestLoggerPageComponent } from './containers/request-logger/request-logger-page.component';

export const routes: Route[] = [
    {
        path: '',
        component: RootPageContainerComponent
    },
    {
        path: 'signin-auth0',
        canActivate: [signInPageGuard],
        component: SignInPageContainerComponent
    },
    {
        path: 'home',
        component: HomePageContainerComponent
    },
    {
        path: 'search',
        component: SearchPageContainerComponent
    },
    {
        path: 'orders/:orderId',
        component: OrderPageContainerComponent
    },
    {
        path: 'projects/:projectId',
        component: ProjectPageContainerComponent
    },
    {
        path: 'shared-projects/:sharedProjectId',
        component: SharedProjectPageContainerComponent
    },
    {
        path: 'projects/:projectId/edit',
        component: MediaclipDesignerComponent
    },
    {
        path: 'projects/:projectId/versions/:projectVersionId',
        component: ProjectPageContainerComponent
    },
    {
        path: 'users/:userId',
        component: UserPageContainerComponent
    },
    {
        path: 'select-store',
        component: StoreSelectorPageComponent
    },
    {
        path: 'select-subscription',
        component: SubscriptionSelectorPageComponent
    },
    {
        path: 'stores/:storeId',
        canActivate: [viewContextGuard],
        children: [
            {
                path: 'dashboards/overview',
                component: DashboardsOverviewPageContainerComponent
            },
            {
                path: 'logs/fulfillers/:fulfillerId',
                component: StoreFulfillerLogsPageContainerComponent
            },
            {
                path: 'logs',
                component: StoreLogsPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/logs'
                }
            },
            {
                path: 'lists/action-required',
                component: OrdersPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/lists/action-required'
                }
            },
            {
                path: 'create-order',
                component: CreateOrderComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/create-order'
                }
            },
            {
                path: 'lists/recent',
                component: RecentOrderPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/lists/recent'
                }
            },
            {
                path: 'orders/:storeOrderId',
                children: [
                    {
                        path: '',
                        component: OrderPageContainerComponent
                    },
                    {
                        path: 'lines/:storeLineNumber/edit',
                        component: MediaclipDesignerComponent
                    },
                    {
                        path: 'lines/:storeLineNumber',
                        component: OrderPageContainerComponent
                    }
                ]
            },
            {
                path: 'library/products',
                component: ProductsPageContainerComponent
            },
            {
                path: 'library/product',
                component: ProductPageContainerComponent
            },
            {
                path: 'settings/endpoints',
                component: StoreEndpointsPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/endpoints'
                }
            },
            {
                path: 'settings/general',
                component: StoreSettingsPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/general'
                }
            },
            {
                path: 'settings/status',
                component: StoreStatusPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/status'
                }
            },
            {
                path: 'settings/external-photo-sources',
                component: ExternalPhotoSourcesPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/photos-upscaling',
                component: StorePhotosUpscalingPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/photos-upscaling'
                }
            },
            {
                path: 'settings/generated-text',
                component: StoreGeneratedTextPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/generated-text'
                }
            },
            {
                path: 'settings/external-photo-sources/generic/create/:photoSourceId',
                component: GenericExternalPhotoSourceEditPageComponent,
                data: {
                    mode: 'create',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/generic/edit/:photoSourceId',
                component: GenericExternalPhotoSourceEditPageComponent,
                data: {
                    mode: 'edit',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/direct/create/:photoSourceId',
                component: DirectPhotoSourceEditPageComponent,
                data: {
                    mode: 'create',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/direct/edit/:photoSourceId',
                component: DirectPhotoSourceEditPageComponent,
                data: {
                    mode: 'edit',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/built-in/create/:photoSourceId',
                component: BuiltInExternalPhotoSourceEditPageComponent,
                data: {
                    mode: 'create',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/built-in/edit/:photoSourceId',
                component: BuiltInExternalPhotoSourceEditPageComponent,
                data: {
                    mode: 'edit',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'designer/release-channel',
                component: StoreDesignerReleaseChannelConfigurationComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/release-channel'
                }
            },
            {
                path: 'designer/generated-images',
                children: [
                    {
                        path: '',
                        component: GeneratedImageStoreAssociationsPageComponent,
                        data: {
                            viewContextType: 'store',
                            viewContextChangeUrl: 'stores/:storeId/designer/generated-images'
                        }
                    },
                    {
                        path: 'create',
                        component: GeneratedImageStoreAssociationPageComponent,
                        data: {
                            viewContextType: 'store',
                            viewContextChangeUrl: 'stores/:storeId/designer/generated-images/create'
                        }
                    },
                    {
                        path: ':associationId',
                        component: GeneratedImageStoreAssociationPageComponent,
                        data: {
                            viewContextType: 'store',
                            viewContextChangeUrl: 'stores/:storeId/designer/generated-images'
                        }
                    }
                ]
            },
            {
                path: 'designer/custom-javascript',
                component: StoreDesignerCustomJavascriptComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/custom-javascript'
                }
            },
            {
                path: 'designer/custom-locales',
                component: StoreDesignerCustomLocalesComponentComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/custom-locales'
                }
            },
            {
                path: 'designer/custom-css',
                component: StoreDesignerCustomCssComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/custom-css'
                }
            },
            {
                path: 'designer/logo',
                component: StoreDesignerLogoComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/logo'
                }
            },
            {
                path: 'designer/photo-filters',
                component: StorePhotoFilterConfigurationsComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/photo-filters'
                }
            },
            {
                path: 'designer/remote-filters',
                component: StoreRemoteFilterPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/remote-filters'
                }
            },
            {
                path: 'designer/filter-categories',
                component: StoreFilterCategoriesPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/filter-categories'
                }
            },
            {
                path: 'settings/authorized-users',
                component: AuthorizedUsersPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/authorized-users'
                }
            },
            {
                path: 'settings/fulfillers',
                component: StoreFulfillersPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/fulfillers'
                }
            },
            {
                path: 'settings/fulfillers/:fulfillerId',
                component: StoreFulfillerPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/fulfillers'
                }
            },
            {
                path: 'settings/secrets',
                component: StoreSecretsPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/secrets'
                }
            }
        ]
    },
    {
        path: 'anomalies',
        component: AnomaliesPageContainerComponent
    },
    {
        path: 'manage-designer-release-channel-version',
        component: ManageDesignerReleaseChannelVersionPageContainerComponent
    },
    {
        path: 'subscriptions',
        component: SubscriptionsPageContainerComponent
    },
    {
        path: 'subscriptions/:subscriptionId',
        canActivate: [viewContextGuard],
        children: [
            {
                path: '',
                component: SubscriptionPageContainerComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId'
                }
            },
            {
                path: 'authorizedUsers',
                component: SubscriptionAuthorizedUserPageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/authorizedUsers'
                }
            },
            {
                path: 'billing',
                component: SubscriptionBillingPageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/billing'
                }
            },
            {
                path: 'cleanup',
                component: SubscriptionCleanupConfigPageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/cleanup'
                }
            },
            {
                path: 'generated-images',
                component: GeneratedImagesPageContainerComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/generated-images'
                }
            },
            {
                path: 'generated-images/create',
                component: GeneratedImagePageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/generated-images'
                }
            },
            {
                path: 'generated-images/:generatedImageId',
                component: GeneratedImagePageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/generated-images/:generatedImageId'
                }
            }
        ]
    },
    {
        path: 'fulfillers',
        component: FulfillersPageContainerComponent
    },
    {
        path: 'libraries',
        component: LibrariesPageComponent
    },
    {
        path: 'libraries/:libraryOwner',
        canActivate: [viewContextGuard],
        component: LibraryPageComponent,
        data: {
            viewContextType: 'library',
            viewContextChangeUrl: 'libraries/:libraryOwner'
        }
    },
    {
        path: 'libraries/:libraryOwner/authorizedUsers',
        canActivate: [viewContextGuard],
        component: LibraryAuthorizedUserComponent,
        data: {
            viewContextType: 'library',
            viewContextChangeUrl: 'libraries/:libraryOwner/authorizedUsers'
        }
    },
    {
        path: 'photos/:photoUrn',
        component: PhotoPageComponent
    },
    {
        path: 'fulfillers/:fulfillerId',
        canActivate: [viewContextGuard],
        component: FulfillerPageContainerComponent
    },
    {
        path: 'fulfillers/:fulfillerId/authorizedUsers',
        canActivate: [viewContextGuard],
        component: FulfillerAuthorizedUserComponent,
        data: {
            viewContextType: 'fulfiller',
            viewContextChangeUrl: 'fulfillers/:fulfillerId/authorizedUsers'
        }
    },
    {
        path: 'fulfillers/:fulfillerId/secrets',
        canActivate: [viewContextGuard],
        component: FulfillerSecretsPageComponent,
        data: {
            viewContextType: 'fulfiller',
            viewContextChangeUrl: 'fulfillers/:fulfillerId/secrets'
        }
    },
    {
        path: 'stock-photo-sources',
        component: StockPhotoSourcesPageContainerComponent
    },
    {
        path: 'request-logger',
        component: RequestLoggerPageComponent
    },
    {
        path: 'stock-photo-sources/:stockPhotoSourceId',
        component: StockPhotoSourcePageComponent
    },
    {
        path: 'generated-images',
        component: GeneratedImagesPageContainerComponent
    },
    {
        path: 'generated-images/create',
        component: GeneratedImagePageComponent
    },
    {
        path: 'generated-images/:generatedImageId',
        component: GeneratedImagePageComponent
    },
    {
        path: 'admin-users',
        children: [
            {
                path: '',
                component: AdminUsersPageComponent
            },
            {
                path: ':userEmail',
                component: AdminUserPageComponent
            }
        ]
    },
    {
        path: 'dev/style',
        component: StylePageContainerComponent
    },
    {
        path: '**',
        component: Error404PageContainerComponent
    }
];
