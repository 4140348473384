import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {GeneratedImagesService} from '../../services/generated-images.service';
import {AuthorizationContext, AuthorizationService} from '../../services';
import {GeneratedImageStoreAssociation, Store} from '../../../schema-dotnet';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog-service';

@Component({
    templateUrl: './generated-image-store-associations-page.component.html',
    styleUrls: ['./generated-image-store-associations-page.component.scss']
})
export class GeneratedImageStoreAssociationsPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    storeId: string;
    authContext: AuthorizationContext;
    storeWithGeneratedImages?: Store;

    constructor(
        private readonly generatedImagesService: GeneratedImagesService,
        private readonly authorizationService: AuthorizationService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.storeId = params.storeId;

                this.subscribeWithGenericLoadingErrorHandling(this.generatedImagesService.listStoreAssociations(this.storeId), (storeWithGeneratedImages) => {
                    this.storeWithGeneratedImages = storeWithGeneratedImages;
                });

            }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    delete(association: GeneratedImageStoreAssociation) {
        this.confirmDialogService.confirm(
            'Delete Generated Image'
            , `<p><strong>Deleting this may break existing projects.</strong></p>
<p>Be sure to provide an alternative version with the same alias to keep them working.</p>
 <p>Are you sure you want to delete the generated image association with the alias: <code>${association.alias}</code> ?</p>`,
            undefined,
            {
                yes: {
                    label: 'Delete',
                    warn: true
                }
            })
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.deleteGeneratedImageAssociation(association.id));
                let indexToRemove = this.storeWithGeneratedImages?.generatedImageAssociations.findIndex(x =>x.id === association.id);
                this.storeWithGeneratedImages?.generatedImageAssociations.splice(indexToRemove!, 1);
            });
    }
}
