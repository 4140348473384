import { Pipe, PipeTransform } from '@angular/core';

const regex = /\$\(package:(.+?)\/(.+?)\)\/([^ "'\);,:]+)/g;

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
    transform(value: string): string {
        if (typeof value !== 'string') {
            return <any>value;
        }

        return value.replace(regex, '<a href="https://git.mediacliphub.com/librairies/$1/tree/master/$2/$3">$&</a>');
    }
}
