import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { DesignerCustomizationsService } from '../../services';
import { PageStatus } from '../../models';
import { AdminComponentBase } from '../../utils/admin-component-base';

@Component({
    selector: 'admin-store-designer-custom-javascript',
    templateUrl: './store-designer-custom-javascript.component.html',
    styleUrls: ['./store-designer-custom-javascript.component.scss']
})
export class StoreDesignerCustomJavascriptComponent extends AdminComponentBase implements OnInit, OnDestroy {
    public javascript: string;
    public storeId: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly designerCustomizationsService: DesignerCustomizationsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.designerCustomizationsService.getStoreFiles(this.storeId), (storeFiles) => {
                if (!storeFiles.javascript?.url) {
                    this.javascript = '';
                    this.pageStatus = PageStatus.loaded;
                } else {
                    this.designerCustomizationsService
                        .getCustomizationFile(storeFiles.javascript?.url)
                        .subscribe({
                            next: (javascript) => {
                                this.javascript = javascript;
                                this.pageStatus = PageStatus.loaded;
                            },
                            error: (err) => {
                                this.pageError = err;
                                this.pageStatus = PageStatus.loadError;
                            }
                        });
                }
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    save(): void {
        this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateJavascriptFile({
            storeId: this.storeId,
            content: this.javascript
        }));
    }

    async onFilesChanged(files: FileList) {
        if (!files.length) {
            return;
        }
        this.javascript = await files[0].text();
    }
}
