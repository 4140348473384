import { Component, Input, OnInit } from '@angular/core';

import { PageStatus } from '../../models';
import { fuseAnimations } from '../../../@fuse/animations';

@Component({
    selector: 'admin-page-simple-content',
    templateUrl: './page-simple-content.component.html',
    styleUrls: ['./page-simple-content.component.scss'],
    animations: fuseAnimations,
})
export class PageSimpleContentComponent {
    @Input() title: string;
    @Input() icon: string;
    @Input() pageStatus: PageStatus = PageStatus.loading;
    @Input() pageError?: Error;

    constructor() {}
}
