import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ViewContextService, ViewContextStore} from '../../navigation/view-context.service';

@Component({
    templateUrl: './store-selector-page.component.html',
    styleUrls: ['./store-selector-page.component.scss']
})
export class StoreSelectorPageComponent implements OnInit {
    availableContexts: ViewContextStore[];
    filteredContexts: ViewContextStore[];
    contextFilterText: string = '';
    selectedStore?: ViewContextStore;

    constructor(
        private readonly router: Router,
        private readonly viewContextService: ViewContextService
    ) {
    }

    ngOnInit(): void {
        this.viewContextService.availableContexts$.subscribe(contexts => {
            this.availableContexts = [...contexts.filter(x => x.type === 'store')] as ViewContextStore[];
            this.updateFilter();
        });
    }

    updateFilter() {
        let filterText = this.contextFilterText.toLowerCase();
        this.filteredContexts = this.availableContexts.filter(c => {
            if (!filterText) {
                return true;
            }
            return c.storeId.toLowerCase().includes(filterText) || c.storeLabel.toLowerCase().includes(filterText);
        });
    }

    selectStore(store: ViewContextStore) {
        if (!store) {
            return;
        }
        const redirectSubPath = localStorage.getItem('routeRedirectAfterStoreSelect') || 'dashboards/overview';
        localStorage.removeItem('routeRedirectAfterStoreSelect');
        this.router.navigateByUrl(`stores/${encodeURIComponent(store.storeId)}/${redirectSubPath}`);
    }

    updateSelectedStore(storeId: string) {
        this.selectedStore = this.availableContexts.find(x => x.storeId == storeId);
    }
}
