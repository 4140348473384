import {AuthenticatedUser, OrderLine, OrderLineStatusFlags, OrderLineStatusValues} from 'schema-dotnet';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {SelectionModel} from '@angular/cdk/collections';
import {Sort} from '@angular/material/sort';
import {take} from 'rxjs/operators';
import {ChangeStatusDialogComponent, ChangeStatusDialogData} from '../../components/change-status-dialog/change-status-dialog';
import {MatDialog} from '@angular/material/dialog';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {AnomaliesService, AuthenticationService, OrdersService} from '../../services';

@Component({
    templateUrl: './anomalies-page.component.html',
    styleUrls: ['./anomalies-page.component.scss'],
})
export class AnomaliesPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    anomalies: OrderLine[] = [];
    sortedAnomalies: OrderLine[] = [];
    lastSelected?: OrderLine;
    currentSort?: Sort;

    public selection = new SelectionModel<OrderLine>(true, []);
    private user$: Observable<AuthenticatedUser>;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly dialog: MatDialog,
        private readonly anomaliesService: AnomaliesService,
        private readonly orderService: OrdersService,
    ) {
        super();
        this.loadSort();
    }


    ngOnInit(): void {
        this.subscribeWithGenericLoadingErrorHandling(this.anomaliesService.load(), anomalies => {
            this.anomalies = anomalies.lines;
            this.updateSort();
        });
        this.user$ = this.authenticationService.authenticatedUser$;
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    isAllSelected(anomaliesCount: number) {
        const numSelected = this.selection.selected.length;
        return numSelected === anomaliesCount;
    }

    toggleAllRows(anomaliesCount: number, anomalies: OrderLine[]) {
        this.isAllSelected(anomaliesCount) ?
            this.selection.clear() :
            anomalies.forEach(row => this.selection.select(row));
    }

    sortData(sort: Sort) {
        this.currentSort = sort;
        this.updateSort();
    }

    selectRow($event: MouseEvent, anomaly: OrderLine, sortedAnomalies: OrderLine[]) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.selection.toggle(anomaly);
        if ($event.shiftKey && this.lastSelected) {
            const lastIndex = sortedAnomalies.findIndex(o => o.id === this.lastSelected?.id);
            const selectedIndex = sortedAnomalies.findIndex(o => o.id === anomaly.id);
            if (lastIndex !== -1 && selectedIndex !== -1) {
                if (lastIndex > selectedIndex) {
                    for (let i = selectedIndex; i <= lastIndex; i++) {
                        this.selection.select(sortedAnomalies[i]);
                    }
                } else {
                    for (let i = lastIndex; i <= selectedIndex; i++) {
                        this.selection.select(sortedAnomalies[i]);
                    }
                }

            }
        }
        this.lastSelected = anomaly;
    }

    selectSingleRow(anomaly: OrderLine) {
        this.selection.toggle(anomaly);
        this.lastSelected = anomaly;
    }

    reload() {
        this.subscribeWithGenericLoadingErrorHandling(this.anomaliesService.load(), anomalies => {
            this.anomalies = anomalies.lines;
            this.updateSort();
            this.selection.clear();
        });
    }

    retryAll() {
        this.subscribeWithGenericSavinErrorHandling(this.anomaliesService.retryAll(), () => {
            this.reload();
        });
    }

    retrySelection(anomalies: OrderLine[]) {
        let orderIds = [...new Set(anomalies
            .filter(a => a.status.value === 'Error')
            .map(x => x.order.id))];
        this.retryOrderIdRecursive(orderIds);
    }

    retryOrderIdRecursive(orderIds: string[]) {
        let orderId = orderIds[0];
        if (!orderId) {
            this.reload();
            return;
        }
        this.subscribeWithGenericSavinErrorHandling(this.orderService.retryFailedOrderLines(orderId), () => {
            this.retryOrderIdRecursive(orderIds.slice(1));
        });
    }

    openChangeStatus(anomalies: OrderLine[]) {
        let email = '?';
        this.user$
            .pipe(take(1))
            .subscribe((user) => (email = user.email || '?'))
            .unsubscribe();

        const dialogRef = this.dialog.open<ChangeStatusDialogComponent, ChangeStatusDialogData>(
            ChangeStatusDialogComponent,
            {
                maxWidth: '400px',
                data: {
                    lineId: '-',
                    status: anomalies[0].status.value,
                    details: `Changed by ${email}`,
                    actionRequired: false,
                    isSuperAdmin: true,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.changeStatusRecursive(anomalies, result.status, result.details, result.actionRequired);
        });
    }

    changeStatusRecursive(anomalies: OrderLine[], status: OrderLineStatusValues, details: string, actionRequired: boolean) {
        let anomaly = anomalies[0];
        if (!anomaly) {
            this.reload();
            return;
        }
        this.subscribeWithGenericSavinErrorHandling(this.orderService.setOrderLineStatus(
            anomaly.id,
            status,
            details,
            actionRequired ? OrderLineStatusFlags.StoreActionRequired : OrderLineStatusFlags.None,
        ), () => {
            this.changeStatusRecursive(anomalies.slice(1), status, details, actionRequired);
        });
    }

    private updateSort() {
        this.sortedAnomalies = this.sortLines();
        this.saveSort();
    }

    private sortLines(): OrderLine[] {
        let sort = this.currentSort;
        if (!sort?.direction) {
            return [...this.anomalies];
        }

        switch (sort?.active) {
            case 'storeId': {
                if (sort.direction === 'asc') {
                    return [...this.anomalies].sort((a, b) => a.store.id.localeCompare(b.store.id));
                } else {
                    return [...this.anomalies].sort((a, b) => b.store.id.localeCompare(a.store.id));
                }
            }
            case 'status': {
                if (sort.direction === 'asc') {
                    return [...this.anomalies].sort((a, b) => a.status.value.localeCompare(b.status.value));
                } else {
                    return [...this.anomalies].sort((a, b) => b.status.value.localeCompare(a.status.value));
                }
            }
            case 'dateCreatedUtc': {
                if (sort.direction === 'asc') {
                    return [...this.anomalies].sort((a, b) => a.dateCreatedUtc.localeCompare(b.dateCreatedUtc));
                } else {
                    return [...this.anomalies].sort((a, b) => b.dateCreatedUtc.localeCompare(a.dateCreatedUtc));
                }
            }
        }
        return [...this.anomalies];
    }

    $orderLine(o: any): OrderLine {
        return o as OrderLine;
    }

    private saveSort() {
        localStorage.setItem('anomalies-page-sort', JSON.stringify(this.currentSort));
    }

    private loadSort() {
        try {
            const savedSortJson = localStorage.getItem('anomalies-page-sort');
            if (savedSortJson) {
                const savedSort = JSON.parse(savedSortJson);
                if (savedSort) {
                    if ('active' in savedSort && 'direction' in savedSort) {
                        this.currentSort = savedSort;
                    }
                }
            }
        } catch {
            // ignored
        }
    }
}
