import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderLineStatusValues } from 'schema-dotnet';

export interface ChangeStatusDialogData {
    lineId: string;
    status: OrderLineStatusValues;
    details: string;
    actionRequired: boolean;
    isSuperAdmin: boolean;
}

@Component({
    selector: 'admin-change-status-dialog',
    templateUrl: 'change-status-dialog.html',
    styleUrls: ['change-status-dialog.scss'],
})
export class ChangeStatusDialogComponent {
    public previousStatus: string;
    public statuses: { value: OrderLineStatusValues; viewValue: string }[];

    constructor(
        public dialogRef: MatDialogRef<ChangeStatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ChangeStatusDialogData
    ) {
        this.previousStatus = data.status;

        if (data.isSuperAdmin) {
            this.statuses = [
                { value: OrderLineStatusValues.AvailableForDownload, viewValue: 'Available for download' },
                { value: OrderLineStatusValues.SentToFulfillment, viewValue: 'Sent to fulfillment' },
                { value: OrderLineStatusValues.Shipped, viewValue: 'Shipped' },
                { value: OrderLineStatusValues.Cancelled, viewValue: 'Cancelled' },
                { value: OrderLineStatusValues.Error, viewValue: 'Error' },
                { value: OrderLineStatusValues.OnHold, viewValue: 'On hold' },
                { value: OrderLineStatusValues.WaitingForCorrections, viewValue: 'Waiting for corrections' },
                { value: OrderLineStatusValues.ReviewCompleted, viewValue: 'Review Completed' },
            ];
        } else {
            this.statuses = [
                { value: OrderLineStatusValues.AvailableForDownload, viewValue: 'Available for download' },
                { value: OrderLineStatusValues.SentToFulfillment, viewValue: 'Sent to fulfillment' },
                { value: OrderLineStatusValues.Shipped, viewValue: 'Shipped' },
                { value: OrderLineStatusValues.Cancelled, viewValue: 'Cancelled' },
                { value: OrderLineStatusValues.WaitingForCorrections, viewValue: 'Waiting for corrections' },
                { value: OrderLineStatusValues.ReviewCompleted, viewValue: 'Review Completed' },
            ];
        }

        if (!this.statuses.some((s) => s.value === data.status)) {
            this.statuses.unshift({ value: data.status, viewValue: data.status });
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
