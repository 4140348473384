import {ActivatedRoute} from '@angular/router';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FuseMatchMediaService} from '@fuse/services/match-media.service';
import {OrderLine, OrderLineStatusFlags, OrderLineUnion} from 'schema-dotnet';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {OrdersService} from '../../services';

const allColumns = ['storeOrderId', 'dateCreatedUtc', 'status'];
const mobileColumns = ['storeOrderId', 'status'];

@Component({
    templateUrl: './orders-page.component.html',
    styleUrls: ['./orders-page.component.scss'],
})
export class OrdersPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    @ViewChild('filter', {static: true})
    filter: ElementRef;

    lines: OrderLineUnion[] = [];
    displayedColumns = allColumns;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly mediaService: FuseMatchMediaService,
        private readonly ordersService: OrdersService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(param => {
            let storeId = param.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.ordersService.getLines(storeId, OrderLineStatusFlags.StoreActionRequired), lines => {
                this.lines = lines;
            });
        }));
        this.registerSubscription(this.mediaService.onMediaChange.subscribe((value) => {
            if (value == 'lt-md')
                this.displayedColumns = mobileColumns
            if (value == 'gt-sm')
                this.displayedColumns = allColumns
        }));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    $orderLine(o: any): OrderLine {
        return o as OrderLine;
    }
}
