<admin-page-simple-content [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Settings: API Secrets'"
  [icon]="'key'">
  @if (storeWithSecrets) {
    <div class="content">
      <h2>Information</h2>
      <p>This is the list of secrets used to <a
        href="https://doc.mediaclip.ca/hub/api/authorization/store">authenticate
      your store against Mediaclip Hub</a>.</p>
      <p>If you revoke a secret all the call using it will fail immediately, so if you want to change the secret,
      start by creating a new secret, then revoke the old one once you've updated the usage.</p>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Secrets</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list>
            @for (secret of secrets; track secret) {
              <mat-list-item>
                <div class="secret">
                  {{secret.id}} - {{secret.label}}
                  @if (canManageSecrets(storeWithSecrets)) {
                    <button mat-stroked-button color="warn"
                      (click)="revokeSecret(storeId, secret)">
                      <mat-icon>delete</mat-icon>
                      REVOKE
                    </button>
                  }
                </div>
              </mat-list-item>
            }
          </mat-list>
          <div>
            @if (canManageSecrets(storeWithSecrets)) {
              <button mat-stroked-button color="primary"
                (click)="createSecret(storeId)">
                <mat-icon>add</mat-icon>
                CREATE NEW SECRET
              </button>
            }
          </div>
        </mat-card-content>
      </mat-card>
      @if (lastCreatedSecret) {
        <mat-card>
          <mat-card-header>
            <mat-card-title>Generated secret</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              A new secret was successfully generated. It will be shown only once, you'll have to generate a new
              one if you lose this.
            </p>
            <p>Label: {{lastCreatedSecret.label}}</p>
            <p>Secret: <code adminCopyToClipboard>{{lastCreatedSecret.secret}}</code>
          </p>
        </mat-card-content>
      </mat-card>
    }
    <div>
      @if (pageStatus === 'saveError' && pageError) {
        <admin-error [error]="pageError"></admin-error>
      }
    </div>
  </div>
}

</admin-page-simple-content>

