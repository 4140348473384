import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { AuthorizationContext, AuthorizationService, AuthorizedUsersService, LibraryService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { GlobalPermission, Library, LibraryRole, LibraryUserRole } from '../../../schema-dotnet';
import { AddAuthorizedUserDialogComponent, AddAuthorizedUserDialogData, AddAuthorizedUserDialogResult } from '../authorized-users-page/add-authorized-user-dialog.component';
import { PageStatus } from '../../models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';

@Component({
    templateUrl: './library-authorized-user.component.html',
    styleUrls: ['./library-authorized-user.component.scss']
})
export class LibraryAuthorizedUserComponent extends AdminComponentBase implements OnInit, OnDestroy {
    libraryOwner: string;
    libraryRoles: LibraryRole[] = [];
    libraryWithAuthorizedUsers?: Library;
    authContext: AuthorizationContext;

    constructor(
        private readonly authorizedUsersService: AuthorizedUsersService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly libraryService: LibraryService,
        private readonly authorizationService: AuthorizationService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.libraryOwner = params.libraryOwner;
                this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.authorizedUsersService.getAvailableRoles(),
                    this.libraryService.getAuthorizedUsers(this.libraryOwner)
                ]), ([availableRoles, libraryWithAuthorizedUsers]) => {
                    this.libraryRoles = availableRoles.libraryRoles;
                    this.libraryWithAuthorizedUsers = libraryWithAuthorizedUsers;
                });
            }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    canManageUsers() {
        return this.authContext.hasGlobalPermissions(GlobalPermission.ManageAdminUsers);
    }

    openAddLibraryRole(library: Library, user?: LibraryUserRole) {
        const dialogRef = this.dialog.open<AddAuthorizedUserDialogComponent<LibraryRole>, AddAuthorizedUserDialogData<LibraryRole>, AddAuthorizedUserDialogResult<LibraryRole>>(AddAuthorizedUserDialogComponent, {
            data: {
                email: user?.user?.email,
                target: {
                    availableRoles: this.libraryRoles.filter(role => !user || !user.roles.includes(role)),
                    label: library.owner
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRoleOnLibrary({
                email: result.email,
                libraryAccess: { id: library.owner, roles: [result.role] }
            }), user => {
                let existingUser = library.authorizedUsers.find(e => e.user?.email === user.email);
                if (existingUser) {
                    existingUser.roles.push(result.role);
                } else {
                    library.authorizedUsers.push({
                        user,
                        roles: [result.role]
                    });
                }
            });
        });
    }

    removeLibraryRole(library: Library, email: string, role: LibraryRole) {
        this.confirmDialogService.confirm('Remove role', `Remove the role <code>${role}</code> for the user <code>${email}</code>?`, undefined, {
            yes: {
                label: 'Remove role',
                warn: true
            }
        }).subscribe(() => {
            this.pageStatus = PageStatus.saving;
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email,
                libraries: [{ id: library.owner, roles: [role] }]
            }), () => {
                let user = library.authorizedUsers.find(e => e.user?.email == email);
                if (user) {
                    let roleIndex = user.roles.indexOf(role);
                    if (roleIndex !== -1) {
                        user.roles.splice(roleIndex, 1);
                    }
                }
            });
        });
    }
}
