import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ErrorDialogData {
    message: string;
    details?: string;
}

@Component({
    selector: 'admin-error-dialog',
    templateUrl: 'error-dialog.html',
    styleUrls: ['error-dialog.scss'],
})
export class ErrorDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData
    ) {}

    ok(): void {
        this.dialogRef.close();
    }
}
