import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalPermission, Store, StoreFulfiller, StorePermission } from 'schema-dotnet';
import { AuthorizationContext, AuthorizationService, StoreFulfillersService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddStoreFulfillerDialogComponent, AddStoreFulfillerDialogData, AddStoreFulfillerDialogResult } from './add-store-fulfiller-dialog.component';
import { AdminComponentBase } from '../../utils/admin-component-base';

@Component({
    selector: 'admin-store-fulfillers-page',
    templateUrl: './store-fulfillers-page.component.html',
    styleUrls: ['./store-fulfillers-page.component.scss']
})
export class StoreFulfillersPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    storeId: string;

    storeWithFulfillers?: Store;
    auth: AuthorizationContext;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly storeFulfillersService: StoreFulfillersService,
        private readonly authorizationService: AuthorizationService,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((auth) => {
            this.auth = auth;
        });

        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeFulfillersService.getStoreFulfillers(this.storeId), storeWithFulfillers => {
                this.storeWithFulfillers = storeWithFulfillers;
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    openAddStoreFulfillerDialog() {
        let dialogRef = this.dialog.open<AddStoreFulfillerDialogComponent, AddStoreFulfillerDialogData, AddStoreFulfillerDialogResult>(AddStoreFulfillerDialogComponent, {
            data: {
                usedFulfillerId: this.storeWithFulfillers?.fulfillers?.map(e => e.fulfillerId) || []
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.router.navigate([result.fulfillerId], { relativeTo: this.route });
        });
    }

    canManageStoreFulfillers(): boolean {
        if (!this.storeWithFulfillers) {
            return false;
        }
        return this.auth.hasStorePermissions(this.storeId, this.storeWithFulfillers.subscriptionId, StorePermission.ManageStoreFulfillers);
    }

    canAddStoreFulfiller() {
        if (!this.storeWithFulfillers) {
            return false;
        }

        return this.auth.hasGlobalPermissions(GlobalPermission.ListFulfillers) &&
            this.auth.hasStorePermissions(this.storeId, this.storeWithFulfillers.subscriptionId, StorePermission.ManageStoreFulfillers);
    }
}
