import { Component, OnInit, ViewChild } from '@angular/core';
import { GeneratedImageDefinition, GlobalPermission, SubscriptionPermission } from 'schema-dotnet';
import { MatTable } from '@angular/material/table';
import { AuthorizationContext, AuthorizationService } from "../../services";
import { AdminComponentBase } from "../../utils/admin-component-base";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneratedImagesService } from "../../services/generated-images.service";

enum Scope {
    global,
    subscription
}

@Component({
    selector: 'admin-generated-images-page',
    templateUrl: './generated-images-page.component.html',
    styleUrls: ['./generated-images-page.component.scss']
})
export class GeneratedImagesPageContainerComponent extends AdminComponentBase implements OnInit {

    @ViewChild('generatedImageDefinitionsTable', { static: false })
    generatedImageDefinitionsTable: MatTable<GeneratedImageDefinition>;
    generatedImageDefinitions: GeneratedImageDefinition[] = [];
    displayedGeneratedImageDefinitions: GeneratedImageDefinition[] = [];
    displayedColumns: string[] = [
        'displayName',
        'alias',
        'description',
        'actions'
    ];
    filter: {
        name: string;
    } = {
        name: ''
    };

    private authorizationContext: AuthorizationContext;
    private subscriptionId: string | undefined;
    private scope: Scope;

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly generatedImagesService: GeneratedImagesService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;

            this.route.params.subscribe(params => {
                if (params.subscriptionId) {
                    this.subscriptionId = params.subscriptionId;
                    this.scope = Scope.subscription;
                } else {
                    this.subscriptionId = undefined;
                    this.scope = Scope.global;
                }

                this.load();
            })
        }));
    }

    private load() {
        if (this.scope == Scope.subscription) {
            this.subscribeWithGenericLoadingErrorHandling(this.generatedImagesService.listForSubscription(this.subscriptionId!!), (generatedImageDefinitions) => {
                this.generatedImageDefinitions = generatedImageDefinitions;
                this.updateDisplayedGeneratedImageDefinitions();
            });
        } else {
            this.subscribeWithGenericLoadingErrorHandling(this.generatedImagesService.listForGlobal(), (generatedImageDefinitions) => {
                this.generatedImageDefinitions = generatedImageDefinitions;
                this.updateDisplayedGeneratedImageDefinitions();
            });
        }
    }

    updateFilter() {
        this.updateDisplayedGeneratedImageDefinitions();
    }

    private updateDisplayedGeneratedImageDefinitions() {
        this.displayedGeneratedImageDefinitions = this.generatedImageDefinitions
            .filter(s => this.isGeneratedImageDefinitionVisible(s))
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.generatedImageDefinitionsTable.renderRows();
    }

    private isGeneratedImageDefinitionVisible(f: GeneratedImageDefinition) {
        if (this.filter.name) {
            if (f.displayName.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return true;
            } else if (f.defaultAlias.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return true;
            }
            return false;
        }
        return true;
    }

    generateImageDefinitionId(i: number, generatedImageDefinition: GeneratedImageDefinition) {
        return generatedImageDefinition.id;
    }

    addGeneratedImage() {
        if (this.scope == Scope.subscription) {
            this.router.navigate(['/subscriptions', this.subscriptionId, 'generated-images', 'create']);
        } else {
            this.router.navigate(['/generated-images/create']);
        }

    }

    canManage(): boolean {
        if (this.scope == Scope.subscription) {
            return this.authorizationContext.hasGlobalPermissions(SubscriptionPermission.ManageGeneratedImages);
        } else {
            return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageGeneratedImages);
        }
    }

    $generatedImageDefinition(source: any): GeneratedImageDefinition {
        return source as GeneratedImageDefinition;
    }
}
