import { Component, Input, OnInit } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { GroupedLogs } from './store-logs-page.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GlobalPermission } from '../../../schema-dotnet';
import { AuthorizationContext, AuthorizationService } from '../../services';

@Component({
    templateUrl: './store-logs-group.component.html',
    styleUrls: ['./store-logs-group.component.scss'],
    selector: 'admin-store-logs-group'
})
export class StoreLogsGroupComponent extends AdminComponentBase implements OnInit {
    @Input() storeLogs: GroupedLogs[] = [];
    authContext?: AuthorizationContext;

    maxMessageLength = 100;
    maxMessageLengthByScreenSize = new Map([
        [Breakpoints.XSmall, 15],
        [Breakpoints.Small, 40],
        [Breakpoints.Medium, 90],
        [Breakpoints.Large, 110],
        [Breakpoints.XLarge, 130]
    ]);

    constructor(
        private readonly observer: BreakpointObserver,
        private readonly authService: AuthorizationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.observer.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe(result => {
            for (const query of Object.keys(result.breakpoints)) {
                if (result.breakpoints[query]) {
                    this.maxMessageLength = this.maxMessageLengthByScreenSize.get(query) ?? 100;
                }
            }
        }));

        this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
    }

    canReadApplicationInsightsLogs() {
        return this.authContext?.hasGlobalPermissions(GlobalPermission.ReadApplicationInsightsLogs);
    }
}
