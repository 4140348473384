import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CancelSubscriptionPendingProjectExpirationsInput,
    CreateStoreInput, CreateSubscriptionInput,
    DeactivateSubscriptionInput, MediaclipMutationCancelSubscriptionPendingProjectExpirationsArgs,
    MediaclipMutationCreateStoreArgs, MediaclipMutationCreateSubscriptionArgs,
    MediaclipMutationDeactivateSubscriptionAndScheduleDeletionOfUserDataArgs,
    MediaclipMutationUpdateSubscriptionCleanupDelayArgs,
    Subscription,
    UpdateSubscriptionCleanupDelayInput,
    UpdateSubscriptionInput
} from 'schema-dotnet';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from './graph-dotnet-service';

@Injectable()
export class SubscriptionsService {
    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    list(): Observable<Subscription[]> {
        return this.graphDotnetService.query(`
                query getSubscriptions {
                    subscriptions {
                        id
                        label
                        contact
                        active
                        deactivationDateUtc
                        storesCount
                        billing {
                            billingStartDateUtc
                            monthlyFee
                            currency
                            revShare {
                                percentage
                            }
                        }
                    }
                }
            `
        ).pipe(map(r => r.subscriptions));
    }

    load(subscriptionId: string): Observable<Subscription> {
        return this.graphDotnetService.query(`
                query getSubscription($subscriptionId: UUID!) {
                    subscription(id: $subscriptionId) {
                        id
                        label
                        contact
                        active
                        deactivationDateUtc
                        storesCount
                        notes
                        stores {
                            id
                            label
                            environment
                            status
                            projectsCount(sinceDays: 14)
                            ordersCount(sinceDays: 14)
                        }
                    }
                }
            `, { subscriptionId }
        ).pipe(map(e => e.subscription));
    }

    loadWithCleanup(subscriptionId: string): Observable<Subscription> {
        return this.graphDotnetService.query(`
                query getSubscription($subscriptionId: UUID!) {
                    subscription(id: $subscriptionId) {
                        id
                        label
                        cleanup {
                            projectExpirations {
                                giftingDelayInDays
                                printDelayInDays
                                photobookDelayInDays
                            }
                            pendingProjectExpirations {
                                giftingDelayInDays
                                printDelayInDays
                                photobookDelayInDays
                                changedConfigurationDateUtc
                            }
                        }
                    }
                }
            `, { subscriptionId },
        ).pipe(map(e => e.subscription));
    }

    loadForBilling(subscriptionId: string): Observable<Subscription> {
        return this.graphDotnetService.query(`
                query getSubscription($subscriptionId: UUID!) {
                    subscription(id: $subscriptionId) {
                        id
                        label
                        contact
                        active
                        storesCount
                        notes
                        billing {
                            billingStartDateUtc
                            multiplyByQuantity
                            revShare {
                                calculatedOn
                                percentage
                                minimum
                                maximum
                            }
                            monthlyFee
                            currency
                        }
                        invoices {
                            year
                            month
                            currency
                            generated
                            monthlyFee
                            revenue
                            invoiced
                            invoicedAdjusted
                            linesCount
                            url
                        }
                    }
                }
            `, { subscriptionId }
        ).pipe(map(e => e.subscription));
    }

    getAuthorizedUsers(subscriptionId: string): Observable<Subscription> {
        return this.graphDotnetService.query(`
                query getSubscription($subscriptionId: UUID!) {
                    subscription(id: $subscriptionId) {
                        id
                        label
                        authorizedUsers {
                            user {
                                email
                                picture
                            }
                            roles
                        }
                    }
                }
            `, { subscriptionId }
        ).pipe(map(e => e.subscription));
    }

    edit(input: UpdateSubscriptionInput): Observable<any> {
        return this.graphDotnetService.mutate<any>(`
                mutation updateSubscription($input: UpdateSubscriptionInput!) {
                    updateSubscription(input: $input)
                }
            `, { input }
        );
    }

    updateSubscriptionCleanupDelay(input: UpdateSubscriptionCleanupDelayInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateSubscriptionCleanupDelayArgs>(
            `
                mutation updateSubscriptionCleanupDelay($input: UpdateSubscriptionCleanupDelayInput!) {
                    updateSubscriptionCleanupDelay(input: $input)
                }
            `,
            { input }
        );
    }

    cancelSubscriptionPendingProjectExpirations(input: CancelSubscriptionPendingProjectExpirationsInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationCancelSubscriptionPendingProjectExpirationsArgs>(
            `
                mutation cancelSubscriptionPendingProjectExpirations($input: CancelSubscriptionPendingProjectExpirationsInput!) {
                    cancelSubscriptionPendingProjectExpirations(input: $input)
                }
            `,
            { input }
        );
    }

    deactivateSubscriptionAndScheduleDeletionOfUserData(input: DeactivateSubscriptionInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationDeactivateSubscriptionAndScheduleDeletionOfUserDataArgs>(
            `
                mutation deactivateSubscriptionAndScheduleDeletionOfUserData($input: DeactivateSubscriptionInput!) {
                    deactivateSubscriptionAndScheduleDeletionOfUserData(input: $input)
                }
            `,
            { input }
        );
    }

    createStore(input: CreateStoreInput) {
        return this.graphDotnetService.mutate<MediaclipMutationCreateStoreArgs>(
            `
                mutation createStore($input: CreateStoreInput!) {
                    createStore(input: $input) {
                        id
                        label
                        environment
                        status
                        projectsCount(sinceDays: 14)
                        ordersCount(sinceDays: 14)
                    }
                }
            `,
            { input: input }
        ).pipe(
            map(m => m.createStore!)
        );
    }

    createSubscription(input: CreateSubscriptionInput): Observable<Subscription>{
        return this.graphDotnetService.mutate<MediaclipMutationCreateSubscriptionArgs>(
            `mutation createSubscription($input:CreateSubscriptionInput!) {
                createSubscription(input: $input) {
                    id
                    label
                    contact
                    active
                    billing {
                        currency
                    }
                    deactivationDateUtc
                    storesCount
                }
            }`,
            {input: input}
        ).pipe(
            map(m => m.createSubscription!)
        );
    }
}
