import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConsolidateUserDialogData {
    anonymousStoreUSerId: string;
}

export interface ConsolidateUserDialogResult {
    targetStoreUserId: string;
}

@Component({
    templateUrl: './consolidate-user-dialog.component.html',
    styleUrls: ['./consolidate-user-dialog.component.scss']
})
export class ConsolidateUserDialogComponent {
    public targetStoreUserId: string;

    constructor(
        public dialogRef: MatDialogRef<ConsolidateUserDialogComponent, ConsolidateUserDialogResult>,
        @Inject(MAT_DIALOG_DATA) public data: ConsolidateUserDialogData
    ) {

    }

    cancel() {
        this.dialogRef.close();
    }

    valid() {
        this.dialogRef.close({targetStoreUserId: this.targetStoreUserId});
    }
}
