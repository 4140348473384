import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HubStoreLog, ShopifyStoreLog, StoreLogUnion} from 'schema-dotnet';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class StoreLogsService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getHubStoreLogs(storeId: string): Observable<StoreLogUnion[]> {
        return this.graphDotnetService.query(`
                query getHubStoreLogs($storeId: String!) {
                    store(id: $storeId) {
                        logs(source: Hub) {
                            __typename
                            ... on HubStoreLog {
                                timestampUtc
                                message
                                type
                                level
                                operation
                                appName
                                occurrences
                                runs
                                users
                                firstOccurrenceUtc
                                lastOccurrenceUtc
                                openOperationQueryUrl
                                openItemQueryUrl
                            }
                        }
                    }
                }
            `, { storeId }
        ).pipe(map(q => q.store.logs));
    }

    getShopifyStoreLogs(storeId: string): Observable<StoreLogUnion[]> {
        return this.graphDotnetService.query(`
            query getHubStoreLogs($storeId: String!) {
                store(id: $storeId) {
                    logs(source: Shopify) {
                        __typename
                        ... on ShopifyStoreLog {
                            timestampUtc
                            message
                            errorCode
                            level
                            occurrences
                            openOperationQueryUrl
                            runs
                            users
                            firstOccurrenceUtc
                            lastOccurrenceUtc
                            openItemQueryUrl
                        }
                    }
                }
            }
        `, { storeId }
        ).pipe(map(q => q.store.logs));
    }
}
