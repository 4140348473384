<admin-page-simple-content id="libraries"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Libraries'"
  [icon]="'library_books'">
  <div class="content">
    <div class='actions'>

      <mat-form-field class="dense-2" floatLabel="always">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Filter</mat-label>
        <input matInput
          (keydown)="updateDisplayedLibraries()"
          (keyup)="updateDisplayedLibraries()"
          [(ngModel)]="filter">
      </mat-form-field>

      <button mat-stroked-button (click)='deployNewLibrary()'>
        <mat-icon>add</mat-icon>
        Deploy new library
      </button>
    </div>

    @if (pageStatus === 'saveError' && pageError) {
      <admin-error [error]="pageError"></admin-error>
    }

    <div class="mat-elevation-z4">
      <div class='library header'>
        <div class='owner'>Owner</div>
        <div class='branches'>
          <div class='branch'>
            <div class='name'>Branch</div>
            <div class='version'>Version</div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      @for (library of displayedLibraries; track library) {
        <div class='library' mat-ripple (click)='displayLibrary(library.owner)'>
          <div class='owner'>
            {{library.owner}}
          </div>
          <div class='branches'>
            @for (branch of library.branches; track branch) {
              <div class='branch'>
                <div class='name'>{{branch.name}}</div>
                <div class='version'>{{branch.metadata.version}}</div>
              </div>
            }
          </div>
        </div>
        <mat-divider></mat-divider>
      }
    </div>

  </div>
</admin-page-simple-content>

