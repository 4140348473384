import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FulfillersService } from '../../services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

export interface AddStoreFulfillerDialogResult {
    fulfillerId: string;
}

export interface AddStoreFulfillerDialogData {
    usedFulfillerId: string[];
}

@Component({
    selector: 'admin-add-store-fulfiller-dialog',
    templateUrl: './add-store-fulfiller-dialog.component.html',
    styleUrls: ['./add-store-fulfiller-dialog.component.scss']
})
export class AddStoreFulfillerDialogComponent implements OnInit {
    fulfillerId?: string;
    fulfillerIds: string[] = [];
    @ViewChild('form')
    form: NgForm;

    filteredFulfillerIds: BehaviorSubject<string[]> = new BehaviorSubject([]);

    constructor(
        private readonly dialogRef: MatDialogRef<AddStoreFulfillerDialogComponent, AddStoreFulfillerDialogResult>,
        private readonly fulfillersService: FulfillersService,
        @Inject(MAT_DIALOG_DATA) public readonly data: AddStoreFulfillerDialogData
    ) {
    }

    ngOnInit(): void {
        this.fulfillersService.list().subscribe(s => {
            this.fulfillerIds = s.map(s => s.id).filter(fulfillerId => !this.data.usedFulfillerId.includes(fulfillerId))
                .sort((a, b) => a.localeCompare(b));
            this.form.controls['fulfillerId'].valueChanges.subscribe(filter => {
                this.filteredFulfillerIds.next(this.fulfillerIds.filter(x => x.toLowerCase().includes(filter.toLowerCase())));
            });
        });
    }

    valid() {
        if (!this.fulfillerId) {
            return;
        }

        this.dialogRef.close({
            fulfillerId: this.fulfillerId
        });
    }

    isValueValid(): boolean {
        if (!this.fulfillerId) {
            return false;
        }
        return this.fulfillerIds.includes(this.fulfillerId);
    }
}
