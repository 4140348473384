import {Anomalies, RetryAnomalies} from 'schema-dotnet';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class AnomaliesService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    load(): Observable<Anomalies> {
        return this.graphDotnetService.query(
            `
                query getAnomalies {
                    anomalies {
                        lines {
                            __typename
                            id
                            fulfillerHubLineNumber
                            storeOrderLineNumber
                            dateCreatedUtc
                            order {
                                id
                                storeOrderId
                            }
                            status {
                                value
                                flags
                            }
                            store {
                                id
                            }
                        }
                    }
                }
            `)
            .pipe((map(q => q.anomalies)));
    }

    retryAll(): Observable<RetryAnomalies> {
        return this.graphDotnetService.mutate(`
                mutation retryAllAnomalies {
                    retryOrdersAnomalies() {
                        totalAnomalies
                        processedAnomalies
                    }
                }
            `)
            .pipe((map(q => q.retryOrdersAnomalies)));
    }
}
