<h2 mat-dialog-title>Connect to stock photo source</h2>
<mat-dialog-content>
  <form #form="ngForm">
    <mat-form-field>
      <mat-label>Stock Photo Source</mat-label>
      <mat-select [(ngModel)]="result.photoSourceId" name="client" required>
        @for (stockPhotoSource of stockPhotoSources; track stockPhotoSource) {
          <mat-option [value]="stockPhotoSource.id">
            @if (stockPhotoSource.label) {
              {{stockPhotoSource.label}} ({{stockPhotoSource.id}})
            } @else {
              {{stockPhotoSource.id}}
            }
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    CANCEL
  </button>

  <button mat-button color="primary" (click)="valid()" [disabled]="!form.valid">
    ADD
  </button>
</mat-dialog-actions>
