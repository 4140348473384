import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription as HubSubscription, SubscriptionPermission } from 'schema-dotnet';
import {MatDialog} from '@angular/material/dialog';
import {
    UpdateSubscriptionInformationDialogComponent,
    UpdateSubscriptionInformationDialogData,
    UpdateSubscriptionInformationDialogResult
} from 'app/components/update-subscription-info-dialog/update-subscription-info-dialog';
import {fuseAnimations} from '@fuse/animations';
import {ConfirmDialogComponent, ConfirmDialogData} from '../confirm-dialog/confirm-dialog.component';
import {AuthorizationContext, AuthorizationService, SubscriptionsService} from '../../services';
import {PageStatus} from '../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminComponentBase} from '../../utils/admin-component-base';

@Component({
    selector: 'admin-subscription-page',
    templateUrl: './subscription-page.component.html',
    styleUrls: ['./subscription-page.component.scss'],
    animations: fuseAnimations
})
export class SubscriptionPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    subscription?: HubSubscription;
    auth: AuthorizationContext;

    subscriptionId: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly authService: AuthorizationService,
        private readonly subscriptionsService: SubscriptionsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.authService.authorizationContext().subscribe(authContext => {
            this.auth = authContext;
        });

        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.subscriptionId = params.subscriptionId;
                this.subscribeWithGenericLoadingErrorHandling(this.subscriptionsService.load(this.subscriptionId), subscription => {
                    this.subscription = subscription;
                });
            }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    editInfo(subscription: HubSubscription) {
        let dialogRef = this.dialog.open<UpdateSubscriptionInformationDialogComponent, UpdateSubscriptionInformationDialogData, UpdateSubscriptionInformationDialogResult>(
            UpdateSubscriptionInformationDialogComponent,
            {
                data: <UpdateSubscriptionInformationDialogData>{
                    label: subscription.label,
                    contact: subscription.contact,
                    notes: subscription.notes
                }
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.subscriptionsService.edit({ id: subscription.id, ...result }), () => {
                this.reloadSubscription();
            });
        });
    }

    openDeactivationDialog(subscription: HubSubscription) {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
                title: 'Deactivate subscription',
                message: `Do you really want to deactivate this subscription ?`,
                subMessage: `All users data of all the stores will be deleted after 90 days.`,
                options: {
                    yes: {
                        label: 'Deactivate',
                        warn: true
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.pageStatus = PageStatus.saving;
            this.subscriptionsService.deactivateSubscriptionAndScheduleDeletionOfUserData({ id: subscription.id })
                .subscribe({
                    next: () => {
                        this.reloadSubscription();
                    },
                    error: err => {
                        this.pageError = err;
                        this.pageStatus = PageStatus.saveError;
                    }
                });

        });
    }

    private reloadSubscription() {
        this.pageStatus = PageStatus.loading;
        this.subscriptionsService.load(this.subscriptionId).subscribe({
            next: subscription => {
                this.subscription = subscription;
                this.pageStatus = PageStatus.loaded;
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.saveError;
            }
        });
    }

    canManageSubscription(): boolean {
        return this.auth.hasSubscriptionPermissions(this.subscriptionId, SubscriptionPermission.ManageSubscriptionMetadata);
    }

    canDeactivateSubscription(): boolean {
        return this.auth.hasSubscriptionPermissions(this.subscriptionId, SubscriptionPermission.DeactivateSubscription);
    }
}
