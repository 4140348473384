import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface CreateFulfillerAppDialogResult {
    displayName: string;
}

@Component({
    templateUrl: './create-fulfiller-app-dialog.component.html',
    styleUrls: ['./create-fulfiller-app-dialog.component.scss']
})
export class CreateFulfillerAppDialogComponent {

    fulfillerInfo: CreateFulfillerAppDialogResult = {
        displayName: ''
    };

    constructor(
        public dialogRef: MatDialogRef<CreateFulfillerAppDialogResult>
    ) {
    }

    valid() {
        this.dialogRef.close(this.fulfillerInfo);
    }
}
