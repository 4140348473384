<admin-page-simple-content [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Create Order'"
  [icon]="'add_shopping_cart'">
  @if (storeWithFulfiller) {
    <div class="content">
      <p>
        To create an order, please refer to the <a class="external" target="_blank" href="https://doc.mediaclip.ca/hub/api/orders/create/">documentation<mat-icon>open_in_new</mat-icon>
      </a>
    </p>
    <p>Checkout json:</p>
    <ngx-codemirror [(ngModel)]="checkoutJson"
      (ngModelChange)="updatePreview()"
      [options]="{ lineNumbers: true, matchBrackets: true, lint: true, theme: 'material', mode: 'application/javascript'}"
    ></ngx-codemirror>
    @if (this.createOrderRequest) {
      <div class="summary">
        @for (item of createOrderRequest.itemOut; track item) {
          <div class="project">
            @if (item.itemId?.supplierPartAuxiliaryId) {
              Project: <span><code adminCopyToClipboard>{{item.itemId.supplierPartAuxiliaryId}}</code></span> x {{item.quantity}}
              <a mat-icon-button
                [routerLink]="['/projects',  item.itemId.supplierPartAuxiliaryId]"
                target="_blank">
                <mat-icon>open_in_new</mat-icon>
              </a>
              @if (item.supplierId?.domain === 'fulfillerId') {
                <span>Fulfiller: {{item.supplierId?.value}}</span>
              }
            }
          </div>
        }
      </div>
    }
    <button mat-stroked-button [disabled]="!createOrderRequest || pageStatus === 'saving'" (click)="createOrder()">Create Order</button>
    <div>
      @if (pageStatus === 'saveError' && pageError) {
        <admin-error [error]="pageError"></admin-error>
      }
    </div>
    @if (createdOrder) {
      <mat-card class="created-order">
        <mat-card-header>
          <mat-card-title>
            Order created
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Order Id: <a [routerLink]="['/orders', createdOrder.id]">{{createdOrder.id}}</a>
          </p>
          <p>
            Store Order Id: {{createdOrder.storeOrderId}}
          </p>
        </mat-card-content>
      </mat-card>
    }
  </div>
}
</admin-page-simple-content>
