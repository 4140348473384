import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {GraphDotnetService} from './graph-dotnet-service';
import {
    DesignerBranch,
    DesignerModuleName,
    DesignerSelectorRule,
    MediaclipMutationAddStoreDesignerBranchRuleArgs,
    MediaclipMutationDeleteStoreDesignerBranchRuleArgs,
    MediaclipMutationUpdateStoreDefaultDesignerBranchArgs,
    MediaclipMutationUpdateStoreDesignerBranchRuleArgs
} from '../../schema-dotnet';

@Injectable()
export class DesignerReleaseChannelService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    public getDesignerReleaseChannels(): Observable<DesignerBranch[]> {
        return this.graphDotnetService.query(`
                query designerReleaseChannels {
                    designerReleaseChannels {
                        id
                        version
                    }
                }
            `,
            {}
        ).pipe(map((q) => q.designerReleaseChannels));
    }

    public getDesignerReleaseChannelConfiguration(storeId: string): Observable<{ designerBranch: DesignerBranch, rules?: Array<DesignerSelectorRule> }> {
        return this.graphDotnetService.query(`
                query getStoreDesignerBranch($storeId: String!) {
                    store(id: $storeId) {
                        designerReleaseChannel {
                           id
                           version
                        }
                        designerBranchRules {
                            id
                            label
                            weight
                            designerBranch
                            module
                        }
                    }
                }
            `,
            {
                storeId: storeId
            }
        ).pipe(map((q) => ({
            designerBranch: q.store?.designerReleaseChannel!,
            rules: q.store?.designerBranchRules
        })));
    }

    public updateStoreDefaultDesignerReleaseChannel(storeId: string, designerBranch: string): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreDefaultDesignerBranchArgs>(
            `
                mutation updateStoreDefaultDesignerBranch($input: UpdateStoreDefaultDesignerBranchInput!) {
                    updateStoreDefaultDesignerBranch(input: $input)
                }
            `,
            {
                input: {
                    storeId: storeId,
                    designerBranch: designerBranch
                }
            }
        ).pipe(
            map(m => m.updateStoreDefaultDesignerBranch!)
        );
    }

    public addStoreDesignerReleaseChannelRule(
        storeId: string,
        designerBranch: string,
        weight: number,
        label: string,
        module?: DesignerModuleName | undefined | null
    ): Observable<DesignerSelectorRule> {
        return this.graphDotnetService.mutate<MediaclipMutationAddStoreDesignerBranchRuleArgs>(
            `
                mutation addStoreDesignerBranchRule($input: AddDesignerBranchRuleInput!) {
                    addStoreDesignerBranchRule(input: $input) {
                        id
                    }
                }
            `,
            {
                input: {
                    storeId: storeId,
                    designerBranch: designerBranch,
                    weight: weight,
                    label: label,
                    module: module
                }
            }
        ).pipe(
            map(m => m.addStoreDesignerBranchRule!)
        );
    }

    public deleteStoreDesignerReleaseChannelRule(storeId: string, ruleId: string): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteStoreDesignerBranchRuleArgs>(
            `
                mutation deleteStoreDesignerBranchRule($input: DeleteDesignerBranchRuleInput!) {
                    deleteStoreDesignerBranchRule(input: $input)
                }
            `,
            {
                input: {
                    storeId: storeId,
                    ruleId: ruleId
                }
            }
        ).pipe(
            map(m => m.updateStoreDesignerBranchRule!)
        );
    }

    public updateStoreDesignerReleaseChannelRule(
        storeId: string,
        ruleId: string,
        designerBranch: string,
        weight: number,
        label: string,
        module?: DesignerModuleName | undefined | null
    ): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreDesignerBranchRuleArgs>(
            `
                mutation updateStoreDesignerBranchRule($input: UpdateDesignerBranchRuleInput!) {
                    updateStoreDesignerBranchRule(input: $input)
                }
            `,
            {
                input: {
                    storeId: storeId,
                    ruleId: ruleId,
                    designerBranch: designerBranch,
                    weight: weight,
                    label: label,
                    module: module
                }
            }
        ).pipe(
            map(m => m.updateStoreDesignerBranchRule!)
        );
    }
}
