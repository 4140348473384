import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService, StockPhotoSourcesService } from "../../services";
import { StockPhotoSourceConfig } from "../../../schema-dotnet";
import { AdminComponentBase } from "../../utils/admin-component-base";
import { Router } from "@angular/router";

export interface RelatedStoresStockPhotoSourceDialogData {
    stockPhotoSourceId: string;
}

@Component({
    templateUrl: './related-store-stock-photo-source-dialog.component.html',
    styleUrls: ['./related-store-stock-photo-source-dialog.component.scss']
})
export class RelatedStoresStockPhotoSourceDialogComponent extends AdminComponentBase implements OnInit {

    stockPhotoSource: StockPhotoSourceConfig;

    constructor(
        public dialogRef: MatDialogRef<RelatedStoresStockPhotoSourceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: RelatedStoresStockPhotoSourceDialogData,
        private readonly authorizationService: AuthorizationService,
        private readonly stockPhotoSourcesService: StockPhotoSourcesService,
        private readonly router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeWithGenericLoadingErrorHandling(this.stockPhotoSourcesService.loadStores(this.data.stockPhotoSourceId), (stockPhotoSourceConfig) => {
            this.stockPhotoSource = stockPhotoSourceConfig;
        });
    }

    openStoreSettings(storeId: string) {
        this.router.navigate(['stores', storeId, 'settings', 'external-photo-sources']);
        this.dialogRef.close()
    }
}
