import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {FuseConfig} from '../../../@fuse/types';
import { Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'layout', // eslint-disable-line
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit, OnDestroy {
    fuseConfig: FuseConfig;
    navigation: any;
    quickPanelOpened$: Subject<boolean>;
    lockedOpen = [Breakpoints.Large, Breakpoints.XLarge]

    private _unsubscribeAll: Subject<any>;

    constructor(private _fuseConfigService: FuseConfigService) {
        // Set the defaults

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.quickPanelOpened$ = new Subject<boolean>();
    }

    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
            this.fuseConfig = config;
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    quickPanelOpenedChanged(isOpened: boolean): void {
        this.quickPanelOpened$.next(isOpened);
    }
}
