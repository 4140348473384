import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalPhotosConfigService } from '../../services';
import { DirectPhotoSource, Endpoint, GenericExternalPhotoSourceConfig } from '../../../schema-dotnet';
import { PageStatus } from '../../models';

@Component({
    selector: 'admin-direct-photo-source-edit-page',
    templateUrl: './direct-photo-source-edit-page.component.html',
    styleUrls: ['./direct-photo-source-edit-page.component.scss']
})
export class DirectPhotoSourceEditPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    public storeId: string;
    public photoSourceId: string;
    public directPhotoSource?: DirectPhotoSource;
    public mode: 'create' | 'edit';
    private redirectTimerId: number;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly externalPhotosConfigService: ExternalPhotosConfigService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.photoSourceId = params.photoSourceId;
            this.mode = this.route.snapshot.data.mode;

            if (this.mode == 'edit') {
                this.subscribeWithGenericLoadingErrorHandling(this.externalPhotosConfigService.loadDirectPhotoSource(this.storeId, this.photoSourceId), (source) => {
                    this.directPhotoSource = source;
                });
            } else {
                this.directPhotoSource = {
                    name: this.photoSourceId,
                    photoEndpoint: {
                        url: '',
                        headers: []
                    }
                };
                this.setPageStatus(PageStatus.loaded);
            }

        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
        if (this.redirectTimerId)
            window.clearTimeout(this.redirectTimerId)
    }

    save() {
        if (!this.directPhotoSource) {
            return;
        }
        if (this.mode === 'edit') {
            let input = {
                storeId: this.storeId,
                ...this.directPhotoSource
            };
            this.subscribeWithGenericSavinErrorHandling(this.externalPhotosConfigService.updateDirectPhotoSource(input), this.completeSave.bind(this));
        } else {
            let input = {
                storeId: this.storeId,
                ...this.directPhotoSource
            };
            this.subscribeWithGenericSavinErrorHandling(this.externalPhotosConfigService.createDirectPhotoSource(input), this.completeSave.bind(this));
        }
    }
    private completeSave() {
        this.pageStatus = PageStatus.loaded;
        this.redirectTimerId = window.setTimeout(() => {
            this.router.navigate(['stores', this.storeId, 'settings', 'external-photo-sources']);
        }, 500);
    }

    addHeader(endpoint: Endpoint) {
        endpoint.headers.push({ name: '', value: '' });
    }

    deleteHeader(endpoint: Endpoint, index: number) {
        endpoint.headers.splice(index, 1);
    }
}
