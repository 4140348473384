import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface EditInvoiceDialogData {
    value: number,
    currency: string,
}

export interface EditInvoiceDialogResult {
    value: string,
}

@Component({
    templateUrl: './edit-invoice-dialog.component.html',
    styleUrls: ['./edit-invoice-dialog.component.scss'],
})
export class EditInvoiceDialogComponent {
    invoiceAmount: number;
    constructor(
        public dialogRef: MatDialogRef<EditInvoiceDialogData, EditInvoiceDialogResult>,
        @Inject(MAT_DIALOG_DATA) public data: EditInvoiceDialogData) {
        this.invoiceAmount = data.value;
    }

    valid(){
        this.dialogRef.close({
            value: this.invoiceAmount.toString(),
        });
    }
}
