import { AdminDirectivesModule } from 'app/directives/directives.module';
import { AdminPipesModule } from 'app/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ChangeStatusDialogComponent } from './change-status-dialog/change-status-dialog';
import { CurrencyComponent } from './currency/currency.component';
import { ErrorComponent } from './error/error.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog';
import { LineStatusComponent } from './line-status/line-status.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { SelectableStoreElementComponent } from './selectable-store-element/selectable-store-element.component';
import { SelectableSubscriptionElementComponent } from  './selectable-subscription-element/selectable-subscription-element.component'
import { ShortStoreEnvPipe } from './short-store-env.pipe';
import { StatusColorComponentDirective } from './status-color/status-color.component';
import { UpdateSubscriptionBillingDialogComponent } from './update-subscription-billing-dialog/update-subscription-billing-dialog';
import { UpdateSubscriptionInformationDialogComponent } from './update-subscription-info-dialog/update-subscription-info-dialog';
import { UpdateSubscriptionProjectExpirationsDialogComponent } from './update-subscription-project-expirations-dialog/update-subscription-project-expirations-dialog';
import { WidgetUsageTimelineComponent } from './widget-usage-timeline/widget-usage-timeline.component';
import { DisplayCodeDialogComponent } from './display-code-dialog/display-code-dialog.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RemoteFilterLabelPipe } from "./remote-filter-label.pipe";
import { SecretInputToggleComponent } from './secret-input-toggle.component';
import { BaseChartDirective } from 'ng2-charts';

@NgModule({
    declarations: [
        WidgetUsageTimelineComponent,
        StatusColorComponentDirective,
        ChangeStatusDialogComponent,
        ErrorComponent,
        ErrorDialogComponent,
        SaveButtonComponent,
        UpdateSubscriptionBillingDialogComponent,
        UpdateSubscriptionInformationDialogComponent,
        UpdateSubscriptionProjectExpirationsDialogComponent,
        LineStatusComponent,
        CurrencyComponent,
        ShortStoreEnvPipe,
        RemoteFilterLabelPipe,
        SelectableStoreElementComponent,
        SelectableSubscriptionElementComponent,
        DisplayCodeDialogComponent,
        CopyToClipboardComponent,
        SecretInputToggleComponent,
    ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    FuseSharedModule,

    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatTabsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatListModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatRippleModule,

    CodemirrorModule,
    BaseChartDirective,

    AdminDirectivesModule,
    AdminPipesModule,
    MatExpansionModule,
    MatDatepickerModule
  ],
    exports: [
        WidgetUsageTimelineComponent,
        StatusColorComponentDirective,
        ChangeStatusDialogComponent,
        CopyToClipboardComponent,
        ErrorComponent,
        ErrorDialogComponent,
        SaveButtonComponent,
        UpdateSubscriptionBillingDialogComponent,
        UpdateSubscriptionInformationDialogComponent,
        LineStatusComponent,
        CurrencyComponent,
        ShortStoreEnvPipe,
        RemoteFilterLabelPipe,
        SelectableStoreElementComponent,
        SelectableSubscriptionElementComponent,
        SecretInputToggleComponent
    ]
})
export class AdminComponentsModule {
}
