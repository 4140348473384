import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SearchResultUnion} from '../../schema-dotnet';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class SearchService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    search(query: string, storeId: string | null): Observable<SearchResultUnion[]> {
        return this.graphDotnetService.query(`
                query search($query: String!, $storeId: String) {
                    search(query: $query, storeId: $storeId) {
                        __typename
                        ... on OrderSearchResult {
                            hubOrderId
                            storeId
                            storeOrderId
                        }
                        ... on OrderLineSearchResult {
                            hubLineId
                            hubOrderId
                            storeId
                            storeOrderId
                            storeOrderLineNumber
                            fulfillerHubLineNumber
                        }
                        ... on UserSearchResult {
                            hubUserId
                            storeId
                            storeUserId
                        }
                        ... on ProjectSearchResult {
                            storeId
                            projectId
                        }
                        ... on SharedProjectSearchResult {
                            storeId
                            sharedProjectId
                        }
                        ... on SubscriptionSearchResult {
                            subscriptionId
                            subscriptionLabel
                        }
                        ... on StoreSearchResult {
                            storeId
                            storeLabel
                            subscriptionId
                            subscriptionLabel
                        }
                    }
                }
            `,
            { query, storeId }
        ).pipe(map(q => q.search));
    }
}
