<button
  class="admin-button"
  type="submit"
  mat-stroked-button
  [color]="state.color"
  (click)="onClick()"
  [ngClass]="{ disabled: state.disabled || disabled }"
  [disabled]="state.disabled || disabled"
  >
  <span class="button-content">
    @if (state.mayUseContent && useContent) {
      <ng-content></ng-content>
    } @else {
      {{ state.text }}
    }
    <ng-template #text>
      {{ state.text }}
    </ng-template>
    @if (state.showSpinner || state.showCheck) {
      <span class="padding"></span>
    }
    @if (state.showSpinner) {
      <mat-spinner [diameter]="24"></mat-spinner>
    }
    @if (state.showCheck) {
      <mat-icon>check</mat-icon>
    }
  </span>
</button>
