import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {AuthorizationContext, AuthorizationService} from '../../services';
import {ActivatedRoute, Router} from '@angular/router';
import {
    GeneratedImageDefinition,
    GeneratedImageKind,
    GlobalPermission, Property,
    SubscriptionPermission
} from '../../../schema-dotnet';
import {GeneratedImagesService} from '../../services/generated-images.service';
import {PageStatus} from '../../models';
import {GeneratedImageConfigUtil} from './generated-image-config-util.service';

enum GenerateImageViewMode {
    create,
    edit
}

enum Scope {
    global = 'global',
    subscription = 'subscription'
}

@Component({
    templateUrl: './generated-image-page.component.html',
    styleUrls: ['./generated-image-page.component.scss']
})
export class GeneratedImagePageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    public readonly GeneratedImageKind = GeneratedImageKind;
    private authorizationContext: AuthorizationContext;
    private generatedImageDefinitionId: any;
    private mode: GenerateImageViewMode = GenerateImageViewMode.create;

    subscriptionId?: string;
    scope: Scope;
    urlPattern = /^https:\/\/.+$/;
    invalidUrlMessage = 'This url is not valid.';
    generatedImageDefinition: GeneratedImageDefinition;
    generatedImageKinds: Record<GeneratedImageKind, string> = {
        [GeneratedImageKind.Generic]: 'Generic',
        [GeneratedImageKind.MapBox]: 'Mapbox',
        [GeneratedImageKind.QrCode]: 'QR Code',
    };
    mapBoxAccessKey?: string;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly authorizationService: AuthorizationService,
        private readonly generatedImagesService: GeneratedImagesService,
        private readonly generatedImageConfigUtil: GeneratedImageConfigUtil,
    ) {
        super();
    }

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authorizationContext = authContext;
        });

        this.registerSubscription(
            this.route.params.subscribe(params => {
                if (params.subscriptionId) {
                    this.scope = Scope.subscription;
                    this.subscriptionId = params.subscriptionId;
                } else {
                    this.scope = Scope.global;
                    this.subscriptionId = undefined;
                }

                if (params.generatedImageId) {
                    this.generatedImageDefinitionId = params.generatedImageId;
                    this.mode = GenerateImageViewMode.edit;
                    this.reload();
                } else {
                    this.mode = GenerateImageViewMode.create;
                    this.initNew();
                    this.setPageStatus(PageStatus.loaded);
                }
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    canManage(): boolean {
        if (this.subscriptionId) {
            return this.authorizationContext.hasSubscriptionPermissions(this.subscriptionId, SubscriptionPermission.ManageGeneratedImages);
        }
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageGeneratedImages);
    }

    createOrUpdate() {
        if (this.generatedImageDefinition.kind === GeneratedImageKind.MapBox) {
            this.generatedImageConfigUtil.updateMapBoxApiKey(this.generatedImageDefinition.configs, this.mapBoxAccessKey)
        }
        if (this.mode == GenerateImageViewMode.edit) {
            this.update();
        } else if (this.mode == GenerateImageViewMode.create) {
            this.create();
        }
    }

    update() {
        this.subscribeWithGenericSavinErrorHandling(
            this.generatedImagesService.updateGeneratedImageDefinition({
                id: this.generatedImageDefinition.id,
                kind: this.generatedImageDefinition.kind,
                displayName: this.generatedImageDefinition.displayName,
                description: this.generatedImageDefinition.description,
                defaultAlias: this.generatedImageDefinition.defaultAlias,
                endpoint: this.generatedImageDefinition.endpoint,
                preserveAspectRatio: this.generatedImageDefinition.preserveAspectRatio,
                configs: this.generatedImageDefinition.configs,
                warning: this.generatedImageDefinition.warning,
                maxHeight: this.generatedImageDefinition.maxHeight,
                maxWidth: this.generatedImageDefinition.maxWidth
            }),
            () => {
                this.reload();
            }
        );
    }

    create() {
        this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.createGeneratedImageDefinition(
                {
                    kind: this.generatedImageDefinition.kind,
                    displayName: this.generatedImageDefinition.displayName,
                    description: this.generatedImageDefinition.description,
                    defaultAlias: this.generatedImageDefinition.defaultAlias,
                    endpoint: this.generatedImageDefinition.endpoint,
                    preserveAspectRatio: this.generatedImageDefinition.preserveAspectRatio,
                    configs: this.generatedImageDefinition.configs,
                    subscriptionId: this.subscriptionId,
                    warning: this.generatedImageDefinition.warning,
                    maxHeight: this.generatedImageDefinition.maxHeight,
                    maxWidth: this.generatedImageDefinition.maxWidth,
                }
            ),
            () => {
                this.router.navigate(['..'], {relativeTo: this.route});
            }
        );
    }

    reload() {
        this.subscribeWithGenericLoadingErrorHandling(this.generatedImagesService.loadDefinition(this.generatedImageDefinitionId), generatedImageDefinition => {
            this.generatedImageDefinition = generatedImageDefinition;
            this.mapBoxAccessKey = this.generatedImageConfigUtil.getMapBoxAccessToken(generatedImageDefinition.configs);
        });
    }

    initNew() {
        this.generatedImageDefinition = {
            kind: GeneratedImageKind.Generic,
            id: '',
            version: '',
            displayName: '',
            defaultAlias: '',
            endpoint: '',
            preserveAspectRatio: false,
            fileEditorInformation: [],
            subscriptionId: this.subscriptionId,
            configs: {
                headers: [],
                additionalProperties: [],
                editorParameters: []
            }
        };
    }
}
