import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {AdminComponentBase} from '../../utils/admin-component-base';
import {ActivatedRoute} from '@angular/router';
import {AuthorizationContext, AuthorizationService, AuthorizedUsersService} from '../../services';
import { AvailableRoles, DownloadPath, GlobalPermission, GlobalRole, Store, StoreRole, StoreUserRole, UserRoles } from '../../../schema-dotnet';
import {AddAuthorizedUserDialogComponent, AddAuthorizedUserDialogData, AddAuthorizedUserDialogResult} from '../authorized-users-page/add-authorized-user-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog-service';
import { NgForm } from '@angular/forms';

@Component({
    templateUrl: './admin-user-page.component.html',
    styleUrls: ['./admin-user-page.component.scss']
})
export class AdminUserPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    userEmail: string;
    user?: UserRoles;
    authorizationContext: AuthorizationContext;
    availableRoles: AvailableRoles;
    availableDownloads: DownloadPath[];
    downloadValue: string;
    @ViewChild('form')
    form: NgForm;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog,
        private readonly authorizedUsersService: AuthorizedUsersService,
        private readonly authorizationService: AuthorizationService,
        private readonly confirmDialogService: ConfirmDialogService,
    ) {
        super();
    }

    ngOnInit() {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.registerSubscription(this.route.params.subscribe(params => {
            this.userEmail = params.userEmail;

            this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                this.authorizedUsersService.getUser(this.userEmail),
                this.authorizedUsersService.getAvailableRoles(),
                this.authorizedUsersService.getAvailableDownloadPaths(),
            ], (user, availableRoles, availableDownloads) => {
                this.user = user;
                this.availableRoles = availableRoles;
                this.availableDownloads = [...availableDownloads, { path: '*' }];
            }));
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    canManageUser(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageAdminUsers);
    }

    // region Global role

    openAddGlobalRole(user: UserRoles) {
        const dialogRef = this.dialog.open<AddAuthorizedUserDialogComponent<GlobalRole>, AddAuthorizedUserDialogData<GlobalRole>, AddAuthorizedUserDialogResult<GlobalRole>>(
            AddAuthorizedUserDialogComponent, {
                data: {
                    email: user.user.email,
                    target: {
                        availableRoles: this.availableRoles.globalRoles.filter(role => !user || !user.globalRoles.includes(role)),
                        label: user.user.email
                    }
                }
            });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRole({
                email: result.email,
                global: [result.role],
            }), () => {
                this.user?.globalRoles.push(result.role);
            });
        });
    }


    removeGlobalRole(user: UserRoles, role: GlobalRole) {
        this.confirmDialogService.confirm('Remove role', `Remove the role <code>${role}</code> for the user <code>${user.user.email}</code>?`, undefined, {
            yes: {
                label: 'Remove role',
                warn: true
            }
        }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email: user.user.email,
                global: [role]
            }), () => {
                let roleIndex = user.globalRoles.indexOf(role);
                if (roleIndex !== -1) {
                    user.globalRoles.splice(roleIndex, 1);
                }
            });
        });
    }

    // endregion

    removeDownload(user: UserRoles, download: string) {
        this.confirmDialogService.confirm('Remove download', `Remove permission to download <code>${download}</code> for the user <code>${user.user.email}</code>?`, undefined, {
            yes: {
                label: 'Remove download',
                warn: true
            }
        }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email: user.user.email,
                downloads: [download]
            }), () => {
                let roleIndex = user.downloads.indexOf(download);
                if (roleIndex !== -1) {
                    user.downloads.splice(roleIndex, 1);
                }
            });
        });
    }

    addDownload(download: string) {
        this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRole({
            email: this.userEmail,
            downloads: [download],
        }), () => {
            this.user?.downloads.push(download);
        });
        this.form.resetForm();
    }

    filterDownloads(availableDownloads: DownloadPath[]) {
        if (!this.user) {
            return availableDownloads;
        }
        return availableDownloads.filter(d => !this.user?.downloads.includes(d.path));
    }
}
