import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { FilterCategoryService } from '../../services/filter-category.service';
import {
    CreateFilterCategoryInput,
    FilterCategory,
    GlobalPermission,
    LocalFilterDefinition,
    RemoteFilter
} from '../../../schema-dotnet';
import { AuthorizationContext, AuthorizationService } from '../../services';
import {
    EditFilterCategoryDialogComponent,
    EditFilterCategoryDialogData,
    EditFilterCategoryDialogResult
} from '../edit-filter-category-dialog/edit-filter-category.component';
import { PhotoFilterService } from 'app/services/photo-filter.service';
import { RemoteFilterService } from 'app/services/remote-filter.service';
import { forkJoin } from 'rxjs';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';

@Component({
    selector: 'admin-store-filter-categories-page.ts',
    templateUrl: './store-filter-categories-page.component.html',
    styleUrls: ['./store-filter-categories-page.component.scss']
})
export class StoreFilterCategoriesPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private authorizationContext: AuthorizationContext;
    private filterBuiltIns: Array<LocalFilterDefinition> = [];
    private remoteFilters: Array<RemoteFilter> = [];

    public storeId: string;
    public filterCategories: Array<FilterCategory> = [];

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly route: ActivatedRoute,
        private readonly matDialog: MatDialog,
        private readonly filterCategoryService: FilterCategoryService,
        private readonly photoFilterService: PhotoFilterService,
        private readonly remoteFilterService: RemoteFilterService,
        private readonly confirmDialogService: ConfirmDialogService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));

        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.filterCategoryService.loadStoreFilterCategories(this.storeId),
                    this.photoFilterService.getFiltersDefinitions(),
                    this.remoteFilterService.loadRemoteFilters(this.storeId)
                ]), ([filterCategories, filterBuiltIns, remoteFilters]) => {
                    this.filterCategories = filterCategories;
                    this.filterBuiltIns = filterBuiltIns;
                    this.remoteFilters = remoteFilters;
                }
            );
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    canManageFilterCategories(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageStockPhotoSources);
    }

    addFilterCategory() {
        const dialogRef = this.matDialog.open<EditFilterCategoryDialogComponent, EditFilterCategoryDialogData, EditFilterCategoryDialogResult>(EditFilterCategoryDialogComponent, {
            data: {
                mode: 'create',
                filterBuiltIn: this.filterBuiltIns,
                remoteFilters: this.remoteFilters,
                filterCategories: this.filterCategories
            } as EditFilterCategoryDialogData
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result)
                return;

            let newFilterCategory: CreateFilterCategoryInput = {
                label: result.label,
                storeId: this.storeId,
                builtInFilters: result.builtInFilter,
                remoteFilters: result.remoteFilters
            };
            this.subscribeWithGenericSavinErrorHandling(
                this.filterCategoryService.createFilterCategory(newFilterCategory)
                , (createdCategory) => {
                    this.filterCategories.push(createdCategory)
                });
        })
    }

    openEditFilterCategory(filterCategory: FilterCategory) {
        const dialogRef = this.matDialog.open<EditFilterCategoryDialogComponent, EditFilterCategoryDialogData, EditFilterCategoryDialogResult>(EditFilterCategoryDialogComponent, {
            data: {
                mode: 'edit',
                filterBuiltIn: this.filterBuiltIns,
                remoteFilters: this.remoteFilters,
                filterCategory,
                filterCategories: this.filterCategories
            } as EditFilterCategoryDialogData
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result)
                return;

            this.subscribeWithGenericSavinErrorHandling(
                this.filterCategoryService.updateFilterCategory(
                    {
                        id: result.id!,
                        storeId: this.storeId,
                        label: result.label!,
                        builtInFilters: result.builtInFilter,
                        remoteFilters: result.remoteFilters,
                    }),
                _ => {
                    filterCategory.label = result.label;
                    filterCategory.builtInFilters = result.builtInFilter;
                    filterCategory.remoteFilters = this.remoteFilters.filter(rf => result.remoteFilters.indexOf(rf.id) !== -1);
                }
            );
        })

    }

    deleteFilterCategory(filterCategory: FilterCategory) {
        this.confirmDialogService.confirm(
            'Delete Filter Category',
            `You will delete this filter category: ${filterCategory.label}.`,
            undefined,
            {
                yes: {
                    label: 'Delete',
                    warn: true
                }
            }
        ).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(
                this.filterCategoryService.deleteFilterCategory({
                    storeId: this.storeId,
                    filterCategoryId: filterCategory.id
                })
                , () => {
                    let categoryIndex = this.filterCategories.findIndex(f => f.id == filterCategory.id);
                    if (categoryIndex !== -1) {
                        this.filterCategories.splice(categoryIndex, 1)
                    }
                });
        })
    }
}
