<div id="error-404" >

    <div class="content">

        <div class="error-code">404</div>

        <div class="message">Sorry but we could not find the page you are looking for</div>

        <a class="back-link" [routerLink]="'/home'">Go back to your home page</a>

    </div>

</div>
