import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ViewContextSubscription} from '../../navigation/view-context.service';

@Component({
    selector: 'admin-selectable-subscription-element',
    templateUrl: './selectable-subscription-element.component.html',
    styleUrls: ['./selectable-subscription-element.component.scss'],
})
export class SelectableSubscriptionElementComponent {
    @Input() subscription: ViewContextSubscription;
    @Input() lastAccessed?: number;
    @Output() selectSubscription: EventEmitter<ViewContextSubscription> = new EventEmitter<ViewContextSubscription>();

    constructor() {}
}
