<div>
  @if (time$ | async; as time) {
    <mat-list class="date">
      <h2>Now</h2>
      <div class="date-info">
        Local Time
        <div class="secondary-text"> {{ time|date:'medium' }}</div>
        <div class="secondary-text"> {{ time|date:'full' }}</div>
      </div>
      <div class="date-info">
        UTC Time
        <div class="secondary-text"> {{ time|date:'medium':'UTC' }}</div>
        <div class="secondary-text"> {{ time|date:'full':'UTC' }}</div>
      </div>
    </mat-list>
  }
</div>
