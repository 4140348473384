import { NgModule } from '@angular/core';

import {
    AnomaliesService,
    ApplicationInsightsService,
    AuthorizationService,
    AuthorizedUsersService,
    BlobStorageService,
    DesignerCustomizationsService,
    FulfillersService,
    InvoiceService,
    LibraryService,
    MeService,
    OrdersService,
    ExternalPhotosConfigService,
    ProjectsService,
    SharedProjectsService,
    ReportsService,
    SearchService,
    StoreConfigService,
    StoreFulfillersService,
    SubscriptionsService,
    UserService,
    StockPhotoSourcesService
} from '.';
import { DesignerBranchService } from "./designer-branch.service";
import { DesignerReleaseChannelService } from './designer-release-channel.service';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { HubTokenService } from './hub-token.service';
import { PhotoFilterService } from './photo-filter.service';
import { StoreLogsService } from './store-logs.service';
import { StoreSecretsService } from './store-secrets.service';
import { StorePhotosUpscalingService } from './store-photos-upscaling.service';
import { StoreGeneratedTextService } from './store-generated-text.service';
import { StoresService } from './stores.service';
import { GeneratedImagesService } from "./generated-images.service";

@NgModule({
    providers: [
        MeService,
        AuthorizationService,
        SearchService,
        ReportsService,
        OrdersService,
        ProjectsService,
        SharedProjectsService,
        BlobStorageService,
        UserService,
        LibraryService,
        StoreConfigService,
        ExternalPhotosConfigService,
        DesignerBranchService,
        DesignerCustomizationsService,
        AnomaliesService,
        SubscriptionsService,
        InvoiceService,
        AuthorizedUsersService,
        StoreFulfillersService,
        StoresService,
        FulfillersService,
        StoreLogsService,
        StoreSecretsService,
        ApplicationInsightsService,
        HubTokenService,
        DynamicScriptLoaderService,
        PhotoFilterService,
        DesignerReleaseChannelService,
        StockPhotoSourcesService,
        GeneratedImagesService,
        StorePhotosUpscalingService,
        StoreGeneratedTextService
    ],
})
export class AdminServicesModule {}
