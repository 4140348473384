import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class DesignerBranchService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    public switchNonAutomaticUpgradeStoresToDeprecatedStableChannel(): Observable<void> {
        return this.graphDotnetService.mutate(`
        mutation switchNonAutomaticUpgradeStoresToDeprecatedStableChannel() {
            switchNonAutomaticUpgradeStoresToDeprecatedStableChannel()
        }`)
            .pipe(map(x => x.switchNonAutomaticUpgradeStoresToDeprecatedStableChannel));
    }


    public switchStoresOnDeprecatedStableChannelToStableChannel(): Observable<void> {
        return this.graphDotnetService.mutate(`
        mutation switchStoresOnDeprecatedStableChannelToStableChannel() {
            switchStoresOnDeprecatedStableChannelToStableChannel()
        }`)
            .pipe(map(x => x.switchStoresOnDeprecatedStableChannelToStableChannel));
    }
}
