import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'admin-currency',
    templateUrl: './currency.component.html',
})
export class CurrencyComponent {
    @Input()
    value: number;
    @Input()
    currency: string;

    constructor() {}
}
