<admin-page-simple-content id="designer-release-channel-rules"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Designer Release Channel'"
  [icon]="'merge_type'">
  <div class="content">

    <div class="designer-default-release-channel">
      <div class="section-header">
        <h2>Default Release Channel</h2>
        <p>
          The designer release channel to use when no rules are defined.
        </p>
        <p>
          A release channel determines the frequency of updates:
        </p>
        <ul>
          <li>The <code>ContinuousDelivery</code> channel is only available in English and French, and is updated approximately weekly with new features. It _is_ stable and can be used for production purposes.</li>
          <li>The <code>Stable</code> channel is available in more languages and receives major updates and features a few times per year. It allows for more time to prepare for upgrades. It is recommended for international deployments or when advanced CSS and JavaScript customizations were applied to your integration.</li>
        </ul>
      </div>
      <div class="form">
        <mat-form-field>
          <mat-label>Release Channel</mat-label>
          <mat-select displayName [(ngModel)]="designerReleaseChannel">
            @for (releaseChannel of releaseChannels; track releaseChannel) {
              <mat-option [value]="releaseChannel.id">
                {{ releaseChannel.id }} ({{releaseChannel.version}})
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <div>
          <admin-save-button [pageStatus]="pageStatus" (save)="saveDefaultDesignerReleaseChannel(designerReleaseChannel)">
          </admin-save-button>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="designer-release-channel-rules">
      <div class="section-header">
        <h2>Rules</h2>
        <p>
          The rules defined in this section take precedence on the default release channel.
        </p>
        <p>
          When several rules apply, users are redirected to a channel proportionally to the weight of each rule.
          For example, two rules associated with different release channels with a weight of 1 each will dispatch 50% of the users to each designer release channel.
        </p>
      </div>
      <div class="designer-release-channel-rules-table">
        @for (rule of rules; track rule) {
          <div class="rule">
            <div class="label">
              <mat-form-field>
                <mat-label>Label</mat-label>
                <input matInput [(ngModel)]="rule.label" name="label" autocomplete="off" required/>
              </mat-form-field>
            </div>
            <div class="designer-release-channel">
              <mat-form-field>
                <mat-label>Release Channel</mat-label>
                <mat-select displayName [(ngModel)]="rule.designerBranch" required>
                  @for (releaseChannel of releaseChannels; track releaseChannel) {
                    <mat-option [value]="releaseChannel.id">
                      {{ releaseChannel.id }} ({{releaseChannel.version}})
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="weight">
              <mat-form-field>
                <mat-label>Weight</mat-label>
                <input matInput [(ngModel)]="rule.weight" name="weight" type="number"
                  autocomplete="off"
                  required min="0" max="1000"/>
              </mat-form-field>
            </div>
            <div class="module">
              <mat-form-field>
                <mat-label>Module</mat-label>
                <mat-select displayName [(ngModel)]="rule.module">
                  @for (module of modules; track module) {
                    <mat-option [value]="module.value">
                      {{ module.display }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="actions">
              @if (rule.id) {
                <admin-save-button [pageStatus]="pageStatus" (save)="editRule(rule)"
                  [disabled]="!isRuleValid(rule)" [useContent]="true">
                  Apply
                </admin-save-button>
                <button mat-raised-button (click)="deleteRule(rule)" color="warn">
                  Remove
                </button>
              } @else {
                <admin-save-button [pageStatus]="pageStatus" (save)="addRule(rule)"
                  [disabled]="!isRuleValid(rule)" [useContent]="true">
                  Add
                </admin-save-button>
              }
            </div>
          </div>
        }
      </div>

    </div>

    <div>
      @if (pageStatus === 'saveError' && pageError) {
        <admin-error [error]="pageError"></admin-error>
      }
    </div>
  </div>
</admin-page-simple-content>

