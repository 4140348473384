import { Injectable } from "@angular/core";
import { ResourceIdentityParser } from "./resource-identity-parser.util";

@Injectable()
export class GitLabUrlBuilder {

    constructor(
        private readonly resourceIdentityParser: ResourceIdentityParser,
    ) {
    }

    GetResourceUrl(resourceId: string): string | undefined {
        let [success, resourceIdentity] = this.resourceIdentityParser.TryParse(resourceId);
        if (!success)
            return undefined;

        let gitlabGroup = resourceIdentity?.owner.startsWith('mediaclip') ? 'mediaclip': 'librairies';
        return `https://git.mediacliphub.com/${gitlabGroup}/${resourceIdentity?.owner}/tree/master/${resourceIdentity?.package}/${resourceIdentity?.group}/${resourceIdentity?.id}`;
    }
}
