<admin-page-simple-content id="store-fulfillers"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Settings: Fulfillers'"
  [icon]="'print'">
  @if (storeWithFulfillers) {
    <div class="content">
      <h2>Fulfillers available for the store</h2>
      @if (!storeWithFulfillers.fulfillers.length) {
        <div>
          <p>No fulfiller configured for this store.</p>
          <p>Placed orders will be set to the status <span [adminStatusColor]="$any('AvailableForDownload')">AvailableForDownload</span></p>
        </div>
      }
      @if (storeWithFulfillers.fulfillers.length) {
        <div>
          @for (storeFulfiller of storeWithFulfillers.fulfillers; track storeFulfiller) {
            <div class="store-fulfiller">
              <mat-icon>print</mat-icon>
              {{ storeFulfiller.fulfillerId }}
              @if (storeFulfiller.isDefault) {
                <em
                  matTooltip="All orders without a specific fulfiller will be sent to this one.">
                (default)</em>
              }
              @if (canManageStoreFulfillers()) {
                <a mat-icon-button
                  [matTooltip]="'Edit configuration'"
                  aria-label="Edit configuration"
                  [routerLink]="['/stores', storeWithFulfillers.id, 'settings', 'fulfillers', storeFulfiller.fulfillerId]"
                  >
                  <mat-icon>edit</mat-icon>
                </a>
              }
              @if (canManageStoreFulfillers()) {
                <a [routerLink]="['/stores', storeWithFulfillers.id, 'logs', 'fulfillers', storeFulfiller.fulfillerId]"
                  [matTooltip]="'Logs'"
                  aria-label="Show logs"
                  mat-icon-button>
                  <mat-icon>history</mat-icon>
                </a>
              }
            </div>
          }
        </div>
      }
      @if (canAddStoreFulfiller()) {
        <div>
          <button mat-stroked-button (click)="openAddStoreFulfillerDialog()">
            <mat-icon>add</mat-icon>
            Add fulfiller
          </button>
        </div>
      } @else {
        Contact support&#64;mediacliphub.com to add another fulfiller to your store.
      }
    </div>
  }
</admin-page-simple-content>
