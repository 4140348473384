@if (!(subscription.stores?.length)) {
  <p>No stores were created for this subscription.</p>
}

<div class="actions">
  <div class="actions-left">
    @if (subscription.stores?.length > 0) {
      <div>
        <button mat-icon-button [matMenuTriggerFor]="filterMenu">
          <mat-icon>filter_alt</mat-icon>
        </button>
        <mat-menu #filterMenu>
          <button mat-menu-item
            (click)="toggleShowActiveOnly()">
            @if (filter.showActiveOnly) {
              <mat-icon>check_box</mat-icon>
            }
            @if (!filter.showActiveOnly) {
              <mat-icon>check_box_outline_blank</mat-icon>
            }
            Only show active
          </button>
        </mat-menu>
        <mat-form-field class="dense-2">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput
            placeholder="search"
            (keydown)="updateFilter()"
            (keyup)="updateFilter()"
            [(ngModel)]="filter.name">
        </mat-form-field>
        <mat-chip-set>
          @if (filter.showActiveOnly) {
            <mat-chip>Active
              <button matChipRemove aria-label="Remove filter: Active" (click)="toggleShowActiveOnly()">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          }
        </mat-chip-set>
      </div>
    }
  </div>

  <div class="actions-right">
    @if (canAddStore()) {
      <button mat-stroked-button
        (click)="openAddStoreDialog()"
        [disabled]="pageStatus === 'saving'"
        id="addStoreAction">
        <mat-icon>add</mat-icon>
        Add Store
      </button>
    }
  </div>
</div>

@if (pageStatus === 'saveError' && pageError) {
  <admin-error [error]="pageError"></admin-error>
}

@if (subscription.stores?.length) {
  <table mat-table
    class="stores-table mat-elevation-z4"
    #storesTable="matTable"
    [dataSource]="displayedStores">
    <mat-header-row
    *matHeaderRowDef="['id', 'environment', 'status', 'projectsCount', 'ordersCount', 'links']"></mat-header-row>
    <mat-row
    *matRowDef="let store; columns: ['id', 'environment', 'status', 'projectsCount', 'ordersCount', 'links']"></mat-row>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
      <mat-cell *matCellDef="let store">
        <div class="store-label">
          <div>
            <a [routerLink]="['/stores', store.id, 'dashboards', 'overview']">{{ store.label }}</a>
          </div>
          <div><code adminCopyToClipboard>{{ store.id }}</code></div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="displayName">
      <mat-header-cell *matHeaderCellDef>Display Name</mat-header-cell>
      <mat-cell *matCellDef="let store">{{ store.label }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="environment">
      <mat-header-cell *matHeaderCellDef>Environment</mat-header-cell>
      <mat-cell *matCellDef="let store">{{ store.environment }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let store">{{ store.status }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="projectsCount">
      <mat-header-cell *matHeaderCellDef>Projects (14d)</mat-header-cell>
      <mat-cell *matCellDef="let store">{{ store.projectsCount | number}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="ordersCount">
      <mat-header-cell *matHeaderCellDef>Orders (14d)</mat-header-cell>
      <mat-cell *matCellDef="let store">{{ store.ordersCount | number}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="links">
      <mat-header-cell *matHeaderCellDef>Links</mat-header-cell>
      <mat-cell *matCellDef="let store">
        <a [routerLink]="['/stores', store.id, 'dashboards', 'overview']" mat-stroked-button matTooltip="Overview"
          class="mr-4">
          <mat-icon>dashboard</mat-icon>
        </a>
        @if (canManageStoreMetadata(store.id)) {
          <a [routerLink]="['/stores', store.id, 'settings', 'status']"
            mat-stroked-button matTooltip="Store Status" class="mr-4">
            <mat-icon>business_center</mat-icon>
          </a>
        }
        <a [routerLink]="['/stores', store.id, 'lists', 'recent']" mat-stroked-button matTooltip="Recent Orders">
          <mat-icon>inbox</mat-icon>
        </a>
      </mat-cell>
    </ng-container>
  </table>
}

