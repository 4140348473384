import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { DesignerCustomizationsService } from '../../services';
import { PageStatus } from '../../models';
import { AdminComponentBase } from '../../utils/admin-component-base';

@Component({
    selector: 'admin-store-designer-custom-css',
    templateUrl: './store-designer-custom-css.component.html',
    styleUrls: ['./store-designer-custom-css.component.scss']
})
export class StoreDesignerCustomCssComponent extends AdminComponentBase implements OnInit, OnDestroy {
    css: string;
    storeId: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly designerCustomizationsService: DesignerCustomizationsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.designerCustomizationsService.getStoreFiles(this.storeId), storeFiles => {
                if (!storeFiles.css?.url) {
                    this.css = '';
                    this.pageStatus = PageStatus.loaded;
                } else {
                    this.designerCustomizationsService.getCustomizationFile(storeFiles.css?.url).subscribe({
                        next: (css) => {
                            this.css = css;
                            this.pageStatus = PageStatus.loaded;
                        },
                        error: (err) => {
                            this.pageError = err;
                            this.pageStatus = PageStatus.loadError;
                        }
                    });
                }
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    save(): void {
        this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateCssFile({
            storeId: this.storeId,
            content: this.css
        }));
    }

    async onFilesChanged(files: FileList) {
        if (!files.length) {
            return;
        }
        this.css = await files[0].text();
    }
}
