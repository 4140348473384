<h2 mat-dialog-title>Edit project expirations</h2>
<div mat-dialog-content>
    <p>30 to 730 days. Leave empty to not configure expiration for that module</p>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Photobook</mat-label>
            <input matInput
                   min="30" max="730"
                   type="number"
                   name="photobook"
                   [(ngModel)]="result.photobook"
                   (keydown.enter)="editSubscription()"/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Gifting</mat-label>
            <input matInput
                   min="30" max="730"
                   type="number"
                   name="gifting"
                   [(ngModel)]="result.gifting"
                   (keydown.enter)="editSubscription()"/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Print</mat-label>
            <input matInput
                   min="30" max="730"
                   type="number"
                   name="print"
                   [(ngModel)]="result.print"
                   (keydown.enter)="editSubscription()"/>
        </mat-form-field>
    </form>

    <p>New configuration will be effective after 1 week</p>
</div>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button color="primary"
            [disabled]="form.invalid"
            (click)="editSubscription()">
        OK
    </button>
</mat-dialog-actions>
