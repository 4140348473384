import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface AddStockPhotoSourceDialogResult {
    id: string;
    label: string | undefined;
}

@Component({
    templateUrl: './add-stock-photo-source-dialog.component.html',
    styleUrls: ['./add-stock-photo-source-dialog.component.scss']
})
export class AddStockPhotoSourceDialogComponent {
    stockPhotoSourceInfo: AddStockPhotoSourceDialogResult = {
        id: '',
        label: undefined
    };
    constructor(
        public dialogRef: MatDialogRef<AddStockPhotoSourceDialogComponent>
    ) {
    }

    valid() {
        this.dialogRef.close(this.stockPhotoSourceInfo);
    }
}
