import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';

@Component({
    selector: 'quick-panel', // eslint-disable-line
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickPanelComponent implements OnDestroy {
    time$: Subject<Date>;
    subscription: Subscription | null;

    @Input()
    set opened(value: boolean) {
        if (value && !this.subscription) {
            this.subscription = timer(0, 1000).subscribe(() => this.time$.next(new Date()));
        } else {
            if (this.subscription) {
                this.subscription.unsubscribe();
                this.subscription = null;
            }
        }
    }

    constructor() {
        this.time$ = new Subject();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
