import { PromptDialogComponent, PromptDialogData } from './prompt-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PromptDialogService {
    constructor(
        private readonly dialog: MatDialog
    ) {
    }

    prompt(title: string, message?: string, options?: {defaultValue?: string, fieldLabel?: string}): Observable<string> {
        const dialogRef = this.dialog.open<PromptDialogComponent, PromptDialogData, string>(PromptDialogComponent, {
            data: { title: title, message: message, defaultValue: options?.defaultValue, label: options?.fieldLabel } as PromptDialogData
        });
        return dialogRef.afterClosed().pipe(
            filter(r => !!r),
            map(r => r!)
        );
    }

}
