<admin-page-simple-content id="store-fulfillers"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Settings: General'"
  [icon]="'assignment'">
  @if (storeConfig) {
    <div class="content">
      <p>
        Any changes made to the configuration may take up to 2 minutes to become effective.
      </p>
      <form #form="ngForm" (ngSubmit)="save()">
        <mat-form-field>
          <mat-label>Default library package</mat-label>
          <input
            matInput
            [(ngModel)]="storeConfig.defaultLibraryPackage"
            name="defaultLibraryPackage"
            placeholder="mediaclip/default"
            pattern="^[a-zA-Z0-9\-_]+\/[a-zA-Z0-9\-_]+$"
            required
            autocomplete="off">
          <mat-error>Value must be in the form <code>company/package</code></mat-error>
          <mat-hint>
            When launching a project, which library package should be used.
            Can be overridden
            <a class="external"
              href="https://doc.mediaclip.ca/hub/api/projects/create/"
              rel="external"
              target="_blank">
              by project
              <mat-icon>open_in_new</mat-icon>
            </a>.
          </mat-hint>
        </mat-form-field>
        <div class="config-checkbox">
          <mat-checkbox [(ngModel)]="storeConfig.allowAnonymousRequest" name="allowAnonymousRequest">
            Allow anonymous requests
          </mat-checkbox>
          <span class="mat-caption">Whether calls without authentication are allowed. Only used for native embedded integrations.</span>
        </div>
        <div class="config-checkbox">
          <mat-checkbox [(ngModel)]="storeConfig.isRecentUploadsEnabled" name="isRecentUploadsEnabled">
            Recent uploads
          </mat-checkbox>
          <span class="mat-caption">Shows the "Recent Uploads" album in Mediaclip Designer.</span>
        </div>
        <div class="config-checkbox">
          <mat-checkbox [(ngModel)]="storeConfig.isProofingEnabled" name="isProofingEnabled">
            Enable Proofing Mode
          </mat-checkbox>
          <span class="mat-caption">Prevents orders from being sent to fulfillment before the order has been approved.
            See <a class="external"
            href="https://doc.mediaclip.ca/hub/features/proofing/"
            rel="external"
            target="blank">
            proofing workflow
            <mat-icon>open_in_new</mat-icon>
          </a>.
        </span>
      </div>
      <div class="config-checkbox">
        <mat-checkbox [(ngModel)]="storeConfig.uploadInBackgroundEnabled" name="uploadInBackgroundEnabled">
          Upload In Background
        </mat-checkbox>
        <span class="mat-caption">
          Enable Upload In Background feature. Photos will be uploaded while the user is designing it's project.
        </span>
      </div>
      <div class="config-checkbox">
        <mat-checkbox [(ngModel)]="storeConfig.enableThumbnailGeneration" name="enableThumbnailGeneration">
          Enable project thumbnails
        </mat-checkbox>
        <span class="mat-caption">
          When enabled, allow to use project thumbnails.
        </span>
      </div>
      <admin-save-button
        [disabled]="!form.valid"
        [pageStatus]="pageStatus">
      </admin-save-button>
    </form>
  </div>
}

<div>
  @if (pageStatus === 'saveError' && pageError) {
    <admin-error [error]="pageError"></admin-error>
  }
</div>

</admin-page-simple-content>
