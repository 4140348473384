<div id="home" class="page-layout simple inner-scroll">
  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24">
    <!-- APP TITLE -->
    <div>
      <div class="logo">
        <mat-icon class="logo-icon mr-16" [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
          >home
        </mat-icon>
        <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
          Home
        </span>
      </div>
    </div>
    <!-- / APP TITLE -->
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content" fusePerfectScrollbar>
    <div class="section">
      @if ((me$ | async); as me) {
        <p>Welcome, {{ me.email }}!</p>
      }

      <p>
        Start by selecting the store you want to edit in the top of the page
      </p>
    </div>
  </div>
</div>
