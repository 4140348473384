import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationContext, AuthorizationService, StoresService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Store, StoreEnvironment, StoreStatus, SubscriptionPermission } from '../../../schema-dotnet';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { PageStatus } from '../../models';

@Component({
    selector: 'admin-store-status',
    templateUrl: './store-status-page.component.html',
    styleUrls: ['./store-status-page.component.scss']
})
export class StoreStatusPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    storeId: string;
    authContext?: AuthorizationContext;
    storeWithMetadata?: Store;
    noCommaPattern = /^[^,]*$/;

    environments = [
        { value: StoreEnvironment.Production, display: 'Production (billable, monitored)' },
        { value: StoreEnvironment.Staging, display: 'Staging (non-billable, monitored)' },
        { value: StoreEnvironment.Development, display: 'Development (non-billable, non-monitored)' },
        { value: StoreEnvironment.Demonstration, display: 'Demonstration (non-billable, monitored)' },
        { value: StoreEnvironment.Test, display: 'Test (used for testing)' }
    ];

    statuses = [
        { value: StoreStatus.Active, display: 'Active' },
        { value: StoreStatus.Closed, display: 'Closed', disabled: true },
        { value: StoreStatus.ScheduledForDeletion, display: 'ScheduledForDeletion', disabled: true },
        { value: StoreStatus.ScheduledForDeletionNotificationSent, display: 'ScheduledForDeletionNotificationSent', disabled: true }
    ];

    platforms = [
        { value: '', display: 'Unspecified' },
        { value: 'BigCommerce', display: 'BigCommerce' },
        { value: 'Ecwid', display: 'Ecwid' },
        { value: 'Magento', display: 'Magento' },
        { value: 'Prestashop', display: 'Prestashop' },
        { value: 'Shopify', display: 'Shopify' },
        { value: 'Wix', display: 'Wix' },
        { value: 'WooCommerce', display: 'WooCommerce' },
        { value: 'Other', display: 'Other' },
        { value: 'Custom', display: 'In-House (Custom)' }
    ];

    plugins = [
        { value: '', display: 'Unspecified' },
        { value: 'Magento', display: 'Magento' },
        { value: 'Shopify', display: 'Shopify' },
        { value: 'WooCommerce', display: 'WooCommerce' },
        { value: 'None', display: 'None' }
    ];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authService: AuthorizationService,
        private readonly storesService: StoresService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storesService.getStore(this.storeId), (store) => {
                this.storeWithMetadata = store;
            });
        }))
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    deactivate() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Deactivate store',
                message: `Do you really want to deactivate the store <code>${this.storeId}</code> ?`,
                subMessage: `All data will be deleted after 90 days. This will immediately prevent the store from creating any new orders or projects`,
                options: {
                    yes: {
                        label: 'Deactivate',
                        warn: true
                    },
                    no: {
                        label: 'Cancel'
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.storesService.deactivateStore({id: this.storeId}), () => {
                this.reloadStore();
            });
        });
    }

    save() {
        if (!this.storeWithMetadata) {
            return;
        }

        this.pageStatus = PageStatus.saving;
        this.subscribeWithGenericSavinErrorHandling(this.storesService.updateStore({
            id: this.storeId,
            label: this.storeWithMetadata.label,
            environment: this.storeWithMetadata.environment,
            status: this.storeWithMetadata.status,
            automaticDesignerUpgrade: this.storeWithMetadata.automaticDesignerUpgrade,
            platform: this.storeWithMetadata.metadata.platform,
            plugin: this.storeWithMetadata.metadata.plugin,
            testDesignerInfo: this.storeWithMetadata.metadata.testDesignerInfo,
            ignoredProblems: this.storeWithMetadata.metadata.ignoredProblems,
        }));
    }

    addDesignerTestInfo() {
        if (!this.storeWithMetadata) {
            return;
        }
        this.storeWithMetadata.metadata.testDesignerInfo.push({})
    }

    removeDesignerTestInfo(i: number) {
        if (!this.storeWithMetadata) {
            return;
        }
        this.storeWithMetadata.metadata.testDesignerInfo.splice(i, 1);
    }

    canDeactivateSubscription(subscriptionId: string) {
        return this.authContext?.hasSubscriptionPermissions(subscriptionId, SubscriptionPermission.ManageSubscriptionMetadata)
    }

    private reloadStore() {
        this.subscribeWithGenericLoadingErrorHandling(this.storesService.getStore(this.storeId), (store) => {
            this.storeWithMetadata = store;
        });
    }
}
