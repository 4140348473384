import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

export interface AddSubscriptionDialogResult {
    subscriptionLabel: string;
}

@Component({
  selector: 'admin-add-subscription-dialog',
  templateUrl: './add-subscription-dialog.component.html',
  styleUrls: ['./add-subscription-dialog.component.scss']
})
export class AddSubscriptionDialogComponent {
    subscriptionInfo: AddSubscriptionDialogResult = {
        subscriptionLabel: '',
    };

    constructor(
        public dialogRef: MatDialogRef<AddSubscriptionDialogComponent>
    ) {
    }

    valid() {
        this.dialogRef.close(this.subscriptionInfo);
    }
}
