import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './direct-photo-source-add-dialog.component.html',
    styleUrls: ['./direct-photo-source-add-dialog.component.scss'],
})
export class DirectPhotoSourceAddDialogComponent {
    public photoSourceIdControl = new UntypedFormControl('', [
        Validators.required,
        Validators.max(255),
        Validators.pattern(/^[a-z0-9]+$/),
    ]);

    constructor(public dialogRef: MatDialogRef<DirectPhotoSourceAddDialogComponent, string>) {}

    create(photoSourceId: string): void {
        this.dialogRef.close(photoSourceId);
    }
}
