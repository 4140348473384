import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { DesignerCustomizationsService } from '../../services';
import { PageStatus } from '../../models';
import { environment } from '../../../environments/environment';
import { AdminComponentBase } from '../../utils/admin-component-base';

@Component({
    selector: 'admin-store-designer-logo',
    templateUrl: './store-designer-logo.component.html',
    styleUrls: ['./store-designer-logo.component.scss']
})
export class StoreDesignerLogoComponent extends AdminComponentBase implements OnInit, OnDestroy {
    public logoUrl: string;
    public storeId: string;
    private baseLogoUrl: string;

    @ViewChild('logo')
    public logo: HTMLImageElement;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly designerCustomizationsService: DesignerCustomizationsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.baseLogoUrl = `${environment.hub.hubApiUrl}/stores/${this.storeId}/config/logo`;
            this.logoUrl = `${this.baseLogoUrl}?t=${new Date().getTime()}`;
            this.setPageStatus(PageStatus.loaded);
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    async onFilesChanged(files: FileList) {
        const contentBase64 = await blobToBase64(files[0]);

        this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateLogo({
            storeId: this.storeId,
            contentBase64: contentBase64,
            mimeType: files[0].type
        }), () => {
            this.logoUrl = `${this.baseLogoUrl}?t=${new Date().getTime()}`;
        });
    }
}

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const raw = (reader.result as string);
            const separator = raw.indexOf(',');
            resolve(raw.substring(separator === -1 ? 0 : separator + 1) as string);
        };
        reader.readAsDataURL(blob);
    });
}
