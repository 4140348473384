import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalPermission, StockPhotoSourceConfig } from 'schema-dotnet';
import { MatTable } from '@angular/material/table';
import { AuthorizationContext, AuthorizationService, StockPhotoSourcesService } from "../../services";
import { AdminComponentBase } from "../../utils/admin-component-base";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
    AddStockPhotoSourceDialogComponent,
    AddStockPhotoSourceDialogResult
} from "./add-stock-photo-source-dialog.component";
import {
    RelatedStoresStockPhotoSourceDialogComponent,
    RelatedStoresStockPhotoSourceDialogData
} from "./related-store-stock-photo-source-dialog.component";

@Component({
    selector: 'admin-stock-photo-sources-page',
    templateUrl: './stock-photo-sources-page.component.html',
    styleUrls: ['./stock-photo-sources-page.component.scss']
})
export class StockPhotoSourcesPageContainerComponent extends AdminComponentBase implements OnInit {

    @ViewChild('stockPhotoSourcesTable', { static: false })
    stockPhotoSourcesTable: MatTable<StockPhotoSourceConfig>;
    stockPhotoSources: StockPhotoSourceConfig[] = [];
    displayedStockPhotoSources: StockPhotoSourceConfig[] = [];
    displayedColumns: string[] = [
        'id',
        'photosEndpointUrl',
        'visibility',
        'enabled',
        'actions'
    ];
    filter: {
        name: string;
    } = {
        name: ''
    };

    private authorizationContext: AuthorizationContext;

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly stockPhotoSourcesService: StockPhotoSourcesService,
        private readonly dialog: MatDialog,
        private readonly router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));

        this.subscribeWithGenericLoadingErrorHandling(this.stockPhotoSourcesService.list(), (stockPhotoSources) => {
            this.stockPhotoSources = stockPhotoSources;
            this.updateDisplayedStockPhotoSources();
        });
    }

    updateFilter() {
        this.updateDisplayedStockPhotoSources();
    }

    private updateDisplayedStockPhotoSources() {
        this.displayedStockPhotoSources = this.stockPhotoSources
            .filter(s => this.isStockPhotoSourcesVisible(s))
            .sort((a, b) => a.id.localeCompare(b.id));
        this.stockPhotoSourcesTable.renderRows();
    }


    private isStockPhotoSourcesVisible(f: StockPhotoSourceConfig) {
        if (this.filter.name) {
            if (!f.id.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return false;
            }
        }
        return true;
    }

    stockPhotoSourceId(i: number, stockPhotoSourceConfig: StockPhotoSourceConfig) {
        return stockPhotoSourceConfig.id;
    }

    addStockPhotoSource() {
        this.dialog.open<AddStockPhotoSourceDialogComponent, void, AddStockPhotoSourceDialogResult>(AddStockPhotoSourceDialogComponent).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.stockPhotoSourcesService.createStockPhotoSource({
                id: result.id,
                label: result.label,
                visible: true,
                photosEndpoint: {
                    url: ''
                }
            }), () => {
                this.router.navigate(['/stock-photo-sources', result.id]);
            });
        });
    }

    showRelatedStores(stockPhotoSourceId: string) {
        this.dialog.open<RelatedStoresStockPhotoSourceDialogComponent, RelatedStoresStockPhotoSourceDialogData, void>(
            RelatedStoresStockPhotoSourceDialogComponent,
            {
                data: {
                    stockPhotoSourceId: stockPhotoSourceId
                }
            }).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
        });
    }

    canManageStockPhotoSource(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageStockPhotoSources);
    }


    $stockPhotoSource(source: any): StockPhotoSourceConfig {
        return source as StockPhotoSourceConfig;
    }
}
