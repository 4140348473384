import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FulfillerRole, GlobalRole, LibraryRole, StoreRole, SubscriptionRole} from '../../../schema-dotnet';

type Roles = FulfillerRole | LibraryRole | SubscriptionRole | StoreRole | GlobalRole

export interface AddAuthorizedUserDialogData<TRole extends Roles> {
    target: {
        label: string,
        availableRoles: TRole[],
    };
    email?: string;
}

export interface AddAuthorizedUserDialogResult<TRole extends Roles> {
    email: string;
    role: TRole;
}

@Component({
    templateUrl: './add-authorized-user-dialog.component.html',
    styleUrls: ['./add-authorized-user-dialog.component.scss']
})
export class AddAuthorizedUserDialogComponent<TRole extends Roles> {
    email: string = '';
    selectedRole?: TRole;

    constructor(
        public dialogRef: MatDialogRef<AddAuthorizedUserDialogComponent<TRole>, AddAuthorizedUserDialogResult<TRole>>,
        @Inject(MAT_DIALOG_DATA) public data: AddAuthorizedUserDialogData<TRole>
    ) {
        this.email = data.email || '';
    }

    valid() {
        if (!this.selectedRole) {
            return;
        }
        this.dialogRef.close({
            email: this.email,
            role: this.selectedRole
        });
    }
}
