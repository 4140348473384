export class MediaclipError extends Error {
    public readonly innerError?: Error;

    constructor(message: string, options?: { innerError?: Error }) {
        super(message);
        this.innerError = options?.innerError;

        // Workaround: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, MediaclipError.prototype);
    }
}
