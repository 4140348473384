import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FuseModule} from '@fuse/fuse.module';
import {FuseProgressBarModule, FuseSidebarModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {AdminContainersModule} from './containers/containers.module';
import {AdminServicesModule} from './services/services.module';
import {AdminUtilsModule} from "./utils/utils.module";
import {AppComponent} from './app.component';
import {LayoutModule} from './components/layout/layout.module';
import {fuseConfig} from './fuse-config';
import {routes} from './app.routes';
import {AuthenticationService} from './services';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

// TODO: If we can figure out a way to make BrowserAnimationsModule switch to NoopAnimationsModule when running e2e
//       it would help a lot. My best idea: wrap both in a custom ConfigurableAnimationsModule
@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        LayoutModule,
        MatNativeDateModule,
        AdminServicesModule,
        AdminContainersModule,
        AdminUtilsModule
    ], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (authenticationService: AuthenticationService) => () => {
                return authenticationService.handleAuthentication();
            },
            deps: [AuthenticationService],
            multi: true
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
                subscriptSizing: 'dynamic'
            }
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
