import {Injectable} from '@angular/core';
import {
    DeleteStoreGeneratedTextInput,
    Maybe,
    MediaclipMutationDeleteStoreGeneratedTextArgs, MediaclipMutationUpdateStoreGeneratedTextArgs, UpdateStoreGeneratedTextInput,
    GeneratedTextConfiguration
} from 'schema-dotnet';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class StoreGeneratedTextService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getConfig(storeId: string): Observable<Maybe<GeneratedTextConfiguration> | undefined> {
        return this.graphDotnetService.query(`
                query getStoreGeneratedText($storeId: String!) {
                    store(id: $storeId) {
                        generatedText {
                            accessToken
                            organization
                        }
                    }
                }
            `,
            {
                storeId
            }
        ).pipe(map(q => q.store.generatedText));
    }

    deleteConfiguration(input: DeleteStoreGeneratedTextInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteStoreGeneratedTextArgs>(
            `
                mutation deleteStoreGeneratedText($input: DeleteStoreGeneratedTextInput!) {
                    deleteStoreGeneratedText(input: $input)
                }
            `,
            { input }
        );
    }

    updateConfiguration(input: UpdateStoreGeneratedTextInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreGeneratedTextArgs>(
            `
                mutation UpdateStoreGeneratedText($input: UpdateStoreGeneratedTextInput!) {
                    updateStoreGeneratedText(input: $input)
                }
            `,
            { input }
        );
    }
}
