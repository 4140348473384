import {
    DesignerModuleName,
    Library,
    Maybe,
    MediaclipMutationReleaseLibraryLockArgs,
    MediaclipMutationStartUpdateLibraryArgs,
    MediaclipMutationUpdateLibraryMetadataArgs, MediaclipMutationUpdateLibraryUpdateSchedulesArgs,
    Product,
    ReleaseLibraryLockInput,
    StartUpdateLibrary,
    StartUpdateLibraryInput,
    UpdateLibraryMetadataInput, UpdateLibraryScheduleUpdatesInput
} from 'schema-dotnet';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class LibraryService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getLibraries(): Observable<Library[]> {
        return this.graphDotnetService.query(`query {
        libraries {
            owner
            branches {
              name
              metadata {
                version
              }
            }
          }
        }`).pipe(map(q => q.libraries));
    }


    getAuthorizedUsers(owner: string): Observable<Library> {
        return this.graphDotnetService.query(`
            query loadLibrary($owner: String!) {
                library(owner: $owner) {
                    owner
                    authorizedUsers {
                        user {
                            email
                            picture
                        }
                        roles
                    }
                }
            }`, {owner})
            .pipe(map(q => q.library));
    }

    getLibraryStatus(owner: string): Observable<Library> {
        return this.graphDotnetService.query(`
        query loadLibrary($owner: String!) {
            library(owner: $owner) {
                owner
                gitBranches {
                    name
                    commitHash
                }
                branches {
                  name
                  scheduledUpdates {
                    gitBranch
                    schedules {
                      hourUtc
                      weekDays
                    }
                  }
                  metadata {
                    version
                    doNotValidate
                    validationDefaultPackage
                    validationCulture
                    progress {
                      progressSignalRUrl
                      updateId
                      stage
                      lastUpdateDateUtc
                      completed
                      max
                      value
                      errorMessage
                      totalDuration
                      logs {
                        stage
                        duration
                      }
                      hubValidation {
                        logs {
                          level
                          message
                        }
                      }
                      validation {
                        entries {
                          level
                          category
                          package
                          url
                          message
                        }
                      }
                    }
                  }
                }
              }
            }`, {owner})
            .pipe(map(q => q.library));
    }

    startUpdateLibrary(input: StartUpdateLibraryInput): Observable<StartUpdateLibrary> {
        return this.graphDotnetService.mutate<MediaclipMutationStartUpdateLibraryArgs>(`
          mutation startUpdateLibrary($input: StartUpdateLibraryInput!) {
            startUpdateLibrary(input: $input) {
              updateId
              message
              progressPollingEndpointUrl
              progressSignalRUrl
            }
          }`, {input}
        ).pipe(map(x => x.startUpdateLibrary));
    }

    releaseLibraryLock(input: ReleaseLibraryLockInput): Observable<StartUpdateLibrary> {
        return this.graphDotnetService.mutate<MediaclipMutationReleaseLibraryLockArgs>(`
          mutation releaseLibraryLock($input: ReleaseLibraryLockInput!) {
            releaseLibraryLock(input: $input)
          }`, {input}
        ).pipe(map(x => x.releaseLibraryLock));
    }

    updateLibraryMetadata(input: UpdateLibraryMetadataInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateLibraryMetadataArgs>(`
          mutation updateLibraryMetadata($input: UpdateLibraryMetadataInput!) {
            updateLibraryMetadata(input: $input)
          }`, {input}
        ).pipe(map(x => x.updateLibraryMetadata));
    }

    getProducts(
        storeId: string,
        module: DesignerModuleName | null = null,
        culture: string = 'en-CA'
    ): Observable<Product[]> {
        return this.graphDotnetService.query(`
                query getProducts($storeId: String!, $module: DesignerModuleName, $culture: String) {
                    store(id: $storeId) {
                        products(module: $module, culture: $culture) {
                            id
                            module
                            plu
                            label
                            thumbnail
                        }
                    }
                }
            `,
            {
                storeId,
                module,
                culture
            }
        ).pipe(map(x => x.store.products));
    }

    getProduct(storeId: string, productId: string, culture: string = 'en-CA'): Observable<Maybe<Product> | undefined> {
        return this.graphDotnetService.query(`
                query getProductThemes($storeId: String!, $productId: String!, $culture: String) {
                    store(id: $storeId) {
                        product(id: $productId, culture: $culture) {
                            id
                            module
                            plu
                            label
                            thumbnail
                            themes {
                                id
                                label
                                thumbnail
                            }
                            options {
                                name
                                label
                                default
                                values {
                                    name
                                    label
                                }
                            }
                        }
                    }
                }
            `,
            {
                storeId,
                productId,
                culture
            }
        ).pipe(map(x => x.store.product));
    }

    updateLibraryUpdateSchedules(input: UpdateLibraryScheduleUpdatesInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateLibraryUpdateSchedulesArgs>(`
          mutation updateLibraryUpdateSchedules($input: UpdateLibraryScheduleUpdatesInput!) {
            updateLibraryUpdateSchedules(input: $input)
          }`, {input}
        ).pipe(map(x => x.updateLibraryUpdateSchedules));
    }
}
