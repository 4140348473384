import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ContextTagKeys } from '@microsoft/applicationinsights-common';
import { newId } from "@microsoft/applicationinsights-core-js";
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { filter } from 'rxjs/operators';

interface RouteTemplate {
    template: string;
    storeId?: string;
    operationId?: string;
}

// Source: https://dev.to/azure/using-azure-application-insights-with-angular-5-7-4kej
@Injectable()
export class ApplicationInsightsService {
    private appInsights: ApplicationInsights;
    private lastRouteTemplate: RouteTemplate;
    private tagKeys = new ContextTagKeys();

    constructor(private router: Router) {
        if (!environment.appInsights || !environment.appInsights.instrumentationKey) {
            return;
        }
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                // NOTE: Only the first page view is actually useful, so to avoid any confusion let's turn that off.
                //       We should instead use startTrackPage and stopTrackPage but that would require more code.
                overridePageViewDuration: true,
            },
        });
        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer((envelope) => this.telemetryInitializer(envelope));
        this.router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
            const activatedComponent = this.getActivatedComponent(event.state.root);
            if (activatedComponent) {
                const routeTemplate = this.getRouteTemplate(event.state.root);
                routeTemplate.operationId = newId();
                this.lastRouteTemplate = routeTemplate;
                this.logPageView(routeTemplate.template || '(unspecified route template)', event.urlAfterRedirects);
            }
        });
    }

    setUserId(userId: string): void {
        if (this.appInsights) {
            this.appInsights.setAuthenticatedUserContext(userId);
        }
    }

    clearUserId(): void {
        if (this.appInsights) {
            this.appInsights.clearAuthenticatedUserContext();
        }
    }

    logError(exception: Error | null, message: string): void {
        if (this.appInsights) {
            if (exception) {
                this.appInsights.trackException({
                    exception,
                    properties: {
                        message,
                    },
                });
            } else {
                this.appInsights.trackTrace({
                    message,
                });
            }
        }
    }

    logPageView(name?: string, uri?: string): void {
        if (this.appInsights) {
            this.appInsights.trackPageView({ name, uri });
        }
    }

    private telemetryInitializer(envelope: ITelemetryItem): void {
        if (this.lastRouteTemplate) {
            if (envelope.tags && this.lastRouteTemplate.template) {
                envelope.tags[this.tagKeys.operationName] = this.lastRouteTemplate.template;
                envelope.tags[this.tagKeys.operationId] = this.lastRouteTemplate.operationId;
            }
            if (envelope.baseData) {
                if (!envelope.baseData.properties) {
                    envelope.baseData.properties = { AppName: 'Mediaclip.Hub.Admin' };
                }
                if (this.lastRouteTemplate.storeId) {
                    envelope.baseData.properties.StoreId = this.lastRouteTemplate.storeId;
                }
            }
        }
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): RouteTemplate {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            const firstChildTemplate = this.getRouteTemplate(snapshot.firstChild);
            return {
                template: path + firstChildTemplate.template,
                storeId: firstChildTemplate.storeId || snapshot.params.storeId,
            };
        }

        return { template: path, storeId: snapshot.params.storeId };
    }
}
