import { Component, OnDestroy, OnInit } from '@angular/core';
import { EnableRequestLoggerInput } from '../../../schema-dotnet';
import { ViewContextService } from '../../navigation/view-context.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'admin-add-request-logger-rule-dialog',
    templateUrl: './add-request-logger-rule-dialog.component.html',
    styleUrls: ['./add-request-logger-rule-dialog.component.scss']
})
export class AddRequestLoggerRuleDialogComponent implements OnInit, OnDestroy {
    subscriptions = new Subscription();
    ruleInfo: EnableRequestLoggerInput = {
        routePrefix: '/',
        statusCode: 400,
        maximumCaptureCount: 10,
        logsExpirationInSeconds: 3600
    };

    constructor(
        public viewContextService: ViewContextService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.viewContextService.context$.subscribe(context => {
            console.warn(context)
            if (context.type == 'store') {
                this.ruleInfo.storeId = context.storeId;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
