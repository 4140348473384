import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ConfirmDialogData {
    title: string;
    message: string;
    subMessage?: string;
    options?: ConfirmDialogDataOptions;
}

export interface ConfirmDialogDataOptions {

    yes?: {
        label?: string;
        warn?: boolean;
    },
    no?: {
        label?: string;
    }
}

@Component({
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    }
}
