import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {VolumeTimelineReportRow} from '../../schema-dotnet';
import {map} from 'rxjs/operators';
import {GraphDotnetService} from './graph-dotnet-service';

@Injectable()
export class ReportsService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    getVolumeTimelineReport(storeId: string): Observable<VolumeTimelineReportRow[]> {
        return this.graphDotnetService.query(`
                query getVolumeTimelineReport($storeId: String!) {
                    store(id: $storeId) {
                        volumeTimelineReport {
                            timestampUtc
                            projectCount
                            orderCount
                        }
                    }
                }
            `,
            {storeId}
        ).pipe(map(x => x.store.volumeTimelineReport));
    }
}
