import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from 'app/services';
import { inject } from '@angular/core';

let savedReturnUrl: string | undefined;

export const signInPageGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : UrlTree | boolean => {
    const router = inject(Router);
    const authService = inject(AuthenticationService);

    if (savedReturnUrl) {
        return router.parseUrl(savedReturnUrl);
    }
    // Restore url before login
    const returnUrl = authService.getReturnUrl();
    // Avoid infinite loop
    if (returnUrl === state.url) {
        return router.parseUrl('/');
    }
    // Redirect to previous page
    if (returnUrl) {
        savedReturnUrl = returnUrl;
        return router.parseUrl(returnUrl);
    }
    // Fallback to home page
    return router.parseUrl('/');
};
