import { Component, OnInit } from '@angular/core';
import { Maybe, Product, Theme } from 'schema-dotnet';
import { PageStatus } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { LibraryService } from '../../services';
import { environment } from '../../../environments/environment';
import { GitLabUrlBuilder } from "../../utils/git-lab-url-builder.util";

@Component({
    selector: 'admin-product-page',
    templateUrl: './product-page.component.html',
    styleUrls: ['./product-page.component.scss'],
})
export class ProductPageContainerComponent implements OnInit {
    storeId: string;
    productId: string;

    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;

    product?: Maybe<Product>;
    filteredThemes: Theme[] = [];

    filter: string;
    showPreview: boolean;
    selectedOptions: Record<string, string> = {};

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly libraryService: LibraryService,
        private readonly gitLabUrlBuilder: GitLabUrlBuilder,
    ) {
        this.storeId = this.route.snapshot.params.storeId;
        this.productId = this.route.snapshot.queryParams.id;
    }

    ngOnInit(): void {
        this.libraryService.getProduct(this.storeId, this.productId).subscribe({
            next: (product) => {
                this.product = product;
                if (this.product?.options)
                    this.selectedOptions = this.product.options.reduce((selectedOptions, option) => {
                        selectedOptions[option.name] = option.default
                        return selectedOptions;
                    }, {} as Record<string, string>);
                else
                    this.selectedOptions = {};
                this.updateFilter();
                this.pageStatus = PageStatus.loaded;
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        })
    }

    updateFilter() {
        if (!this.product){
            return;
        }
        if (!this.product.themes){
            return;
        }
        if (this.filter) {
            this.filteredThemes = this.product.themes.filter(p =>
                p.id.includes(this.filter) || p.label?.includes(this.filter)
            );
        } else {
            this.filteredThemes = this.product.themes;
        }
    }

    getGitlabLink(productId: string) {
        return this.gitLabUrlBuilder.GetResourceUrl(productId);
    }

    previewUrl(theme: Theme): string | undefined {
        if (!this.product){
            return undefined;
        }
        if (!this.product.module) {
            return undefined;
        }
        let productId = this.productId;
        if (this.product.options?.length) {
            productId = this.productId.substring(0, this.productId.indexOf('['));
            productId += '[';
            for (let option of this.product.options) {
                productId += `${option.name}:${this.selectedOptions[option.name]},`;
            }
            productId = productId.substring(0, productId.length - 1);
            productId += ']';
        }

        let url = new URL('/api/theme/preview', environment.hub.renderUrl);
        url.searchParams.append('module', this.product.module);
        url.searchParams.append('product', productId);
        url.searchParams.append('theme', theme.id);
        url.searchParams.append('width', '600');
        url.searchParams.append('height', '400');
        url.searchParams.append('data-storeId', this.storeId);

        return url.toString();
    }
}
