<admin-page-simple-content id="external-photo-sources"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Subscription: ' + (subscription?.label || subscriptionId)"
  [icon]="'receipt'">
  <!-- CONTENT -->
  @if (subscription) {
    <div class="content" fusePerfectScrollbar>
      <h2>Information</h2>
      @if (!subscription.active) {
        <p class="deactivated-warning">
          <mat-icon color="warn">warning</mat-icon>
          Subscription is not active.
          @if (subscription.deactivationDateUtc) {
            It was deactivated on {{ subscription.deactivationDateUtc | date:'mediumDate' }}
            ({{ subscription.deactivationDateUtc | fromNow }}).
          }
        </p>
      }
      <table class="subscription-info-table">
        <tr>
          <td>Subscription ID</td>
          <td>
            <code adminCopyToClipboard>{{ subscription.id }}</code>
          </td>
        </tr>
        <tr>
          <td>Name</td>
          <td adminCopyToClipboard>
            {{ subscription.label }}
          </td>
        </tr>
        <tr>
          <td>Contact</td>
          <td adminCopyToClipboard>
            {{ subscription.contact }}
          </td>
        </tr>
        @if (subscription.dateCreatedUtc) {
          <tr>
            <td>Date Created</td>
            <td>
              {{ subscription.dateCreatedUtc | date:'mediumDate' }} ({{ subscription.dateCreatedUtc | fromNow }})
            </td>
          </tr>
        }
        @if (subscription.notes) {
          <tr>
            <td>Notes</td>
            <td>
              <div class="subscription-notes">{{ subscription.notes }}</div>
            </td>
          </tr>
        }
      </table>
      <h2>Actions</h2>
      <div class="actions">
        @if (canManageSubscription()) {
          <button mat-stroked-button
            (click)="editInfo(subscription)"
            [disabled]="pageStatus !== 'loaded'">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
        }
        @if (subscription.active && canDeactivateSubscription()) {
          <button mat-stroked-button
            (click)="openDeactivationDialog(subscription)"
            color="warn"
            [disabled]="pageStatus !== 'loaded'">
            <mat-icon>unpublished</mat-icon>
            Deactivate
          </button>
        }
      </div>
      <h2>Stores</h2>
      <admin-subscription-stores-component [subscription]="subscription"
        [auth]="auth">
      </admin-subscription-stores-component>
    </div>
  }

</admin-page-simple-content>
