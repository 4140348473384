import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageStatus } from 'app/models';
import { AuthorizationContext, AuthorizationService, StoreSecretsService } from 'app/services';
import { ActivatedRoute } from '@angular/router';
import { AppSecret, CreateAppSecret, Store, StorePermission } from '../../../schema-dotnet';
import { PromptDialogService } from '../prompt-dialog/prompt-dialog.service';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { AppSecretService } from '../../services/app-secret.service';

@Component({
    templateUrl: './store-secrets-page.component.html',
    styleUrls: ['./store-secrets-page.component.scss']
})
export class StoreSecretsPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    authorizationContext: AuthorizationContext;

    storeId: String = '';
    secrets: AppSecret[] = [];
    storeWithSecrets?: Store;
    lastCreatedSecret?: CreateAppSecret;

    constructor(
        private readonly storeSecretsService: StoreSecretsService,
        private readonly appSecretService: AppSecretService,
        private readonly route: ActivatedRoute,
        private readonly promptService: PromptDialogService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly authorizationService: AuthorizationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.registerSubscription(this.route.params.subscribe(params => {
                const storeId = params.storeId;
                this.storeId = storeId;
                this.secrets = [];
                this.subscribeWithGenericLoadingErrorHandling(this.storeSecretsService.getStoreSecrets(storeId), (storeWithSecrets) => {
                    this.storeWithSecrets = storeWithSecrets;
                    this.secrets = storeWithSecrets?.secrets || [];
                });
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    revokeSecret(storeId: string, secret: AppSecret) {
        this.confirmDialogService
            .confirm(`Revoke secret ${secret.label}`, `Do you really want to revoke the secret ${secret.label} for the store ${storeId} ?`, undefined, { yes: { warn: true } })
            .subscribe(_ => {
            this.subscribeWithGenericSavinErrorHandling(this.appSecretService.revokeSecret({ appId: storeId, id: secret.id }), () => {
                const index = this.secrets.findIndex(s => s.id == secret.id);
                if (index !== -1) {
                    this.secrets.splice(index, 1);
                }
            });
        });
    }

    createSecret(storeId: string) {
        this.promptService.prompt('Create a new secret', undefined, {defaultValue: this.secrets.length == 0 ? 'default' : undefined, fieldLabel: 'Label'})
            .subscribe(result => {
                this.pageStatus = PageStatus.saving;
                if (this.secrets.find(s => s.label == result)) {
                    this.pageError = new Error('This label is already used by another secret');
                    this.pageStatus = PageStatus.saveError;
                    return;
                }
                this.subscribeWithGenericSavinErrorHandling(this.appSecretService.createAppSecret({ appId: storeId, label: result }), secret => {
                    this.secrets.push({ id: secret.id, label: secret.label });
                    this.lastCreatedSecret = secret;
                });
            });
    }

    canManageSecrets(store: Store): boolean {
        return this.authorizationContext.hasStorePermissions(store.id, store.subscriptionId, StorePermission.ManageStoreSecrets);
    }
}
