<admin-page-simple-content id="store-fulfillers"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Fulfillers'"
  [icon]="'print'">
  <div class="content">
    <div class="header">
      <mat-form-field class="dense-2" floatLabel="always">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Filter</mat-label>
        <input matInput
          (keydown)="updateFilter()"
          (keyup)="updateFilter()"
          [(ngModel)]="filter.name">
      </mat-form-field>
      @if (canCreateFulfiller()) {
        <button mat-stroked-button (click)="addFulfiller()">
          <mat-icon>add</mat-icon>
          Add fulfiller
        </button>
      }
    </div>

    @if (pageStatus === 'saveError' && pageError) {
      <admin-error [error]="pageError"></admin-error>
    }

    <table mat-table #fulfillersTable="matTable" [dataSource]="displayedFulfillers" [trackBy]="fulfillerId" class="mat-elevation-z4">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let fulfiller">
          <a [routerLink]="'./' + fulfiller.id ">{{fulfiller.id}}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="libraryPackage">
        <th mat-header-cell *matHeaderCellDef>Library Package</th>
        <td mat-cell *matCellDef="let fulfiller" adminCopyToClipboard>
          {{fulfiller.libraryPackage}}
        </td>
      </ng-container>
      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let fulfiller" adminCopyToClipboard>
          {{fulfiller.client}}
        </td>
      </ng-container>
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef>Url</th>
        <td mat-cell *matCellDef="let fulfiller" adminCopyToClipboard>
          {{fulfiller.url}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</admin-page-simple-content>
