<admin-page-simple-content id="store-generated-text"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Generated Texts'"
  [icon]="'text_fields'">
  <div class="content">
    @if (hasConfigDefined) {
      <div>
        <h2>Actions</h2>
        <button mat-stroked-button
          color="warn"
          (click)="deleteConfig()"
          [disabled]="pageStatus === 'saving'">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
      </div>
    }

    <h2>Configuration</h2>

    <form #form="ngForm" (ngSubmit)="save()">

      <mat-form-field class="dense-2">
        <mat-label>Access Token</mat-label>
        <input matInput
          name="accessToken"
          #secretInput
          [(ngModel)]="currentGeneratedTextConfig.accessToken"
          autocomplete="off"
          required/>
        <admin-secret-input-toggle matSuffix [secretInput]="secretInput"/>
      </mat-form-field>

      <mat-form-field class="dense-2">
        <mat-label>Organization</mat-label>
        <input matInput
          name="organization"
          [(ngModel)]="currentGeneratedTextConfig.organization"
          autocomplete="off"
          required/>
      </mat-form-field>

      <admin-save-button
        (save)="save()"
        [disabled]="!form.valid"
        [pageStatus]="pageStatus">
      </admin-save-button>
    </form>
  </div>

  <div>
    @if (pageStatus === 'saveError' && pageError) {
      <admin-error [error]="pageError"></admin-error>
    }
  </div>

</admin-page-simple-content>
