export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  TimeSpan: { input: any; output: any; }
  URL: { input: any; output: any; }
  UUID: { input: string; output: string; }
  Void: { input: any; output: any; }
};

export type AccessManagementInput = {
  downloads?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  fulfillers?: InputMaybe<Array<FulfillerAccessInput>>;
  global?: InputMaybe<Array<Scalars['String']['input']>>;
  libraries?: InputMaybe<Array<LibraryAccessInput>>;
  stores?: InputMaybe<Array<StoreAccessInput>>;
  subscriptions?: InputMaybe<Array<SubscriptionAccessInput>>;
};

export type AddDesignerBranchRuleInput = {
  designerBranch: Scalars['String']['input'];
  label: Scalars['String']['input'];
  module?: InputMaybe<DesignerModuleName>;
  storeId: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
};

export type AddStoreStockPhotoSourceInput = {
  photoSourceId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type AppSecret = {
  __typename?: 'AppSecret';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export enum ApplicationInsightsSeverityLevel {
  Error = 'Error',
  Fatal = 'Fatal',
  Information = 'Information',
  Trace = 'Trace',
  Warning = 'Warning'
}

export type ArchivedOrder = {
  __typename?: 'ArchivedOrder';
  dateCreatedUtc: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  lines: Array<ArchivedOrderLine>;
  store: Store;
  storeOrderId: Scalars['String']['output'];
};

/** A specific archived order line from the shopping cart that was ordered (typically, one project) */
export type ArchivedOrderLine = {
  __typename?: 'ArchivedOrderLine';
  dateCreatedUtc: Scalars['DateTime']['output'];
  /** The fulfiller assigned to this line, if any */
  fulfiller?: Maybe<Fulfiller>;
  fulfillerHubLineNumber: Scalars['Int']['output'];
  /** An unique ID created by Mediaclip Hub */
  id: Scalars['UUID']['output'];
  /** Historical information about what happened with this line */
  logs: Array<ArchivedOrderLineLog>;
  /** The order in which that line exists */
  order: ArchivedOrder;
  /** Pricing information attached to this order line for revenue share purposes (see https://doc.mediacliphub.com/pages/Getting%20started/billing.html) */
  price?: Maybe<OrderLinePrice>;
  /** The project version associated to the line order */
  projectVersion?: Maybe<ArchivedProjectVersion>;
  quantity: Scalars['Int']['output'];
  /** The state of this line */
  status?: Maybe<OrderLineStatus>;
  /** The store for which the order was made */
  store: Store;
  storeOrderLineId?: Maybe<Scalars['String']['output']>;
  storeOrderLineNumber: Scalars['Int']['output'];
  storeProductId: Scalars['String']['output'];
};

export type ArchivedOrderLineLog = {
  __typename?: 'ArchivedOrderLineLog';
  message?: Maybe<Scalars['String']['output']>;
  timestampUtc: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type ArchivedProjectVersion = {
  __typename?: 'ArchivedProjectVersion';
  additionalSheetCount?: Maybe<Scalars['Int']['output']>;
  sheetCount?: Maybe<Scalars['Int']['output']>;
};

export type Auth0User = {
  __typename?: 'Auth0User';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
  picture: Scalars['String']['output'];
};

export type AuthenticatedUser = {
  __typename?: 'AuthenticatedUser';
  email: Scalars['String']['output'];
  fulfillerPermissions: Array<UserFulfillerPermissions>;
  fulfillers: Array<Fulfiller>;
  globalPermissions: UserGlobalPermissions;
  libraries: Array<Library>;
  libraryPermissions: Array<UserLibraryPermissions>;
  picture: Scalars['String']['output'];
  storePermissions: Array<UserStorePermissions>;
  stores: Array<Store>;
  subscriptionPermissions: Array<UserSubscriptionPermissions>;
  subscriptions: Array<Subscription>;
};

export type AvailableRoles = {
  __typename?: 'AvailableRoles';
  fulfillerRoles: Array<FulfillerRole>;
  globalRoles: Array<GlobalRole>;
  libraryRoles: Array<LibraryRole>;
  storeRoles: Array<StoreRole>;
  subscriptionRoles: Array<SubscriptionRole>;
};

export type BuiltInExternalPhotoSourceConfig = {
  __typename?: 'BuiltInExternalPhotoSourceConfig';
  appId: Scalars['String']['output'];
  data: Array<BuiltInExternalPhotoSourceData>;
  details?: Maybe<Scalars['String']['output']>;
  icon: Scalars['String']['output'];
  label: Scalars['String']['output'];
  photoSourceId: Scalars['String']['output'];
  status: ExternalPhotoSourceStatus;
  store: Store;
};

export type BuiltInExternalPhotoSourceData = {
  __typename?: 'BuiltInExternalPhotoSourceData';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type BuiltInExternalPhotoSourceDataInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type BuiltInExternalPhotoSourceDefinition = {
  __typename?: 'BuiltInExternalPhotoSourceDefinition';
  iconUrl: Scalars['String']['output'];
  label: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
};

export type CancelSubscriptionPendingProjectExpirationsInput = {
  subscriptionId: Scalars['UUID']['input'];
};

export type ConsolidateUser = {
  __typename?: 'ConsolidateUser';
  identifiedHubUserId: Scalars['UUID']['output'];
};

export type ConsolidateUserInput = {
  anonymousStoreUserId: Scalars['String']['input'];
  identifiedStoreUserId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type CreateAppSecret = {
  __typename?: 'CreateAppSecret';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  secret: Scalars['String']['output'];
};

export type CreateAppSecretInput = {
  appId: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type CreateBuiltInExternalPhotoSourceInput = {
  appId: Scalars['String']['input'];
  appSecret: Scalars['String']['input'];
  data: Array<BuiltInExternalPhotoSourceDataInput>;
  photoSourceId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type CreateDirectPhotoSourceInput = {
  name: Scalars['String']['input'];
  photoEndpoint: EndpointInput;
  storeId: Scalars['String']['input'];
};

export type CreateFilterCategoryInput = {
  builtInFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  label: Scalars['String']['input'];
  remoteFilters?: InputMaybe<Array<Scalars['UUID']['input']>>;
  storeId: Scalars['String']['input'];
};

export type CreateFulfillerAppInput = {
  displayName: Scalars['String']['input'];
};

export type CreateFulfillerInput = {
  client: FulfillerClient;
  fulfillerAppId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  libraryPackage?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGeneratedImageDefinitionInput = {
  configs?: InputMaybe<GeneratedImageDataInput>;
  defaultAlias: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
  kind: GeneratedImageKind;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxWidth?: InputMaybe<Scalars['Int']['input']>;
  preserveAspectRatio: Scalars['Boolean']['input'];
  subscriptionId?: InputMaybe<Scalars['UUID']['input']>;
  warning?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGeneratedImageStoreAssociationInput = {
  alias: Scalars['String']['input'];
  configs?: InputMaybe<GeneratedImageDataInput>;
  generatedImageDefinitionId: Scalars['UUID']['input'];
  storeId: Scalars['String']['input'];
};

export type CreateGenericExternalPhotoSourceInput = {
  albumUrl: Scalars['String']['input'];
  appId: Scalars['String']['input'];
  appSecret: Scalars['String']['input'];
  authorizationUrl: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  label: Scalars['String']['input'];
  photoSourceId: Scalars['String']['input'];
  photoUrl: Scalars['String']['input'];
  scopes?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  tokenUrl: Scalars['String']['input'];
};

export type CreateGenericRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<GenericRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CreateOrderInput = {
  /** The JSON to use to create the order */
  checkoutJson: Scalars['String']['input'];
  /** Whether to start the rendering process right away */
  release?: InputMaybe<Scalars['Boolean']['input']>;
  /** The store for which the order should be created */
  storeId: Scalars['String']['input'];
};

export type CreatePerfectlyClearRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<PerfectlyClearRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CreatePicsartEffectsRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<PicsartEffectsRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CreatePicsartRemoveBackgroundRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<PicsartRemoveBackgroundRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CreatePicsartStyleTransferRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<PicsartStyleTransferRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CreateProject = {
  __typename?: 'CreateProject';
  id: Scalars['UUID']['output'];
};

export type CreateProjectInput = {
  hubUserId: Scalars['UUID']['input'];
  /** Override default categorizations for this project. */
  libraryPackage?: InputMaybe<Scalars['String']['input']>;
  /** The Designer module associated to the project (possible values: Photobook, Gifting or Print) */
  module: DesignerModuleName;
  /** Options to provide to JavaScript themes */
  options?: InputMaybe<Array<ProjectPropertyInput>>;
  /** An array of Photo URNs to autofill the project with. */
  photos?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The library of content’s Product ID to initialize the Designer */
  productId?: InputMaybe<Scalars['String']['input']>;
  /** Custom store data property bag. (key & value limited to 255 characters each) */
  properties?: InputMaybe<Array<ProjectPropertyInput>>;
  /** A pre-selected library of content’s ThemeURL. If present, will skip the theme selection step in the Designer */
  themeUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProjectProductIdMigrationInput = {
  destinationProductId: Scalars['String']['input'];
  sourceProductId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type CreateStockPhotoSourceInput = {
  albumsEndpoint?: InputMaybe<StockPhotoSourceEndpointInput>;
  id: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  photosEndpoint: StockPhotoSourceEndpointInput;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  visible: Scalars['Boolean']['input'];
};

export type CreateStoreFulfillerInput = {
  configs: Array<KeyValuePairOfStringAndStringInput>;
  fulfillerId: Scalars['String']['input'];
  isDefault: Scalars['Boolean']['input'];
  storeId: Scalars['String']['input'];
};

export type CreateStoreInput = {
  appId?: InputMaybe<Scalars['String']['input']>;
  designerReleaseChannel: Scalars['String']['input'];
  environment: StoreEnvironment;
  label?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StoreStatus>;
  subscriptionId: Scalars['UUID']['input'];
};

export type CreateStoreSecretInput = {
  /** Text to help identify that secret */
  label: Scalars['String']['input'];
  /** The store in which the secret will be created */
  storeId: Scalars['String']['input'];
};

export type CreateStoreUrlSigningKey = {
  __typename?: 'CreateStoreUrlSigningKey';
  algorithm: Scalars['String']['output'];
  encodedSigningKey: Scalars['String']['output'];
  format: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  signingKey: Scalars['String']['output'];
  storeId: Scalars['String']['output'];
};

export type CreateStoreUrlSigningKeyInput = {
  format?: InputMaybe<SigningKeyFormat>;
  label: Scalars['String']['input'];
  passphrase?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
};

export type CreateSubscriptionInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  admins?: InputMaybe<Array<Scalars['String']['input']>>;
  billing?: InputMaybe<SubscriptionBillingInfoInput>;
  contact?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  label: Scalars['String']['input'];
  licenses?: InputMaybe<SubscriptionLicensesInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  isAnonymous: Scalars['Boolean']['input'];
  storeId: Scalars['String']['input'];
  storeUserId: Scalars['String']['input'];
};

export type CustomerSupportToken = {
  __typename?: 'CustomerSupportToken';
  expirationUtc: Scalars['DateTime']['output'];
  issueDateUtc: Scalars['DateTime']['output'];
  scheme: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['UUID']['output'];
};

export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type DeactivateStoreInput = {
  id: Scalars['String']['input'];
};

export type DeactivateSubscriptionInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteAppSecretInput = {
  appId: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type DeleteBuiltInExternalPhotoSourceInput = {
  photoSourceId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type DeleteDesignerBranchRuleInput = {
  ruleId: Scalars['UUID']['input'];
  storeId: Scalars['String']['input'];
};

export type DeleteFilterCategoryInput = {
  filterCategoryId: Scalars['UUID']['input'];
  storeId: Scalars['String']['input'];
};

export type DeleteGeneratedImageEditorFileInput = {
  definitionId: Scalars['UUID']['input'];
  filename: Scalars['String']['input'];
};

export type DeleteGenericExternalPhotoSourceInput = {
  photoSourceId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type DeleteProjectInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteStockPhotoSourceInput = {
  id: Scalars['String']['input'];
};

export type DeleteStoreFulfillerInput = {
  fulfillerId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type DeleteStoreGeneratedTextInput = {
  storeId: Scalars['String']['input'];
};

export type DeleteStorePhotoUpscalingInput = {
  storeId: Scalars['String']['input'];
};

export type DeleteStoreSecretInput = {
  /** The secret number */
  id: Scalars['Int']['input'];
  /** The store in which the secret was created */
  storeId: Scalars['String']['input'];
};

export type DeleteStoreUrlSigningKeyInput = {
  id: Scalars['Int']['input'];
  storeId: Scalars['String']['input'];
};

export type DeleteSubscriptionInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteUserInput = {
  id: Scalars['UUID']['input'];
};

export type DesignerBranch = {
  __typename?: 'DesignerBranch';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export enum DesignerModuleName {
  Gifting = 'Gifting',
  Photobook = 'Photobook',
  Print = 'Print'
}

export type DesignerSelectorRule = {
  __typename?: 'DesignerSelectorRule';
  designerBranch: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  label: Scalars['String']['output'];
  module?: Maybe<DesignerModuleName>;
  weight: Scalars['Float']['output'];
};

export type DirectPhotoSource = {
  __typename?: 'DirectPhotoSource';
  name: Scalars['String']['output'];
  photoEndpoint: Endpoint;
};

export type DownloadPath = {
  __typename?: 'DownloadPath';
  path: Scalars['String']['output'];
};

export type DownloadProjectVersionInput = {
  projectVersionId: Scalars['Int']['input'];
};

export type EnableRequestLoggerInput = {
  logsExpirationInSeconds?: InputMaybe<Scalars['Int']['input']>;
  maximumCaptureCount?: InputMaybe<Scalars['Int']['input']>;
  routePrefix: Scalars['String']['input'];
  statusCode?: InputMaybe<Scalars['Int']['input']>;
  storeId?: InputMaybe<Scalars['String']['input']>;
};

export type Endpoint = {
  __typename?: 'Endpoint';
  headers: Array<HttpHeader>;
  url?: Maybe<Scalars['String']['output']>;
};

export type EndpointHeaderInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type EndpointInput = {
  headers?: InputMaybe<Array<EndpointHeaderInput>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum ExternalPhotoSourceStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Invalid = 'Invalid',
  Undefined = 'Undefined'
}

export type FileEditorInformation = {
  __typename?: 'FileEditorInformation';
  filename: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FilterCategory = {
  __typename?: 'FilterCategory';
  builtInFilters: Array<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  label: Scalars['String']['output'];
  remoteFilters: Array<RemoteFilter>;
};

export type ForceRerenderOrderLineInput = {
  lineId: Scalars['UUID']['input'];
};

export type Fulfiller = {
  __typename?: 'Fulfiller';
  authorizedUsers: Array<FulfillerUserRole>;
  /** The API used to call the fulfiller */
  client?: Maybe<FulfillerClient>;
  form?: Maybe<FulfillerForm>;
  /** Which Hub App ID will be used to authorize this fulfiller's status callbacks to Hub */
  fulfillerAppId?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for that fulfiller (the same organization can have multiple identifiers, e.g. for staging and production) */
  id: Scalars['String']['output'];
  /** When specified, determines which rendering-settings file will be used when rendering store orders */
  libraryPackage?: Maybe<Scalars['String']['output']>;
  /** Secrets associated to this fulfiller app */
  secrets: Array<AppSecret>;
  /** The base URL for the fulfiller API */
  url?: Maybe<Scalars['String']['output']>;
};

export type FulfillerAccessInput = {
  id: Scalars['String']['input'];
  roles: Array<Scalars['String']['input']>;
};

export type FulfillerApp = {
  __typename?: 'FulfillerApp';
  /** The display name for the fulfiller app to identify it in the interface */
  displayName: Scalars['String']['output'];
  /** The fulfiller App ID, or App Key, used to authenticate server-to-server API calls to api.mediacliphub.com */
  id: Scalars['String']['output'];
};

export enum FulfillerClient {
  Cloudprinter = 'Cloudprinter',
  Default = 'Default',
  Fuji = 'Fuji',
  NewFuji = 'NewFuji',
  OneFlow = 'OneFlow',
  Polling = 'Polling'
}

export type FulfillerForm = {
  __typename?: 'FulfillerForm';
  documentationLink?: Maybe<Scalars['String']['output']>;
  fields: Array<FulfillerFormField>;
};

export type FulfillerFormField = {
  __typename?: 'FulfillerFormField';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  sampleValue?: Maybe<Scalars['String']['output']>;
  type?: Maybe<FulfillerFormFieldType>;
};

export enum FulfillerFormFieldType {
  CarrierIdentifier = 'CarrierIdentifier',
  Json = 'Json',
  Password = 'Password',
  Text = 'Text'
}

export type FulfillerLog = {
  __typename?: 'FulfillerLog';
  data: Scalars['String']['output'];
  dateCreatedUtc: Scalars['DateTime']['output'];
  /** Which fulfiller will receive orders for that store */
  fulfiller: Fulfiller;
  /** An unique ID created by Mediaclip Hub */
  id: Scalars['Int']['output'];
  orderId: Scalars['UUID']['output'];
  /** To which store does this association applies */
  store: Store;
  type: FulfillerLogType;
};

export enum FulfillerLogType {
  Request = 'Request',
  Response = 'Response'
}

export enum FulfillerPermission {
  ManageAdminUsers = 'ManageAdminUsers',
  ManageFulfillerAppSecrets = 'ManageFulfillerAppSecrets',
  ManageFulfillerConfig = 'ManageFulfillerConfig',
  ReadAdminUsers = 'ReadAdminUsers',
  ReadFulfillerAppSecrets = 'ReadFulfillerAppSecrets',
  ReadFulfillerConfig = 'ReadFulfillerConfig'
}

export enum FulfillerRole {
  Admin = 'Admin'
}

export type FulfillerUserAccessInput = {
  email: Scalars['String']['input'];
  fulfillerAccess: FulfillerAccessInput;
};

export type FulfillerUserRole = {
  __typename?: 'FulfillerUserRole';
  roles: Array<FulfillerRole>;
  user?: Maybe<Auth0User>;
};

export type GenerateCustomerSupportTokenInput = {
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  renderingJobId?: InputMaybe<Scalars['UUID']['input']>;
  storeId: Scalars['String']['input'];
  storeOrderId?: InputMaybe<Scalars['String']['input']>;
  storeOrderLineNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type GenerateProjectVersionInput = {
  projectId: Scalars['UUID']['input'];
};

export type GeneratedImageConfigProperty = {
  __typename?: 'GeneratedImageConfigProperty';
  isSecret: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GeneratedImageData = {
  __typename?: 'GeneratedImageData';
  additionalProperties: Array<GeneratedImageConfigProperty>;
  editorParameters: Array<GeneratedImageConfigProperty>;
  headers: Array<GeneratedImageConfigProperty>;
};

export type GeneratedImageDataInput = {
  additionalProperties: Array<GeneratedImageDataPropertyInput>;
  editorParameters: Array<GeneratedImageDataPropertyInput>;
  headers: Array<GeneratedImageDataPropertyInput>;
};

export type GeneratedImageDataPropertyInput = {
  isSecret: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type GeneratedImageDefinition = {
  __typename?: 'GeneratedImageDefinition';
  configs: GeneratedImageData;
  defaultAlias: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  endpoint: Scalars['URL']['output'];
  fileEditorInformation: Array<FileEditorInformation>;
  id: Scalars['UUID']['output'];
  kind: GeneratedImageKind;
  maxHeight?: Maybe<Scalars['Int']['output']>;
  maxWidth?: Maybe<Scalars['Int']['output']>;
  preserveAspectRatio: Scalars['Boolean']['output'];
  subscriptionId?: Maybe<Scalars['UUID']['output']>;
  version: Scalars['String']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export enum GeneratedImageKind {
  Generic = 'Generic',
  MapBox = 'MapBox',
  QrCode = 'QrCode'
}

export type GeneratedImageStoreAssociation = {
  __typename?: 'GeneratedImageStoreAssociation';
  alias: Scalars['String']['output'];
  configs: GeneratedImageData;
  definition: GeneratedImageDefinition;
  id: Scalars['UUID']['output'];
  storeId: Scalars['String']['output'];
};

export type GeneratedTextConfiguration = {
  __typename?: 'GeneratedTextConfiguration';
  accessToken: Scalars['String']['output'];
  organization: Scalars['String']['output'];
};

export type GenericExternalPhotoSourceConfig = {
  __typename?: 'GenericExternalPhotoSourceConfig';
  albumUrl: Scalars['String']['output'];
  appId: Scalars['String']['output'];
  authorizationUrl: Scalars['String']['output'];
  details?: Maybe<Scalars['String']['output']>;
  icon: Scalars['String']['output'];
  label: Scalars['String']['output'];
  photoSourceId: Scalars['String']['output'];
  photoUrl: Scalars['String']['output'];
  scopes?: Maybe<Scalars['String']['output']>;
  status: ExternalPhotoSourceStatus;
  store: Store;
  tokenUrl: Scalars['String']['output'];
};

export type GenericRemoteFilterData = {
  __typename?: 'GenericRemoteFilterData';
  headers?: Maybe<Array<HttpHeader>>;
  url: Scalars['String']['output'];
};

export type GenericRemoteFilterDataInput = {
  headers?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  url: Scalars['String']['input'];
};

export enum GlobalPermission {
  AllowSetOrderToAnyStatus = 'AllowSetOrderToAnyStatus',
  CreateSubscription = 'CreateSubscription',
  DeleteSubscription = 'DeleteSubscription',
  DownloadProjects = 'DownloadProjects',
  ListAvailableDownloadPaths = 'ListAvailableDownloadPaths',
  ListFulfillers = 'ListFulfillers',
  ListLibraries = 'ListLibraries',
  ListPrivateDesignerReleaseChannels = 'ListPrivateDesignerReleaseChannels',
  ListStockPhotoSources = 'ListStockPhotoSources',
  ListStores = 'ListStores',
  ListSubscriptions = 'ListSubscriptions',
  ManageAdminUsers = 'ManageAdminUsers',
  ManageAppSecrets = 'ManageAppSecrets',
  ManageDesignerBranch = 'ManageDesignerBranch',
  ManageFulfillers = 'ManageFulfillers',
  ManageGeneratedImages = 'ManageGeneratedImages',
  ManageRequestLogger = 'ManageRequestLogger',
  ManageStockPhotoSources = 'ManageStockPhotoSources',
  ReadAdminUsers = 'ReadAdminUsers',
  ReadApplicationInsightsLogs = 'ReadApplicationInsightsLogs',
  ReadGeneratedImages = 'ReadGeneratedImages',
  SystemAnomalies = 'SystemAnomalies'
}

export enum GlobalRole {
  AccountManager = 'AccountManager',
  BillingManager = 'BillingManager',
  Doc = 'Doc',
  LibraryManager = 'LibraryManager',
  SuperAdmin = 'SuperAdmin'
}

export type HttpHeader = {
  __typename?: 'HttpHeader';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type HubStoreLog = {
  __typename?: 'HubStoreLog';
  appName: Scalars['String']['output'];
  firstOccurrenceUtc: Scalars['DateTime']['output'];
  lastOccurrenceUtc: Scalars['DateTime']['output'];
  level: LogLevel;
  message: Scalars['String']['output'];
  occurrences: Scalars['Int']['output'];
  openItemQueryUrl?: Maybe<Scalars['String']['output']>;
  openOperationQueryUrl?: Maybe<Scalars['String']['output']>;
  operation?: Maybe<Scalars['String']['output']>;
  runs: Scalars['Int']['output'];
  timestampUtc: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  users: Scalars['Int']['output'];
};

export type InvalidatePhotosCache = {
  __typename?: 'InvalidatePhotosCache';
  count: Scalars['Int']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  /** The currency of this invoice. Exchange rates will be applied on other currencies. */
  currency: Scalars['String']['output'];
  format: TabularFormats;
  generated?: Maybe<Scalars['DateTime']['output']>;
  /** The number of invoice files */
  invoiceFileCount: Scalars['Int']['output'];
  /** The invoiced total, based on the generated report */
  invoiced?: Maybe<Scalars['Decimal']['output']>;
  /** The adjusted invoiced total, if the invoiced value was incorrect */
  invoicedAdjusted?: Maybe<Scalars['Decimal']['output']>;
  /** The amount of billable order lines (excludes canceled) */
  linesCount?: Maybe<Scalars['Int']['output']>;
  /** The month number (1 is January) */
  month: Scalars['Int']['output'];
  /** The base monthly fee for that invoiced month */
  monthlyFee?: Maybe<Scalars['Decimal']['output']>;
  /** The total registered revenue converted in the invoice currency */
  revenue?: Maybe<Scalars['Decimal']['output']>;
  status?: Maybe<InvoiceStatus>;
  subscriptionId: Scalars['UUID']['output'];
  url?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export enum InvoiceStatus {
  Generated = 'Generated',
  Manual = 'Manual',
  Pending = 'Pending'
}

export type KeyValuePairOfStringAndStringInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Library = {
  __typename?: 'Library';
  authorizedUsers: Array<LibraryUserRole>;
  branches: Array<LibraryBranch>;
  gitBranches: Array<LibraryGitBranch>;
  owner: Scalars['String']['output'];
};

export type LibraryAccessInput = {
  id: Scalars['String']['input'];
  roles: Array<Scalars['String']['input']>;
};

export type LibraryBranch = {
  __typename?: 'LibraryBranch';
  metadata: LibraryBranchMetadata;
  name: Scalars['String']['output'];
  scheduledUpdates: LibraryScheduleUpdates;
};

export type LibraryBranchMetadata = {
  __typename?: 'LibraryBranchMetadata';
  doNotValidate: Scalars['Boolean']['output'];
  progress?: Maybe<LibraryUpdateProgress>;
  validationCulture?: Maybe<Scalars['String']['output']>;
  validationDefaultPackage?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type LibraryGitBranch = {
  __typename?: 'LibraryGitBranch';
  commitHash: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum LibraryPermission {
  DeployLibrary = 'DeployLibrary',
  ManageAdminUsers = 'ManageAdminUsers',
  ManageLibraryMetadata = 'ManageLibraryMetadata',
  ManageLibrarySchedules = 'ManageLibrarySchedules',
  ReadAdminUsers = 'ReadAdminUsers',
  ReadLibrary = 'ReadLibrary',
  ReadLibrarySchedules = 'ReadLibrarySchedules',
  UnlockLibrary = 'UnlockLibrary'
}

export enum LibraryRole {
  Admin = 'Admin',
  Reader = 'Reader'
}

export type LibraryScheduleUpdate = {
  __typename?: 'LibraryScheduleUpdate';
  hourUtc: Scalars['Int']['output'];
  weekDays: Array<DayOfWeek>;
};

export type LibraryScheduleUpdates = {
  __typename?: 'LibraryScheduleUpdates';
  gitBranch?: Maybe<Scalars['String']['output']>;
  schedules: Array<LibraryScheduleUpdate>;
};

export type LibraryUpdateHubValidation = {
  __typename?: 'LibraryUpdateHubValidation';
  logs: Array<LibraryUpdateHubValidationLog>;
};

export type LibraryUpdateHubValidationLog = {
  __typename?: 'LibraryUpdateHubValidationLog';
  level: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type LibraryUpdateLog = {
  __typename?: 'LibraryUpdateLog';
  duration: Scalars['TimeSpan']['output'];
  stage: Scalars['String']['output'];
};

export type LibraryUpdateProgress = {
  __typename?: 'LibraryUpdateProgress';
  completed: Scalars['Boolean']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  hubValidation?: Maybe<LibraryUpdateHubValidation>;
  lastUpdateDateUtc?: Maybe<Scalars['DateTime']['output']>;
  logs?: Maybe<Array<Maybe<LibraryUpdateLog>>>;
  max?: Maybe<Scalars['Int']['output']>;
  progressSignalRUrl?: Maybe<Scalars['URL']['output']>;
  stage: Scalars['String']['output'];
  totalDuration?: Maybe<Scalars['TimeSpan']['output']>;
  updateId: Scalars['String']['output'];
  validation?: Maybe<LibraryUpdateValidation>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type LibraryUpdateValidation = {
  __typename?: 'LibraryUpdateValidation';
  entries?: Maybe<Array<LibraryUpdateValidationEntry>>;
};

export type LibraryUpdateValidationEntry = {
  __typename?: 'LibraryUpdateValidationEntry';
  category?: Maybe<Scalars['String']['output']>;
  level: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  package?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LibraryUserAccessInput = {
  email: Scalars['String']['input'];
  libraryAccess: LibraryAccessInput;
};

export type LibraryUserRole = {
  __typename?: 'LibraryUserRole';
  roles: Array<LibraryRole>;
  user?: Maybe<Auth0User>;
};

export type LocalFilterConfiguration = {
  __typename?: 'LocalFilterConfiguration';
  allowList?: Maybe<Array<LocalFilterName>>;
  blockList?: Maybe<Array<LocalFilterName>>;
};

export type LocalFilterDefinition = {
  __typename?: 'LocalFilterDefinition';
  displayName: Scalars['String']['output'];
  id: LocalFilterName;
};

export enum LocalFilterName {
  DesaturatedFilter = 'DesaturatedFilter',
  GrayscaleFilter = 'GrayscaleFilter',
  NeutralSepiaFilter = 'NeutralSepiaFilter',
  PolaroidFilter = 'PolaroidFilter',
  SaturatedFilter = 'SaturatedFilter',
  Sepia2Filter = 'Sepia2Filter',
  SepiaFilter = 'SepiaFilter'
}

export enum LocalFilterSelectionMode {
  Allow = 'Allow',
  Block = 'Block'
}

export enum LogLevel {
  Error = 'Error',
  Fatal = 'Fatal',
  Information = 'Information',
  Trace = 'Trace',
  Warning = 'Warning'
}

export type MediaclipMutation = {
  __typename?: 'MediaclipMutation';
  addStoreDesignerBranchRule?: Maybe<DesignerSelectorRule>;
  addStoreStockPhotoSource?: Maybe<Scalars['Void']['output']>;
  cancelSubscriptionPendingProjectExpirations?: Maybe<Scalars['Void']['output']>;
  consolidateUser: ConsolidateUser;
  createAppSecret: CreateAppSecret;
  createBuiltInExternalPhotoSource?: Maybe<BuiltInExternalPhotoSourceConfig>;
  createDirectPhotoSource?: Maybe<Scalars['Void']['output']>;
  createFilterCategory: FilterCategory;
  createFulfiller?: Maybe<Scalars['Void']['output']>;
  createFulfillerApp: FulfillerApp;
  createGeneratedImageDefinition?: Maybe<Scalars['Void']['output']>;
  createGeneratedImageStoreAssociation?: Maybe<Scalars['Void']['output']>;
  createGenericExternalPhotoSource?: Maybe<GenericExternalPhotoSourceConfig>;
  createGenericRemoteFilter?: Maybe<RemoteFilter>;
  /** Create an order from a checkout Json */
  createOrder: Order;
  createPerfectlyClearRemoteFilter?: Maybe<RemoteFilter>;
  createPicsartEffectsRemoteFilter?: Maybe<RemoteFilter>;
  createPicsartRemoveBackgroundRemoteFilter?: Maybe<RemoteFilter>;
  createPicsartStyleTransferRemoteFilter?: Maybe<RemoteFilter>;
  createProject?: Maybe<CreateProject>;
  createProjectProductIdMigration: ProjectProductIdMigration;
  createStockPhotoSource: StockPhotoSourceConfig;
  createStore?: Maybe<Store>;
  createStoreFulfiller?: Maybe<Scalars['Void']['output']>;
  /** @deprecated use createAppSecret */
  createStoreSecret?: Maybe<CreateAppSecret>;
  createStoreUrlSigningKey?: Maybe<CreateStoreUrlSigningKey>;
  createSubscription?: Maybe<Subscription>;
  createUser?: Maybe<User>;
  deactivateStoreAndScheduleDeletionOfUserData?: Maybe<Scalars['Void']['output']>;
  deactivateSubscriptionAndScheduleDeletionOfUserData?: Maybe<Scalars['Void']['output']>;
  deleteAppSecret?: Maybe<Scalars['Void']['output']>;
  deleteBuiltInExternalPhotoSource?: Maybe<Scalars['Void']['output']>;
  deleteFilterCategory?: Maybe<Scalars['Void']['output']>;
  deleteGeneratedImageEditorFile?: Maybe<Scalars['Void']['output']>;
  deleteGeneratedImageStoreAssociation?: Maybe<Scalars['Void']['output']>;
  deleteGenericExternalPhotoSource?: Maybe<Scalars['Void']['output']>;
  deleteProject?: Maybe<Scalars['Void']['output']>;
  deleteStockPhotoSource?: Maybe<Scalars['Void']['output']>;
  deleteStoreDesignerBranchRule?: Maybe<Scalars['Void']['output']>;
  deleteStoreFulfiller?: Maybe<Scalars['Void']['output']>;
  deleteStoreGeneratedText?: Maybe<Scalars['Void']['output']>;
  deleteStorePhotoUpscaling?: Maybe<Scalars['Void']['output']>;
  /** @deprecated use deleteAppSecret */
  deleteStoreSecret?: Maybe<Scalars['Void']['output']>;
  deleteStoreUrlSigningKey?: Maybe<Scalars['Void']['output']>;
  deleteSubscription?: Maybe<Scalars['Void']['output']>;
  deleteUser?: Maybe<Scalars['Void']['output']>;
  denyAuthenticatedAccessRoles?: Maybe<Scalars['Void']['output']>;
  disableRequestLogger?: Maybe<Scalars['Void']['output']>;
  downloadProjectVersion?: Maybe<Scalars['Void']['output']>;
  enableRequestLogger?: Maybe<Scalars['Void']['output']>;
  forceRerenderOrderLine?: Maybe<Scalars['Void']['output']>;
  generateCustomerSupportToken: CustomerSupportToken;
  generateProjectVersion?: Maybe<Scalars['Void']['output']>;
  grantAuthenticatedAccessRoles?: Maybe<Auth0User>;
  grantAuthenticatedAccessRolesForFulfiller?: Maybe<Auth0User>;
  grantAuthenticatedAccessRolesForLibrary?: Maybe<Auth0User>;
  grantAuthenticatedAccessRolesForStore?: Maybe<Auth0User>;
  grantAuthenticatedAccessRolesForSubscription?: Maybe<Auth0User>;
  /** Invalidate then rebuild cache for an external photo */
  invalidateExternalPhotoCache?: Maybe<Scalars['Void']['output']>;
  /** Invalidate then rebuild cache for all external photos in a project version */
  invalidateProjectStorageExternalPhotosCache?: Maybe<InvalidatePhotosCache>;
  /** Re-encode original version of the photo when (used when Gdi+ or ImageSharp are not able to parse the photo and the render fail) */
  reEncodePhoto?: Maybe<Scalars['Void']['output']>;
  releaseLibraryLock?: Maybe<Scalars['Void']['output']>;
  removeStoreStockPhotoSource?: Maybe<Scalars['Void']['output']>;
  /** Retry a specific order */
  retryFailedOrderLines?: Maybe<Scalars['Void']['output']>;
  /** Retry all anomalies within 10 seconds, and return the number of retries lines */
  retryOrdersAnomalies: RetryAnomalies;
  revokeSharedProject?: Maybe<Scalars['Void']['output']>;
  setOrderLineStatus?: Maybe<Scalars['Void']['output']>;
  setRemoteFilterThumbnail?: Maybe<Scalars['Void']['output']>;
  setStoreRateLimit?: Maybe<SetStoreRateLimit>;
  signOut?: Maybe<Scalars['Void']['output']>;
  startUpdateLibrary: StartUpdateLibrary;
  switchNonAutomaticUpgradeStoresToDeprecatedStableChannel?: Maybe<Scalars['Void']['output']>;
  switchStoresOnDeprecatedStableChannelToStableChannel?: Maybe<Scalars['Void']['output']>;
  /** Update the Application Insights stores datasource (call through function scheduled trigger) */
  syncStoresToApplicationInsights?: Maybe<Scalars['Void']['output']>;
  testStoreSecret?: Maybe<TestStoreSecret>;
  updateBuiltInExternalPhotoSource?: Maybe<BuiltInExternalPhotoSourceConfig>;
  updateDirectPhotoSource?: Maybe<Scalars['Void']['output']>;
  updateFilterCategory?: Maybe<Scalars['Void']['output']>;
  updateFulfiller?: Maybe<Scalars['Void']['output']>;
  updateGeneratedImageDefinition?: Maybe<Scalars['Void']['output']>;
  updateGeneratedImageStoreAssociation?: Maybe<Scalars['Void']['output']>;
  updateGenericExternalPhotoSource?: Maybe<GenericExternalPhotoSourceConfig>;
  updateGenericRemoteFilter?: Maybe<Scalars['Void']['output']>;
  updateInvoice?: Maybe<Scalars['Void']['output']>;
  updateLibraryMetadata?: Maybe<Scalars['Void']['output']>;
  updateLibraryUpdateSchedules?: Maybe<Scalars['Void']['output']>;
  updateLocalFiltersConfiguration?: Maybe<Scalars['Void']['output']>;
  updatePerfectlyClearRemoteFilter?: Maybe<Scalars['Void']['output']>;
  updatePicsartEffectsRemoteFilter?: Maybe<Scalars['Void']['output']>;
  updatePicsartRemoveBackgroundRemoteFilter?: Maybe<Scalars['Void']['output']>;
  updatePicsartStyleTransferRemoteFilter?: Maybe<Scalars['Void']['output']>;
  updateProjectVersionContent?: Maybe<Scalars['Void']['output']>;
  updateStockPhotoSource: StockPhotoSourceConfig;
  updateStore?: Maybe<Scalars['Void']['output']>;
  updateStoreConfig?: Maybe<Scalars['Void']['output']>;
  updateStoreDefaultDesignerBranch?: Maybe<Scalars['Void']['output']>;
  updateStoreDesignerBranchRule?: Maybe<Scalars['Void']['output']>;
  updateStoreDesignerCss?: Maybe<Scalars['Void']['output']>;
  updateStoreDesignerJavascript?: Maybe<Scalars['Void']['output']>;
  updateStoreDesignerLocale?: Maybe<Scalars['Void']['output']>;
  updateStoreDesignerLogo?: Maybe<Scalars['Void']['output']>;
  updateStoreFulfiller?: Maybe<Scalars['Void']['output']>;
  updateStoreGeneratedText?: Maybe<Scalars['Void']['output']>;
  updateStorePhotoUpscaling?: Maybe<Scalars['Void']['output']>;
  updateSubscription?: Maybe<Scalars['Void']['output']>;
  updateSubscriptionCleanupDelay?: Maybe<Scalars['Void']['output']>;
  uploadGeneratedImageEditorFile: FileEditorInformation;
};


export type MediaclipMutationAddStoreDesignerBranchRuleArgs = {
  input: AddDesignerBranchRuleInput;
};


export type MediaclipMutationAddStoreStockPhotoSourceArgs = {
  input: AddStoreStockPhotoSourceInput;
};


export type MediaclipMutationCancelSubscriptionPendingProjectExpirationsArgs = {
  input: CancelSubscriptionPendingProjectExpirationsInput;
};


export type MediaclipMutationConsolidateUserArgs = {
  input: ConsolidateUserInput;
};


export type MediaclipMutationCreateAppSecretArgs = {
  input: CreateAppSecretInput;
};


export type MediaclipMutationCreateBuiltInExternalPhotoSourceArgs = {
  input: CreateBuiltInExternalPhotoSourceInput;
};


export type MediaclipMutationCreateDirectPhotoSourceArgs = {
  input: CreateDirectPhotoSourceInput;
};


export type MediaclipMutationCreateFilterCategoryArgs = {
  input: CreateFilterCategoryInput;
};


export type MediaclipMutationCreateFulfillerArgs = {
  input: CreateFulfillerInput;
};


export type MediaclipMutationCreateFulfillerAppArgs = {
  input: CreateFulfillerAppInput;
};


export type MediaclipMutationCreateGeneratedImageDefinitionArgs = {
  input: CreateGeneratedImageDefinitionInput;
};


export type MediaclipMutationCreateGeneratedImageStoreAssociationArgs = {
  input: CreateGeneratedImageStoreAssociationInput;
};


export type MediaclipMutationCreateGenericExternalPhotoSourceArgs = {
  input: CreateGenericExternalPhotoSourceInput;
};


export type MediaclipMutationCreateGenericRemoteFilterArgs = {
  input: CreateGenericRemoteFilterInput;
};


export type MediaclipMutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MediaclipMutationCreatePerfectlyClearRemoteFilterArgs = {
  input: CreatePerfectlyClearRemoteFilterInput;
};


export type MediaclipMutationCreatePicsartEffectsRemoteFilterArgs = {
  input: CreatePicsartEffectsRemoteFilterInput;
};


export type MediaclipMutationCreatePicsartRemoveBackgroundRemoteFilterArgs = {
  input: CreatePicsartRemoveBackgroundRemoteFilterInput;
};


export type MediaclipMutationCreatePicsartStyleTransferRemoteFilterArgs = {
  input: CreatePicsartStyleTransferRemoteFilterInput;
};


export type MediaclipMutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MediaclipMutationCreateProjectProductIdMigrationArgs = {
  input: CreateProjectProductIdMigrationInput;
};


export type MediaclipMutationCreateStockPhotoSourceArgs = {
  input: CreateStockPhotoSourceInput;
};


export type MediaclipMutationCreateStoreArgs = {
  input: CreateStoreInput;
};


export type MediaclipMutationCreateStoreFulfillerArgs = {
  input: CreateStoreFulfillerInput;
};


export type MediaclipMutationCreateStoreSecretArgs = {
  input: CreateStoreSecretInput;
};


export type MediaclipMutationCreateStoreUrlSigningKeyArgs = {
  input: CreateStoreUrlSigningKeyInput;
};


export type MediaclipMutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MediaclipMutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MediaclipMutationDeactivateStoreAndScheduleDeletionOfUserDataArgs = {
  input: DeactivateStoreInput;
};


export type MediaclipMutationDeactivateSubscriptionAndScheduleDeletionOfUserDataArgs = {
  input: DeactivateSubscriptionInput;
};


export type MediaclipMutationDeleteAppSecretArgs = {
  input: DeleteAppSecretInput;
};


export type MediaclipMutationDeleteBuiltInExternalPhotoSourceArgs = {
  input: DeleteBuiltInExternalPhotoSourceInput;
};


export type MediaclipMutationDeleteFilterCategoryArgs = {
  input: DeleteFilterCategoryInput;
};


export type MediaclipMutationDeleteGeneratedImageEditorFileArgs = {
  input: DeleteGeneratedImageEditorFileInput;
};


export type MediaclipMutationDeleteGeneratedImageStoreAssociationArgs = {
  id: Scalars['UUID']['input'];
};


export type MediaclipMutationDeleteGenericExternalPhotoSourceArgs = {
  input: DeleteGenericExternalPhotoSourceInput;
};


export type MediaclipMutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


export type MediaclipMutationDeleteStockPhotoSourceArgs = {
  input: DeleteStockPhotoSourceInput;
};


export type MediaclipMutationDeleteStoreDesignerBranchRuleArgs = {
  input: DeleteDesignerBranchRuleInput;
};


export type MediaclipMutationDeleteStoreFulfillerArgs = {
  input: DeleteStoreFulfillerInput;
};


export type MediaclipMutationDeleteStoreGeneratedTextArgs = {
  input: DeleteStoreGeneratedTextInput;
};


export type MediaclipMutationDeleteStorePhotoUpscalingArgs = {
  input: DeleteStorePhotoUpscalingInput;
};


export type MediaclipMutationDeleteStoreSecretArgs = {
  input: DeleteStoreSecretInput;
};


export type MediaclipMutationDeleteStoreUrlSigningKeyArgs = {
  input: DeleteStoreUrlSigningKeyInput;
};


export type MediaclipMutationDeleteSubscriptionArgs = {
  input: DeleteSubscriptionInput;
};


export type MediaclipMutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MediaclipMutationDenyAuthenticatedAccessRolesArgs = {
  input: AccessManagementInput;
};


export type MediaclipMutationDisableRequestLoggerArgs = {
  appName: Scalars['String']['input'];
  ruleId: Scalars['UUID']['input'];
};


export type MediaclipMutationDownloadProjectVersionArgs = {
  input: DownloadProjectVersionInput;
};


export type MediaclipMutationEnableRequestLoggerArgs = {
  appName: Scalars['String']['input'];
  input: EnableRequestLoggerInput;
};


export type MediaclipMutationForceRerenderOrderLineArgs = {
  input: ForceRerenderOrderLineInput;
};


export type MediaclipMutationGenerateCustomerSupportTokenArgs = {
  input: GenerateCustomerSupportTokenInput;
};


export type MediaclipMutationGenerateProjectVersionArgs = {
  input: GenerateProjectVersionInput;
};


export type MediaclipMutationGrantAuthenticatedAccessRolesArgs = {
  input: AccessManagementInput;
};


export type MediaclipMutationGrantAuthenticatedAccessRolesForFulfillerArgs = {
  input: FulfillerUserAccessInput;
};


export type MediaclipMutationGrantAuthenticatedAccessRolesForLibraryArgs = {
  input: LibraryUserAccessInput;
};


export type MediaclipMutationGrantAuthenticatedAccessRolesForStoreArgs = {
  input: StoreUserAccessInput;
};


export type MediaclipMutationGrantAuthenticatedAccessRolesForSubscriptionArgs = {
  input: SubscriptionUserAccessInput;
};


export type MediaclipMutationInvalidateExternalPhotoCacheArgs = {
  urn: Scalars['String']['input'];
};


export type MediaclipMutationInvalidateProjectStorageExternalPhotosCacheArgs = {
  projectVersionId: Scalars['Int']['input'];
};


export type MediaclipMutationReEncodePhotoArgs = {
  urn: Scalars['String']['input'];
};


export type MediaclipMutationReleaseLibraryLockArgs = {
  input: ReleaseLibraryLockInput;
};


export type MediaclipMutationRemoveStoreStockPhotoSourceArgs = {
  input: RemoveStoreStockPhotoSourceInput;
};


export type MediaclipMutationRetryFailedOrderLinesArgs = {
  input: RetryFailedOrderLinesInput;
};


export type MediaclipMutationRevokeSharedProjectArgs = {
  input: RevokeSharedProjectInput;
};


export type MediaclipMutationSetOrderLineStatusArgs = {
  input: SetOrderLineStatusInput;
};


export type MediaclipMutationSetRemoteFilterThumbnailArgs = {
  input: SetRemoteFilterThumbnailInput;
};


export type MediaclipMutationSetStoreRateLimitArgs = {
  input: SetStoreRateLimitInput;
};


export type MediaclipMutationStartUpdateLibraryArgs = {
  input: StartUpdateLibraryInput;
};


export type MediaclipMutationTestStoreSecretArgs = {
  input: TestStoreSecretInput;
};


export type MediaclipMutationUpdateBuiltInExternalPhotoSourceArgs = {
  input: UpdateBuiltInExternalPhotoSourceInput;
};


export type MediaclipMutationUpdateDirectPhotoSourceArgs = {
  input: UpdateDirectPhotoSourceInput;
};


export type MediaclipMutationUpdateFilterCategoryArgs = {
  input: UpdateFilterCategoryInput;
};


export type MediaclipMutationUpdateFulfillerArgs = {
  input: UpdateFulfillerInput;
};


export type MediaclipMutationUpdateGeneratedImageDefinitionArgs = {
  input: UpdateGeneratedImageDefinitionInput;
};


export type MediaclipMutationUpdateGeneratedImageStoreAssociationArgs = {
  input: UpdateGeneratedImageStoreAssociationInput;
};


export type MediaclipMutationUpdateGenericExternalPhotoSourceArgs = {
  input: UpdateGenericExternalPhotoSourceInput;
};


export type MediaclipMutationUpdateGenericRemoteFilterArgs = {
  input: UpdateGenericRemoteFilterInput;
};


export type MediaclipMutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MediaclipMutationUpdateLibraryMetadataArgs = {
  input: UpdateLibraryMetadataInput;
};


export type MediaclipMutationUpdateLibraryUpdateSchedulesArgs = {
  input: UpdateLibraryScheduleUpdatesInput;
};


export type MediaclipMutationUpdateLocalFiltersConfigurationArgs = {
  input: UpdateLocalFiltersConfigurationInput;
};


export type MediaclipMutationUpdatePerfectlyClearRemoteFilterArgs = {
  input: UpdatePerfectlyClearRemoteFilterInput;
};


export type MediaclipMutationUpdatePicsartEffectsRemoteFilterArgs = {
  input: UpdatePicsartEffectsRemoteFilterInput;
};


export type MediaclipMutationUpdatePicsartRemoveBackgroundRemoteFilterArgs = {
  input: UpdatePicsartRemoveBackgroundRemoteFilterInput;
};


export type MediaclipMutationUpdatePicsartStyleTransferRemoteFilterArgs = {
  input: UpdatePicsartStyleTransferRemoteFilterInput;
};


export type MediaclipMutationUpdateProjectVersionContentArgs = {
  input: UpdateProjectVersionContentInput;
};


export type MediaclipMutationUpdateStockPhotoSourceArgs = {
  input: UpdateStockPhotoSourceInput;
};


export type MediaclipMutationUpdateStoreArgs = {
  input: UpdateStoreInput;
};


export type MediaclipMutationUpdateStoreConfigArgs = {
  input: UpdateStoreConfigInput;
};


export type MediaclipMutationUpdateStoreDefaultDesignerBranchArgs = {
  input: UpdateStoreDefaultDesignerBranchInput;
};


export type MediaclipMutationUpdateStoreDesignerBranchRuleArgs = {
  input: UpdateDesignerBranchRuleInput;
};


export type MediaclipMutationUpdateStoreDesignerCssArgs = {
  input: UpdateStoreFilesInput;
};


export type MediaclipMutationUpdateStoreDesignerJavascriptArgs = {
  input: UpdateStoreFilesInput;
};


export type MediaclipMutationUpdateStoreDesignerLocaleArgs = {
  input: UpdateLocaleStoreFilesInput;
};


export type MediaclipMutationUpdateStoreDesignerLogoArgs = {
  input: UpdateStoreBinaryFilesInput;
};


export type MediaclipMutationUpdateStoreFulfillerArgs = {
  input: UpdateStoreFulfillerInput;
};


export type MediaclipMutationUpdateStoreGeneratedTextArgs = {
  input: UpdateStoreGeneratedTextInput;
};


export type MediaclipMutationUpdateStorePhotoUpscalingArgs = {
  input: UpdateStorePhotoUpscalingInput;
};


export type MediaclipMutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MediaclipMutationUpdateSubscriptionCleanupDelayArgs = {
  input: UpdateSubscriptionCleanupDelayInput;
};


export type MediaclipMutationUploadGeneratedImageEditorFileArgs = {
  input: UploadGeneratedImageEditorInput;
};

export type MediaclipQuery = {
  __typename?: 'MediaclipQuery';
  adminUser: UserRoles;
  adminUsers: PagingResultOfUserRoles;
  anomalies: Anomalies;
  availableDownloadPaths: Array<DownloadPath>;
  availableRoles: AvailableRoles;
  builtInExternalPhotoSourceDefinitions: Array<BuiltInExternalPhotoSourceDefinition>;
  designerReleaseChannels: Array<DesignerBranch>;
  filterCategories: Array<FilterCategory>;
  fulfiller?: Maybe<Fulfiller>;
  fulfillerLogs: Array<FulfillerLog>;
  fulfillers: Array<Fulfiller>;
  generatedImageDefinition: GeneratedImageDefinition;
  generatedImageDefinitions: Array<GeneratedImageDefinition>;
  libraries: Array<Library>;
  library: Library;
  line?: Maybe<OrderLineUnion>;
  localFilterDefinitions: Array<LocalFilterDefinition>;
  me: AuthenticatedUser;
  order?: Maybe<OrderUnion>;
  project?: Maybe<Project>;
  requestLoggerConfiguration: RequestLoggingConfiguration;
  requestLogs: Array<RequestLog>;
  search: Array<SearchResultUnion>;
  sharedProject?: Maybe<SharedProject>;
  stockPhotoSourceConfiguration: StockPhotoSourceConfig;
  stockPhotoSourceConfigurations: Array<StockPhotoSourceConfig>;
  store: Store;
  subscription: Subscription;
  subscriptions: Array<Subscription>;
  user: User;
};


export type MediaclipQueryAdminUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MediaclipQueryAdminUsersArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
};


export type MediaclipQueryFilterCategoriesArgs = {
  storeId: Scalars['String']['input'];
};


export type MediaclipQueryFulfillerArgs = {
  id: Scalars['String']['input'];
};


export type MediaclipQueryFulfillerLogsArgs = {
  fulfillerId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};


export type MediaclipQueryGeneratedImageDefinitionArgs = {
  id: Scalars['UUID']['input'];
};


export type MediaclipQueryLibraryArgs = {
  owner: Scalars['String']['input'];
};


export type MediaclipQueryLineArgs = {
  id: Scalars['UUID']['input'];
};


export type MediaclipQueryOrderArgs = {
  id: Scalars['UUID']['input'];
};


export type MediaclipQueryProjectArgs = {
  id: Scalars['UUID']['input'];
};


export type MediaclipQueryRequestLoggerConfigurationArgs = {
  appName: Scalars['String']['input'];
};


export type MediaclipQueryRequestLogsArgs = {
  ruleId: Scalars['UUID']['input'];
};


export type MediaclipQuerySearchArgs = {
  query: Scalars['String']['input'];
  storeId?: InputMaybe<Scalars['String']['input']>;
};


export type MediaclipQuerySharedProjectArgs = {
  id: Scalars['UUID']['input'];
};


export type MediaclipQueryStockPhotoSourceConfigurationArgs = {
  id: Scalars['String']['input'];
};


export type MediaclipQueryStoreArgs = {
  id: Scalars['String']['input'];
};


export type MediaclipQuerySubscriptionArgs = {
  id: Scalars['UUID']['input'];
};


export type MediaclipQuerySubscriptionsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billable?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MediaclipQueryUserArgs = {
  id: Scalars['UUID']['input'];
};

export type MissingPhoto = {
  __typename?: 'MissingPhoto';
  urn: Scalars['String']['output'];
};

export type Order = {
  __typename?: 'Order';
  /** When this order was checked out */
  dateCreatedUtc?: Maybe<Scalars['DateTime']['output']>;
  /** An unique ID created by Mediaclip Hub */
  id: Scalars['UUID']['output'];
  /** The different items in the shopping cart that were ordered */
  lines: Array<OrderLine>;
  originalCheckoutJson?: Maybe<Scalars['String']['output']>;
  /** The properties passed when the order was created */
  properties?: Maybe<Array<Property>>;
  store: Store;
  storeId: Scalars['String']['output'];
  /** The order ID provided by the store at checkout */
  storeOrderId: Scalars['String']['output'];
  /** The user who placed the order */
  user: User;
};

export type OrderLine = {
  __typename?: 'OrderLine';
  /** When this order was checked out */
  dateCreatedUtc: Scalars['DateTime']['output'];
  /** Files associated with the line */
  files: Array<RenderingJobFile>;
  /** The fulfiller assigned to this line, if any */
  fulfiller?: Maybe<Fulfiller>;
  /** The line number provided by the fulfiller */
  fulfillerHubLineNumber: Scalars['Int']['output'];
  /** An unique ID created by Mediaclip Hub */
  id: Scalars['UUID']['output'];
  /** Historical information about what happened with this line */
  logs: Array<OrderLineLogUnion>;
  /** The order in which that line exists */
  order: Order;
  /** Pricing information attached to this order line for revenue share purposes (see https://doc.mediacliphub.com/pages/Getting%20started/billing.html) */
  price: OrderLinePrice;
  /** The project version associated to the line order */
  projectVersion?: Maybe<ProjectVersion>;
  /** The properties passed to the line when the order was created */
  properties?: Maybe<Array<Property>>;
  quantity: Scalars['Int']['output'];
  /** When provided by the fulfiller, shipping and tracking information for that line */
  shipping: OrderLineTracking;
  /** The state of this line */
  status: OrderLineStatus;
  store: Store;
  /** The line id provided by the store when the order was created. */
  storeOrderLineId?: Maybe<Scalars['String']['output']>;
  /** The line number provided by the store (normally a sequential number  in the order) */
  storeOrderLineNumber: Scalars['Int']['output'];
  /** The store's product ID (from the e-commerce platform) */
  storeProductId: Scalars['String']['output'];
};


export type OrderLineLogsArgs = {
  includeAppInsights?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderLineApplicationInsightsLog = {
  __typename?: 'OrderLineApplicationInsightsLog';
  cloudRoleInstance: Scalars['String']['output'];
  cloudRoleName: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  exceptions: Array<OrderLineJobApplicationInsightsException>;
  operationId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  timestampUtc: Scalars['DateTime']['output'];
};

export type OrderLineJobApplicationInsightsException = {
  __typename?: 'OrderLineJobApplicationInsightsException';
  itemId: Scalars['String']['output'];
  openQueryUrl?: Maybe<Scalars['String']['output']>;
  outerMessage: Scalars['String']['output'];
  severityLevel: ApplicationInsightsSeverityLevel;
};

export type OrderLineLog = {
  __typename?: 'OrderLineLog';
  details: Scalars['String']['output'];
  message: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  timestampUtc: Scalars['DateTime']['output'];
};

export type OrderLineLogUnion = OrderLineApplicationInsightsLog | OrderLineLog;

export type OrderLinePrice = {
  __typename?: 'OrderLinePrice';
  currency: Scalars['String']['output'];
  finalLinePrice?: Maybe<Scalars['Float']['output']>;
  originalLinePrice?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type OrderLineSearchResult = {
  __typename?: 'OrderLineSearchResult';
  fulfillerHubLineNumber: Scalars['Int']['output'];
  hubLineId: Scalars['UUID']['output'];
  hubOrderId: Scalars['UUID']['output'];
  storeId: Scalars['String']['output'];
  storeOrderId: Scalars['String']['output'];
  storeOrderLineNumber: Scalars['Int']['output'];
};

export type OrderLineStatus = {
  __typename?: 'OrderLineStatus';
  details?: Maybe<Scalars['String']['output']>;
  effectiveDateUtc: Scalars['DateTime']['output'];
  flags?: Maybe<OrderLineStatusFlags>;
  value: OrderLineStatusValues;
};

export enum OrderLineStatusFlags {
  None = 'None',
  StoreActionRequired = 'StoreActionRequired'
}

export enum OrderLineStatusValues {
  AvailableForDownload = 'AvailableForDownload',
  Cancelled = 'Cancelled',
  EnqueuedForFulfillment = 'EnqueuedForFulfillment',
  Error = 'Error',
  FulfillmentInProgress = 'FulfillmentInProgress',
  New = 'New',
  OnHold = 'OnHold',
  Printed = 'Printed',
  RenderingCompleted = 'RenderingCompleted',
  RenderingDelayed = 'RenderingDelayed',
  RenderingInProgress = 'RenderingInProgress',
  ReviewCompleted = 'ReviewCompleted',
  SentToFulfillment = 'SentToFulfillment',
  SentToRendering = 'SentToRendering',
  Shipped = 'Shipped',
  TrackingInformationAvailable = 'TrackingInformationAvailable',
  Undefined = 'Undefined',
  WaitingForCorrections = 'WaitingForCorrections',
  WaitingForFulfillment = 'WaitingForFulfillment',
  WaitingForRelease = 'WaitingForRelease',
  WaitingForReview = 'WaitingForReview'
}

export type OrderLineTracking = {
  __typename?: 'OrderLineTracking';
  carrier?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type OrderLineUnion = ArchivedOrderLine | OrderLine;

export type OrderSearchResult = {
  __typename?: 'OrderSearchResult';
  hubOrderId: Scalars['UUID']['output'];
  storeId: Scalars['String']['output'];
  storeOrderId: Scalars['String']['output'];
};

export type OrderUnion = ArchivedOrder | Order;

export type Page = {
  __typename?: 'Page';
  url?: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type PageInput = {
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PagingResultOfUserRoles = {
  __typename?: 'PagingResultOfUserRoles';
  items: Array<UserRoles>;
  pageInfo: PageInfo;
};

export type PendingProjectExpirationConfig = {
  __typename?: 'PendingProjectExpirationConfig';
  changedConfigurationDateUtc?: Maybe<Scalars['DateTime']['output']>;
  giftingDelayInDays?: Maybe<Scalars['Int']['output']>;
  photobookDelayInDays?: Maybe<Scalars['Int']['output']>;
  printDelayInDays?: Maybe<Scalars['Int']['output']>;
};

export type PerfectlyClearRemoteFilterData = {
  __typename?: 'PerfectlyClearRemoteFilterData';
  apiKey: Scalars['String']['output'];
  customPreset?: Maybe<Scalars['String']['output']>;
};

export type PerfectlyClearRemoteFilterDataInput = {
  apiKey: Scalars['String']['input'];
  customPreset?: InputMaybe<Scalars['String']['input']>;
};

export type Photo = {
  __typename?: 'Photo';
  dateTakenUtc?: Maybe<Scalars['DateTime']['output']>;
  dateUploadedUtc: Scalars['DateTime']['output'];
  height: Scalars['Int']['output'];
  print: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
  urn: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type PhotoUnion = MissingPhoto | Photo;

export enum PhotoUpscalerType {
  Picsart = 'Picsart'
}

export type PhotoUpscalingConfiguration = {
  __typename?: 'PhotoUpscalingConfiguration';
  enabled: Scalars['Boolean']['output'];
  minDpiUpscaleConfiguration?: Maybe<Scalars['Int']['output']>;
  type: PhotoUpscalerType;
};

export type PicsartEffectsRemoteFilterData = {
  __typename?: 'PicsartEffectsRemoteFilterData';
  apiKey: Scalars['String']['output'];
  effect: Scalars['String']['output'];
};

export type PicsartEffectsRemoteFilterDataInput = {
  apiKey: Scalars['String']['input'];
  effect: Scalars['String']['input'];
};

export enum PicsartImageResponseFormat {
  Jpg = 'Jpg',
  Png = 'Png'
}

export enum PicsartRemoveBackgroundOutputType {
  Cutout = 'Cutout',
  Mask = 'Mask'
}

export type PicsartRemoveBackgroundRemoteFilterData = {
  __typename?: 'PicsartRemoveBackgroundRemoteFilterData';
  apiKey: Scalars['String']['output'];
  backgroundBlur?: Maybe<Scalars['Int']['output']>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  backgroundImageUrl?: Maybe<Scalars['String']['output']>;
  format?: Maybe<PicsartImageResponseFormat>;
  outputType?: Maybe<PicsartRemoveBackgroundOutputType>;
  scale?: Maybe<PicsartRemoveBackgroundScale>;
};

export type PicsartRemoveBackgroundRemoteFilterDataInput = {
  apiKey: Scalars['String']['input'];
  backgroundBlur?: InputMaybe<Scalars['Int']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundImageUrl?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<PicsartImageResponseFormat>;
  outputType: PicsartRemoveBackgroundOutputType;
  scale?: InputMaybe<PicsartRemoveBackgroundScale>;
};

export enum PicsartRemoveBackgroundScale {
  Fill = 'Fill',
  Fit = 'Fit'
}

export enum PicsartStyleTransferLevel {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  L4 = 'L4',
  L5 = 'L5'
}

export type PicsartStyleTransferRemoteFilterData = {
  __typename?: 'PicsartStyleTransferRemoteFilterData';
  apiKey: Scalars['String']['output'];
  level: PicsartStyleTransferLevel;
  referenceImageUrl: Scalars['String']['output'];
};

export type PicsartStyleTransferRemoteFilterDataInput = {
  apiKey: Scalars['String']['input'];
  level: PicsartStyleTransferLevel;
  referenceImageUrl: Scalars['String']['input'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  module?: Maybe<DesignerModuleName>;
  options?: Maybe<Array<ProductOption>>;
  plu?: Maybe<Scalars['String']['output']>;
  themes: Array<Theme>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  default: Scalars['String']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  values: Array<ProductOptionValue>;
};

export type ProductOptionValue = {
  __typename?: 'ProductOptionValue';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  currentVersion?: Maybe<ProjectVersion>;
  currentVersionId?: Maybe<Scalars['Int']['output']>;
  dateCreatedUtc: Scalars['DateTime']['output'];
  externalProductId?: Maybe<Scalars['String']['output']>;
  /** An unique ID created by Mediaclip Hub */
  id: Scalars['UUID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  lastAccessDateUtc?: Maybe<Scalars['DateTime']['output']>;
  module?: Maybe<DesignerModuleName>;
  product?: Maybe<Product>;
  properties: Array<ProjectProperty>;
  sourceSharedProjectId?: Maybe<Scalars['UUID']['output']>;
  /** The store in which the project was created */
  store: Store;
  storeId: Scalars['String']['output'];
  theme?: Maybe<Theme>;
  title?: Maybe<Scalars['String']['output']>;
  /** The user who owns the project */
  user?: Maybe<User>;
  variableData?: Maybe<ProjectVariableData>;
  version?: Maybe<ProjectVersion>;
  versions: Array<ProjectVersion>;
};


export type ProjectVersionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectExpirationConfig = {
  __typename?: 'ProjectExpirationConfig';
  giftingDelayInDays?: Maybe<Scalars['Int']['output']>;
  photobookDelayInDays?: Maybe<Scalars['Int']['output']>;
  printDelayInDays?: Maybe<Scalars['Int']['output']>;
};

export type ProjectProductIdMigration = {
  __typename?: 'ProjectProductIdMigration';
  count: Scalars['Int']['output'];
  dateCreatedUtc: Scalars['DateTime']['output'];
  dateEndedUtc?: Maybe<Scalars['DateTime']['output']>;
  destinationProductId: Scalars['String']['output'];
  sourceProductId: Scalars['String']['output'];
  status: ProjectProductIdMigrationStatus;
  storeId: Scalars['String']['output'];
  triggeredBy: Scalars['String']['output'];
};

export enum ProjectProductIdMigrationStatus {
  Done = 'Done',
  InProgress = 'InProgress',
  OnError = 'OnError',
  Queued = 'Queued'
}

export type ProjectProperty = {
  __typename?: 'ProjectProperty';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjectPropertyInput = {
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectSearchResult = {
  __typename?: 'ProjectSearchResult';
  projectId: Scalars['UUID']['output'];
  storeId: Scalars['String']['output'];
};

export type ProjectVariableData = {
  __typename?: 'ProjectVariableData';
  checkSum?: Maybe<Scalars['String']['output']>;
  dataName?: Maybe<Scalars['String']['output']>;
  items: Scalars['Int']['output'];
  uploadedFilename?: Maybe<Scalars['String']['output']>;
  variableDataResourceUrl: Scalars['String']['output'];
};

export type ProjectVersion = {
  __typename?: 'ProjectVersion';
  additionalSheetCount?: Maybe<Scalars['Int']['output']>;
  /** The identifier of the project version */
  id: Scalars['Int']['output'];
  isInCart: Scalars['Boolean']['output'];
  lastModifiedUtc: Scalars['DateTime']['output'];
  lines: Array<OrderLine>;
  photos: Array<PhotoUnion>;
  /** The parent project */
  project: Project;
  sheetCount?: Maybe<Scalars['Int']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  /** The content as XML of the project version */
  xml: Scalars['String']['output'];
};

export type Property = {
  __typename?: 'Property';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum RateLimitTarget {
  StoreBeautyShots = 'StoreBeautyShots',
  UserBeautyShots = 'UserBeautyShots'
}

export type ReleaseLibraryLockInput = {
  branch: Scalars['String']['input'];
  owner: Scalars['String']['input'];
};

export type RemoteFilter = {
  __typename?: 'RemoteFilter';
  alias?: Maybe<Scalars['String']['output']>;
  data?: Maybe<RemoteFilterDataUnionType>;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  kind: RemoteFilterKind;
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  urlThumb?: Maybe<Scalars['String']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type RemoteFilterDataUnionType = GenericRemoteFilterData | PerfectlyClearRemoteFilterData | PicsartEffectsRemoteFilterData | PicsartRemoveBackgroundRemoteFilterData | PicsartStyleTransferRemoteFilterData;

export enum RemoteFilterKind {
  Generic = 'Generic',
  PerfectlyClear = 'PerfectlyClear',
  PicsartEffects = 'PicsartEffects',
  PicsartRemoveBackground = 'PicsartRemoveBackground',
  PicsartStyleTransfer = 'PicsartStyleTransfer'
}

export type RemoveStoreStockPhotoSourceInput = {
  photoSourceId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type RenderingJobFile = {
  __typename?: 'RenderingJobFile';
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  plu: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type RequestLog = {
  __typename?: 'RequestLog';
  body?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  headers: Array<HttpHeader>;
  statusCode: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type RequestLoggingConfiguration = {
  __typename?: 'RequestLoggingConfiguration';
  logsExpirationInSeconds: Scalars['Int']['output'];
  rules: Array<RouteLoggingRule>;
};

export type RetryAnomalies = {
  __typename?: 'RetryAnomalies';
  processedAnomalies: Scalars['Int']['output'];
  totalAnomalies: Scalars['Int']['output'];
};

export type RetryFailedOrderLinesInput = {
  orderId: Scalars['UUID']['input'];
};

export enum RevShareCalculatedOnValues {
  NetSellingPrice = 'NetSellingPrice',
  Undefined = 'Undefined'
}

export type RevShareInfo = {
  __typename?: 'RevShareInfo';
  calculatedOn?: Maybe<RevShareCalculatedOnValues>;
  maximum?: Maybe<Scalars['Decimal']['output']>;
  minimum: Scalars['Decimal']['output'];
  percentage: Scalars['Decimal']['output'];
};

export type RevokeSharedProjectInput = {
  id: Scalars['UUID']['input'];
};

export type RouteLoggingRule = {
  __typename?: 'RouteLoggingRule';
  id: Scalars['UUID']['output'];
  logsExpirationInSeconds?: Maybe<Scalars['Int']['output']>;
  maximumCaptureCount?: Maybe<Scalars['Int']['output']>;
  routePrefix: Scalars['String']['output'];
  statusCode?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['String']['output']>;
};

export type SearchResultUnion = OrderLineSearchResult | OrderSearchResult | ProjectSearchResult | SharedProjectSearchResult | StoreSearchResult | SubscriptionSearchResult | UserSearchResult;

export type SetOrderLineStatusInput = {
  details?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<OrderLineStatusFlags>;
  lineId: Scalars['UUID']['input'];
  status: OrderLineStatusValues;
};

export type SetRemoteFilterThumbnailInput = {
  contentBase64?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remoteFilterId: Scalars['UUID']['input'];
};

export type SetStoreRateLimit = {
  __typename?: 'SetStoreRateLimit';
  maxCalls?: Maybe<Scalars['Int']['output']>;
  periodInSeconds?: Maybe<Scalars['Int']['output']>;
  storeId: Scalars['String']['output'];
  type?: Maybe<RateLimitTarget>;
};

export type SetStoreRateLimitInput = {
  maxCalls?: InputMaybe<Scalars['Int']['input']>;
  periodInSeconds?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['String']['input'];
  type?: InputMaybe<RateLimitTarget>;
};

export type SharedProject = {
  __typename?: 'SharedProject';
  expiration?: Maybe<Scalars['DateTime']['output']>;
  /** An unique ID created by Mediaclip Hub */
  id: Scalars['UUID']['output'];
  /** The associated project */
  project: Project;
  projectId: Scalars['UUID']['output'];
  /** The associated project version */
  projectStorage?: Maybe<ProjectVersion>;
  projectStorageId?: Maybe<Scalars['Int']['output']>;
  properties?: Maybe<Array<Property>>;
  shareDate: Scalars['DateTime']['output'];
  sharingMode: SharingMode;
  storeId: Scalars['String']['output'];
};

export type SharedProjectSearchResult = {
  __typename?: 'SharedProjectSearchResult';
  sharedProjectId: Scalars['UUID']['output'];
  storeId: Scalars['String']['output'];
};

export enum SharingMode {
  Product = 'Product',
  PublicEdit = 'PublicEdit',
  PublicOrderOnly = 'PublicOrderOnly',
  PublicViewOnly = 'PublicViewOnly',
  Undefined = 'Undefined'
}

export type ShopifyStoreLog = {
  __typename?: 'ShopifyStoreLog';
  errorCode: Scalars['String']['output'];
  errorData: Scalars['String']['output'];
  firstOccurrenceUtc: Scalars['DateTime']['output'];
  lastOccurrenceUtc: Scalars['DateTime']['output'];
  level: LogLevel;
  message: Scalars['String']['output'];
  occurrences: Scalars['Int']['output'];
  openItemQueryUrl?: Maybe<Scalars['String']['output']>;
  openOperationQueryUrl?: Maybe<Scalars['String']['output']>;
  runs: Scalars['Int']['output'];
  timestampUtc: Scalars['DateTime']['output'];
  users: Scalars['Int']['output'];
};

export enum SigningKeyFormat {
  Der = 'Der',
  Pem = 'Pem'
}

export enum Source {
  Hub = 'Hub',
  Shopify = 'Shopify'
}

export type StartUpdateLibrary = {
  __typename?: 'StartUpdateLibrary';
  message: Scalars['String']['output'];
  progressPollingEndpointUrl?: Maybe<Scalars['String']['output']>;
  progressSignalRUrl?: Maybe<Scalars['String']['output']>;
  updateId?: Maybe<Scalars['String']['output']>;
};

export type StartUpdateLibraryInput = {
  branch: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  validate: Scalars['Boolean']['input'];
  validationCulture?: InputMaybe<Scalars['String']['input']>;
  validationDefaultPackage?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
};

export type StockPhotoSourceConfig = {
  __typename?: 'StockPhotoSourceConfig';
  albumsEndpoint?: Maybe<StockPhotoSourceEndpoint>;
  disabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  photosEndpoint: StockPhotoSourceEndpoint;
  stores: Array<Store>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type StockPhotoSourceEndpoint = {
  __typename?: 'StockPhotoSourceEndpoint';
  headers?: Maybe<Array<HttpHeader>>;
  url: Scalars['String']['output'];
};

export type StockPhotoSourceEndpointInput = {
  headers?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  url: Scalars['String']['input'];
};

export type Store = {
  __typename?: 'Store';
  authorizedUsers: Array<StoreUserRole>;
  /** Determines whether this store is will automatically get updated when a new version is released or if it will used the delayed update to Stable versions */
  automaticDesignerUpgrade: Scalars['Boolean']['output'];
  availableGeneratedImageDefinitions: Array<GeneratedImageDefinition>;
  /** List of built-in (Facebook, instagram, google photos) external photo sources enabled for this store. */
  builtInExternalPhotoSources: Array<BuiltInExternalPhotoSourceConfig>;
  config: StoreConfig;
  /** The date when the store was deactivated. 90 days after this date the users data is cleaned */
  deactivationDateUtc?: Maybe<Scalars['DateTime']['output']>;
  /** Rules to choose which Mediaclip Designer release channel will be used, that can be used for testing or A/B testing purpose */
  designerBranchRules: Array<DesignerSelectorRule>;
  designerCustomization: StoreDesignerCustomization;
  /** Determines which Mediaclip Designer release channel will be used */
  designerReleaseChannel?: Maybe<DesignerBranch>;
  directPhotoSources: Array<DirectPhotoSource>;
  /** Determines whether this store is used for production purposes, and whether orders are billable */
  environment: StoreEnvironment;
  fulfiller?: Maybe<StoreFulfiller>;
  fulfillers: Array<StoreFulfiller>;
  generatedImageAssociation: GeneratedImageStoreAssociation;
  generatedImageAssociations: Array<GeneratedImageStoreAssociation>;
  generatedText?: Maybe<GeneratedTextConfiguration>;
  /** List of generic external photo sources enabled for this store. */
  genericExternalPhotoSources: Array<GenericExternalPhotoSourceConfig>;
  /** The store App ID, or App Key, used to authenticate server-to-server API calls to api.mediacliphub.com */
  id: Scalars['String']['output'];
  /** The name of this subscription, for display purposes; usually the store domain name */
  label?: Maybe<Scalars['String']['output']>;
  /** Order lines associated to this store */
  lines: Array<OrderLineUnion>;
  localFilters?: Maybe<LocalFilterConfiguration>;
  /** Errors related to the store, that need action from the store owner. */
  logs: Array<StoreLogUnion>;
  metadata: StoreMetadata;
  /** Order */
  order?: Maybe<OrderUnion>;
  ordersCount: Scalars['Int']['output'];
  photoUpscaling?: Maybe<PhotoUpscalingConfiguration>;
  product?: Maybe<Product>;
  products: Array<Product>;
  projectProductIdMigrations: Array<ProjectProductIdMigration>;
  projects: Array<Project>;
  projectsCount: Scalars['Int']['output'];
  /** A rate limit for a type of calls to hub. */
  rateLimits?: Maybe<Array<Maybe<StoreRateLimit>>>;
  /** Last 100 orders */
  recentOrders: Array<Order>;
  remoteFilters: Array<RemoteFilter>;
  /** Secrets associated to this store */
  secrets: Array<AppSecret>;
  /** Whether this store is currently active */
  status: StoreStatus;
  /** List of stock photo sources enabled for this store. */
  stockPhotoSources: Array<StockPhotoSourceConfig>;
  /** The subscription that owns this store */
  subscription: Subscription;
  subscriptionId: Scalars['UUID']['output'];
  /** Urls signing keys associated to this store */
  urlSigningKeys: Array<StoreUrlSigningKey>;
  volumeTimelineReport: Array<VolumeTimelineReportRow>;
};


export type StoreFulfillerArgs = {
  id: Scalars['String']['input'];
};


export type StoreGeneratedImageAssociationArgs = {
  id: Scalars['UUID']['input'];
};


export type StoreLinesArgs = {
  flag?: InputMaybe<OrderLineStatusFlags>;
  sinceDays?: InputMaybe<Scalars['Int']['input']>;
  sinceHours?: InputMaybe<Scalars['Int']['input']>;
  sinceMinutes?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OrderLineStatusValues>;
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type StoreLogsArgs = {
  source: Source;
};


export type StoreOrderArgs = {
  storeOrderId: Scalars['String']['input'];
};


export type StoreOrdersCountArgs = {
  sinceDays?: InputMaybe<Scalars['Int']['input']>;
};


export type StoreProductArgs = {
  culture?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type StoreProductsArgs = {
  culture?: InputMaybe<Scalars['String']['input']>;
  module?: InputMaybe<DesignerModuleName>;
};


export type StoreProjectsArgs = {
  module?: InputMaybe<DesignerModuleName>;
  productId?: InputMaybe<Scalars['String']['input']>;
  sinceDays?: InputMaybe<Scalars['Int']['input']>;
  sinceHours?: InputMaybe<Scalars['Int']['input']>;
  sinceMinutes?: InputMaybe<Scalars['Int']['input']>;
  themeUrl?: InputMaybe<Scalars['String']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type StoreProjectsCountArgs = {
  sinceDays?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreAccessInput = {
  id: Scalars['String']['input'];
  roles: Array<Scalars['String']['input']>;
};

export type StoreConfig = {
  __typename?: 'StoreConfig';
  addToCartEndpoint?: Maybe<Endpoint>;
  allowAnonymousRequest: Scalars['Boolean']['output'];
  cartRedirectionPage: Page;
  correctionsCompletedPage: Page;
  defaultLibraryPackage?: Maybe<Scalars['String']['output']>;
  enableThumbnailGeneration: Scalars['Boolean']['output'];
  exitPage: Page;
  getAlternateProductsEndpoint?: Maybe<Endpoint>;
  getPriceEndpoint?: Maybe<Endpoint>;
  getProductQuantitiesEndpoint?: Maybe<Endpoint>;
  getProductsPriceEndpoint?: Maybe<Endpoint>;
  isProofingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isRecentUploadsEnabled?: Maybe<Scalars['Boolean']['output']>;
  loginPage: Page;
  onProjectDeletedEndpoint?: Maybe<Endpoint>;
  statusUpdateEndpoint?: Maybe<Endpoint>;
  uploadInBackgroundEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type StoreDesignerCustomization = {
  __typename?: 'StoreDesignerCustomization';
  css?: Maybe<StoreFile>;
  javascript?: Maybe<StoreFile>;
  locales: Array<StoreFileLocale>;
  logo?: Maybe<StoreFile>;
};

export enum StoreEnvironment {
  Demonstration = 'Demonstration',
  Development = 'Development',
  Monitoring = 'Monitoring',
  Production = 'Production',
  Staging = 'Staging',
  Test = 'Test',
  Undefined = 'Undefined'
}

export type StoreFile = {
  __typename?: 'StoreFile';
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type StoreFileLocale = {
  __typename?: 'StoreFileLocale';
  culture: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type StoreFulfiller = {
  __typename?: 'StoreFulfiller';
  configs?: Maybe<Array<StoreFulfillerData>>;
  fulfiller: Fulfiller;
  fulfillerId: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  logs: Array<FulfillerLog>;
  store: Store;
  storeId: Scalars['String']['output'];
};

export type StoreFulfillerData = {
  __typename?: 'StoreFulfillerData';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type StoreLogUnion = HubStoreLog | ShopifyStoreLog;

export type StoreMetadata = {
  __typename?: 'StoreMetadata';
  id: Scalars['String']['output'];
  /** Problems that was already raised to the customer support team, temporarily removed from logs to reduce noise in monitoring. */
  ignoredProblems: Array<Scalars['String']['output']>;
  /** The platform on which the store runs, such as Shopify, Magento, WooCommerce. */
  platform?: Maybe<Scalars['String']['output']>;
  /** Which Mediaclip plugin is used, including Shopify, Magento, WooCommerce. */
  plugin?: Maybe<Scalars['String']['output']>;
  /** Information to allow dev team to test the client integration quickly when deploying risky update or if the client is reporting a problem to reproduce quickly and collect information to understand and fix the problem */
  testDesignerInfo: Array<TestDesignerInfo>;
};

export enum StorePermission {
  DeactivateStore = 'DeactivateStore',
  GenerateCustomerSupportToken = 'GenerateCustomerSupportToken',
  ManageAdminUsers = 'ManageAdminUsers',
  ManageDesignerBranchRules = 'ManageDesignerBranchRules',
  ManageDesignerCustomizations = 'ManageDesignerCustomizations',
  ManageFilterCategories = 'ManageFilterCategories',
  ManageGeneratedImages = 'ManageGeneratedImages',
  ManageGeneratedText = 'ManageGeneratedText',
  ManageLocalFilters = 'ManageLocalFilters',
  ManageOrders = 'ManageOrders',
  ManagePhotoSources = 'ManagePhotoSources',
  ManagePhotos = 'ManagePhotos',
  ManageProjectProductIdMigrations = 'ManageProjectProductIdMigrations',
  ManageProjects = 'ManageProjects',
  ManageRemoteFilters = 'ManageRemoteFilters',
  ManageStoreConfigs = 'ManageStoreConfigs',
  ManageStoreFulfillers = 'ManageStoreFulfillers',
  ManageStoreMetadata = 'ManageStoreMetadata',
  ManageStoreRateLimiting = 'ManageStoreRateLimiting',
  ManageStoreSecrets = 'ManageStoreSecrets',
  ManageStoreUpscaling = 'ManageStoreUpscaling',
  ManageStoreUrlSigningKeys = 'ManageStoreUrlSigningKeys',
  ManageStoreUsers = 'ManageStoreUsers',
  ReadAdminUsers = 'ReadAdminUsers',
  ReadDesignerCustomizations = 'ReadDesignerCustomizations',
  ReadFilterCategories = 'ReadFilterCategories',
  ReadIntegrationVersions = 'ReadIntegrationVersions',
  ReadOrders = 'ReadOrders',
  ReadProjects = 'ReadProjects',
  ReadReports = 'ReadReports',
  ReadStoreFulfillers = 'ReadStoreFulfillers',
  ReadStoreLibrary = 'ReadStoreLibrary',
  ReadStoreLogs = 'ReadStoreLogs',
  ReadStoreMetadata = 'ReadStoreMetadata',
  ReadStoreUsers = 'ReadStoreUsers',
  RerenderOrders = 'RerenderOrders'
}

export type StoreRateLimit = {
  __typename?: 'StoreRateLimit';
  maxCalls: Scalars['Int']['output'];
  periodInSeconds: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export enum StoreRole {
  Admin = 'Admin',
  Rerender = 'Rerender'
}

export type StoreSearchResult = {
  __typename?: 'StoreSearchResult';
  storeId: Scalars['String']['output'];
  storeLabel?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['UUID']['output'];
  subscriptionLabel?: Maybe<Scalars['String']['output']>;
};

export enum StoreStatus {
  Active = 'Active',
  Closed = 'Closed',
  ScheduledForDeletion = 'ScheduledForDeletion',
  ScheduledForDeletionNotificationSent = 'ScheduledForDeletionNotificationSent'
}

export type StoreUrlSigningKey = {
  __typename?: 'StoreUrlSigningKey';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type StoreUserAccessInput = {
  email: Scalars['String']['input'];
  storeAccess: StoreAccessInput;
};

export type StoreUserRole = {
  __typename?: 'StoreUserRole';
  roles: Array<StoreRole>;
  user?: Maybe<Auth0User>;
};

export type Subscription = {
  __typename?: 'Subscription';
  active: Scalars['Boolean']['output'];
  authorizedUsers: Array<SubscriptionUserRole>;
  billing?: Maybe<SubscriptionBillingInfo>;
  cleanup: SubscriptionCleanupConfig;
  contact?: Maybe<Scalars['String']['output']>;
  dateCreatedUtc?: Maybe<Scalars['DateTime']['output']>;
  deactivationDateUtc?: Maybe<Scalars['DateTime']['output']>;
  generatedImages: Array<GeneratedImageDefinition>;
  id: Scalars['String']['output'];
  invoices: Array<Invoice>;
  label: Scalars['String']['output'];
  licenses?: Maybe<SubscriptionLicenses>;
  notes?: Maybe<Scalars['String']['output']>;
  stores?: Maybe<Array<Store>>;
  storesCount: Scalars['Int']['output'];
};


export type SubscriptionStoresArgs = {
  environments?: InputMaybe<Array<InputMaybe<StoreEnvironment>>>;
  status?: InputMaybe<StoreStatus>;
};

export type SubscriptionAccessInput = {
  id: Scalars['UUID']['input'];
  roles: Array<Scalars['String']['input']>;
};

export type SubscriptionBillingInfo = {
  __typename?: 'SubscriptionBillingInfo';
  billingStartDateUtc?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  monthlyFee?: Maybe<Scalars['Decimal']['output']>;
  multiplyByQuantity?: Maybe<Scalars['Boolean']['output']>;
  revShare?: Maybe<RevShareInfo>;
};

export type SubscriptionBillingInfoInput = {
  billingStartDateUtc?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  monthlyFee?: InputMaybe<Scalars['Decimal']['input']>;
  multiplyByQuantity?: InputMaybe<Scalars['Boolean']['input']>;
  revShare?: InputMaybe<SubscriptionBillingRevShareInput>;
};

export type SubscriptionBillingRevShareInput = {
  calculatedOn?: InputMaybe<RevShareCalculatedOnValues>;
  maximum?: InputMaybe<Scalars['Decimal']['input']>;
  minimum: Scalars['Decimal']['input'];
  percentage: Scalars['Decimal']['input'];
};

export type SubscriptionCleanupConfig = {
  __typename?: 'SubscriptionCleanupConfig';
  pendingProjectExpirations?: Maybe<PendingProjectExpirationConfig>;
  projectExpirations?: Maybe<ProjectExpirationConfig>;
};

export type SubscriptionLicenses = {
  __typename?: 'SubscriptionLicenses';
  download?: Maybe<Array<Scalars['String']['output']>>;
};

export type SubscriptionLicensesInput = {
  download: Array<Scalars['String']['input']>;
};

export enum SubscriptionPermission {
  CreateStore = 'CreateStore',
  DeactivateSubscription = 'DeactivateSubscription',
  ListSubscriptionStores = 'ListSubscriptionStores',
  ManageAdminUsers = 'ManageAdminUsers',
  ManageGeneratedImages = 'ManageGeneratedImages',
  ManageSubscriptionMetadata = 'ManageSubscriptionMetadata',
  ReadGeneratedImages = 'ReadGeneratedImages',
  ReadInvoices = 'ReadInvoices',
  ReadSubscriptionMetadata = 'ReadSubscriptionMetadata',
  UpdateInvoice = 'UpdateInvoice'
}

export enum SubscriptionRole {
  AccountManager = 'AccountManager',
  Admin = 'Admin',
  Reader = 'Reader'
}

export type SubscriptionSearchResult = {
  __typename?: 'SubscriptionSearchResult';
  subscriptionId: Scalars['UUID']['output'];
  subscriptionLabel?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionUserAccessInput = {
  email: Scalars['String']['input'];
  subscriptionAccess: SubscriptionAccessInput;
};

export type SubscriptionUserRole = {
  __typename?: 'SubscriptionUserRole';
  roles: Array<SubscriptionRole>;
  user?: Maybe<Auth0User>;
};

export enum TabularFormats {
  Csv = 'csv',
  Xlsx = 'xlsx'
}

export type TestDesignerInfo = {
  __typename?: 'TestDesignerInfo';
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TestDesignerInfoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type TestStoreSecret = {
  __typename?: 'TestStoreSecret';
  success: Scalars['Boolean']['output'];
};

export type TestStoreSecretInput = {
  secret: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type UpdateBuiltInExternalPhotoSourceInput = {
  appId?: InputMaybe<Scalars['String']['input']>;
  appSecret?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Array<BuiltInExternalPhotoSourceDataInput>>;
  photoSourceId: Scalars['String']['input'];
  status?: InputMaybe<ExternalPhotoSourceStatus>;
  storeId: Scalars['String']['input'];
};

export type UpdateDesignerBranchRuleInput = {
  designerBranch: Scalars['String']['input'];
  label: Scalars['String']['input'];
  module?: InputMaybe<DesignerModuleName>;
  ruleId: Scalars['UUID']['input'];
  storeId: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
};

export type UpdateDirectPhotoSourceInput = {
  name: Scalars['String']['input'];
  photoEndpoint: EndpointInput;
  storeId: Scalars['String']['input'];
};

export type UpdateFilterCategoryInput = {
  builtInFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['UUID']['input'];
  label: Scalars['String']['input'];
  remoteFilters?: InputMaybe<Array<Scalars['UUID']['input']>>;
  storeId: Scalars['String']['input'];
};

export type UpdateFulfillerInput = {
  client?: InputMaybe<FulfillerClient>;
  fulfillerAppId?: InputMaybe<Scalars['String']['input']>;
  fulfillerAppKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  libraryPackage?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGeneratedImageDefinitionInput = {
  configs?: InputMaybe<GeneratedImageDataInput>;
  defaultAlias: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  kind: GeneratedImageKind;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxWidth?: InputMaybe<Scalars['Int']['input']>;
  preserveAspectRatio: Scalars['Boolean']['input'];
  warning?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGeneratedImageStoreAssociationInput = {
  alias: Scalars['String']['input'];
  configs?: InputMaybe<GeneratedImageDataInput>;
  id: Scalars['UUID']['input'];
};

export type UpdateGenericExternalPhotoSourceInput = {
  albumUrl?: InputMaybe<Scalars['String']['input']>;
  appId?: InputMaybe<Scalars['String']['input']>;
  appSecret?: InputMaybe<Scalars['String']['input']>;
  authorizationUrl?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  photoSourceId: Scalars['String']['input'];
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ExternalPhotoSourceStatus>;
  storeId: Scalars['String']['input'];
  tokenUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGenericRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<GenericRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateInvoiceInput = {
  invoicedAdjusted?: InputMaybe<Scalars['Decimal']['input']>;
  month: Scalars['Int']['input'];
  subscriptionId: Scalars['UUID']['input'];
  year: Scalars['Int']['input'];
};

export type UpdateLibraryMetadataInput = {
  branch: Scalars['String']['input'];
  doNotValidate: Scalars['Boolean']['input'];
  owner: Scalars['String']['input'];
  validationCulture?: InputMaybe<Scalars['String']['input']>;
  validationDefaultPackage?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLibraryScheduleUpdateInput = {
  hourUtc: Scalars['Int']['input'];
  weekDays: Array<DayOfWeek>;
};

export type UpdateLibraryScheduleUpdatesInput = {
  branch: Scalars['String']['input'];
  gitBranch?: InputMaybe<Scalars['String']['input']>;
  owner: Scalars['String']['input'];
  schedules?: InputMaybe<Array<UpdateLibraryScheduleUpdateInput>>;
};

export type UpdateLocalFiltersConfigurationInput = {
  filters?: InputMaybe<Array<LocalFilterName>>;
  mode: LocalFilterSelectionMode;
  storeId: Scalars['String']['input'];
};

export type UpdateLocaleStoreFilesInput = {
  content: Scalars['String']['input'];
  culture: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type UpdatePerfectlyClearRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<PerfectlyClearRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePicsartEffectsRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<PicsartEffectsRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePicsartRemoveBackgroundRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<PicsartRemoveBackgroundRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePicsartStyleTransferRemoteFilterInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<PicsartStyleTransferRemoteFilterDataInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateProjectVersionContentInput = {
  projectVersionId: Scalars['Int']['input'];
  projectXml: Scalars['String']['input'];
};

export type UpdateStockPhotoSourceInput = {
  albumsEndpoint?: InputMaybe<StockPhotoSourceEndpointInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  photosEndpoint?: InputMaybe<StockPhotoSourceEndpointInput>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateStoreBinaryFilesInput = {
  contentBase64: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type UpdateStoreConfigInput = {
  addToCartEndpoint?: InputMaybe<EndpointInput>;
  allowAnonymousRequest?: InputMaybe<Scalars['Boolean']['input']>;
  cartRedirectionPage?: InputMaybe<PageInput>;
  correctionsCompletedPage?: InputMaybe<PageInput>;
  defaultLibraryPackage?: InputMaybe<Scalars['String']['input']>;
  enableThumbnailGeneration?: InputMaybe<Scalars['Boolean']['input']>;
  exitPage?: InputMaybe<PageInput>;
  getAlternateProductsEndpoint?: InputMaybe<EndpointInput>;
  getPriceEndpoint?: InputMaybe<EndpointInput>;
  getProductQuantitiesEndpoint?: InputMaybe<EndpointInput>;
  getProductsPriceEndpoint?: InputMaybe<EndpointInput>;
  isProofingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRecentUploadsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  loginPage?: InputMaybe<PageInput>;
  onProjectDeletedEndpoint?: InputMaybe<EndpointInput>;
  statusUpdateEndpoint?: InputMaybe<EndpointInput>;
  storeId: Scalars['String']['input'];
  uploadInBackgroundEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateStoreDefaultDesignerBranchInput = {
  designerBranch: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type UpdateStoreFilesInput = {
  content: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type UpdateStoreFulfillerInput = {
  configs: Array<KeyValuePairOfStringAndStringInput>;
  fulfillerId: Scalars['String']['input'];
  isDefault: Scalars['Boolean']['input'];
  storeId: Scalars['String']['input'];
};

export type UpdateStoreGeneratedTextInput = {
  accessToken: Scalars['String']['input'];
  organization: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type UpdateStoreInput = {
  automaticDesignerUpgrade: Scalars['Boolean']['input'];
  environment: StoreEnvironment;
  id: Scalars['String']['input'];
  /** Problems that was already raised to the customer support team, temporarily removed from logs to reduce noise in monitoring. */
  ignoredProblems: Array<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** The platform on which the store runs, such as Shopify, Magento, WooCommerce. */
  platform?: InputMaybe<Scalars['String']['input']>;
  /** Which Mediaclip plugin is used, including Shopify, Magento, WooCommerce. */
  plugin?: InputMaybe<Scalars['String']['input']>;
  status: StoreStatus;
  /** Information to allow dev team to test the client integration quickly when deploying risky update or if the client is reporting a problem to reproduce quickly and collect information to understand and fix the problem */
  testDesignerInfo?: InputMaybe<Array<TestDesignerInfoInput>>;
};

export type UpdateStorePhotoUpscalingInput = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  enableMinDpiUpscale: Scalars['Boolean']['input'];
  enabled: Scalars['Boolean']['input'];
  minDpiUpscaleConfiguration?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['String']['input'];
  type: PhotoUpscalerType;
  upscaleOnlyProduct: Scalars['Boolean']['input'];
};

export type UpdateSubscriptionCleanupDelayInput = {
  giftingProjectCleanupDelayInDays?: InputMaybe<Scalars['Int']['input']>;
  photobookProjectCleanupDelayInDays?: InputMaybe<Scalars['Int']['input']>;
  printProjectCleanupDelayInDays?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['UUID']['input'];
};

export type UpdateSubscriptionInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billing?: InputMaybe<SubscriptionBillingInfoInput>;
  contact?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  licenses?: InputMaybe<SubscriptionLicensesInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UploadGeneratedImageEditorInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  contentBase64?: InputMaybe<Scalars['String']['input']>;
  definitionId: Scalars['UUID']['input'];
  filename: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  /** A list of guest user identifier this user has used for consolidation, only for consolidated users */
  aliases?: Maybe<Array<Scalars['UUID']['output']>>;
  /** Id of the real account if the user is a guest and consolidation has been made */
  consolidatedId?: Maybe<Scalars['UUID']['output']>;
  /** An unique ID created by Mediaclip Hub */
  id: Scalars['UUID']['output'];
  /** A field to determine whether the user is deleted or not */
  isDeleted: Scalars['Boolean']['output'];
  /** A field to determine that user has an anonymous or real account */
  isGuest: Scalars['Boolean']['output'];
  projects?: Maybe<Array<Project>>;
  store: Store;
  /** Id of the store of the user */
  storeId: Scalars['String']['output'];
  /** Id of the user in the store e-commerce system */
  storeUserId: Scalars['String']['output'];
};


export type UserProjectsArgs = {
  listUnsavedProjects?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserFulfillerPermissions = {
  __typename?: 'UserFulfillerPermissions';
  fulfillerId: Scalars['String']['output'];
  fulfillerPermissions: Array<FulfillerPermission>;
};

export type UserGlobalPermissions = {
  __typename?: 'UserGlobalPermissions';
  fulfillerPermissions: Array<FulfillerPermission>;
  globalPermissions: Array<GlobalPermission>;
  libraryPermissions: Array<LibraryPermission>;
  storePermissions: Array<StorePermission>;
  subscriptionPermissions: Array<SubscriptionPermission>;
};

export type UserLibraryPermissions = {
  __typename?: 'UserLibraryPermissions';
  libraryOwner: Scalars['String']['output'];
  libraryPermissions: Array<LibraryPermission>;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  downloads: Array<Scalars['String']['output']>;
  globalRoles: Array<GlobalRole>;
  user: Auth0User;
};

export type UserSearchResult = {
  __typename?: 'UserSearchResult';
  hubUserId: Scalars['UUID']['output'];
  storeId: Scalars['String']['output'];
  storeUserId: Scalars['String']['output'];
};

export type UserStorePermissions = {
  __typename?: 'UserStorePermissions';
  storeId: Scalars['String']['output'];
  storePermissions: Array<StorePermission>;
};

export type UserSubscriptionPermissions = {
  __typename?: 'UserSubscriptionPermissions';
  storePermissions: Array<StorePermission>;
  subscriptionId: Scalars['UUID']['output'];
  subscriptionPermissions: Array<SubscriptionPermission>;
};

export type VolumeTimelineReportRow = {
  __typename?: 'VolumeTimelineReportRow';
  orderCount: Scalars['Int']['output'];
  projectCount: Scalars['Int']['output'];
  timestampUtc: Scalars['DateTime']['output'];
};

export type Anomalies = {
  __typename?: 'anomalies';
  lines: Array<OrderLine>;
};
