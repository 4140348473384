import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterCategory, LocalFilterDefinition, RemoteFilter } from '../../../schema-dotnet';
import { FormControl } from '@angular/forms';

export interface EditFilterCategoryDialogData {
    mode: 'create' | 'edit';
    filterBuiltIn: LocalFilterDefinition[];
    remoteFilters: RemoteFilter[]
    filterCategory: FilterCategory;
    filterCategories: Array<FilterCategory>;
}

export interface EditFilterCategoryDialogResult {
    id?: string;
    label: string;
    builtInFilter: string[];
    remoteFilters: string[];
}

@Component({
    selector: 'admin-edit-filter-category.component',
    templateUrl: './edit-filter-category.component.html',
    styleUrls:['./edit-filter-category.component.scss']
})
export class EditFilterCategoryDialogComponent {
    public filterCategoryInfo: EditFilterCategoryDialogResult;
    public storeId: string;
    label: string;
    builtInFilters= new FormControl();
    remoteFilters= new FormControl();
    IsValid = true;

    constructor(public dialogRef: MatDialogRef<EditFilterCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: EditFilterCategoryDialogData
    ){
        if(data.filterCategory){
            this.filterCategoryInfo = {
                id: data.filterCategory.id,
                label: data.filterCategory.label,
                builtInFilter: [],
                remoteFilters: []
            };
            this.builtInFilters.setValue(data.filterCategory.builtInFilters);
            this.remoteFilters.setValue(data.filterCategory.remoteFilters.map(rf => rf.id));
        } else {
            this.filterCategoryInfo = {
                label: '',
                builtInFilter: [],
                remoteFilters: [],
            }
        }
    }

    verifyUnicityLabel(event: string){
        if (this.data.mode === "create" )
            this.IsValid = this.data.filterCategories.findIndex(x => x.label == event) == -1;
        else
            this.IsValid = this.data.filterCategories.findIndex(x => x.label == event && x.id != this.data.filterCategory.id) == -1;
    }

    valid() {
        this.filterCategoryInfo.builtInFilter = this.builtInFilters.value;
        this.filterCategoryInfo.remoteFilters = this.remoteFilters.value;
        this.dialogRef.close(this.filterCategoryInfo);
    }

    cancel() {
        this.dialogRef.close();
    }
}
