import {
    CreateGeneratedImageDefinitionInput,
    CreateGeneratedImageStoreAssociationInput,
    DeleteGeneratedImageEditorFileInput,
    FileEditorInformation,
    GeneratedImageDefinition,
    GeneratedImageStoreAssociation,
    MediaclipMutationCreateGeneratedImageDefinitionArgs,
    MediaclipMutationCreateGeneratedImageStoreAssociationArgs,
    MediaclipMutationDeleteGeneratedImageEditorFileArgs,
    MediaclipMutationUpdateGeneratedImageDefinitionArgs,
    MediaclipMutationUpdateGeneratedImageStoreAssociationArgs,
    MediaclipMutationUploadGeneratedImageEditorFileArgs,
    Store,
    UpdateGeneratedImageDefinitionInput,
    UpdateGeneratedImageStoreAssociationInput,
    UploadGeneratedImageEditorInput
} from 'schema-dotnet';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GraphDotnetService } from './graph-dotnet-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MediaclipError } from '../errors/mediaclip-error';

@Injectable()
export class GeneratedImagesService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService,
        private readonly httpClient: HttpClient
    ) {
    }

    listForGlobal(): Observable<GeneratedImageDefinition[]> {
        return this.graphDotnetService.query(`
            query getGeneratedImageDefinitions {
                generatedImageDefinitions() {
                    id
                    displayName
                    defaultAlias
                    description
                }
            }
            `
        ).pipe(map(e => e.generatedImageDefinitions));
    }

    listForSubscription(subscriptionId: string): Observable<GeneratedImageDefinition[]> {
        return this.graphDotnetService.query(`
            query getSubscriptionGeneratedImageDefinitions($subscriptionId: UUID!) {
                subscription(id: $subscriptionId) {
                    generatedImages {
                        id
                        displayName
                        defaultAlias
                        description
                        subscriptionId
                    }
                }
            }
            `,
            {subscriptionId}
        ).pipe(map(e => e.subscription.generatedImages));
    }

    loadDefinition(definitionId: any): Observable<GeneratedImageDefinition> {
        return this.graphDotnetService.query(`
            query getGeneratedImageDefinition($definitionId: UUID!) {
                generatedImageDefinition(id: $definitionId) {
                    id
                    version
                    displayName
                    kind
                    defaultAlias
                    description
                    endpoint
                    preserveAspectRatio
                    maxWidth
                    maxHeight
                    warning
                    fileEditorInformation {
                        filename
                        url
                        mimeType
                    }
                    configs {
                        headers {
                            name
                            value
                            isSecret
                        }
                        additionalProperties {
                            name
                            value
                            isSecret
                        }
                        editorParameters {
                            name
                            value
                            isSecret
                        }
                    }
                }
            }
            `,
            { definitionId: definitionId }
        ).pipe(map(e => e.generatedImageDefinition));
    }

    listAvailableDefinitionForStore(storeId: string): Observable<GeneratedImageDefinition[]> {
        return this.graphDotnetService.query(`
            query getAvailableGeneratedImageDefinitions($storeId: String!) {
                store(id: $storeId) {
                    id
                    subscriptionId
                    availableGeneratedImageDefinitions {
                        id
                        displayName
                        defaultAlias
                        description
                        subscriptionId
                    }
                }
            }
            `,
            { storeId }
        ).pipe(map(e => e.store.availableGeneratedImageDefinitions));
    }

    listStoreAssociations(storeId: string): Observable<Store> {
        return this.graphDotnetService.query(`
            query getGeneratedImageStoreAssociation($storeId: String!) {
                store(id: $storeId) {
                    id
                    subscriptionId
                    generatedImageAssociations {
                        id
                        alias
                        storeId
                        definition {
                            id
                            displayName
                            kind
                            description
                            warning
                        }
                    }
                }
            }
            `,
            { storeId }
        ).pipe(map(e => e.store));
    }

    loadStoreAssociation(storeId: string, associationId: string): Observable<GeneratedImageStoreAssociation> {
        return this.graphDotnetService.query(`
            query getGeneratedImageStoreAssociation($storeId: String!, $associationId: UUID!) {
                store(id: $storeId) {
                    generatedImageAssociation(id: $associationId) {
                        id
                        alias
                        storeId
                        definition {
                            id
                            displayName
                            kind
                            description
                            warning
                        }
                        configs {
                            headers {
                                name
                                value
                            }
                            additionalProperties {
                                name
                                value
                            }
                            editorParameters {
                                name
                                value
                            }
                        }
                    }
                }
            }
            `,
            { storeId, associationId }
        ).pipe(map(e => e.store.generatedImageAssociation));
    }

    createGeneratedImageDefinition(input: CreateGeneratedImageDefinitionInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateGeneratedImageDefinitionArgs>(`
                mutation createGeneratedImageDefinition($input: CreateGeneratedImageDefinitionInput!) {
                    createGeneratedImageDefinition(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.createGeneratedImageDefinition));
    }

    updateGeneratedImageDefinition(input: UpdateGeneratedImageDefinitionInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateGeneratedImageDefinitionArgs>(`
                mutation updateGeneratedImageDefinition($input: UpdateGeneratedImageDefinitionInput!) {
                    updateGeneratedImageDefinition(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.updateGeneratedImageDefinition));
    }

    createGeneratedImageStoreAssociation(input: CreateGeneratedImageStoreAssociationInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateGeneratedImageStoreAssociationArgs>(`
                mutation createGeneratedImageStoreAssociation($input: CreateGeneratedImageStoreAssociationInput!) {
                    createGeneratedImageStoreAssociation(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.createGeneratedImageDefinition));
    }

    updateGeneratedImageStoreAssociation(input: UpdateGeneratedImageStoreAssociationInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateGeneratedImageStoreAssociationArgs>(`
                mutation updateGeneratedImageStoreAssociation($input: UpdateGeneratedImageStoreAssociationInput!) {
                    updateGeneratedImageStoreAssociation(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.updateGeneratedImageDefinition));
    }

    uploadGeneratedImageEditorFile(input: UploadGeneratedImageEditorInput): Observable<FileEditorInformation> {
        return this.graphDotnetService.mutate<MediaclipMutationUploadGeneratedImageEditorFileArgs>(`
                mutation uploadGeneratedImageEditorFile($input: UploadGeneratedImageEditorInput!) {
                    uploadGeneratedImageEditorFile(input: $input) {
                        filename
                        url
                        mimeType
                    }
                }
            `, { input }
        ).pipe(map(x => x.uploadGeneratedImageEditorFile));
    }

    deleteGeneratedImageEditorFile(input: DeleteGeneratedImageEditorFileInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteGeneratedImageEditorFileArgs>(`
                mutation deleteGeneratedImageEditorFile($input: DeleteGeneratedImageEditorFileInput!) {
                    deleteGeneratedImageEditorFile(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.deleteGeneratedImageEditorFile));
    }

    getEditorFile(url: string): Observable<{content: string, mimeType: string | null}> {
        return this.httpClient
            .get(url, {
                observe: 'response',
                responseType: 'text'
            })
            .pipe(
                catchError((err) => {
                    if (err instanceof HttpErrorResponse && err.status === 404) {
                        return of('');
                    }
                    throw new MediaclipError(`Unable to load generated image asset file at ${url}`, {innerError: err});
                }),
                map((response) => {
                    if (typeof response === 'string')
                        throw new Error('Not supported response of type string in getEditorFile')
                    return {
                        content: response.body || '',
                        mimeType: response.headers.get('Content-Type'),
                    }
                })
            );
    }

    deleteGeneratedImageAssociation(id: string): Observable<void> {
        return this.graphDotnetService.mutate(`
        mutation deleteGeneratedImageStoreAssociation($id: UUID!) {
            deleteGeneratedImageStoreAssociation(id: $id)
        }`, { id })
        .pipe(map(x => x.deleteGeneratedImageStoreAssociation));
    }
}
