<admin-page-simple-content [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Generated Images'"
  [icon]="'qr_code'">
  @if (storeWithGeneratedImages) {
    <div class="content">
      <section class="header">
        <h2>Information</h2>
        <p>
          This page allows you to configure the generated images you want to use for this store.
          The <code>alias</code> will be used to identify the generated image in a project following this urn
          format
          <code>urn:mediaclip:generated-image:&lbrace;alias&rbrace;</code>. Except for complex scenario, leave the
          default value when you add a new generated image.
        </p>
        <p>
          If you want to add your own implementation, please follow this
          <a [routerLink]="['/subscriptions', storeWithGeneratedImages.subscriptionId, 'generated-images']">documentation</a>.
        </p>
      </section>
      <section class="configuration-title">
        <h2>Configured Generated Images</h2>
        <a mat-stroked-button
          [disabled]="pageStatus === 'saving'"
          [routerLink]="'create'">
          <mat-icon>add</mat-icon>
          Add Generated Image
        </a>
      </section>
      @if (pageStatus === 'saveError' && pageError) {
        <admin-error [error]="pageError"></admin-error>
      }
      <section class="configuration">
        <div class="associations">
          @for (association of storeWithGeneratedImages.generatedImageAssociations; track association; let last = $last) {
            <div class="association">
              <span class="name">
                <div>
                  <a [routerLink]="association.id">{{association.definition.displayName}}</a>
                  @if (association.definition.description) {
                    - <span class="mat-caption">{{association.definition.description | truncate:80}}</span>
                  }
                </div>
                <div class="urn">
                  <code adminCopyToClipboard>urn:mediaclip:generated-image:{{association.alias}}</code>
                </div>
              </span>
              <button mat-icon-button
                class="actions"
                [disabled]="pageStatus === 'saving'"
                [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <mat-menu #menu>
              <button mat-menu-item [disabled]="pageStatus === 'saving'" [routerLink]="association.id">
                <mat-icon>edit</mat-icon>
                Edit
              </button>
              <button mat-menu-item [disabled]="pageStatus === 'saving'" (click)="delete(association)">
                <mat-icon color="warn">delete</mat-icon>
                Delete
              </button>
            </mat-menu>
            @if (!last) {
              <mat-divider></mat-divider>
            }
          }
        </div>
      </section>
    </div>
  }
</admin-page-simple-content>
