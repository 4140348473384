@if (message) {
  <div class="content">
    @if (showTitle) {
      <div class="error-code">{{ code || 'An error occurred' }}</div>
    }
    <button mat-stroked-button color="primary" (click)="copyErrorInfo()">
      <mat-icon>content_copy</mat-icon>
      Copy error information
    </button>
    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{message || 'Error' |truncate:100 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          @if (message) {
            <pre>{{message}}</pre>
          }
          @if (failedQuery) {
            <div class="sub-message">
              <p>The following query failed</p>
              <pre>{{ removeLeadingWhitespace(failedQuery) }}</pre>
            </div>
          }
          @if (responseBody) {
            <div class="sub-message">
              <p>with the following response</p>
              <pre>{{ responseBody }}</pre>
            </div>
          }
          @if (stack) {
            <pre class="sub-message">{{ stack }}</pre>
          }
          @if (details) {
            <pre class="sub-message">{{ details }}</pre>
          }
        </div>
        @if (innerError) {
          <admin-error [error]="innerError" [showTitle]="false"></admin-error>
        }
      </mat-expansion-panel>
    </mat-accordion>
  </div>
}
