import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/json-lint';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { JSHINT } from "jshint";
(<any>window).JSHINT = JSHINT;

export function getBaseUrl(): string {
    return document.getElementsByTagName('base')[0].href;
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => {
        const errorMsgElement = document.querySelector('#initialization-error');
        if (errorMsgElement) {
            errorMsgElement.innerHTML = `An error occurred during initialization.<br>Please try to reload in a few instants.<br>If the problem persists contact <a href="mailto:support@mediacliphub.com">support@mediacliphub.com</a>`;
        }
        const spinnerElement = document.querySelector('#fuse-splash-screen .spinner-wrapper');
        if (spinnerElement) {
            spinnerElement.remove();
        }
        console.error(err)
    });
