import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GraphDotnetService } from '../../services/graph-dotnet-service';
import { RemoteFilter, RemoteFilterKind } from '../../../schema-dotnet';
import { PageStatus } from '../../models';
import {
    EditRemoteFilterDialogComponent,
    EditRemoteFilterDialogData,
    EditRemoteFilterDialogResult
} from '../edit-remote-filter-dialog/edit-remote-filter-dialog.component';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { RemoteFilterService } from '../../services/remote-filter.service';
import { MatTable } from "@angular/material/table";

@Component({
    selector: 'admin-store-remote-filter-page.ts',
    templateUrl: './store-remote-filter-page.component.html',
    styleUrls: ['./store-remote-filter-page.component.scss']
})
export class StoreRemoteFilterPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    public storeId: string;

    @ViewChild('storeRemoteFiltersTable', {static: false})
    remoteFiltersTable: MatTable<RemoteFilter>;
    remoteFilters: RemoteFilter[] = [];
    displayedRemoteFilters: RemoteFilter[] = [];
    displayedColumns: string[] = [
        'thumbnail',
        'visible',
        'order',
        'name',
        'group',
        'actions'
    ];
    filter: {
        name: string;
    } = {
        name: ''
    };

    constructor(
        private readonly route: ActivatedRoute,
        private readonly graphDotnetService: GraphDotnetService, // FIXME: Move things to RemoteFilterService
        private readonly matDialog: MatDialog,
        private readonly remoteFilterService: RemoteFilterService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.loadRemoteFilters();
        }));
    }

    private loadRemoteFilters() {
        this.subscribeWithGenericSavinErrorHandling(
            this.remoteFilterService.loadRemoteFilters(this.storeId),
            (result) => {
                this.remoteFilters = result;
                this.updateDisplayedRemoteFilters();
            }
        );
    }

    updateFilter() {
        this.updateDisplayedRemoteFilters();
    }

    private updateDisplayedRemoteFilters() {
        this.displayedRemoteFilters = this.remoteFilters
            .filter(s => this.isRemoteFilterVisible(s))
            .sort((a, b) => {
                return (a.group ?? "").localeCompare(b.group ?? "")
            });
        this.remoteFiltersTable.renderRows();
    }

    private isRemoteFilterVisible(f: RemoteFilter) {
        if (this.filter.name) {
            if (f.name.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return true;
            } else if (f.alias && f.alias.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return true;
            }
            return false;
        }
        return true;
    }

    remoteFilterId(i: number, remoteFilter: RemoteFilter) {
        return remoteFilter.id;
    }

    openCreateFilterDialog() {
        const dialogRef = this.matDialog.open<EditRemoteFilterDialogComponent, EditRemoteFilterDialogData, EditRemoteFilterDialogResult>(EditRemoteFilterDialogComponent, {
            data: {mode: 'create'} as EditRemoteFilterDialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.pageStatus = PageStatus.saving;
            let mutation: string;
            const variable = {input: {...result, storeId: this.storeId, kind: undefined}};
            switch (result.kind) {
                case RemoteFilterKind.Generic:
                    mutation = `mutation CreateRemoteFilters($input: CreateGenericRemoteFilterInput!) {
                        createGenericRemoteFilter(input: $input) {
                            id
                        }
                    }`;
                    break;
                case RemoteFilterKind.PerfectlyClear:
                    mutation = `mutation CreateRemoteFilters($input: CreatePerfectlyClearRemoteFilterInput!) {
                        createPerfectlyClearRemoteFilter(input: $input) {
                            id
                        }
                    }`;
                    break;
                case RemoteFilterKind.PicsartRemoveBackground:
                    mutation = `mutation CreateRemoteFilters($input: CreatePicsartRemoveBackgroundRemoteFilterInput!) {
                        createPicsartRemoveBackgroundRemoteFilter(input: $input) {
                            id
                        }
                    }`;
                    break;
                case RemoteFilterKind.PicsartEffects:
                    mutation = `mutation CreateRemoteFilters($input: CreatePicsartEffectsRemoteFilterInput!) {
                        createPicsartEffectsRemoteFilter(input: $input) {
                            id
                        }
                    }`;
                    break;
                case RemoteFilterKind.PicsartStyleTransfer:
                    mutation = `mutation CreateRemoteFilters($input: CreatePicsartStyleTransferRemoteFilterInput!) {
                        createPicsartStyleTransferRemoteFilter(input: $input) {
                            id
                        }
                    }`;
                    break;
            }
            this.graphDotnetService.query(mutation, variable).subscribe({
                next: _v => {
                    this.pageStatus = PageStatus.loaded;
                    this.loadRemoteFilters();
                },
                error: error => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = error;
                }
            });
        });
    }

    openEditRemoteFilter(remoteFilter: RemoteFilter) {
        const dialogRef = this.matDialog.open<EditRemoteFilterDialogComponent, EditRemoteFilterDialogData, EditRemoteFilterDialogResult>(EditRemoteFilterDialogComponent, {
            data: {mode: 'edit', remoteFilter: remoteFilter} as EditRemoteFilterDialogData
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.pageStatus = PageStatus.saving;
            let mutation: string;
            const variable = {input: {...result, id: remoteFilter.id, kind: undefined}};
            switch (result.kind) {
                case RemoteFilterKind.Generic:
                    mutation = `mutation UpdateRemoteFilters($input: UpdateGenericRemoteFilterInput!) {
                        updateGenericRemoteFilter(input: $input)
                    }`;
                    break;
                case RemoteFilterKind.PerfectlyClear:
                    mutation = `mutation UpdateRemoteFilters($input: UpdatePerfectlyClearRemoteFilterInput!) {
                        updatePerfectlyClearRemoteFilter(input: $input)
                    }`;
                    break;
                case RemoteFilterKind.PicsartRemoveBackground:
                    mutation = `mutation UpdateRemoteFilters($input: UpdatePicsartRemoveBackgroundRemoteFilterInput!) {
                        updatePicsartRemoveBackgroundRemoteFilter(input: $input)
                    }`;
                    break;
                case RemoteFilterKind.PicsartEffects:
                    mutation = `mutation UpdateRemoteFilters($input: UpdatePicsartEffectsRemoteFilterInput!) {
                        updatePicsartEffectsRemoteFilter(input: $input)
                    }`;
                    break;
                case RemoteFilterKind.PicsartStyleTransfer:
                    mutation = `mutation UpdateRemoteFilters($input: UpdatePicsartStyleTransferRemoteFilterInput!) {
                        updatePicsartStyleTransferRemoteFilter(input: $input)
                    }`;
                    break;
            }
            this.graphDotnetService.query(mutation, variable).subscribe({
                next: _v => {
                    this.pageStatus = PageStatus.loaded;
                    this.loadRemoteFilters();
                },
                error: error => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = error;
                }
            });
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    async uploadRemoteFilterThumbnail(remoteFilter: RemoteFilter, files: FileList) {
        if (files.length !== 1) {
            return;
        }

        let file = files[0];
        const contentBase64 = await blobToBase64(file);
        this.subscribeWithGenericSavinErrorHandling(
            this.remoteFilterService.setRemoteFilterThumbnail({
                remoteFilterId: remoteFilter.id,
                contentBase64: contentBase64,
                mimeType: file.type
            }),
            () => {
                this.loadRemoteFilters();
            }
        );
    }

    removeRemoteFilterThumbnail(remoteFilter: RemoteFilter) {
        this.subscribeWithGenericSavinErrorHandling(
            this.remoteFilterService.setRemoteFilterThumbnail({
                remoteFilterId: remoteFilter.id
            }),
            () => {
                this.loadRemoteFilters();
            }
        );
    }
}

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const raw = (reader.result as string);
            const separator = raw.indexOf(',');
            resolve(raw.substring(separator === -1 ? 0 : separator + 1) as string);
        };
        reader.readAsDataURL(blob);
    });
}
