import { Component, OnInit } from '@angular/core';
import { Fulfiller, FulfillerClient, GlobalPermission, Maybe } from 'schema-dotnet';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationContext, AuthorizationService, FulfillersService } from '../../services';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { MatDialog } from '@angular/material/dialog';
import { CreateFulfillerAppDialogComponent, CreateFulfillerAppDialogResult } from './create-fulfiller-app-dialog.component';
import { LinkFulfillerAppDialogComponent, LinkFulfillerAppDialogResult } from './link-fulfiller-app-dialog.component';
import { EditFulfillerDialogComponent, EditFulfillerDialogResult } from './edit-fulfiller-dialog.component';

@Component({
    selector: 'admin-fulfiller-page',
    templateUrl: './fulfiller-page.component.html',
    styleUrls: ['./fulfiller-page.component.scss']
})
export class FulfillerPageContainerComponent extends AdminComponentBase implements OnInit {
    fulfillerId: string;
    fulfiller?: Maybe<Fulfiller>;

    private authorizationContext: AuthorizationContext;

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly fulfillersService: FulfillersService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));

        this.registerSubscription(this.route.params.subscribe(params => {
            this.fulfillerId = params.fulfillerId;
            this.subscribeWithGenericLoadingErrorHandling(this.fulfillersService.load(this.fulfillerId), fulfiller => {
                this.fulfiller = fulfiller;
            });
        }));
    }

    reload() {
        this.subscribeWithGenericLoadingErrorHandling(this.fulfillersService.load(this.fulfillerId), fulfiller => {
            this.fulfiller = fulfiller;
        });
    }

    canManageFulfillers() {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageFulfillers);
    }

    createFulfillerAppAndLinkToIt(fulfiller: Fulfiller) {
        this.dialog.open<CreateFulfillerAppDialogComponent, any, CreateFulfillerAppDialogResult>(CreateFulfillerAppDialogComponent).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.fulfillersService.createFulfillerApp({
                displayName: result.displayName
            }), result => {
                this.subscribeWithGenericSavinErrorHandling(this.fulfillersService.updateFulfiller({
                    id: fulfiller.id,
                    fulfillerAppId: result.id
                }), () => {
                    this.reload();
                });
            });
        });
    }

    linkFulfillerApp(fulfiller: Fulfiller) {
        this.dialog.open<LinkFulfillerAppDialogComponent, any, LinkFulfillerAppDialogResult>(LinkFulfillerAppDialogComponent).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.fulfillersService.updateFulfiller({
                id: fulfiller.id,
                fulfillerAppId: result.appId
            }), () => {
                this.reload();
            });
        });
    }

    editFulfiller(fulfiller: Fulfiller) {
        let dialogRef = this.dialog.open<EditFulfillerDialogComponent, Fulfiller, EditFulfillerDialogResult>(
            EditFulfillerDialogComponent,
            { data: fulfiller }
        );
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.subscribeWithGenericSavinErrorHandling(this.fulfillersService.updateFulfiller({
                id: fulfiller.id,
                client: result.client || undefined,
                fulfillerAppId: result.fulfillerAppId || undefined,
                fulfillerAppKey: result.client == FulfillerClient.Fuji ? (result.appKey || undefined) : undefined,
                libraryPackage: result.libraryPackage || undefined,
                url: result.url || undefined,
            }), () => {
                this.reload();
            });
        });
    }
}
