import { Injectable } from '@angular/core';
import { Invoice, MediaclipMutationUpdateInvoiceArgs, UpdateInvoiceInput } from 'schema-dotnet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from './graph-dotnet-service';

@Injectable()
export class InvoiceService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    adjustInvoiced(input: UpdateInvoiceInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateInvoiceArgs>(`
                mutation updateInvoice($input: UpdateInvoiceInput!) {
                    updateInvoice(input: $input)
                }
            `,
            { input }
        ).pipe(map(result => result.updateInvoice));
    }
}
