import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpHeader, StoreConfig} from 'schema-dotnet';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreConfigService} from '../../services';
import {AdminComponentBase} from '../../utils/admin-component-base';

@Component({
    selector: 'admin-store-endpoints',
    templateUrl: './store-endpoints-page.component.html',
    styleUrls: ['./store-endpoints-page.component.scss']
})
export class StoreEndpointsPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    storeId: string;
    storeConfig?: StoreConfig;

    urlPattern = /^https?:\/\/.+$/;
    invalidUrlMessage = 'This url is not valid.';

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly storeConfigService: StoreConfigService
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeConfigService.getStoreConfig(this.storeId), storeConfig => {
                let storeConfigNoNull = {...storeConfig};
                if (!storeConfigNoNull.addToCartEndpoint) {
                    storeConfigNoNull.addToCartEndpoint = {headers: []};
                }
                if (!storeConfigNoNull.getProductsPriceEndpoint) {
                    storeConfigNoNull.getProductsPriceEndpoint = {headers: []};
                }
                if (!storeConfigNoNull.getPriceEndpoint) {
                    storeConfigNoNull.getPriceEndpoint = {headers: []};
                }
                if (!storeConfigNoNull.onProjectDeletedEndpoint) {
                    storeConfigNoNull.onProjectDeletedEndpoint = {headers: []};
                }
                if (!storeConfigNoNull.statusUpdateEndpoint) {
                    storeConfigNoNull.statusUpdateEndpoint = {headers: []};
                }
                if(!storeConfigNoNull.getProductQuantitiesEndpoint){
                    storeConfigNoNull.getProductQuantitiesEndpoint = {headers: []}
                }
                if(!storeConfigNoNull.getAlternateProductsEndpoint){
                    storeConfigNoNull.getAlternateProductsEndpoint = {headers: []}
                }
                this.storeConfig = storeConfigNoNull;
            });
        }))
    }

    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    save() {
        if (!this.storeConfig) {
            return;
        }

        this.subscribeWithGenericSavinErrorHandling(this.storeConfigService.updateStoreConfig({
            storeId: this.storeId,
            cartRedirectionPage: this.storeConfig.cartRedirectionPage,
            exitPage: this.storeConfig.exitPage,
            loginPage: this.storeConfig.loginPage,
            correctionsCompletedPage: this.storeConfig.correctionsCompletedPage,
            getPriceEndpoint: this.storeConfig.getPriceEndpoint,
            addToCartEndpoint: this.storeConfig.addToCartEndpoint,
            getProductsPriceEndpoint: this.storeConfig.getProductsPriceEndpoint,
            statusUpdateEndpoint: this.storeConfig.statusUpdateEndpoint,
            onProjectDeletedEndpoint: this.storeConfig.onProjectDeletedEndpoint,
            getProductQuantitiesEndpoint: this.storeConfig.getProductQuantitiesEndpoint,
            getAlternateProductsEndpoint: this.storeConfig.getAlternateProductsEndpoint
        }));
    }

    removeHeader(headers: Array<HttpHeader>, index: number): void {
        headers.splice(index, 1);
    }

    addHeader(headers: Array<HttpHeader>): void {
        headers.push({
            name: '',
            value: ''
        });
    }
}
