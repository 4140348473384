import { Component, OnInit } from '@angular/core';
import { StoreFulfillersService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { PageStatus } from '../../models';
import { FulfillerLog } from '../../../schema-dotnet';

@Component({
    selector: 'admin-store-fulfiller-logs-page',
    templateUrl: './store-fulfiller-logs-page.component.html',
    styleUrls: ['./store-fulfiller-logs-page.component.scss']
})
export class StoreFulfillerLogsPageContainerComponent implements OnInit {
    storeId: string;
    fulfillerId: string;

    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;

    logs: FulfillerLog[] = [];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly storeFulfillersService: StoreFulfillersService
    ) {
        this.storeId = this.route.snapshot.params.storeId;
        this.fulfillerId = this.route.snapshot.params.fulfillerId;
    }

    ngOnInit() {
        this.storeFulfillersService.getStoreFulfillerLogs(this.storeId, this.fulfillerId)
            .subscribe({
                next: logs => {
                    this.logs = logs;
                    this.pageStatus = PageStatus.loaded;
                },
                error: err => {
                    this.pageError = err;
                    this.pageStatus = PageStatus.loadError;
                }
            });
    }

    prettifyJson(json?: string): string | undefined {
        if (!json)
            return undefined;
        if (!json.startsWith('{'))
            return json;
        return JSON.stringify(JSON.parse(json), null, '  ');
    }
}
