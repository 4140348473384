import { Injectable } from '@angular/core';
import { GraphDotnetService } from './graph-dotnet-service';
import { Observable } from 'rxjs';
import { EnableRequestLoggerInput, RequestLog, RequestLoggingConfiguration, RetryAnomalies } from '../../schema-dotnet';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RequestLoggerService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    loadRequestLoggerConfiguration(appName: string): Observable<RequestLoggingConfiguration> {
        return this.graphDotnetService.query(
            `query getRequestLoggerConfiguration($appName: String!) {
              requestLoggerConfiguration(appName: $appName) {
                logsExpirationInSeconds
                rules {
                  id
                  routePrefix
                  storeId
                  statusCode
                  maximumCaptureCount
                  logsExpirationInSeconds
                }
              }
            }`, {
                appName: appName
            })
            .pipe((map(q => q.requestLoggerConfiguration)));
    }

    loadRequestLogs(ruleId: string): Observable<RequestLog[]> {
        return this.graphDotnetService.query(
            `query requestLogs($ruleId: UUID!) {
              requestLogs(ruleId: $ruleId) {
                url
                statusCode
                body
                date
                headers {
                  name
                  value
                }
              }
            }`, {
                ruleId: ruleId
            })
            .pipe((map(q => q.requestLogs)));
    }

    enableRequestLogger(appName: string, input: EnableRequestLoggerInput): Observable<void> {
        return this.graphDotnetService.mutate(`
                mutation enableRequestLogger($appName: String!, $input: EnableRequestLoggerInput!) {
                    enableRequestLogger(
                        appName: $appName
                        input: $input
                    )
                }
            `, {appName, input})
            .pipe((map(q => q.enableRequestLogger)));
    }

    disableRequestLogger(appName: string, ruleId: string): Observable<void> {
        return this.graphDotnetService.mutate(`
                mutation disableRequestLogger($appName: String!, $ruleId: UUID!) {
                    disableRequestLogger(
                        appName: $appName
                        ruleId: $ruleId
                    )
                }
            `, {appName, ruleId})
            .pipe((map(q => q.disableRequestLogger)));
    }
}
