import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Fulfiller, GlobalPermission } from 'schema-dotnet';
import { AuthorizationContext, AuthorizationService, FulfillersService } from '../../services';
import { PageStatus } from '../../models';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddFulfillerDialogComponent, AddFulfillerDialogResult } from './add-fulfiller-dialog.component';
import { AdminComponentBase } from '../../utils/admin-component-base';
import { Router } from '@angular/router';

@Component({
    selector: 'admin-fulfillers-page',
    templateUrl: './fulfillers-page.component.html',
    styleUrls: ['./fulfillers-page.component.scss']
})
export class FulfillersPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    @ViewChild('fulfillersTable', { static: false })
    fulfillersTable: MatTable<any>;

    fulfillers: Fulfiller[] = [];
    displayedFulfillers: Fulfiller[] = [];

    displayedColumns: string[] = [
        'id',
        'libraryPackage',
        'client',
        'url'
    ];

    filter: {
        name: string;
    } = {
        name: ''
    };

    private authorizationContext: AuthorizationContext;

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly fulfillersService: FulfillersService,
        private readonly dialog: MatDialog,
        private readonly router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));

        this.subscribeWithGenericLoadingErrorHandling(this.fulfillersService.list(), (fulfillers) => {
            this.fulfillers = fulfillers;
            this.updateDisplayedFulfillers();
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    updateFilter() {
        this.updateDisplayedFulfillers();
    }

    private updateDisplayedFulfillers() {
        this.displayedFulfillers = this.fulfillers
            .filter(s => this.isFulfillersVisible(s))
            .sort((a, b) => a.id.localeCompare(b.id));
        this.fulfillersTable.renderRows();
    }


    private isFulfillersVisible(f: Fulfiller) {
        if (this.filter.name) {
            if (!f.id.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return false;
            }
        }
        return true;
    }

    fulfillerId(i: number, fulfiller: Fulfiller) {
        return fulfiller.id;
    }

    addFulfiller() {
        this.dialog.open<AddFulfillerDialogComponent, void, AddFulfillerDialogResult>(AddFulfillerDialogComponent).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.fulfillersService.createFulfiller({
                id: result.fulfillerId,
                client: result.client
            }), () => {
                this.router.navigate(['/fulfillers', result.fulfillerId]);
            });
        });
    }

    canCreateFulfiller(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageFulfillers);
    }
}
