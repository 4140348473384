import { Component, ElementRef, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[adminCopyToClipboard]',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent {
    @Input()
    textToCopy?: string;

    constructor(
        private readonly clipboard: Clipboard,
        private readonly elementRef: ElementRef,
        private readonly snackBar: MatSnackBar,
    ) {
    }

    copyToClipboard() {
        if (this.textToCopy) {
            this.clipboard.copy(this.textToCopy);
            this.snackBar.open('Text copied', undefined, {duration: 1800})
        }
       else {
            let textToCopy = this.elementRef.nativeElement.textContent;
            if (textToCopy.endsWith('content_copy')) {
                textToCopy = textToCopy.substring(0, textToCopy.length - 'content_copy'.length)
            }
            this.clipboard.copy(textToCopy);
            this.snackBar.open('Text copied', undefined, {duration: 1800})
        }
    }
}
