<admin-page-simple-content id="store-fulfillers"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Mediaclip Management'"
  [icon]="'business_center'">
  @if (storeWithMetadata && authContext) {
    <div class="content">
      @if (storeWithMetadata.status === 'Active' && canDeactivateSubscription(storeWithMetadata.subscriptionId)) {
        <div>
          <h2>Actions</h2>
          <button mat-stroked-button color="warn" (click)="deactivate()">
            Deactivate store
          </button>
        </div>
      }
      <h2>Information</h2>
      @if (storeWithMetadata.status !== 'Active' ) {
        <p class="deactivated-warning">
          <mat-icon color="warn">warning</mat-icon> This store is not active
          @if (storeWithMetadata.deactivationDateUtc) {
            It was deactivated {{ storeWithMetadata.deactivationDateUtc | date:'mediumDate' }} ({{ storeWithMetadata.deactivationDateUtc | fromNow }})
          }
        </p>
      }
      <form #form="ngForm" (ngSubmit)="save()">
        <mat-form-field class="dense-2">
          <mat-label>Display name</mat-label>
          <input matInput
            name="label"
            [(ngModel)]="storeWithMetadata.label"
            placeholder="www.mystore.example"
            autocomplete="off" />
          <mat-hint>Only used to identify your store in menus and reports.</mat-hint>
        </mat-form-field>
        <mat-form-field class="dense-2">
          <mat-label>Environment</mat-label>
          <mat-select name="environment" [(ngModel)]="storeWithMetadata.environment" placeholder="Environment">
            @for (entry of environments; track entry) {
              <mat-option [value]="entry.value">
                {{ entry.display }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="dense-2">
          <mat-label>Status</mat-label>
          <mat-select name="status" [(ngModel)]="storeWithMetadata.status">
            @for (entry of statuses; track entry) {
              <mat-option [value]="entry.value" [disabled]="entry.disabled">
                {{ entry.display }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="dense-2">
          <mat-label>Platform</mat-label>
          <mat-select name="platform" [(ngModel)]="storeWithMetadata.metadata.platform">
            @for (entry of platforms; track entry) {
              <mat-option [value]="entry.value">
                {{ entry.display }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="dense-2">
          <mat-label>Plugin</mat-label>
          <mat-select name="plugin" [(ngModel)]="storeWithMetadata.metadata.plugin">
            @for (entry of plugins; track entry) {
              <mat-option [value]="entry.value">
                {{ entry.display }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-checkbox name="automaticDesignerUpgrade" [(ngModel)]="storeWithMetadata.automaticDesignerUpgrade">
          Automatic Designer Upgrade: When the store is using Stable branch, and they want more time before getting the designer upgrade to the latest version, uncheck this
        </mat-checkbox>
        <div class="designer-info">
          <h2>Designer test info</h2>
          @for (info of storeWithMetadata.metadata.testDesignerInfo; track info; let i = $index) {
            <div class="designer-test-info">
              <mat-form-field class="url">
                <mat-label>Url</mat-label>
                <input matInput
                  [name]="'testDesignerInfoUrl' + i"
                  [(ngModel)]="info.url"
                  placeholder="https://"
                  autocomplete="off" />
                <mat-hint>The url to a page where it's possible to start the designer</mat-hint>
              </mat-form-field>
              <mat-form-field class="description">
                <mat-label>Description</mat-label>
                <input matInput
                  [name]="'testDesignerInfoDescription' + i"
                  [(ngModel)]="info.description"
                  autocomplete="off" />
                <mat-hint>Information to allow the support or the dev team to easily tests the integration of the customer.</mat-hint>
              </mat-form-field>
              <div class="actions">
                <button mat-icon-button (click)="removeDesignerTestInfo(i)"><mat-icon>delete</mat-icon></button>
              </div>
            </div>
          }
          <button class='add-designer-info' mat-stroked-button (click)="addDesignerTestInfo()">
            <mat-icon>add</mat-icon>
            Add info
          </button>
        </div>
        <h2>Logs</h2>
        <mat-form-field class="dense-2">
          <mat-label>Ignored problems</mat-label>
          <input matInput
            name="ignoredProblems"
            [(ngModel)]="storeWithMetadata.metadata.ignoredProblems"
            placeholder="problem1;problem2"
            [pattern]="noCommaPattern"
            autocomplete="off" />
          <mat-error>Commas are not allowed.</mat-error>
          <mat-hint>
            Temporary semicolon-separated list of problems to be ignored in logs. Use <code>*</code> to ignore everything for this store.
          </mat-hint>
        </mat-form-field>
        <admin-save-button
          [disabled]="!form.valid"
          [pageStatus]="pageStatus">
        </admin-save-button>
      </form>
    </div>
  }

  <div>
    @if (pageStatus === 'saveError' && pageError) {
      <admin-error [error]="pageError"></admin-error>
    }
  </div>

</admin-page-simple-content>
