import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {OrderLineStatusValues} from '../../../schema-dotnet';

@Component({
    changeDetection: ChangeDetectionStrategy.Default,
    selector: 'admin-style-page',
    templateUrl: './style-page.component.html',
    styleUrls: ['./style-page.component.scss'],
    animations: fuseAnimations,
})
export class StylePageContainerComponent {
    errorStatus = OrderLineStatusValues.Error;
    sentToRenderingStatus = OrderLineStatusValues.SentToRendering;
}
