<h2 mat-dialog-title>Add Subscription</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Subscription Label</mat-label>
            <input name="subscriptionLabel"
                   matInput
                   pattern="[a-zA-Z0-9\-]+"
                   [(ngModel)]="subscriptionInfo.subscriptionLabel"
                   required
            />
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        CANCEL
    </button>

    <button mat-button color="primary" (click)="valid()" [disabled]="!form.valid">
        CREATE
    </button>

</mat-dialog-actions>
