<admin-page-simple-content
  id="filter-categories"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Designer: Filter Categories'"
  [icon]="'folder'">

  <div class="content">
    <section class="info">
      <h2>Information</h2>
      <p>
        Changes might take up to 2 minutes to be active on the designer.
      </p>
      <p>You can add translations for the category label by adding them in the <a
      [routerLink]="['..', 'custom-locales']">Custom Locales</a>. Follow the
      <a href="https://doc.mediaclip.ca/designer/localization-overrides/#filterlabels" target="_blank"
        class="external">documentation
        <mat-icon>open_in_new</mat-icon>
        </a> where <code>Your.Category.ID</code> is the label of your category.
      </p>
    </section>

    <section class="configuration-title">
      <h2>Categories</h2>
      @if (canManageFilterCategories()) {
        <a mat-stroked-button
          [disabled]="pageStatus === 'saving'"
          (click)="addFilterCategory()"
          >
          <mat-icon>add</mat-icon>
          Add Category
        </a>
      }
    </section>

    <section class="configurations">
      @for (filterCategory of filterCategories; track filterCategory) {
        <div class="filter-category">
          <div class="title">
            {{ filterCategory.label }}
            <div class="action">
              <button mat-icon-button [matMenuTriggerFor]="filterCategoryMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #filterCategoryMenu>
                <button mat-menu-item (click)='openEditFilterCategory(filterCategory)'>
                  <mat-icon>edit</mat-icon>
                  Edit
                </button>
                <button mat-menu-item (click)='deleteFilterCategory(filterCategory)'>
                  <mat-icon color="warn">delete</mat-icon>
                  Delete
                </button>
              </mat-menu>
            </div>
          </div>
          @if (filterCategory.builtInFilters.length) {
            <div class="built-in-filters">
              <h4>Built-In filters</h4>
              <ul>
                @for (filter of filterCategory.builtInFilters; track filter) {
                  <li>
                    {{ filter }}
                  </li>
                }
              </ul>
            </div>
          }
          @if (filterCategory.remoteFilters.length) {
            <div class="remote-filters">
              <h4>Remote filters (<a [routerLink]="['..', 'remote-filters']">Edit remote filters</a>)</h4>
              <ul>
                @for (remoteFilter of filterCategory.remoteFilters; track remoteFilter) {
                  <li>
                    {{ remoteFilter.name }}
                  </li>
                }
              </ul>
            </div>
          }
        </div>
        <mat-divider></mat-divider>
      }
    </section>

    @if (pageStatus === 'saveError' && pageError) {
      <admin-error [error]="pageError"></admin-error>
    }
  </div>
</admin-page-simple-content>
