import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface UpdateSubscriptionInformationDialogData {
    label?: string;
    contact?: string;
    notes?: string;
}

export interface UpdateSubscriptionInformationDialogResult {
    label: string;
    contact: string;
    notes?: string;
}

@Component({
    templateUrl: 'update-subscription-info-dialog.html',
    styleUrls: ['update-subscription-info-dialog.scss']
})
export class UpdateSubscriptionInformationDialogComponent {
    contactPattern = /^[\w\-]+( .+)? <.+?>$/;
    result: UpdateSubscriptionInformationDialogResult;

    constructor(
        public dialogRef: MatDialogRef<UpdateSubscriptionInformationDialogComponent, UpdateSubscriptionInformationDialogResult>,
        @Inject(MAT_DIALOG_DATA) data: UpdateSubscriptionInformationDialogData
    ) {
        this.result = {
            contact: data.contact || '',
            label: data.label || '',
            notes: data.notes
        };
    }

    cancel(): void {
        this.dialogRef.close();
    }

    editSubscription() {
        this.dialogRef.close(this.result);
    }
}
