<h2 mat-dialog-title>Update invoiced value</h2>
<mat-dialog-content>
  <mat-form-field>
    <span matTextPrefix>{{data.currency}} &nbsp;</span>
    <input matInput [(ngModel)]="this.invoiceAmount" type="number">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="primary" (click)="valid()">Ok</button>
</mat-dialog-actions>
