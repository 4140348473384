import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticatedUser, FulfillerPermission, GlobalPermission, LibraryPermission, StorePermission, SubscriptionPermission} from 'schema-dotnet';
import {AuthenticationService} from './authentication.service';

export class AuthorizationContext {
    constructor(
        public readonly authenticatedUser: AuthenticatedUser
    ) {
    }

    hasStorePermissions(storeId: string, subscriptionId: string, permission: StorePermission) {
        if (this.authenticatedUser?.globalPermissions.storePermissions.includes(permission)) {
            return true;
        }
        if (this.authenticatedUser?.subscriptionPermissions.find(x => x.subscriptionId == subscriptionId)?.storePermissions.includes(permission)) {
            return true;
        }
        if (this.authenticatedUser?.storePermissions.find(x => x.storeId == storeId)?.storePermissions.includes(permission)) {
            return true;
        }
        return false;
    }

    hasSubscriptionPermissions(subscriptionId: string, permission: SubscriptionPermission) {
        if (this.authenticatedUser.globalPermissions.subscriptionPermissions.includes(permission)) {
            return true;
        }
        if (this.authenticatedUser.subscriptionPermissions.find(x => x.subscriptionId == subscriptionId)?.subscriptionPermissions.includes(permission)) {
            return true;
        }
        return false;
    }

    hasLibraryPermissions(libraryOwner: string, permission: LibraryPermission): boolean {
        if (this.authenticatedUser.globalPermissions.libraryPermissions.includes(permission)) {
            return true;
        }
        if (this.authenticatedUser.libraryPermissions.find(x => x.libraryOwner === libraryOwner)?.libraryPermissions.includes(permission)) {
            return true;
        }
        return false;
    }

    hasFulfillerPermissions(fulfillerId: string, permission: FulfillerPermission) {
        if (this.authenticatedUser?.globalPermissions.fulfillerPermissions.includes(permission)) {
            return true;
        }
        if (this.authenticatedUser?.fulfillerPermissions.find(x => x.fulfillerId == fulfillerId)?.fulfillerPermissions.includes(permission)) {
            return true;
        }
        return false;
    }

    hasGlobalPermissions(permission: string | GlobalPermission) {
        if (!this.authenticatedUser) {
            return false;
        }
        if (this.authenticatedUser?.globalPermissions.globalPermissions.includes(permission as GlobalPermission)) {
            return true;
        }
        return false;
    }
}

@Injectable()
export class AuthorizationService {
    private user$: Observable<AuthenticatedUser>;

    constructor(
        private readonly authenticationService: AuthenticationService
    ) {
        this.user$ = authenticationService.authenticatedUser$;
    }

    public authorizationContext(): Observable<AuthorizationContext> {
        return this.user$.pipe(map(user => new AuthorizationContext(user)));
    }
}
