import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ViewContextService, ViewContextSubscription} from '../../navigation/view-context.service';

@Component({
    templateUrl: './subscription-selector-page.component.html',
    styleUrls: ['./subscription-selector-page.component.scss']
})

export class SubscriptionSelectorPageComponent implements OnInit {
    availableContexts: ViewContextSubscription[];
    filteredContexts: ViewContextSubscription[];
    contextFilterText: string = '';
    selectedSubscription?: ViewContextSubscription;

    constructor(
        private readonly router: Router,
        private readonly viewContextService: ViewContextService
    ) {
    }

    ngOnInit(): void {
        this.viewContextService.availableContexts$.subscribe(contexts => {
            this.availableContexts = [...contexts.filter(x => x.type === 'subscription')] as ViewContextSubscription[];
            this.updateFilter();
        });
    }

    updateFilter() {
        let filterText = this.contextFilterText.toLowerCase();
        this.filteredContexts = this.availableContexts.filter(c => {
            if (!filterText) {
                return true;
            }
            return c.subscriptionId.toLowerCase().includes(filterText) || c.subscriptionLabel.toLowerCase().includes(filterText);
        });
    }

    selectSubscription(subscription: ViewContextSubscription) {
        if (!subscription) {
            return;
        }
        const redirectSubPath = localStorage.getItem('routeRedirectAfterSubscriptionSelect')
            ? "/" + localStorage.getItem('routeRedirectAfterSubscriptionSelect')
            : '';
        localStorage.removeItem('routeRedirectAfterSubscriptionSelect');
        this.router.navigateByUrl(`subscriptions/${encodeURIComponent(subscription.subscriptionId)}${redirectSubPath}`);
    }

    updateSelectedSubscription(subscriptionId: string) {
        this.selectedSubscription = this.availableContexts.find(x => x.subscriptionId == subscriptionId);
    }
}
